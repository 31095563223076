import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { Navigate } from 'react-router';

import { connect } from 'react-redux';

import * as Sentry from '@sentry/nextjs';

import MagicLoading from 'Apps/Main/Scenes/MagicLoading';
import MagicLoadingError from 'Apps/Main/Scenes/MagicLoadingError';

import * as Auth from 'Services/Auth';
import * as AuthService from 'Services/AuthService';
import { isFetchError, isInvalidAuthToken } from 'Services/HTTPError';

import { productsCategories } from 'constants/products';

import useSearchParams from 'utils/useQueryParams';
import { forwardUTMIfNeeded, toRelativeURL } from 'utils/utm';

import * as authActions from 'dux/auth/actions';
import * as giftingActions from 'dux/gift/actions';
import * as userActions from 'dux/user/actions';
import * as userSelectors from 'dux/user/selectors';

const MagicAuth = ({
  setSelectedGift,
  redirectToBlog,
  fetchUser,
  authSuccess,
  authRequest,
  authError,
  hasCompletedHaircareConsultation,
  haveOrders,
}) => {
  const [status, setStatus] = useState({ isLoading: true, isAuthenticated: false });
  const [error, setError] = useState(false);
  const params = useSearchParams();

  const [token, setToken] = useState(params.get('token'));
  const nextUrl = useMemo(() => {
    const redirectionUrl = params.get('next');
    const fromParam = params.get('from');
    const coupon = params.get('coupon');
    const couponSource = params.get('couponSource');

    if (coupon) {
      const couponParams = new URLSearchParams();

      couponParams.append('referralCode', coupon);
      if (couponSource) {
        couponParams.append('utm_source', couponSource);
      }
      if (redirectionUrl) {
        couponParams.append('next', redirectionUrl);
      }

      // delegate coupon setup
      return `/redeem?${couponParams}`;
    }
    if (redirectionUrl) {
      return redirectionUrl;
    }

    if (fromParam === productsCategories.OTHERS) {
      return hasCompletedHaircareConsultation
        ? '/checkout/haircare'
        : '/signin-accessories-complete';
    }

    if (haveOrders) {
      return '/account';
    }
    if (hasCompletedHaircareConsultation) {
      return '/account/history';
    }
    return '/consultation';
  }, [
    hasCompletedHaircareConsultation,
    haveOrders,
    params.get('coupon'),
    params.get('couponSource'),
  ]);

  useEffect(() => {
    let didCleanup = false;
    const selectedGift = params.get('selected-gift');

    if (selectedGift) {
      setSelectedGift(selectedGift);
    }
    async function magicAuth() {
      if (!token) {
        return;
      }

      try {
        authRequest();

        await AuthService.verifySigninToken(token);
        await fetchUser();

        authSuccess();

        if (!didCleanup) {
          setStatus({
            isLoading: false,
            isAuthenticated: true,
          });
        }
      } catch (err) {
        const isInvalidToken = isInvalidAuthToken(err);

        if (!isFetchError(err) && !isInvalidToken) {
          Sentry.captureMessage('Failed to MagicAuth');
        }

        // This will help detect unescaped next params on magic auth URLs
        if (token.indexOf('/') !== -1) {
          Sentry.withScope(scope => {
            scope.setExtra('token', token);
            Sentry.captureMessage('Token contains invalid character');
          });
        }

        if (isInvalidToken) {
          Auth.clearTokens();
        }

        authError();
        if (!didCleanup) {
          setStatus(previousStatus => ({
            ...previousStatus,
            isLoading: false,
          }));

          setError(true);
          setToken(isInvalidToken ? null : token);
        }
      }
    }
    // authenticate user
    magicAuth();

    return () => {
      didCleanup = true;
    };
  }, [params.get('selected-gift'), token]);

  useEffect(() => {
    // generates a side-effect to redirect to the blog server
    if (status.isAuthenticated && /^\/blog/.test(nextUrl)) {
      redirectToBlog(nextUrl);
    }
  }, [status.isAuthenticated, nextUrl]);

  if (!token) {
    return <Navigate replace to={toRelativeURL(forwardUTMIfNeeded(document.location, '/'))} />;
  }

  if (error) {
    return <MagicLoadingError />;
  }

  if (status.isLoading) {
    return <MagicLoading />;
  }

  if (status.isAuthenticated) {
    // if blog, handle the redirection as a side-effect in the componentDidUpdate method
    return /^\/blog/.test(nextUrl) ? null : (
      <Navigate replace to={toRelativeURL(forwardUTMIfNeeded(document.location, nextUrl))} />
    );
  }

  // if failed authentication, go to home
  return <Navigate replace to={toRelativeURL(forwardUTMIfNeeded(document.location, '/'))} />;
};

MagicAuth.defaultProps = {
  redirectToBlog: path => window.location.replace(new URL(path, 'https://prose.com')),
  hasCompletedHaircareConsultation: false,
};

MagicAuth.propTypes = {
  authError: PropTypes.func.isRequired,
  authRequest: PropTypes.func.isRequired,
  authSuccess: PropTypes.func.isRequired,
  fetchUser: PropTypes.func.isRequired,
  hasCompletedHaircareConsultation: PropTypes.bool,
  haveOrders: PropTypes.bool.isRequired,
  redirectToBlog: PropTypes.func,
  setSelectedGift: PropTypes.func.isRequired,
};

export default connect(
  state => ({
    hasCompletedHaircareConsultation: userSelectors.getHasCompletedHaircareConsultation(state),
    haveOrders: userSelectors.getHaveOrders(state),
  }),
  {
    authError: authActions.authError,
    authRequest: authActions.authRequest,
    authSuccess: authActions.authSuccess,
    fetchUser: userActions.fetchUser,
    setSelectedGift: giftingActions.setSelectedGift,
  }
)(MagicAuth);
