import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { matchPath, Outlet, useLocation } from 'react-router';

import { useAppDispatch as useDispatch, useAppSelector } from 'dux/app/hooks';

import { EmailCapturePromotionModalWithStateLogic } from 'Components/EmailCapturePromotionModal';
import { SkincareModalModalWithStateLogic } from 'Components/SkincareMinisModal';
import { TrialOfferMixedWithSkincareMinisModalWithStateLogic } from 'Components/TrialOfferMixedWithSkincareMinisModal';
import { TrialOfferPromotionModalWithStateLogic } from 'Components/TrialOfferPromotionModal';

import { useHomepageEmailCapturePopUpAbTest } from 'abTesting/homepageEmailCapturePopUp/hook';
import { usePostPurchasePopupAbTest } from 'abTesting/postPurchasePopup/hook';

import { seenPromotionalModal } from 'dux/user/actions';
import { getIsNotAuthenticated } from 'dux/auth/selectors';
import { shouldShowTrialOffer } from 'dux/featureFlags/selectors';
import { getShowSkincareStarterSetPromo } from 'dux/promotions/selectors';
import {
  getHasHaircareSubscriptionInAnyState,
  getHasSkincareSubscriptionInAnyState,
} from 'dux/user/selectors';
import * as flagVariants from 'dux/featureFlags/constants/flagVariants';

import {
  skincareMinisDisplayLogic,
  trialOfferDisplayLogic,
  trialOfferMixedWithSkincareMinisDisplayLogic,
} from './offerDisplayLogic';

const storage = {
  local: 'localStorage',
  session: 'sessionStorage',
  none: 'none',
};

const sharedExcludedPagesPath = [
  '/about',
  '/accessibility',
  '/account/*',
  '/careers/*',
  '/checkout/*',
  '/consultation/*',
  '/contact',
  '/faq/*',
  '/feedback/*',
  '/gift/*',
  '/health-privacy-notice',
  '/ingredients/*',
  '/pages/stylist-content-call',
  '/privacy',
  '/reviews/*',
  '/signin/*',
  '/sitemap',
  '/sitemap',
  '/sustainability-and-social-impact',
  '/terms',
  '/the-salon/*',
];

/**
 * Component for remembering whether or not its children has been presented to a customer
 *
 * @param {object} props - Persist props
 * @param {ReactNode} props.children - children of the component
 * @param {string} props.persistKey - key of the value to persist
 * @param {'local'|'session'} props.persistStorage - location where to persist the value
 */
const Persist = ({ children, persistKey, persistStorage }) => {
  const dispatch = useDispatch();
  const storageType = storage[persistStorage];
  const hasSeenModal = window?.[storageType]?.getItem(persistKey);

  useEffect(() => {
    if (storageType !== 'none') {
      window?.[storageType]?.setItem(persistKey, true);
      dispatch(seenPromotionalModal(persistKey));
    }
  }, [storageType, persistKey, dispatch]);

  if (hasSeenModal) return null;

  return children;
};
Persist.propTypes = {
  children: PropTypes.node.isRequired,
  persistKey: PropTypes.string.isRequired,
  persistStorage: PropTypes.string.isRequired,
};

/**
 * Component for delaying the display of it's children
 *
 * @param {object} props - Delay props
 * @param {ReactNode} props.children - children of the component
 * @param {number} [props.delay=2000] - delay's duration
 */
const Delay = ({ children, delay }) => {
  const [showChildren, setShowChildren] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowChildren(true);
    }, delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [delay]);

  if (!showChildren) return null;

  return children;
};
Delay.defaultProps = {
  delay: 2000,
};
Delay.propTypes = {
  /** Delay's children */
  children: PropTypes.node.isRequired,
  /** Delay's duration */
  delay: PropTypes.number,
};

/**
 * @typedef {ModalConfig}
 * @property {() => boolean} shouldShow - arbitrary conditions to display the modal
 * @property {() => JSX.Element} render - what modal to render
 * @property {() => string[]} [includedPaths] - path list where the modal is allowed to be rendered
 * @property {() => string[]} [excludedPaths] - path list where the modal is disallowed to be rendered
 * @property {() => string[]} [exceptionPaths] - path list where the modal is allowed to be rendered despite wildcards in excludedPaths
 * @property {number} delay - duration before showing the modal
 * @property {object} persistence - persistence configuration
 * @property {'local'|'session'} persistence.storage - where to store the info the modal has been seen
 * @property {string} persistence.key - key to retrieve the infor the modal has been seen
 */

/**
 * Modals and their conditions
 *
 * @type Record<string, ModalConfig>
 */
const Modals = {
  trialOfferMixedWithSkincareMinis: {
    shouldShow: ({
      pathname,
      showTrialOffer,
      showSkincareStarterSetPromo,
      hasHaircareSubscriptionInAnyState,
      hasSkincareSubscriptionInAnyState,
    }) =>
      trialOfferMixedWithSkincareMinisDisplayLogic({
        pathname,
        showTrialOffer,
        showSkincareStarterSetPromo,
        hasHaircareSubscriptionInAnyState,
        hasSkincareSubscriptionInAnyState,
      }),
    excludedPaths: () => sharedExcludedPagesPath,
    exceptionPaths: () => [
      '/consultation/haircare/my-hair/age',
      '/consultation/skincare/my-lifestyle/age',
    ],
    render: () => <TrialOfferMixedWithSkincareMinisModalWithStateLogic isOpen />,
    delay: 3000,
    persistence: {
      storage: 'session',
      key: 'seenOfferModal',
    },
  },
  trialOffer: {
    shouldShow: ({ showTrialOffer }) =>
      trialOfferDisplayLogic({
        showTrialOffer,
      }),
    excludedPaths: () => sharedExcludedPagesPath,
    exceptionPaths: () => [
      '/consultation/haircare/my-hair/age',
      '/consultation/skincare/my-lifestyle/age',
    ],
    render: () => <TrialOfferPromotionModalWithStateLogic isOpen />,
    delay: 3000,
    persistence: {
      storage: 'session',
      key: 'seenOfferModal',
    },
  },
  homepageEmailCapture: {
    shouldShow: ({ showTrialOffer, homepageEmailCaptureVariant, isNotAuthenticated }) =>
      showTrialOffer && isNotAuthenticated && homepageEmailCaptureVariant === flagVariants.VARIANT1,
    includedPaths: () => ['/'],
    render: () => <EmailCapturePromotionModalWithStateLogic isOpen />,
    delay: 3000,
    persistence: {
      storage: 'session',
      key: 'seenOfferModal',
    },
  },
  skincareMinis: {
    shouldShow: ({
      showSkincareStarterSetPromo,
      hasHaircareSubscriptionInAnyState,
      pathname,
      hasSkincareSubscriptionInAnyState,
    }) =>
      skincareMinisDisplayLogic({
        showSkincareStarterSetPromo,
        hasHaircareSubscriptionInAnyState,
        pathname,
        hasSkincareSubscriptionInAnyState,
      }),
    excludedPaths: () => sharedExcludedPagesPath,
    render: () => <SkincareModalModalWithStateLogic isOpen />,
    delay: 3000,
    persistence: {
      storage: 'session',
      key: 'seenOfferModal',
    },
  },
  trialOfferPostPurchase: {
    shouldShow: ({ showTrialOffer, isPopupHiddenByAbTest }) => {
      return showTrialOffer && !isPopupHiddenByAbTest;
    },
    includedPaths: () => ['/checkout/success'],
    render: () => (
      <TrialOfferPromotionModalWithStateLogic displayAs="trialOfferPostPurchase" isOpen />
    ),
    delay: 3000,
    persistence: {
      storage: 'none',
      key: 'seenTrialOfferPostPurchaseModal',
    },
  },
  skincareMinisPostPurchase: {
    shouldShow: ({
      showSkincareStarterSetPromo,
      hasSkincareSubscriptionInAnyState,
      isPopupHiddenByAbTest,
    }) =>
      showSkincareStarterSetPromo && !hasSkincareSubscriptionInAnyState && !isPopupHiddenByAbTest,
    includedPaths: () => ['/checkout/success'],
    render: () => <SkincareModalModalWithStateLogic isOpen />,
    delay: 3000,
    persistence: {
      storage: 'none',
      key: 'seenOfferModal',
    },
  },
};

/**
 * List of the modals by order of priority
 *
 * @type ModalConfig[]
 * */
const priorityList = [
  Modals.trialOfferMixedWithSkincareMinis,
  Modals.skincareMinisPostPurchase,
  Modals.skincareMinis,
  Modals.trialOfferPostPurchase,
  Modals.homepageEmailCapture,
  Modals.trialOffer,
];

const useShowCriterias = () => {
  const { pathname } = useLocation();
  const showTrialOffer = useAppSelector(shouldShowTrialOffer);
  const hasSkincareSubscriptionInAnyState = useAppSelector(getHasSkincareSubscriptionInAnyState);
  const hasHaircareSubscriptionInAnyState = useAppSelector(getHasHaircareSubscriptionInAnyState);
  const isNotAuthenticated = useAppSelector(getIsNotAuthenticated);
  const showSkincareStarterSetPromo = useAppSelector(getShowSkincareStarterSetPromo);
  const { variantName: homepageEmailCaptureVariant } = useHomepageEmailCapturePopUpAbTest();
  const { variantName: postPurchasePopupVariant } = usePostPurchasePopupAbTest({
    isTrackingOnMount: true,
  });
  const isPopupHiddenByAbTest = postPurchasePopupVariant === flagVariants.VARIANT1;

  return {
    pathname,
    showTrialOffer,
    hasSkincareSubscriptionInAnyState,
    showSkincareStarterSetPromo,
    hasHaircareSubscriptionInAnyState,
    postPurchasePopupVariant,
    isPopupHiddenByAbTest,
    homepageEmailCaptureVariant,
    isNotAuthenticated,
  };
};

const PromotionalLayout = () => {
  const showCriterias = useShowCriterias();
  const { pathname } = useLocation();

  const isNotAuthenticated = useAppSelector(getIsNotAuthenticated);
  const { onChoice, variantName, flagsDoneFetching } = useHomepageEmailCapturePopUpAbTest();

  useEffect(() => {
    if (isNotAuthenticated && flagsDoneFetching) {
      // This setTimeout should not be used, but this hook sometimes trigger when window.heap is not even on the window object
      setTimeout(() => {
        onChoice();
      }, 3000);
    }
  }, [isNotAuthenticated, variantName, flagsDoneFetching]);

  const foundModal = priorityList.find(
    ({
      shouldShow,
      includedPaths = () => ['*'],
      excludedPaths = () => [],
      exceptionPaths = () => [],
    }) => {
      const isIncluded = includedPaths().some(pattern => Boolean(matchPath(pattern, pathname)));
      const isExcluded = excludedPaths().some(pattern => Boolean(matchPath(pattern, pathname)));
      const isException = exceptionPaths().some(pattern => Boolean(matchPath(pattern, pathname)));

      return (
        (isIncluded && !isExcluded && shouldShow(showCriterias)) ||
        (isException && shouldShow(showCriterias))
      );
    }
  );

  if (!foundModal) {
    return <Outlet />;
  }

  return (
    <>
      <Delay delay={foundModal?.delay}>
        <Persist
          persistKey={foundModal?.persistence?.key}
          persistStorage={foundModal?.persistence?.storage}
        >
          {foundModal?.render()}
        </Persist>
      </Delay>
      <Outlet />
    </>
  );
};

export default PromotionalLayout;
