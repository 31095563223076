import PropTypes from 'prop-types';

import { FastField, getIn } from 'formik';

import { TextField } from 'Components/TextField';

import { formatZipcodeByCountryCode } from 'utils/textUtils';

// TextField component wrapped with formik context for payment forms
const PaymentTextField = ({ name, label, testId, ariaRequired, ...props }) => (
  <FastField name={name}>
    {({ field, form }) => {
      const isInputTouched = getIn(form.touched, name);
      const inputError = isInputTouched ? getIn(form.errors, name) : '';

      const onChange = event => {
        if (field.name === 'newAddress.zipcode') {
          form.setFieldValue(
            field.name,
            formatZipcodeByCountryCode(
              { value: event.target.value, previousValue: field.value },
              form.values?.newAddress?.country
            )
          );
        } else {
          field.onChange(event);
        }
      };

      return (
        <TextField
          {...field}
          aria-invalid={inputError ? 'true' : 'false'}
          aria-required={ariaRequired ? 'true' : 'false'}
          data-testid={testId}
          error={inputError && `${label} ${inputError.toLowerCase()}`}
          id={name}
          label={label}
          onChange={onChange}
          {...props}
        />
      );
    }}
  </FastField>
);

PaymentTextField.propTypes = {
  // Metadata for screen readers
  ariaRequired: PropTypes.bool,
  // Label to describe the input
  label: PropTypes.string.isRequired,
  // Name can be a nested path like newAddress.address1
  name: PropTypes.string.isRequired,
  // Test id for cypress
  testId: PropTypes.string,
};

PaymentTextField.defaultProps = {
  ariaRequired: false,
  testId: null,
};

export default PaymentTextField;
