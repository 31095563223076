import { publicReview } from 'Apps/Feedback/constants/feedbackSlugs';

const publicReviewScreen = {
  name: publicReview,
  shouldBeIncluded: () => true,
  Component: 'PublicReview',
  title: 'Would you like to leave a review?',
  subtitle: 'We may post your review publicly to share your experience with the Prose community.',
  getInitialValue: answers => ({
    open_text_public: answers?.open_text_public,
    open_text_internal: answers?.open_text_internal,
  }),
  getAnswers: ({ selected }) => ({
    open_text_public: selected?.open_text_public,
    open_text_internal: selected?.open_text_internal,
  }),
};
export default publicReviewScreen;
