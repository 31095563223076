import { matchPath } from 'react-router';

import { haircareRoutes, skincareRoutes } from 'constants/routes';

type LogicDisplayProps = {
  pathname?: string;
  showTrialOffer?: boolean;
  showSkincareStarterSetPromo?: boolean;
  hasHaircareSubscriptionInAnyState?: boolean;
  hasSkincareSubscriptionInAnyState?: boolean;
  hasCompletedHaircareConsultation?: boolean;
  hasCompletedSkincareConsultation?: boolean;
  isPopupHiddenByAbTest?: boolean;
};

export const trialOfferMixedWithSkincareMinisDisplayLogic = ({
  pathname,
  showTrialOffer,
  showSkincareStarterSetPromo,
  hasHaircareSubscriptionInAnyState,
  hasSkincareSubscriptionInAnyState,
}: LogicDisplayProps) =>
  // We only want to display haircare trial offer on homepage
  pathname !== '/' &&
  !haircareRoutes.some((url) => matchPath(url, pathname!)) &&
  !skincareRoutes.some((url) => matchPath(url, pathname!)) &&
  showTrialOffer &&
  showSkincareStarterSetPromo &&
  !hasHaircareSubscriptionInAnyState &&
  !hasSkincareSubscriptionInAnyState;

export const trialOfferDisplayLogic = ({ showTrialOffer }: LogicDisplayProps) => {
  return showTrialOffer;
};

export const skincareMinisDisplayLogic = ({
  showSkincareStarterSetPromo,
  pathname,
  hasHaircareSubscriptionInAnyState,
  hasSkincareSubscriptionInAnyState,
}: LogicDisplayProps) => {
  const isHaircareRoute = haircareRoutes.some((url) => matchPath(url, pathname!));

  return (
    // We only want to display haircare trial offer on homepage
    pathname !== '/' &&
    ((isHaircareRoute &&
      showSkincareStarterSetPromo && // Promotion for skincare starter set is active
      hasHaircareSubscriptionInAnyState && // User has an active haircare subscription
      !hasSkincareSubscriptionInAnyState) || // User does not have an active skincare subscription
      (!isHaircareRoute && showSkincareStarterSetPromo)) // If not a haircare route, return true only if the promotion is active
  );
};
