import PropTypes from 'prop-types';
import { forbidExtraProps } from 'airbnb-prop-types';

import { connect } from 'react-redux';

import * as featureFlagsSelector from 'dux/featureFlags/selectors';
import { getShowSkincareStarterSetPromo } from 'dux/promotions/selectors';
import * as userSelectors from 'dux/user/selectors';

import * as Banners from './Banners';
import { dataDrivenBanner } from './DDBanners';

const bannersByPriority = [
  Banners.loading, // Loading needs to be first
  dataDrivenBanner,
  Banners.checkoutMessage,
  Banners.trialOfferMixedSkincareMinisBanner,
  Banners.skincareMinisBanner,
  Banners.trialOfferBanner,
  Banners.referralCta,
  Banners.membershipMultiCategoryWithOneSubscription,
  Banners.membershipMultiCategoryWithoutSubscription,
  Banners.membershipCta,
  Banners.noFreeShippingContent,
  Banners.defaultBanner, // Default needs to be last
];

const Banner = ({
  isFlagsDoneFetching,
  hasEitherHairOrSkinSubscription,
  hasHaircareSubscription,
  hasSkincareSubscription,
  hasHairAndSkinSubscriptions,
  hasCompletedHaircareConsultation,
  hasCompletedSkincareConsultation,
  hasStartedHaircareConsultation,
  hasStartedSkincareConsultation,
  showTrialOffer,
  showSkincareStarterSetPromo,
  hasSkincareSubscriptionInAnyState,
  hasHaircareSubscriptionInAnyState,
  pathname: locationPathname,
  searchParams: locationSearchParams,
  showDataDrivenPromotions,
}) => {
  const foundBanner = bannersByPriority.find(banner =>
    banner.shouldRender({
      isFlagsDoneFetching,
      locationPathname,
      locationSearchParams,
      hasEitherHairOrSkinSubscription,
      hasHairAndSkinSubscriptions,
      hasCompletedHaircareConsultation,
      hasCompletedSkincareConsultation,
      hasStartedHaircareConsultation,
      hasStartedSkincareConsultation,
      showTrialOffer,
      showSkincareStarterSetPromo,
      hasSkincareSubscriptionInAnyState,
      hasHaircareSubscriptionInAnyState,
      showDataDrivenPromotions,
    })
  );

  if (foundBanner) {
    return foundBanner.render({
      hasHaircareSubscription,
      hasSkincareSubscription,
      hasCompletedHaircareConsultation,
      hasCompletedSkincareConsultation,
    });
  }
  return null;
};

Banner.propTypes = forbidExtraProps({
  showSkincareStarterSetPromo: PropTypes.bool,
  hasSkincareSubscriptionInAnyState: PropTypes.bool,
  hasHaircareSubscriptionInAnyState: PropTypes.bool,
  isFlagsDoneFetching: PropTypes.bool,
  hasEitherHairOrSkinSubscription: PropTypes.bool,
  hasHaircareSubscription: PropTypes.bool,
  hasSkincareSubscription: PropTypes.bool,
  hasHairAndSkinSubscriptions: PropTypes.bool,
  hasCompletedHaircareConsultation: PropTypes.bool,
  hasCompletedSkincareConsultation: PropTypes.bool,
  hasStartedHaircareConsultation: PropTypes.bool,
  hasStartedSkincareConsultation: PropTypes.bool,
  showTrialOffer: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  pathname: PropTypes.string.isRequired,
  searchParams: PropTypes.string.isRequired,
  showDataDrivenPromotions: PropTypes.bool,
});

Banner.defaultProps = {
  isFlagsDoneFetching: false,
  hasEitherHairOrSkinSubscription: false,
  hasHaircareSubscription: false,
  hasSkincareSubscription: false,
  hasHairAndSkinSubscriptions: false,
  hasCompletedHaircareConsultation: false,
  hasCompletedSkincareConsultation: false,
  hasStartedHaircareConsultation: false,
  hasStartedSkincareConsultation: false,
  showTrialOffer: false,
  showSkincareStarterSetPromo: false,
  hasSkincareSubscriptionInAnyState: false,
  hasHaircareSubscriptionInAnyState: false,
  showDataDrivenPromotions: false,
};

export default connect(
  state => ({
    isFlagsDoneFetching: featureFlagsSelector.isFlagsDoneFetching(state),
    hasEitherHairOrSkinSubscription: userSelectors.getHasEitherHairOrSkinSubscription(state),
    hasSkincareOrder: userSelectors.getHasOrdersByCategory(state).skincare,
    hasHaircareSubscription: userSelectors.getHasActiveHaircareSubscription(state),
    hasSkincareSubscription: userSelectors.getHasActiveSkincareSubscription(state),
    hasHairAndSkinSubscriptions: userSelectors.getHasHairAndSkinSubscriptions(state),
    hasCompletedHaircareConsultation: userSelectors.getHasCompletedHaircareConsultation(state),
    hasCompletedSkincareConsultation: userSelectors.getHasCompletedSkincareConsultation(state),
    hasStartedHaircareConsultation: userSelectors.getHasDraftHaircareConsultation(state),
    hasStartedSkincareConsultation: userSelectors.getHasDraftSkincareConsultation(state),
    showTrialOffer: featureFlagsSelector.shouldShowTrialOffer(state),
    showSkincareStarterSetPromo: getShowSkincareStarterSetPromo(state),
    hasSkincareSubscriptionInAnyState: userSelectors.getHasSkincareSubscriptionInAnyState(state),
    hasHaircareSubscriptionInAnyState: userSelectors.getHasHaircareSubscriptionInAnyState(state),
    showDataDrivenPromotions: featureFlagsSelector.shouldShowDataDrivenPromotions(state),
  }),
  {}
)(Banner);
