import { createAsyncThunk } from '@reduxjs/toolkit';

import find from 'lodash/fp/find';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';

import * as Sentry from '@sentry/nextjs';
import dayjs from 'dayjs';

import { authClient } from 'Services/AuthClient';
import { isNotAuthenticated } from 'Services/HTTPError';
import * as SubscriptionsService from 'Services/SubscriptionsService';

import { subscriptionCategories } from 'constants/subscriptions';

import { oops } from 'assets/content/error';

import logSentryError from 'utils/logSentry';

import {
  trackSubscriptionCancelled,
  trackSubscriptionSnoozed,
  trackSubscriptionStartedFromAccount,
  trackSubscriptionUpdated,
  trackUserProfile,
} from 'dux/tracking/actions';
import * as subscriptionsSelectors from 'dux/subscriptions/selectors';
import { getHasOpenedLivechat } from 'dux/support/selectors';
import {
  fetchFragrances as fetchConsultationFragrances,
  fetchSkincareFragrances,
} from 'dux/consultation/thunks';

// exported for the slice
export const cancel = createAsyncThunk(
  'subscriptions/cancel',
  async ({ pubkey, payload }, { dispatch, getState }) => {
    const state = getState();

    try {
      const result = await SubscriptionsService.cancelSubscription(pubkey, payload);
      dispatch(
        trackSubscriptionCancelled({
          category: result?.category,
          sub_category: result?.sub_category,
          has_clicked_chat_widget: getHasOpenedLivechat(state),
        })
      );
      return result;
    } catch (error) {
      Sentry.withScope(scope => {
        scope.setLevel('info');
        Sentry.captureMessage(`[Cancel subscription] ${error?.toString()}`);
      });

      throw error;
    }
  }
);

export const cancelSubscription =
  ({ category, payload, subscriptionPubkey }) =>
  async dispatch => {
    let nextRoute;

    const result = await dispatch(cancel({ pubkey: subscriptionPubkey, payload })).unwrap();
    if (result) {
      nextRoute = `/account/subscription/home?status=canceled&category=${category}`;
    }

    return { nextRoute, options: {} };
  };

// exported for the slice
export const create = createAsyncThunk(
  'subscriptions/create',
  async (data, { dispatch, getState }) => {
    const state = getState();

    try {
      const result = await SubscriptionsService.createSubscription(data);
      dispatch(
        trackSubscriptionStartedFromAccount({
          categories: [result.category],
          has_clicked_chat_widget: getHasOpenedLivechat(state),
        })
      );
      dispatch(trackUserProfile());

      return result;
    } catch (error) {
      Sentry.withScope(scope => {
        scope.setLevel('info');
        Sentry.captureMessage(`[Create subscription] ${error?.toString()}`);
      });
      throw error;
    }
  }
);

// used within user reducer
export const { type: CREATE_SUBSCRIPTION_SUCCESS } = create.fulfilled;

export const createSubscription = (data, category) => async dispatch => {
  let nextRoute;
  const result = await dispatch(create(data)).unwrap();

  if (result) {
    /**
     * Redirect by default
     * In some cases like Resubscribe we won't use this url
     */
    nextRoute = `/account/subscription/home?status=created&category=${category}`;
  }

  return { nextRoute, options: {} };
};

/**
 * To refresh the subscription ticket (order summary) in real-time
 * without submitting the form
 * We notify the API of the preview/draft mode which returns the updated ticket
 */
export const createSubscriptionPreview = createAsyncThunk('subscription/preview', async data => {
  try {
    const result = await SubscriptionsService.createSubscriptionPreview(data);

    return {
      ticket: {
        ...result.ticket,
        category: data.category,
        sub_category: data?.sub_category,
        gwps: result.gwps,
      },
      forecast: result.forecast,
    };
  } catch (error) {
    logSentryError('[dux/subscriptions] createSubscriptionPreview', error);

    throw error;
  }
});

/**
 * Same as createSubscriptionPreview but for people with a subscription.
 * Enable to take into account the proper subscription discount amount.
 */
export const createPreviewByPubkey = createAsyncThunk(
  'subscription/previewByPubkey',
  async ({ pubkey, data }) => {
    try {
      const result = await SubscriptionsService.createSubscriptionPreviewByPubkey(pubkey, data);

      return {
        ticket: {
          ...result.ticket,
          category: data.category,
          sub_category: data?.sub_category,
          gwps: result.gwps,
        },
        forecast: result.forecast,
      };
    } catch (error) {
      logSentryError('[dux/subscriptions] createPreviewByPubkey', error);

      throw error;
    }
  }
);

export const createSubscriptionPreviewByPubkey = (pubkey, data) => async dispatch => {
  dispatch(createPreviewByPubkey({ pubkey, data }));
};

export const fetchFragrances = category => async dispatch => {
  if (category === subscriptionCategories.HAIRCARE) {
    await dispatch(fetchConsultationFragrances());
  }

  if (category === subscriptionCategories.SKINCARE) {
    await dispatch(fetchSkincareFragrances());
  }
};

export const fetchSubscriptionForecast = createAsyncThunk(
  'subscriptions/forecast',
  async (/** @type {string} */ category, { getState }) => {
    const state = getState();
    const subscription = subscriptionsSelectors.getEditableSubscriptionByCategory(state, {
      category,
    });
    try {
      const result = await SubscriptionsService.fetchForecast(subscription?.pubkey);
      return result;
    } catch (error) {
      Sentry.captureMessage('API action (Subscriptions.fetchForecast)');

      throw error;
    }
  },
  {
    condition(category, { getState }) {
      const state = getState();
      const subscription = subscriptionsSelectors.getEditableSubscriptionByCategory(state, {
        category,
      });

      return Boolean(subscription);
    },
    dispatchConditionRejection: true,
  }
);

export const fetchSubscriptionLogReasons = createAsyncThunk('subcriptions/logReasons', async () => {
  try {
    const result = await SubscriptionsService.fetchSubscriptionLogReasons();

    return result;
  } catch (error) {
    Sentry.captureMessage('API action (Subscriptions.fetchSubscriptionLogReasons)');

    throw error;
  }
});

/**
 * @typedef {import('dux/subscriptions/types').Subscription} Subscription
 */
export const fetchSubscriptions = createAsyncThunk('subscriptions/fetch', async () => {
  try {
    const /** @type {Array<Subscription>} */ result =
        await SubscriptionsService.fetchSubscriptions();
    return result;
  } catch (error) {
    Sentry.captureMessage('API action (Subscriptions.fetchSubscriptions)');

    throw error;
  }
});

export const snooze = createAsyncThunk(
  'subscriptions/snooze',
  async ({ snoozeData, category }, { dispatch, getState, rejectWithValue }) => {
    const state = getState();

    try {
      const subscription = subscriptionsSelectors.getEditableSubscriptionByCategory(state, {
        category,
      });
      const startBeforeSnooze = subscription?.next_start;
      const pubkey = subscription?.pubkey;
      const days = snoozeData?.[0]?.value;

      const result = await SubscriptionsService.snoozeSubscription(pubkey, days);

      const subscriptionProducts = subscriptionsSelectors.getSubscribedProductsNames(state, {
        category,
      });
      const reason = snoozeData?.[1]?.reason;
      dispatch(
        trackSubscriptionSnoozed({
          has_clicked_chat_widget: getHasOpenedLivechat(state),
          new_charge_date: result?.next_start,
          previous_charge_date: startBeforeSnooze,
          product_category: subscription?.category,
          snooze_frequency_weeks: days / 7,
          snooze_reason: reason,
          subscription_products: subscriptionProducts,
          subscription_category: subscription?.category,
          subscription_sub_category: subscription?.sub_category,
        })
      );

      return result;
    } catch (error) {
      Sentry.withScope(scope => {
        scope.setLevel('info');
      });
      Sentry.captureMessage(`[Snooze subscription] ${error.toString()}`);
      // If user loses its auth token, redirect to error page to avoid being stuck in Snooze modal
      if (error.name === 'AuthError' || isNotAuthenticated(error)) {
        // throw error;
        return rejectWithValue('Not authenticated');
      }
      throw new Error(oops);
    }
  },
  {
    condition({ snoozeData, category }, { getState }) {
      const state = getState();
      const subscription = subscriptionsSelectors.getEditableSubscriptionByCategory(state, {
        category,
      });

      if (!subscription) {
        throw new Error('Missing subscription to snooze');
      }

      const days = snoozeData?.[0]?.value;
      // Limit snoozing for 90 days
      const nextStart = subscription?.next_start;
      const snoozedDate = nextStart && days && dayjs(nextStart).add(days, 'days');
      const maxSnoozeDate = dayjs().add(90, 'days');

      if (snoozedDate && maxSnoozeDate?.isBefore(snoozedDate)) {
        throw new Error('Cannot snooze beyond 90 days');
      }
    },
    dispatchConditionRejection: true,
  }
);

export const snoozeSubscription = (snoozeData, category) => async dispatch => {
  let nextRoute;
  const result = await dispatch(snooze({ snoozeData, category })).unwrap();
  if (result) {
    nextRoute = `/account/subscription/home?status=snoozed&category=${category}`;
  }
  return { nextRoute, options: {} };
};

export const updateSubscription = createAsyncThunk(
  'subscriptions/update',
  async ({ pubkey, data }, { getState, dispatch }) => {
    const state = getState();

    try {
      const result = await SubscriptionsService.updateSubscription(pubkey, data);
      dispatch(trackUserProfile());

      if (data) {
        const subscriptionUpdateTrackingData =
          subscriptionsSelectors.getSubscriptionUpdateTrackingData(state, {
            updatedSubscriptionData: {
              ...data,
              sub_category: result?.sub_category,
              category: result?.category,
            },
            category: result?.category,
          });
        dispatch(
          trackSubscriptionUpdated({
            ...subscriptionUpdateTrackingData,
            has_clicked_chat_widget: getHasOpenedLivechat(state),
          })
        );
      }
      return result;
    } catch (error) {
      logSentryError(`[dux/subscriptions] updateSubscription`, error);

      throw error;
    }
  }
);
// used within user reducer
export const { type: UPDATE_SUBSCRIPTION_SUCCESS } = updateSubscription.fulfilled;

export const updateSubscriptionWithNextRoute = (pubkey, data) => async dispatch => {
  let nextRoute;
  try {
    const result = await dispatch(updateSubscription({ pubkey, data })).unwrap();
    if (result) {
      nextRoute = '/account/subscription/home?status=updated';
    }
  } catch {
    nextRoute = `/account/subscription/${data.category}?status=error`;
  }

  return { nextRoute, options: {} };
};

export const updateAllSubscriptions = payload => async (dispatch, getState) => {
  const state = getState();

  const haircareSubscription = subscriptionsSelectors.getHaircareEditableSubscriptions(state);

  if (haircareSubscription) {
    await dispatch(updateSubscriptionWithNextRoute(haircareSubscription?.pubkey, payload));
  }

  const supplementsSubscription = subscriptionsSelectors.getSupplementsEditableSubscriptions(state);

  if (supplementsSubscription) {
    await dispatch(updateSubscriptionWithNextRoute(supplementsSubscription?.pubkey, payload));
  }

  const skincareSubscription = subscriptionsSelectors.getSkincareEditableSubscriptions(state);

  if (skincareSubscription) {
    await dispatch(updateSubscriptionWithNextRoute(skincareSubscription?.pubkey, payload));
  }
};

export const fetchSubscriptionsRetentionInfos = createAsyncThunk(
  'subscriptions/retentionInfos',
  async (category, { getState }) => {
    const state = getState();
    const subscription = subscriptionsSelectors.getEditableSubscriptionByCategory(state, {
      category,
    });
    try {
      const result = await SubscriptionsService.fetchRetention(subscription?.pubkey);
      return result;
    } catch (error) {
      Sentry.captureMessage('API action (Subscription.fetchRetention)');
      throw error;
    }
  },
  {
    condition(category, { getState }) {
      const state = getState();
      const subscription = subscriptionsSelectors.getEditableSubscriptionByCategory(state, {
        category,
      });
      if (!subscription?.pubkey) {
        Sentry.captureMessage(
          'Dux action (Subscription.fetchSubscriptionsRetentionInfos: no subscription pubkey found)'
        );
        throw new Error('No subscription pubkey found');
      }
    },
    dispatchConditionRejection: true,
  }
);

export const checkBrightbackAfterChanges =
  (change, { category, oldStartDate = null, subscriptionPubkey = null }) =>
  async (dispatch, getState) => {
    await dispatch(fetchSubscriptions());

    const state = getState();

    switch (change) {
      case 'offer': {
        const subscription = subscriptionsSelectors.getLastEditableSubscriptionByUXCategory(state, {
          category,
        });

        const brightbackCoupon = flow(
          get('ticket.coupons'),
          find(coupon => coupon.origin === 'brightback')
        )(subscription);
        return Boolean(brightbackCoupon);
      }

      case 'snooze': {
        const subscription = subscriptionsSelectors.getLastEditableSubscriptionByUXCategory(state, {
          category,
        });
        const { next_start: nextStartDate } = subscription;

        return nextStartDate !== oldStartDate;
      }

      case 'cancel': {
        const subscription = subscriptionsSelectors.getLastCanceledSubscriptionByUXCategory(state, {
          category,
        });

        const isCanceled =
          get('pubkey')(subscription) === subscriptionPubkey &&
          get('status')(subscription) === 'canceled';

        return isCanceled;
      }
      default:
        return false;
    }
  };

export const createShipNowOrder = createAsyncThunk(
  'subscription/ship-now/create-order',
  async ({ pubkey, data }, thunkAPI) => {
    try {
      const { pubkey: orderPubkey } = await authClient
        .post(`v2/subscriptions/${pubkey}/shipnow/`, {
          json: data,
          // INFO: this header is specified in prepraration for the short polling integration
          headers: { Accept: 'application/json' },
        })
        .json();
      if (orderPubkey?.length > 0) {
        return { pubkey: orderPubkey };
      }
      throw new Error('orderPubkey length is 0');
    } catch (error) {
      logSentryError('[dux/subscriptions] createShipNowOrder', error);

      return thunkAPI.rejectWithValue({ code: error.code, detail: error.detail });
    }
  }
);
