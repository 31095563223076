import { useEffect, useMemo } from 'react';

import { matchPath, useLocation } from 'react-router';

import * as APIClient from 'Services/APIClient';

const triggerPageVisited = async page => {
  try {
    await APIClient.post('/v1/trigger/page_visited', { page });
  } catch {
    // ignore errors
  }
};

const TriggerPageVisited = () => {
  const { pathname } = useLocation();
  const page = useMemo(() => {
    const trackingMap = {
      '/pages/brushes': 'Brush Landing Page',
      '/pages/dry-shampoo': 'Dry Shampoo Landing Page',
      '/pages/oil': 'Oil Landing Page',
      '/pages/supplements': 'Supplements Landing Page',
      '/products/custom-dry-shampoo': 'Dry Shampoo Product Page',
      '/products/custom-shampoo': 'Shampoo Product Page',
      '/products/custom-conditioner': 'Conditioner Product Page',
      '/products/custom-hair-mask': 'Hair Mask Product Page',
      '/products/custom-scalp-mask': 'Scalp Mask Product Page',
      '/products/custom-hair-oil': 'Oil Product Page',
      '/ingredients': 'Ingredients Page',
      '/reviews': 'Reviews Page',
      '/about': 'About Us Page',
      '/checkout/haircare': 'Visited Cart',
      '/products/custom-curl-cream': 'Curl Cream Product Page',
      '/products/custom-leave-in-conditioner': 'Leave-in Conditioner Product Page',
      '/products/custom-hair-supplements': 'Supplements Product Page',
    };
    const matchedURL = Object.keys(trackingMap).find(pattern => matchPath(pattern, pathname));
    return trackingMap[matchedURL];
  }, [pathname]);

  useEffect(() => {
    if (page) {
      triggerPageVisited(page);
    }
  }, [page]);

  return null;
};

TriggerPageVisited.propTypes = {};

TriggerPageVisited.defaultProps = {};

export default TriggerPageVisited;
