import * as feedbackComponents from 'Apps/Feedback/constants/feedbackComponents';
import { SKIN } from 'Apps/Feedback/constants/feedbackSections';

import { productsCategories, productsSlugs } from 'constants/products';

import efficiencyTransitionImgDesktop from 'assets/images/reviewAndRefine/efficiency-transition_desktop.jpg';
import efficiencyTransitionImgMobile from 'assets/images/reviewAndRefine/efficiency-transition_mobile.jpg';

import { commasAndListToString } from 'utils/textUtils';

const efficiencyTransitionScreen = {
  name: 'efficiency',
  route: '/feedback/skincare/skin',
  category: SKIN,
  feedbackCategory: productsCategories.SKINCARE,
  shouldBeIncluded: () => true,
  Component: feedbackComponents.SkincareTransitionScreen,
  skipSave: true,
  title: "Next, we'll ask how your products are working",
  ctaNextText: 'Refine my formula',
  subtitle: (date, formulas) =>
    `Think about how your recent ${commasAndListToString(
      formulas
        ?.filter(f =>
          [productsSlugs.CLEANSER, productsSlugs.SERUM, productsSlugs.MOISTURIZER].includes(
            f.variant.product.type
          )
        )
        .map(f => f.variant.label)
    )} ordered on ${date} affected your skin so we can refine your formula for your next order.`,
  style: {
    mode: 'dark',
    backgroundImages: {
      desktop: efficiencyTransitionImgDesktop,
      mobile: efficiencyTransitionImgMobile,
    },
  },
};

export default efficiencyTransitionScreen;
