import { PRODUCTS } from 'Apps/Feedback/constants/feedbackSections';
import { haircareScalpMaskSatisfaction } from 'Apps/Feedback/constants/feedbackSlugs';

import { productsSlugs } from 'constants/products';

const scalpMaskSatisfaction = {
  name: haircareScalpMaskSatisfaction,
  route: `/feedback/haircare/${haircareScalpMaskSatisfaction}`,
  category: PRODUCTS,
  shouldBeIncluded: ({ formulas }) =>
    formulas?.find(f => f.variant.product.type === productsSlugs.SCALP_MASK),
  Component: 'Rating',
  title: 'How satisfied are you with your scalp mask?',
  options: [
    { value: 1, label: 'Very Unsatisfied' },
    { value: 2, label: 'Unsatisfied' },
    { value: 3, label: 'Neutral' },
    { value: 4, label: 'Satisfied' },
    { value: 5, label: 'Very Satisfied' },
  ],
  skippable: "I haven't used it enough",
  skippableValue: 0,
  hasOpenText: true,
  getInitialValue: answers => answers?.product_scalp_mask_satisfaction,
  getInitialProductFeedback: productsAnswers => {
    return productsAnswers.find(answer => answer.product === productsSlugs.SCALP_MASK)
      ?.open_text_feedback;
  },
  getAnswers: ({ selected }) => ({ product_scalp_mask_satisfaction: selected }),
  getFeedbackAnswer: ({ openText }) => ({
    product: productsSlugs.SCALP_MASK,
    open_text_feedback: openText?.feedback_text,
  }),
  multi: true,
};

export default scalpMaskSatisfaction;
