import { type ReactNode, useEffect } from 'react';

import { Link } from 'react-router';

import { useAppDispatch } from 'dux/app/hooks';

import { theme } from '@prose-ui';
import { legacyTheme, styled } from '@prose-ui/legacy';

import { handleKeyPress } from 'utils/a11y';

import { trackHeapEvent } from 'dux/tracking/actions';

const Wrapper = styled.div<{
  hasLink?: boolean;
  backgroundColor?: string;
  color?: string;
  hasBottomModal?: boolean;
}>`
  ${legacyTheme.typography.p2};

  width: 100%;
  height: ${legacyTheme.props.bannerHeight};
  z-index: ${legacyTheme.zIndex.appBar};

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  transition: background 0.3s ease;
  color: ${({ color }) => color ?? theme.colors.neutral[100]};
  background-color: ${({ backgroundColor }) => backgroundColor ?? undefined};
  cursor: ${({ hasBottomModal: hasBanner }) => (hasBanner ? 'pointer' : undefined)};
  text-decoration: ${({ hasBottomModal: hasBanner }) => (hasBanner ? 'underline' : undefined)};

  ${legacyTheme.breakpoints.match('md')} {
    justify-content: ${({ hasLink }) => (hasLink ? 'space-between' : 'center')};
    padding-left: ${({ hasLink }) => (hasLink ? '20px' : 'inherit')};
  }

  ${legacyTheme.breakpoints.match('sm')} {
    justify-content: center;
    padding-left: inherit;
  }

  & em {
    font-style: normal;
    text-decoration: underline;
  }
`;

let lastEvent: string | undefined;

export const BaseBanner = ({
  children,
  hasLink,
  backgroundColor,
  color,
  buttonProps,
  dataTestId = 'banner',
  event,
}: {
  children?: ReactNode;
  hasLink?: boolean;
  backgroundColor?: string;
  color?: string;
  dataTestId?: string;
  event?: string;
  buttonProps?: {
    onClick?: () => void;
    dataClick?: string;
    dataFrom?: string;
    ariaLabel?: string;
  };
}) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (lastEvent !== event) {
      dispatch(trackHeapEvent('Banner Displayed - Non-Data Driven', { bannerType: event }));
      lastEvent = event;
    }
  }, [event]);

  return (
    <Wrapper
      aria-label={buttonProps?.ariaLabel}
      backgroundColor={backgroundColor}
      color={color}
      data-click={buttonProps?.dataClick}
      data-from={buttonProps?.dataFrom}
      data-testid={dataTestId}
      hasBottomModal={Boolean(buttonProps)}
      hasLink={hasLink}
      onClick={buttonProps?.onClick}
      onKeyPress={buttonProps ? handleKeyPress(buttonProps.onClick) : undefined}
      role={buttonProps ? 'button' : undefined}
      tabIndex={buttonProps ? 0 : -1}
    >
      {children}
    </Wrapper>
  );
};

export const StyledLinkedCTA = styled(Link)<{ color?: string }>`
  color: ${({ color }) => color ?? theme.colors.neutral[100]};
`;

export const LongLinkCta = styled(Link)`
  display: flex;
  flex-direction: column;
  color: ${theme.colors.neutral[100]};
  text-decoration: underline;
  letter-spacing: 0.5px;

  ${legacyTheme.breakpoints.up('xs')} {
    display: block;
    line-height: 10px;
    text-decoration: none;
    border-bottom: 1px solid white;
  }
`;
