import { useState } from 'react';

import { useNavigate } from 'react-router';

import { Box, Button, theme } from '@prose-ui';
import { legacyTheme, styled } from '@prose-ui/legacy';

import { TextField } from 'Components/TextField';

import { post } from 'Services/NpsService';

import Arrow from 'assets/images/controlIcons/arrow.svg';
import CrossIcon from 'assets/images/cross_icon.svg';

import { Image } from './Image';
import { Typography } from './Typography';

const ModalContainer = styled.div`
  /* the modal.tsx backdrop component has a 10000 value, we want this component to be over this. */
  z-index: 10001;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  background-color: ${theme.colors.neutral[100]};
  padding: ${theme.spacing['4x']};
  box-shadow: 0 -2px 2px 0 rgba(22 23 22 / 15%);

  ${legacyTheme.breakpoints.up('sm')} {
    width: 375px;
  }
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  ${legacyTheme.breakpoints.up('sm')} {
    align-items: flex-start;
  }
`;

const CloseIcon = styled(Image)`
  display: flex;
  cursor: pointer;
  justify-self: flex-end;
`;

const ArrowIcon = styled(Image)`
  cursor: pointer;
`;

const Title = styled(Typography)`
  margin: ${theme.spacing['4x']} 0;
`;

const NoteContainer = styled.div`
  display: flex;
  width: 100%;
`;

const Note = styled.div<{ selected: boolean }>`
  cursor: pointer;
  width: 30px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid ${theme.colors.neutral[900]};
  margin: 0 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ selected }) =>
    selected ? theme.colors.primary[400] : theme.colors.neutral[100]};
`;

const NoteLabel = styled(Typography)<{ selected: boolean }>`
  font-weight: 500;
  font-size: 12px;
  color: ${({ selected }) => (selected ? theme.colors.neutral[200] : theme.colors.neutral[900])};
`;

const IconsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const NoteRangeLabel = styled(Typography)`
  margin-top: ${theme.spacing['1x']};
  font-size: 10px;
`;

const InputComponent = styled(TextField)`
  width: 100%;
  max-width: none;
`;

const TextContainer = styled.div`
  width: 100%;
`;

const content: Record<
  status,
  {
    title: string;
    placeHolder?: string;
    buttonLabel?: string;
    buttonLabelOver5?: string;
    buttonLabelUnder5?: string;
    descriptionUnder5?: string;
    descriptionOver5?: string;
  }
> = {
  note: {
    title: 'How likely are you to recommend Prose to a friend?',
  },
  text: {
    title: 'Tell us more about why you chose __NOTE__.',
    placeHolder: 'Why did you choose __NOTE__?',
    buttonLabel: 'submit',
  },
  finished: {
    title: 'Thanks, we really appreciate your feedback.',
    descriptionOver5:
      'We’re so happy to hear you’re liking Prose! Ready to share the gift of custom beauty? Give $20 + get $20 for each friend you refer.',
    descriptionUnder5:
      'We’re sorry to hear you’re not satisfied. Your products are covered by The Prose Promise™ — Love your first order (or it’s on us) within 30 days of the delivery date. Need help? Just ask.',
    buttonLabelUnder5: 'contact us',
    buttonLabelOver5: 'share prose',
  },
};

type NpsModalProps = {
  onClose: () => void;
  orderPubkey: string;
};

type status = 'note' | 'text' | 'finished';

export const NpsModal = ({ onClose, orderPubkey }: NpsModalProps) => {
  const navigate = useNavigate();
  const [answered, setAnswered] = useState(false);
  const [payload, setPayload] = useState<{ note: number | undefined; text: string | undefined }>({
    note: undefined,
    text: undefined,
  });
  const [status, setStatus] = useState<status>('note');
  const goodNote = payload.note !== undefined && payload.note > 5;
  const contentByStatus = content[status];

  const onCloseModal = () => {
    if (payload.note !== undefined && !answered) {
      post({
        QID1: payload.note,
        QID3_TEXT: payload.text || '',
        progress: 50,
        finished: 0,
        order_pubkey: orderPubkey,
      });
    }
    onClose();
  };

  const submit = () => {
    post({
      QID1: payload.note!,
      QID3_TEXT: payload.text || '',
      progress: 100,
      finished: 1,
      order_pubkey: orderPubkey,
    });
    setAnswered(true);
  };

  return (
    <ModalContainer id="modal-dialog" role="dialog" tabIndex={0}>
      <Content>
        <IconsContainer>
          {status === 'text' ? (
            <ArrowIcon
              alt="previous step icon"
              height={17}
              onClick={() => setStatus('note')}
              src={Arrow}
              width={17}
            />
          ) : (
            <div />
          )}
          <CloseIcon
            alt="close modal icon"
            aria-label="close"
            height={17}
            onClick={onCloseModal}
            src={CrossIcon}
            width={17}
          />
        </IconsContainer>
        <Title variant="p2">
          {contentByStatus.title.replace('__NOTE__', String(payload.note))}
        </Title>
        {status === 'note' && (
          <div>
            <NoteContainer>
              {Array.from({ length: 10 }, (_, i) => i + 1).map((value) => {
                const selected = payload.note === value;
                return (
                  <Note
                    key={value}
                    onClick={() => {
                      setPayload((state) => ({ ...state, note: value }));
                      setStatus('text');
                    }}
                    selected={selected}
                  >
                    <NoteLabel selected={selected} variant="mono3">
                      {value}
                    </NoteLabel>
                  </Note>
                );
              })}
            </NoteContainer>
            <Box display="flex" justifyContent="space-between">
              <NoteRangeLabel variant="p1">not likely</NoteRangeLabel>
              <NoteRangeLabel variant="p1">very likely</NoteRangeLabel>
            </Box>
          </div>
        )}
        {status === 'text' && (
          <TextContainer>
            <InputComponent
              id="nps_text"
              onChange={(event) => setPayload((state) => ({ ...state, text: event.target.value }))}
              placeholder={
                payload.note !== undefined
                  ? contentByStatus.placeHolder?.replace('__NOTE__', payload.note.toString())
                  : ''
              }
              value={payload.text}
            />
            <Button
              data-click="submit"
              data-from="nps-prompt"
              onClick={() => {
                setStatus('finished');
                submit();
              }}
              size="md"
              variant="primary"
            >
              {contentByStatus.buttonLabel}
            </Button>
          </TextContainer>
        )}
        {status === 'finished' && (
          <>
            <Title variant="p2">
              {goodNote ? contentByStatus.descriptionOver5 : contentByStatus.descriptionUnder5}
            </Title>
            <Button
              data-click={goodNote ? 'share-prose' : 'contact-us'}
              data-from="nps-prompt"
              onClick={() => {
                navigate(goodNote ? '/account/refer-a-friend' : '/contact');
                onClose();
              }}
              size="md"
              variant="primary"
            >
              {goodNote ? contentByStatus.buttonLabelOver5 : contentByStatus.buttonLabelUnder5}
            </Button>
          </>
        )}
      </Content>
    </ModalContainer>
  );
};
