import { matchPath, useLocation } from 'react-router';

import { productsCategories } from 'constants/products';
import { consultationProductsCategories } from '../constants/consultationProductsCategories';

import { type ConsultationCategoryProp } from 'PropTypes/consultationProps';

export const useConsultationCategory = () => {
  const { pathname } = useLocation();
  const matchedParams = matchPath('/consultation/:category/*', pathname)?.params;

  if (
    !matchedParams?.category ||
    !consultationProductsCategories.includes(matchedParams.category)
  ) {
    return productsCategories.AGNOSTIC;
  }

  return matchedParams.category as ConsultationCategoryProp;
};
