import { createBrowserRouter, createMemoryRouter } from 'react-router';

import * as Sentry from '@sentry/nextjs';
import Root from 'root';
import { RootErrorBoundary } from 'root-error-boundary';
import createStore from 'store';

import { locationChanged } from 'dux/router/slice';

const store = createStore();
let legacyRouter: ReturnType<typeof createBrowserRouter>;
/**
 * return the existing legacyRouter & create it if it doesn't exist yet
 */
const createLegacyRouter = () => {
  if (legacyRouter) return legacyRouter;
  const routes = [{ path: '*', Component: Root, ErrorBoundary: RootErrorBoundary }];
  const sentryCreateRouter = Sentry.wrapCreateBrowserRouter(
    typeof window !== 'undefined' ? createBrowserRouter : createMemoryRouter,
  );
  legacyRouter = sentryCreateRouter(routes);

  legacyRouter.subscribe((routerState) => {
    store.dispatch(
      locationChanged({
        location: { pathname: routerState.location.pathname, search: routerState.location.search },
      }),
    );
  });

  return legacyRouter;
};

// export type RouterState = ReturnType<typeof createLegacyRouter>;

export default createLegacyRouter;
