import { matchPath, useLocation } from 'react-router';

import { useAppSelector } from 'dux/app/hooks';

import { productsCategories } from 'constants/products';
import { haircareRoutes, skincareRoutes } from 'constants/routes';

import trialOfferClawClipperGwpPromotionContent from 'assets/content/promotions/trialOfferClawClipperGwpPromotionModal';
import trialOfferCollapsibleWaterBottleContent from 'assets/content/promotions/trialOfferCollapsibleWaterBottleGwpPromotionModal';
import trialOfferGwpDecemberContent from 'assets/content/promotions/trialOfferCombGwpPromotionModal';
import trialOfferGwpHeatlessCurlSetBlackFridaySetContent from 'assets/content/promotions/trialOfferHeatlessCurlerSetBlackFridayGwpPromotionModal';
import trialOfferGwpHeatlessCurlSetContent from 'assets/content/promotions/trialOfferHeatlessCurlerSetGwpPromotionModal';
import trialOfferPetitToteGwpContent from 'assets/content/promotions/trialOfferPetitToteGwpPromotionModal';
import trialOfferContent from 'assets/content/promotions/trialOfferPromotionModal';
import trialOfferGwpSummerSaleContent from 'assets/content/promotions/trialOfferSummerSaleGwpPromotionModal';
import trialOfferToiletryBagGwpPromotionContent from 'assets/content/promotions/trialOfferToiletryBagGwpPromotionModal';
import trialOfferGwpToiletry from 'assets/content/promotions/trialOfferToiletryGWPPromotionModal';
import trialOfferGwpTravelPouchesContent from 'assets/content/promotions/trialOfferTravelPouchesGwpPromotionModal';
import trialOfferToiletryAndCombGwpPromotionContent from 'assets/content/promotions/trialOfferTravelToiletryBagAndCombGwpPromotionModal';
import trialOfferWideToothCombGwpPromotionContent from 'assets/content/promotions/trialOfferWideToothCombGwpPromotionModal';

import {
  getTrialOfferFlagVariant,
  trialOfferClawClipperGwp,
  trialOfferCollapsibleWaterBottleGwp,
  trialOfferCombGwp,
  trialOfferEvergreen,
  trialOfferGwpSummerSale,
  trialOfferHeatlessCurlSetBlackFridaySetGwp,
  trialOfferHeatlessCurlSetGwp,
  trialOfferPetitToteGwp,
  trialOfferToiletryAndCombGwp,
  trialOfferToiletryBagGwp,
  trialOfferToiletryGwp,
  trialOfferTravelPouchesGwp,
  trialOfferWideToothCombGwp,
} from 'dux/featureFlags/selectors';
import {
  getHasHaircareSubscriptionInAnyState,
  getHasSkincareSubscriptionInAnyState,
} from 'dux/user/selectors';

const trialOfferVariantContent = {
  [trialOfferEvergreen]: trialOfferContent,
  [trialOfferCombGwp]: trialOfferGwpDecemberContent,
  [trialOfferToiletryGwp]: trialOfferGwpToiletry,
  [trialOfferTravelPouchesGwp]: trialOfferGwpTravelPouchesContent,
  [trialOfferToiletryAndCombGwp]: trialOfferToiletryAndCombGwpPromotionContent,
  [trialOfferGwpSummerSale]: trialOfferGwpSummerSaleContent,
  [trialOfferHeatlessCurlSetBlackFridaySetGwp]: trialOfferGwpHeatlessCurlSetBlackFridaySetContent,
  [trialOfferHeatlessCurlSetGwp]: trialOfferGwpHeatlessCurlSetContent,
  [trialOfferCollapsibleWaterBottleGwp]: trialOfferCollapsibleWaterBottleContent,
  [trialOfferPetitToteGwp]: trialOfferPetitToteGwpContent,
  [trialOfferWideToothCombGwp]: trialOfferWideToothCombGwpPromotionContent,
  [trialOfferToiletryBagGwp]: trialOfferToiletryBagGwpPromotionContent,
  [trialOfferClawClipperGwp]: trialOfferClawClipperGwpPromotionContent,
};

export const useGetTrialOfferCategory = () => {
  const { pathname } = useLocation();
  const hasHaircareSubscription = useAppSelector(getHasHaircareSubscriptionInAnyState);
  const hasSkincareSubscription = useAppSelector(getHasSkincareSubscriptionInAnyState);

  const isHaircareRoute = haircareRoutes.some(url => matchPath(url, pathname));
  const isSkincareRoute = skincareRoutes.some(url => matchPath(url, pathname));

  const determineProductCategory = (
    currentRouteSubscription,
    alternateRouteSubscription,
    currentRouteCategory,
    alternateRouteCategory
  ) => {
    if (!currentRouteSubscription && !alternateRouteSubscription) return currentRouteCategory;
    if (!currentRouteSubscription && alternateRouteSubscription) return currentRouteCategory;
    if (currentRouteSubscription && !alternateRouteSubscription) return alternateRouteCategory;
    return null;
  };

  if (isHaircareRoute) {
    return determineProductCategory(
      hasHaircareSubscription,
      hasSkincareSubscription,
      productsCategories.HAIRCARE,
      productsCategories.SKINCARE
    );
  }

  if (isSkincareRoute) {
    return determineProductCategory(
      hasSkincareSubscription,
      hasHaircareSubscription,
      productsCategories.SKINCARE,
      productsCategories.HAIRCARE
    );
  }

  if (!hasHaircareSubscription && !hasSkincareSubscription) {
    // We want to force haircare content for new prospects on homepage
    if (pathname === '/') {
      return productsCategories.HAIRCARE;
    }
    return productsCategories.AGNOSTIC;
  }
  if (!hasHaircareSubscription && hasSkincareSubscription) {
    return productsCategories.HAIRCARE;
  }
  if (hasHaircareSubscription && !hasSkincareSubscription) {
    return productsCategories.SKINCARE;
  }
  return null;
};

export const useTrialOfferPromotionModalContent = () => {
  const variant = useAppSelector(getTrialOfferFlagVariant);
  const category = useGetTrialOfferCategory();
  const { pathname } = useLocation();

  const trialOfferGWPContent = trialOfferVariantContent[variant];

  const content = matchPath('/checkout/success', pathname)
    ? trialOfferContent
    : trialOfferGWPContent;

  return {
    content:
      category === productsCategories.AGNOSTIC
        ? content?.contentDefault
        : category === productsCategories.SKINCARE
        ? content?.contentSkincare
        : category === productsCategories.HAIRCARE
        ? content?.contentHaircare
        : null,
    variant,
    category,
  };
};
