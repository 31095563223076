import { Helmet as HelmetLibrary } from 'react-helmet';
import { Outlet, useLocation } from 'react-router';

import getEnv from 'utils/getEnv';

type MedataDataProps = {
  canonicalURL?: string;
  description?: string;
  inheritParentCanonicalURL?: boolean;
  noIndex?: boolean;
  outlet?: boolean;
  title?: string;
  /** The structuredData prop can vary and as its data coming from SEO
   * the shape is not consistent so we can't give a precize input to typscript */
  structuredData?: Object | Object[];
};

export const MetaData = ({
  canonicalURL: specificURL,
  description = 'Discover the difference of truly custom haircare and skincare with Prose: Personalized formulas made just for you. Take your free consultation now.',
  inheritParentCanonicalURL = false,
  noIndex = false,
  outlet = false,
  title = 'Truly Custom Haircare and Skincare | Prose',
  structuredData = undefined,
}: MedataDataProps) => {
  const { pathname } = useLocation();

  /**
   * To be correctly set, a canonical tag must contain the appropriate domain name (e.g. http://localhost, https://prose.com)
   * and the pathname (e.g. /product/haircare, /checkout/haircare).
   * Even if the final goal is to set this tag only in production, we want it to be also correct on each environment (local, staging, preprod, prod)
   * to facilitate QA when non-tech people use SEO tools to verify the correctness of the tag.
   */
  const domainName = getEnv('REACT_APP_DOMAIN_NAME') ?? 'https://prose.com';
  /**
   * If a canonical URL isn't specified, 2 possibilities:
   * - To avoid duplicate content and tell search engines that different pages are actually the same one,
   * we can declare a same canonical URL for several URL paths (e.g. /supplements, /supplements-search, etc.).
   * - By default, we compute the canonical URL based on the URL path.
   */
  const canonicalURL = specificURL ?? (!inheritParentCanonicalURL ? pathname : null);

  /**
   * With Next.js App Router, Helmet doesn't work anymore for React Router pages.
   * The metadata must be overridden this way.
   * Once converted to a Next.js page, the metadata object must be used instead (see src/app/layout.tsx).
   */
  document.title = title;
  const descriptionMetaTag: HTMLMetaElement | null = document.querySelector(
    'meta[name="description"]',
  );
  if (descriptionMetaTag && typeof description === 'string') {
    descriptionMetaTag.content = description;
  }
  const canonicalMetaTag: HTMLLinkElement | null = document.querySelector('link[rel="canonical"]');
  if (canonicalURL && canonicalMetaTag) {
    canonicalMetaTag.href = `${domainName}${canonicalURL}`;
  }
  const noIndexMetaTag: HTMLMetaElement | null = document.querySelector('meta[name="robots"]');
  if (noIndex && noIndexMetaTag) {
    noIndexMetaTag.content = 'noindex';
  }

  const structuredDataToMap =
    structuredData && (Array.isArray(structuredData) ? structuredData : [structuredData]);

  return (
    <>
      <HelmetLibrary>
        <title>{title}</title>
        <meta content={description} name="description" />
        {noIndex && <meta content="noindex" name="robots" />}
        {canonicalURL && <link href={`${domainName}${canonicalURL}`} rel="canonical" />}
      </HelmetLibrary>
      {outlet && <Outlet />}
      {structuredDataToMap?.map((data, i) => (
        <script
          key={i}
          dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
          data-testid="structured-data-script-tag"
          type="application/ld+json"
        />
      ))}
    </>
  );
};
