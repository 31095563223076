import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router';

import { useAppDispatch, useAppSelector } from 'dux/app/hooks';

import { Box, Button, Modal, Text, theme } from '@prose-ui';
import { styled } from '@prose-ui/legacy';
import { mediaMinWidth } from '@prose-ui/utils/media';
import { useTrialOfferPromotionModalContent } from 'hooks/useRedeemCouponForTrialOffer';

import { FIRST_HAIRCARE_QUESTION_ROUTE } from 'Apps/Consultation/constants';

import { Image } from 'Components/Image';

import closeIcon from 'assets/images/pop-up-close-button.svg';

import { isValidEmail } from 'utils/validators';

import { trackHeapEvent } from 'dux/tracking/actions';
import { getIsMagicLinkSent } from 'dux/signup/selectors';
import { actions } from 'dux/signup/slice';
import {
  apiSignupOrigin,
  heapSignupOrigin,
  heapSignupSubOrigin,
  signupUser,
} from 'dux/signup/thunks';

import { TextField } from './TextField';

const CloseModalButton = styled.button`
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 12px;
  appearance: none;
  background: none;
  border: none;
  padding: 0;
`;

const StyledModal = styled(Modal)`
  padding: 0;
  min-width: 330px;
  border-radius: 10px;

  ${mediaMinWidth('sm')} {
    max-width: 555px;
  }
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: ${theme.colors.neutral[200]};
`;

const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing['12x']} ${theme.spacing['5x']};

  ${mediaMinWidth('sm')} {
    padding: ${theme.spacing['10x']} ${theme.spacing['10x']};
  }
`;

const EmailInput = styled(TextField)`
  width: 300%;
`;

const NotReceivedLinkButton = styled.button`
  cursor: pointer;
  appearance: none;
  background: none;
  border: none;
  padding: 0;
`;

type EmailCapturePromotionModalProps = {
  onClose: () => void;
  isOpen: boolean;
};

const EmailCapturePromotionModal = ({
  isOpen = false,
  onClose,
}: EmailCapturePromotionModalProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [modalLayout, setModalLayout] = useState<'default' | 'magicLinkSent'>('default');
  const magickLinkSent = useAppSelector(getIsMagicLinkSent);

  const { content, variant } = useTrialOfferPromotionModalContent();

  const onSubmit = () => {
    if (!email) {
      setError('*Required');
    } else if (email && !isValidEmail(email)) {
      setError('Must be a valid email');
    } else {
      setIsLoading(true);
      dispatch(
        signupUser({
          user: { email, username: email },
          actions: {
            onSuccess: () => {
              navigate(FIRST_HAIRCARE_QUESTION_ROUTE);
            },
            setSubmitting: (value: boolean) => setIsLoading(value),
            setFieldError: (field: string, error: string) => {
              if (field === 'email') {
                setError(error);
              }
            },
          },
          origin: apiSignupOrigin.HOMEPAGE_POP_UP,
          heapOrigin: heapSignupOrigin.HOMEPAGE_POP_UP,
          heapSubOrigin: heapSignupSubOrigin.V1_TEST,
        }),
      );
    }
  };

  useEffect(() => {
    if (modalLayout === 'default' && magickLinkSent) {
      setEmail('');
      setError('');
      setIsLoading(false);
      setModalLayout('magicLinkSent');
    }
  }, [magickLinkSent, modalLayout]);

  if (!content) return null;

  return (
    <StyledModal
      events={{
        onModalClose: () => dispatch(trackHeapEvent('Email Capture Pop-Up - Close')),
        onModalOpen: () => dispatch(trackHeapEvent('Email Capture Pop-Up - View')),
        timer: {
          onModalOpenAfterTime: () =>
            dispatch(trackHeapEvent('Email Capture Pop-Up - View (2 sec)')),
        },
      }}
      hideClose
      isOpen={isOpen}
      onClose={onClose}
      zIndexValue={10000}
    >
      <Container>
        <CloseModalButton
          data-click="exit"
          data-from="email-capture-pop-up"
          data-testid="close-modal"
          id="modal-close-button"
          onClick={onClose}
        >
          <img alt="Close" src={closeIcon} />
        </CloseModalButton>
        {modalLayout === 'magicLinkSent' ? (
          <Box display="flex" flexDirection="column" paddingX="10x" paddingY="20x">
            <Text textAlign="center" variant="h3">
              Nice to see you again!
            </Text>
            <Box paddingBottom="8x" paddingTop="2x">
              <Text textAlign="center" variant="body4regular">
                Check your email, you just received a link to log you in
              </Text>
            </Box>
            <NotReceivedLinkButton
              data-click="not-receive-link"
              data-from="email-capture-pop-up"
              onClick={async () => {
                dispatch(actions.setMagicLinkSent(false));
                setModalLayout('default');
              }}
            >
              <Text textAlign="center" textDecorationLine="underline" variant="body4regular">
                I did not receive a link
              </Text>
            </NotReceivedLinkButton>
          </Box>
        ) : (
          <>
            <StyledImage
              alt={content.popUpModal.imageAlt ?? 'Trial offer'}
              src={content.popUpModal.image}
            />
            <Content>
              <Box paddingX={{ mobile: 'none', tablet: '10x', desktop: '10x' }}>
                <Text
                  data-testid={`modal-homepage-capture-title-${variant}`}
                  id={isOpen ? 'modal-title' : ''}
                  textAlign="center"
                  variant="h3"
                >
                  {content.emailCaptureModal?.title}
                </Text>
                <Box paddingBottom="4x" paddingTop="2x">
                  <Text
                    id={isOpen ? 'modal-description' : ''}
                    textAlign="center"
                    variant="body4regular"
                  >
                    {content.emailCaptureModal?.description}
                  </Text>
                </Box>
                <Box display="flex" justifyContent="center" paddingBottom="4x">
                  <EmailInput
                    error={error}
                    id="email"
                    label="Enter email address"
                    maxWidth={false}
                    onChange={(e) => {
                      if (error) {
                        setError('');
                      }
                      setEmail(e.target.value);
                    }}
                    type="email"
                    value={email}
                  />
                </Box>
                <Box display="flex" justifyContent="center" paddingBottom="8x">
                  <Button
                    data-click="offer-cta"
                    data-from="email-capture-pop-up"
                    disabled={isLoading || Boolean(error)}
                    onClick={onSubmit}
                    size="full"
                    style={{ borderRadius: '60px', backgroundColor: theme.colors.primary[400] }}
                    variant="primary"
                  >
                    Unlock the deal
                  </Button>
                </Box>
              </Box>
              <Text
                color="neutral.800"
                id={isOpen ? 'modal-conditions' : ''}
                textAlign="center"
                variant="body6regular"
              >
                By submitting your email address, you agree to receive marketing emails from Prose.
                You also acknowledge and agree to our{' '}
                <a
                  data-click="terms"
                  data-from="email-capture-pop-up"
                  href="/terms"
                  target="_blank"
                >
                  Terms
                </a>{' '}
                and confirm that you have read and understand our{' '}
                <a
                  data-click="privacy-policy"
                  data-from="email-capture-pop-up"
                  href="/privacy"
                  target="_blank"
                >
                  Privacy Policy
                </a>
                .
              </Text>
            </Content>
          </>
        )}
      </Container>
    </StyledModal>
  );
};

type EmailCapturePromotionModalWithStateLogicProps = {
  isOpen: boolean;
};

export const EmailCapturePromotionModalWithStateLogic = ({
  isOpen,
}: EmailCapturePromotionModalWithStateLogicProps) => {
  const [open, setOpen] = useState(isOpen);
  const onClose = () => setOpen(false);

  return <EmailCapturePromotionModal isOpen={open} onClose={onClose} />;
};
