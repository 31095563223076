import * as feedbackComponents from 'Apps/Feedback/constants/feedbackComponents';
import { FRAGRANCE } from 'Apps/Feedback/constants/feedbackSections';

import { productsCategories } from 'constants/products';

import fragranceTransitionImgDesktop from 'assets/images/reviewAndRefine/fragrance-transition_desktop.jpg';
import fragranceTransitionImgMobile from 'assets/images/reviewAndRefine/fragrance-transition_mobile.jpg';
import fragFreeTransitionImgDesktop from 'assets/images/reviewAndRefine/fragrance-transition-free_desktop.jpg';
import fragFreeTransitionImgMobile from 'assets/images/reviewAndRefine/fragrance-transition-free_mobile.jpg';
import blancaTransitionImgDesktop from 'assets/images/reviewAndRefine/prose-blanca-swatch-desktop.jpg';
import blancaTransitionImgMobile from 'assets/images/reviewAndRefine/prose-blanca-swatch-mobile.jpg';

const FragranceTransitionImages = {
  blanca: { desktop: blancaTransitionImgDesktop, mobile: blancaTransitionImgMobile },
  'fragrance-free': { desktop: fragFreeTransitionImgDesktop, mobile: fragFreeTransitionImgMobile },
  pacifica: { desktop: fragranceTransitionImgDesktop, mobile: fragranceTransitionImgMobile },
};

const efficiencyTransitionScreen = {
  name: 'fragrance',
  route: '/feedback/skincare/fragrance',
  category: FRAGRANCE,
  feedbackCategory: productsCategories.SKINCARE,
  shouldBeIncluded: () => true,
  Component: feedbackComponents.SkincareTransitionScreen,
  skipSave: true,
  title: "Finally, let's talk about your fragrance",
  style: ({ fragrance }) => ({
    mode: 'dark',
    backgroundImages:
      FragranceTransitionImages[fragrance?.toLowerCase?.()] ?? FragranceTransitionImages.pacifica,
  }),
};

export default efficiencyTransitionScreen;
