import { PRODUCTS } from 'Apps/Feedback/constants/feedbackSections';
import { haircareLeaveinConditionerSatisfaction } from 'Apps/Feedback/constants/feedbackSlugs';

import { productsSlugs } from 'constants/products';

const leaveInConditionerSatisfaction = {
  name: haircareLeaveinConditionerSatisfaction,
  route: `/feedback/haircare/${haircareLeaveinConditionerSatisfaction}`,
  category: PRODUCTS,
  shouldBeIncluded: ({ formulas }) =>
    formulas?.find(f => f.variant.product.type === productsSlugs.LEAVEIN_CONDITIONER),
  Component: 'Rating',
  title: 'How satisfied are you with your leave-in conditioner?',
  options: [
    { value: 1, label: 'Very Unsatisfied' },
    { value: 2, label: 'Unsatisfied' },
    { value: 3, label: 'Neutral' },
    { value: 4, label: 'Satisfied' },
    { value: 5, label: 'Very Satisfied' },
  ],
  skippable: "I haven't used it enough",
  skippableValue: 0,
  hasOpenText: true,
  getInitialValue: answers => answers?.product_leavein_conditioner_satisfaction,
  getInitialProductFeedback: productsAnswers => {
    return productsAnswers.find(answer => answer.product === productsSlugs.LEAVEIN_CONDITIONER)
      ?.open_text_feedback;
  },
  getAnswers: ({ selected }) => {
    if (selected === 0) {
      return {
        product_leavein_conditioner_satisfaction: selected,
        product_leavein_conditioner_manageable: null,
        product_leavein_conditioner_tangles: null,
        product_leavein_conditioner_frizz: null,
        product_leavein_conditioner_moisture: null,
      };
    }
    return { product_leavein_conditioner_satisfaction: selected };
  },
  getFeedbackAnswer: ({ openText }) => ({
    product: productsSlugs.LEAVEIN_CONDITIONER,
    open_text_feedback: openText?.feedback_text,
  }),
  multi: true,
};

export default leaveInConditionerSatisfaction;
