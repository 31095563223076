import * as feedbackComponents from 'Apps/Feedback/constants/feedbackComponents';
import { PRODUCTS } from 'Apps/Feedback/constants/feedbackSections';
import { transitionProducts } from 'Apps/Feedback/constants/feedbackSlugs';

import { feedbackCategories } from 'constants/feedback';

const productsTransitionScreen = {
  name: transitionProducts,
  route: `/feedback/${feedbackCategories.SKINCARE}/individual-products`,
  category: PRODUCTS,
  feedbackCategory: feedbackCategories.SKINCARE,
  shouldBeIncluded: () => true,
  Component: feedbackComponents.SkincareTransitionScreen,
  skipSave: true,
  title: "Now, we'll ask about your experience with your individual products",
  orderInfosBlock: true,
  noPumps: false,
  style: {
    mode: 'light',
  },
};

export default productsTransitionScreen;
