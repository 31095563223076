import allCategoriesTransitionDesktop from 'assets/images/reviewAndRefine/all-categories-transition-intro-desktop-2x.jpg';
import allCategoriesTransitionMobile from 'assets/images/reviewAndRefine/all-categories-transition-intro-mobile-3x.jpg';

const supplementsTransitionScreen = {
  name: '',
  route: '/feedback/supplements',
  shouldBeIncluded: () => true,
  Component: 'SupplementsTransitionScreen',
  skipSave: true,
  title: (
    <>
      Check your Root Source<sup>®</sup> progress
    </>
  ),
  subtitle: "Tell us what results you're seeing so far on your hair supplement journey.",
  paragraphs: [
    <>
      <b>For the first 90 days</b>, we’ll help you track key signs of growth as they emerge.
    </>,
    <>
      <b>After 90 days</b>, we’ll determine if you could benefit from any new ingredients in your
      formulas, or if you’re all set to keep growing better hair.
    </>,
  ],
  legalNotice:
    'Unless noted otherwise, collected reviews and ratings may be made public to share with the community.',
  ctaNextText: "Let's get started",
  style: {
    mode: 'dark',
    backgroundImages: {
      desktop: allCategoriesTransitionDesktop,
      mobile: allCategoriesTransitionMobile,
      tablet: allCategoriesTransitionMobile,
    },
  },
};

export default supplementsTransitionScreen;
