import { FRAGRANCE } from 'Apps/Feedback/constants/feedbackSections';
import { haircareFragranceSatisfactionIntensityHairOil } from 'Apps/Feedback/constants/feedbackSlugs';

import { productsSlugs } from 'constants/products';

const hairOilFragranceIntensitySatisfaction = {
  name: haircareFragranceSatisfactionIntensityHairOil,
  route: `/feedback/haircare/${haircareFragranceSatisfactionIntensityHairOil}`,
  category: FRAGRANCE,
  shouldBeIncluded: ({ formulas }) =>
    formulas?.some(
      f =>
        [productsSlugs.OIL].includes(f.variant.product.type) &&
        f.item_object.fragrance &&
        f.item_object.fragrance !== 'FRAGRANCE_FREE' &&
        f.item_object.fragrance !== 'NO_ADDED_FRAGRANCE'
    ),
  Component: 'FragranceSatisfactionIntensity',
  surtitle: {
    showFormulas: 'hair_oil',
    showFragrance: true,
  },
  title: 'How satisfied are you with the scent of your hair oil?',
  getInitialValue: answers => ({
    fragrance_satisfaction: answers?.fragrance_satisfaction_hair_oil,
    fragrance_intensity: answers?.fragrance_intensity_hair_oil,
  }),
  getAnswers: ({ selected }) => ({
    fragrance_satisfaction_hair_oil: selected?.fragrance_satisfaction,
    fragrance_intensity_hair_oil: selected?.fragrance_intensity,
  }),
  multi: true,
};

export default hairOilFragranceIntensitySatisfaction;
