import isBoolean from 'lodash/fp/isBoolean';
import isEmpty from 'lodash/fp/isEmpty';

import { CHECKOUT_NEW_ADDRESS_FIELD_VALUE } from 'Apps/Checkout/constants/checkoutInputValues';

import * as UserService from 'Services/UserService';

import {
  trackAddShippingInfo,
  trackCartUpdated,
  trackCreateAddress,
  trackUpdateAddress,
} from 'dux/tracking/actions';
import { patchUser } from 'dux/user/actions';
import { getTextMarketingOptin, getTextOptin, getUser, getUserPhone } from 'dux/user/selectors';
import { patchCartV2 } from 'dux/cartV2/thunks';
// Relies only on cart actions
import {
  LOAD_ADDRESSES_FAILURE,
  LOAD_ADDRESSES_REQUEST,
  LOAD_ADDRESSES_SUCCESS,
  SET_NEW_ADDRESS,
} from 'dux/checkoutAddresses/actionTypes';
import { updateCart } from 'dux/checkoutCart/thunks';

/**
 * FETCH
 */

export const loadAddresses = () => async dispatch => {
  dispatch({ type: LOAD_ADDRESSES_REQUEST });
  try {
    const data = await UserService.fetchAddresses();
    dispatch({ type: LOAD_ADDRESSES_SUCCESS, data });
    dispatch(trackUpdateAddress(data));
  } catch (error) {
    dispatch({ type: LOAD_ADDRESSES_FAILURE, error });
  }
};

/**
 * PATCH
 */

export const saveContactAddress =
  ({ values }) =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      const phone = getUserPhone(state);
      const textMarketingOptin = getTextMarketingOptin(state);
      const textOptin = getTextOptin(state);

      // Fetch addresses because the store can be empty
      const userAddresses = await UserService.fetchAddresses();
      // Re-use the address if it was previously stored from a previous Express Checkout usage
      const expressCheckoutAddressAlreadySaved = userAddresses.find(
        address =>
          address.name === values?.newAddress?.name &&
          address.address1 === values?.newAddress?.address1
      );

      if (!isEmpty(expressCheckoutAddressAlreadySaved)) {
        await dispatch(updateCart({ shipping_address: expressCheckoutAddressAlreadySaved.pubkey }));
      } else {
        const address = await UserService.createAddress({
          ...values.newAddress,
        });
        await dispatch({
          type: SET_NEW_ADDRESS,
          data: address,
        });
        await dispatch(trackCreateAddress(address));
        await dispatch(updateCart({ shipping_address: address.pubkey }));
        await dispatch(trackAddShippingInfo());
        await dispatch(trackCartUpdated());
      }

      const userParams = {};

      if (phone !== values.phone) {
        userParams.phone = values.phone;
      }

      if (isBoolean(values.textOptin) && textOptin !== values.textOptin) {
        userParams.text_optin = values.textOptin;
      }

      if (
        isBoolean(values.textMarketingOptin) &&
        textMarketingOptin !== values.textMarketingOptin
      ) {
        userParams.text_marketing_optin = values.textMarketingOptin;
      }

      // Update user is needed
      if (!isEmpty(userParams)) {
        await dispatch(patchUser(userParams));
      }

      return { status: 'success' };
    } catch (error) {
      return { status: 'failure', addressError: error };
    }
  };
export const saveAccountDetails =
  ({ values }) =>
  async (dispatch, getState) => {
    try {
      const state = getState();
      const phone = getUserPhone(state);
      const user = getUser(state);
      const textMarketingOptin = getTextMarketingOptin(state);
      const textOptin = getTextOptin(state);

      const userParams = {};

      if (user.first_name !== values.account.firstName) {
        userParams.first_name = values.account.firstName;
      }
      if (user.last_name !== values.account.lastName) {
        userParams.last_name = values.account.lastName;
      }

      if (user.email !== values.account.email) {
        userParams.email = values.account.email;
        userParams.username = values.account.email;
      }

      if (phone !== values.account.phone) {
        userParams.phone = values.account.phone;
      }

      if (isBoolean(values.textOptin) && textOptin !== values.textOptin) {
        userParams.text_optin = values.textOptin;
      }

      if (
        isBoolean(values.textMarketingOptin) &&
        textMarketingOptin !== values.textMarketingOptin
      ) {
        userParams.text_marketing_optin = values.textMarketingOptin;
      }

      // Update user is needed
      if (!isEmpty(userParams)) {
        await dispatch(patchUser(userParams));
      }

      return { status: 'success' };
    } catch (error) {
      return { status: 'failure', userError: error };
    }
  };

export const saveShippingAddress =
  ({ values, actions }) =>
  async dispatch => {
    try {
      // Save shipping address if needed
      if (values.addressPubkey !== CHECKOUT_NEW_ADDRESS_FIELD_VALUE) {
        dispatch(updateCart({ shipping_address: values.addressPubkey }));
      }

      if (values.addressPubkey === CHECKOUT_NEW_ADDRESS_FIELD_VALUE) {
        const address = await UserService.createAddress({
          ...values.newAddress,
        });
        actions.setFieldValue?.('addressPubkey', address.pubkey);
        dispatch({
          type: SET_NEW_ADDRESS,
          data: address,
        });
        dispatch(trackCreateAddress(address));
        dispatch(updateCart({ shipping_address: address.pubkey }));
        dispatch(trackAddShippingInfo());
        dispatch(trackCartUpdated());
      }

      return { status: 'success' };
    } catch (error) {
      return { status: 'failure', addressError: error };
    }
  };

export const saveShippingAddressV2 =
  ({ values, actions }) =>
  async dispatch => {
    // Fetch addresses because the store can be empty
    const userAddresses = await UserService.fetchAddresses();
    // Re-use the address if it was previously stored from a previous Express Checkout usage
    const expressCheckoutAddressAlreadySaved = userAddresses.find(
      address =>
        address.name === values?.newAddress?.name &&
        address.address1 === values?.newAddress?.address1
    );

    try {
      if (!isEmpty(expressCheckoutAddressAlreadySaved)) {
        await dispatch(
          patchCartV2({ shipping_address: expressCheckoutAddressAlreadySaved.pubkey })
        );
      } else {
        const address = await UserService.createAddress({
          ...values.newAddress,
        });
        actions?.setFieldValue?.('addressPubkey', address.pubkey);
        await dispatch({
          type: SET_NEW_ADDRESS,
          data: address,
        });
        await dispatch(trackCreateAddress(address));
        await dispatch(patchCartV2({ shipping_address: address.pubkey }));
      }

      return { status: 'success' };
    } catch (error) {
      return { status: 'failure', addressError: error };
    }
  };
