import { createSelector } from '@reduxjs/toolkit';

import get from 'lodash/fp/get';
import isNull from 'lodash/fp/isNull';
import isEmpty from 'lodash/isEmpty';

import dayjs from 'dayjs';

import {
  cleanserFragranceSatisfactionIntensity,
  haircareConditionerImpact,
  haircareCurlCreamDefinition,
  haircareCurlCreamFrizz,
  haircareCurlCreamMoisture,
  haircareDryShampooCleansing,
  haircareDryShampooVolume,
  haircareHairCurlDefinition,
  haircareHairDryness,
  haircareHairFrizziness,
  haircareHairMaskImpact,
  haircareHairOilFrizz,
  haircareHairOilNourishment,
  haircareHairShine,
  haircareHairTangles,
  haircareHairVolume,
  haircareHasSeenNoProgressAnswers,
  haircareHasSeenNoProgressOtherAnswers,
  haircareHasSeenProgressAnswers,
  haircareLeaveinConditionerFrizz,
  haircareLeaveinConditionerManageable,
  haircareLeaveinConditionerMoisture,
  haircareLeaveinConditionerTangles,
  haircareNoImprovements,
  haircareScalpFlakiness,
  haircareScalpOiliness,
  haircareScalpSensitivity,
  haircareShampooImpact,
  haircareSomeImprovements,
  haircareStylingGelFlexible,
  haircareStylingGelHold,
  haircareTooHeavy,
  moisturizerFragranceSatisfactionIntensity,
  serumFragranceSatisfactionIntensity,
  skincareAntiBlemish,
  skincareAntiRedness,
  skincareAntiShine,
  skincareAntiWrinkles,
  skincareEventone,
  skincareExfoliation,
  skincareFirming,
  skincareHasSeenNoProgressAnswers,
  skincareHasSeenProgressAnswers,
  skincareHydration,
  skincareRescue,
  supplementsBabyHairs,
  supplementsFiveMonths,
  supplementsFourMonths,
  supplementsHairGrowth,
  supplementsHasSeenNoProgressAnswers,
  supplementsHasSeenProgressAnswers,
  supplementsLessShedding,
  supplementsLessThanSixMonths,
  supplementsLessThanThreeMonths,
  supplementsLessThanTwoMonths,
  supplementsScalpComfort,
  supplementsScalpFlakes,
  supplementsScalpOil,
  supplementsShine,
  supplementsSoftness,
  supplementsThickness,
  supplementsThreeMonths,
  supplementsTwoMonths,
  tooLight,
  tooStrong,
} from 'Apps/Feedback/constants/feedbackSlugs';
import haircareFeedbackQuestions from 'Apps/Feedback/hairQuestionsSet';
import skincareMinisFeedbackQuestions from 'Apps/Feedback/skinMinisQuestionsSet';
import skincareFeedbackQuestions from 'Apps/Feedback/skinQuestionsSet';
import supplementsFeedbackQuestions from 'Apps/Feedback/supplementsQuestionsSet';

import { feedbackCategories, feedbackSubCategories } from 'constants/feedback';
import {
  haircareTopicalProducts,
  productsSlugs,
  productsWithFragrance,
  skincareMinisSlugs,
  skincareProducts,
} from 'constants/products';
import { FRAGRANCE_FREE as fragFreeLabel } from 'constants/skincareFragrances';

import { FRAGRANCE_FREE } from 'assets/content/haircareFragrances';

import getOrNil from 'utils/getOrNil';
import { createApiSelectors } from 'utils/reduxUtils';
import { capitalizeFirstLetter } from 'utils/textUtils';

import {
  getHaircareFeedbackOnOrder,
  getSkincareFeedbackOnOrder,
  getSupplementsFeedbackOnOrder,
} from 'dux/user/selectors';

/**
 * Feedback data
 */

export const [getFeedbackData, getFeedbackError, getFeedbackStatus] = createApiSelectors(
  get('feedback')
);

const getFeedback = state => state.feedback;

export const getFeedbackCompletionStatus = createSelector(getFeedbackData, get('status'));

export const getProductsSatisfaction = createSelector(getFeedback, get('productSatisfactionData'));

/**
 * Feedback pubkey
 */

export const getFeedbackPubkey = createSelector(getFeedbackData, get('pubkey'));

/**
 * Feedback's order
 */

export const getHaircareOrderDetails = createSelector(getFeedback, get('haircareOrder'));

export const getSupplementsOrderDetails = createSelector(getFeedback, get('supplementsOrder'));

export const getSkincareOrderDetails = createSelector(getFeedback, get('skincareOrder'));

/**
 * Feedback's items/products
 */

export const getOrderItems = createSelector(getHaircareOrderDetails, get('items'));

export const getSupplementsOrderItems = createSelector(getSupplementsOrderDetails, get('items'));

export const getSkincareOrderItems = createSelector(getSkincareOrderDetails, get('items'));

// Filters + reorder (items are ordered for Ops)
export const getOrderFormulas = createSelector(getOrderItems, items => {
  const formulas = items?.filter(item => item.variant.is_formula);
  return haircareTopicalProducts
    .map(key => formulas?.find(formula => formula.variant.product.type === key))
    .filter(f => f);
});

export const getOrderFormulasWithFragrance = createSelector(getOrderFormulas, formulas =>
  formulas.filter(formula => productsWithFragrance.includes(formula.variant.product.type))
);

// Remains an array because it's only used in a map for now
export const getOrderFormulasHairOil = createSelector(getOrderFormulas, formulas =>
  formulas.filter(formula => formula.variant.product.type === productsSlugs.OIL)
);

// Remains an array because it's only used in a map for now
export const getOrderFormulasScalpMask = createSelector(getOrderFormulas, formulas =>
  formulas.filter(formula => formula.variant.product.type === productsSlugs.SCALP_MASK)
);

export const getOrderFormulasScalpSerum = createSelector(getOrderFormulas, formulas =>
  formulas.filter(formula => formula.variant.product.type === productsSlugs.SCALP_SERUM)
);

export const getOrderSupplements = createSelector(getSupplementsOrderItems, items =>
  items?.filter(item => item.variant.product.type === productsSlugs.SUPPLEMENT_CORE)
);

export const getOrderSkincare = createSelector(getSkincareOrderItems, items => {
  const formulas = items?.filter(item => item.variant.is_formula);
  return formulas?.filter(formula => skincareProducts.includes(formula.variant.product.type)) ?? [];
});

export const getOrderHasSkincareMinis = createSelector(getOrderSkincare, formulas =>
  formulas?.some(formula => skincareMinisSlugs.includes(formula.variant.product.type))
);

export const getOrderSkincareFormulasWithFragrance = createSelector(getOrderSkincare, formulas =>
  formulas.filter(formula => productsWithFragrance.includes(formula.variant.product.type))
);

export const getOrderFormulasCleanser = createSelector(getOrderSkincare, formulas =>
  formulas.filter(formula => formula.variant.product.type === productsSlugs.CLEANSER)
);

export const getOrderFormulasSerum = createSelector(getOrderSkincare, formulas =>
  formulas.filter(formula => formula.variant.product.type === productsSlugs.SERUM)
);

export const getOrderFormulasMoisturizer = createSelector(getOrderSkincare, formulas =>
  formulas.filter(formula => formula.variant.product.type === productsSlugs.MOISTURIZER)
);

export const getOrderFormulasCleanserMini = createSelector(getOrderSkincare, formulas =>
  formulas.filter(formula => formula.variant.product.type === productsSlugs.CLEANSER_MINI)
);

export const getOrderFormulasSerumMini = createSelector(getOrderSkincare, formulas =>
  formulas.filter(formula => formula.variant.product.type === productsSlugs.SERUM_MINI)
);

export const getOrderFormulasMoisturizerMini = createSelector(getOrderSkincare, formulas =>
  formulas.filter(formula => formula.variant.product.type === productsSlugs.MOISTURIZER_MINI)
);

export const getFeedbackEligibleCategories = createSelector(
  getOrderFormulas,
  getOrderSupplements,
  getOrderSkincare,
  (haircareOrderProducts, supplementsOrderProducts, skincareOrderProducts) =>
    [
      !isEmpty(haircareOrderProducts) ? 'haircare' : null,
      !isEmpty(supplementsOrderProducts) ? 'supplements' : null,
      !isEmpty(skincareOrderProducts) ? 'skincare' : null,
    ].filter(n => n)
);

export const hasOrder = createSelector(
  getOrderFormulas,
  getOrderSupplements,
  getOrderSkincare,
  (haircareOrder, supplementsOrder, skincareOrder) =>
    !isEmpty(haircareOrder) || !isEmpty(supplementsOrder) || !isEmpty(skincareOrder)
);

/**
 * Order that are part of the order and for which customer give a feedback
 * (key in consultation survey `product_XYZ_satisfaction = [1..5])
 */
export const getOrderFormulasRated = createSelector(
  getOrderFormulas,
  getFeedbackData,
  (formulas, answers) => {
    const formulasRatedRaw =
      answers &&
      Object.entries(answers)
        .map(([key, value]) => {
          // Look for pattern of a product specific question
          const regex = /^product_(\w*)_satisfaction$/;
          const results = regex.exec(key);
          if (results !== null && value) {
            /**
             * Answer to this specific question
             * Return just the name of the product
             */
            return results[1];
          }
          return null;
        })
        .filter(notNull => notNull);

    return formulas.filter(item => {
      const type =
        item.variant.product.type === productsSlugs.OIL
          ? productsSlugs.HAIR_OIL
          : item.variant.product.type;
      return formulasRatedRaw && formulasRatedRaw.includes(type);
    });
  }
);

/**
 * Feedback eligibility
 */

export const getFeedbackEligibleProducts = createSelector(
  getOrderFormulas,
  getOrderSupplements,
  (haircareItems, supplementsItems) => {
    const eligibleProducts = [];

    if (haircareItems) {
      haircareItems.map(item => {
        if (item?.variant?.product?.type) {
          eligibleProducts.push(item.variant.product.type);
        }
        return item;
      });
    }

    if (supplementsItems) {
      supplementsItems.map(item => {
        if (item?.variant?.product?.type) {
          eligibleProducts.push(item.variant.product.type);
        }
        return item;
      });
    }

    return eligibleProducts;
  }
);

export const hasAtLeastOneOrderToReview = createSelector(
  getHaircareFeedbackOnOrder,
  getSupplementsFeedbackOnOrder,
  getSkincareFeedbackOnOrder,
  (haircareFeedback, supplementsFeedback, skincareFeedback) =>
    !isEmpty(haircareFeedback) || !isEmpty(supplementsFeedback) || !isEmpty(skincareFeedback)
);

export const getSupplementsLastOrderNumberOfMonthsSinceCreation = createSelector(
  getOrderSupplements,
  products => products && products[0] && dayjs().diff(products[0].created_at, 'month')
);

/**
 * Feedback questions
 */

export const getFeedbackQuestionSet = createSelector(
  (state, { feedbackCategory } = {}) => {
    return { feedbackCategory, orderhasSkincareMinis: getOrderHasSkincareMinis(state) };
  },
  ({ orderhasSkincareMinis, feedbackCategory }) => {
    const questionSets = {
      [feedbackSubCategories.SUPPLEMENTS]: supplementsFeedbackQuestions,
      [feedbackCategories.SKINCARE]: skincareFeedbackQuestions,
      [feedbackCategories.HAIRCARE]: haircareFeedbackQuestions,
    };

    if (feedbackCategory === feedbackCategories.SKINCARE && orderhasSkincareMinis) {
      return skincareMinisFeedbackQuestions;
    }

    return questionSets[feedbackCategory] ?? haircareFeedbackQuestions;
  }
);

export const getFeedbackQuestionByIndex = createSelector(
  (_state, { questionIndex } = {}) => questionIndex,
  getFeedbackQuestionSet,
  (questionIndex, questions) => questions?.[questionIndex]
);

/**
 * Feedback answers
 */

export const getInitialValue = createSelector(
  getFeedbackQuestionByIndex,
  getFeedbackData,
  (question, answers) => {
    if (question.getInitialValue && answers) {
      return question.getInitialValue(answers);
    }
    return question.multi ? [] : null;
  }
);

export const getInitialProductFeedback = createSelector(
  getFeedbackQuestionByIndex,
  getProductsSatisfaction,
  (question, productsAnswers) => {
    if (question.getInitialProductFeedback && productsAnswers) {
      return question.getInitialProductFeedback(productsAnswers);
    }
    return null;
  }
);

/**
 * Feedback status
 */

export const isHaircareFeedbackCompleted = createSelector(
  getFeedback,
  get('haircareFeedbackCompleted')
);

export const isSupplementsFeedbackCompleted = createSelector(
  getFeedback,
  get('supplementsFeedbackCompleted')
);

export const isSkincareFeedbackCompleted = createSelector(
  getFeedback,
  get('skincareFeedbackCompleted')
);

/**
 * Feedback results
 */

export const getHaircareMaintainProgressList = createSelector(
  (_state, { answers } = {}) => answers,
  answers => {
    return (
      answers &&
      Object.entries(answers)
        .map(([key, value]) => {
          if (key === haircareScalpSensitivity && haircareHasSeenProgressAnswers.includes(value)) {
            return 'Scalp sensitivity';
          }
          if (key === haircareScalpFlakiness && haircareHasSeenProgressAnswers.includes(value)) {
            return 'Scalp flakiness';
          }
          if (key === haircareScalpOiliness && haircareHasSeenProgressAnswers.includes(value)) {
            return 'Scalp oiliness';
          }
          if (key === haircareHairDryness && haircareHasSeenProgressAnswers.includes(value)) {
            return 'Hair dryness';
          }
          if (key === haircareHairShine && haircareHasSeenProgressAnswers.includes(value)) {
            return 'Hair shine';
          }
          if (
            key === haircareHairCurlDefinition &&
            haircareHasSeenProgressAnswers.includes(value)
          ) {
            return 'Hair curl definitiion';
          }
          if (key === haircareHairFrizziness && haircareHasSeenProgressAnswers.includes(value)) {
            return 'Hair frizziness';
          }
          if (key === haircareHairTangles && haircareHasSeenProgressAnswers.includes(value)) {
            return 'Hair tangles';
          }
          if (key === haircareHairVolume && haircareHasSeenProgressAnswers.includes(value)) {
            return 'Hair volume';
          }
          if (key === haircareHairMaskImpact && haircareHasSeenProgressAnswers.includes(value)) {
            return 'Hair mask nourishment';
          }
          if (key === haircareShampooImpact && haircareHasSeenProgressAnswers.includes(value)) {
            return 'Shampoo cleansing';
          }
          if (key === haircareConditionerImpact && haircareHasSeenProgressAnswers.includes(value)) {
            return 'Conditioning level';
          }
          if (
            key === haircareHairOilNourishment &&
            haircareHasSeenProgressAnswers.includes(value)
          ) {
            return 'Hair oil nourishment';
          }
          if (key === haircareHairOilFrizz && haircareHasSeenProgressAnswers.includes(value)) {
            return 'Hair oil frizz reduction';
          }
          if (
            key === haircareDryShampooCleansing &&
            haircareHasSeenProgressAnswers.includes(value)
          ) {
            return 'Dry shampoo cleansing';
          }
          if (key === haircareDryShampooVolume && haircareHasSeenProgressAnswers.includes(value)) {
            return 'Dry shampoo volume';
          }
          if (
            key === haircareLeaveinConditionerManageable &&
            haircareHasSeenProgressAnswers.includes(value)
          ) {
            return 'Leave-in conditioner manageability';
          }
          if (
            key === haircareLeaveinConditionerTangles &&
            haircareHasSeenProgressAnswers.includes(value)
          ) {
            return 'Leave-in conditioner detangling';
          }
          if (
            key === haircareLeaveinConditionerFrizz &&
            haircareHasSeenProgressAnswers.includes(value)
          ) {
            return 'Leave-in conditioner frizz reduction';
          }
          if (
            key === haircareLeaveinConditionerMoisture &&
            haircareHasSeenProgressAnswers.includes(value)
          ) {
            return 'Leave-in conditioner moisturization';
          }
          if (
            key === haircareCurlCreamDefinition &&
            haircareHasSeenProgressAnswers.includes(value)
          ) {
            return 'Curl cream definition';
          }
          if (key === haircareCurlCreamFrizz && haircareHasSeenProgressAnswers.includes(value)) {
            return 'Curl cream frizz';
          }
          if (key === haircareCurlCreamMoisture && haircareHasSeenProgressAnswers.includes(value)) {
            return 'Curl cream moisturization';
          }
          if (
            key === haircareStylingGelFlexible &&
            haircareHasSeenProgressAnswers.includes(value)
          ) {
            return 'Styling Gel flexibility';
          }
          if (key === haircareStylingGelHold && haircareHasSeenProgressAnswers.includes(value)) {
            return 'Styling Gel hold';
          }
          return null;
        })
        .filter(el => el !== null)
    );
  }
);

export const getSkincareMaintainProgressList = createSelector(
  (_state, { answers } = {}) => answers,
  answers =>
    answers &&
    Object.entries(answers)
      .map(([key, value]) => {
        if (key === skincareHydration && skincareHasSeenProgressAnswers.includes(value)) {
          return 'Hydration';
        }
        if (key === skincareExfoliation && skincareHasSeenProgressAnswers.includes(value)) {
          return 'Radiance';
        }
        if (key === skincareEventone && skincareHasSeenProgressAnswers.includes(value)) {
          return 'Skintone';
        }
        if (key === skincareRescue && skincareHasSeenProgressAnswers.includes(value)) {
          return 'Sensitivity';
        }
        if (key === skincareAntiRedness && skincareHasSeenProgressAnswers.includes(value)) {
          return 'Redness';
        }
        return null;
      })
      .filter(el => el !== null)
);

export const getHaircareFocusImprovingList = createSelector(
  (_state, { answers } = {}) => answers,
  answers => {
    return (
      answers &&
      Object.entries(answers)
        .map(([key, value]) => {
          if (
            key === haircareScalpSensitivity &&
            haircareHasSeenNoProgressAnswers.includes(value)
          ) {
            return 'Scalp sensitivity';
          }
          if (key === haircareScalpFlakiness && haircareHasSeenNoProgressAnswers.includes(value)) {
            return 'Scalp flakiness';
          }
          if (key === haircareScalpOiliness && haircareHasSeenNoProgressAnswers.includes(value)) {
            return 'Scalp oiliness';
          }
          if (key === haircareHairDryness && haircareHasSeenNoProgressAnswers.includes(value)) {
            return 'Hair dryness';
          }
          if (key === haircareHairShine && haircareHasSeenNoProgressAnswers.includes(value)) {
            return 'Hair shine';
          }
          if (
            key === haircareHairCurlDefinition &&
            haircareHasSeenNoProgressAnswers.includes(value)
          ) {
            return 'Hair curl definitiion';
          }
          if (key === haircareHairFrizziness && haircareHasSeenNoProgressAnswers.includes(value)) {
            return 'Hair frizziness';
          }
          if (key === haircareHairTangles && haircareHasSeenNoProgressAnswers.includes(value)) {
            return 'Hair tangles';
          }
          if (key === haircareHairVolume && haircareHasSeenNoProgressAnswers.includes(value)) {
            return 'Hair volume';
          }
          if (
            key === haircareHairMaskImpact &&
            (value === haircareSomeImprovements || value === haircareTooHeavy)
          ) {
            return 'Hair mask nourishment';
          }
          if (
            key === haircareShampooImpact &&
            haircareHasSeenNoProgressOtherAnswers.includes(value)
          ) {
            return 'Shampoo cleansing';
          }
          if (
            key === haircareConditionerImpact &&
            haircareHasSeenNoProgressOtherAnswers.includes(value)
          ) {
            return 'Conditioning level';
          }
          if (
            key === haircareHairOilNourishment &&
            haircareHasSeenNoProgressAnswers.includes(value)
          ) {
            return 'Hair oil nourishment';
          }
          if (key === haircareHairOilNourishment && value === haircareTooHeavy) {
            return 'Hair oil heaviness';
          }
          if (key === haircareHairOilFrizz && haircareHasSeenNoProgressAnswers.includes(value)) {
            return 'Hair oil frizz reduction';
          }
          if (
            key === haircareDryShampooCleansing &&
            haircareHasSeenNoProgressAnswers.includes(value)
          ) {
            return 'Dry shampoo cleansing';
          }
          if (
            key === haircareDryShampooVolume &&
            haircareHasSeenNoProgressAnswers.includes(value)
          ) {
            return 'Dry shampoo volume';
          }
          if (
            key === haircareLeaveinConditionerManageable &&
            haircareHasSeenNoProgressAnswers.includes(value)
          ) {
            return 'Leave-in conditioner manageability';
          }
          if (
            key === haircareLeaveinConditionerTangles &&
            (value === haircareSomeImprovements ||
              value === haircareNoImprovements ||
              haircareHasSeenNoProgressOtherAnswers.includes(value))
          ) {
            return 'Leave-in conditioner detangling';
          }
          if (
            key === haircareLeaveinConditionerFrizz &&
            haircareHasSeenNoProgressAnswers.includes(value)
          ) {
            return 'Leave-in conditioner frizz reduction';
          }
          if (
            key === haircareLeaveinConditionerMoisture &&
            haircareHasSeenNoProgressAnswers.includes(value)
          ) {
            return 'Leave-in conditioner moisturization';
          }
          if (
            key === haircareCurlCreamDefinition &&
            (value === haircareSomeImprovements ||
              value === haircareNoImprovements ||
              haircareHasSeenNoProgressOtherAnswers.includes(value))
          ) {
            return 'Curl cream definition';
          }
          if (key === haircareCurlCreamFrizz && haircareHasSeenNoProgressAnswers.includes(value)) {
            return 'Curl cream frizz reduction';
          }
          if (
            key === haircareCurlCreamMoisture &&
            haircareHasSeenNoProgressAnswers.includes(value)
          ) {
            return 'Curl cream moisturization';
          }
          if (key === haircareCurlCreamMoisture && value === haircareTooHeavy) {
            return 'Curl cream heaviness';
          }
          if (
            key === haircareStylingGelFlexible &&
            haircareHasSeenNoProgressAnswers.includes(value)
          ) {
            return 'Styling Gel flexibility';
          }
          if (key === haircareStylingGelHold && haircareHasSeenNoProgressAnswers.includes(value)) {
            return 'Styling Gel hold';
          }
          return null;
        })
        .filter(el => el !== null)
    );
  }
);

export const getSkincareFocusImprovingList = createSelector(
  (_state, { answers } = {}) => answers,
  answers =>
    answers &&
    Object.entries(answers)
      .map(([key, value]) => {
        if (key === skincareAntiShine && skincareHasSeenNoProgressAnswers.includes(value)) {
          return 'Oiliness';
        }
        if (key === skincareAntiBlemish && skincareHasSeenNoProgressAnswers.includes(value)) {
          return 'Blemish';
        }
        if (key === skincareAntiWrinkles && skincareHasSeenNoProgressAnswers.includes(value)) {
          return 'Plumpness';
        }
        if (key === skincareFirming && skincareHasSeenNoProgressAnswers.includes(value)) {
          return 'Firmness';
        }
        return null;
      })
      .filter(el => el !== null)
);

export const getHaircareFragranceRelatedFormattedAnswers = createSelector(
  (_state, { answers } = {}) => answers,
  answers => {
    const formattedAnswers = [];

    if (answers?.fragrance_new_choice) {
      formattedAnswers.push(
        capitalizeFirstLetter(
          answers?.fragrance_new_choice === fragFreeLabel
            ? FRAGRANCE_FREE.name
            : answers?.fragrance_new_choice
        )
      );
    }

    if (answers?.fragrance_new_choice_hair_oil) {
      formattedAnswers.push(
        `Hair Oil: ${capitalizeFirstLetter(
          answers?.fragrance_new_choice_hair_oil === 'FRAGRANCE_FREE'
            ? FRAGRANCE_FREE.name
            : answers?.fragrance_new_choice_hair_oil
        )}`
      );
    }
    return formattedAnswers;
  }
);

export const getSkincareFragrance = createSelector(
  (_state, { answers } = {}) => answers,
  answers =>
    answers?.fragrance_new_choice === fragFreeLabel
      ? FRAGRANCE_FREE.name
      : answers?.fragrance_new_choice
);

export const getSkincareAdjustFragranceIntensityList = createSelector(
  (_state, { answers }) => answers,
  answers => {
    const adjustedFragrances = {
      toReinforce: [],
      toReduce: [],
    };

    if (answers) {
      Object.entries(answers)
        .map(([key, value]) => {
          if (key === cleanserFragranceSatisfactionIntensity) {
            if (value === tooStrong) {
              adjustedFragrances.toReduce.push('Cleanser');
            }
            if (value === tooLight) {
              adjustedFragrances.toReinforce.push('Cleanser');
            }
          }
          if (key === serumFragranceSatisfactionIntensity) {
            if (value === tooStrong) {
              adjustedFragrances.toReduce.push('Serum');
            }
            if (value === tooLight) {
              adjustedFragrances.toReinforce.push('Serum');
            }
          }
          if (key === moisturizerFragranceSatisfactionIntensity) {
            if (value === tooStrong) {
              adjustedFragrances.toReduce.push('Moisturizer');
            }
            if (value === tooLight) {
              adjustedFragrances.toReinforce.push('Moisturizer');
            }
          }
          return null;
        })
        .filter(el => el !== null);
    }
    return adjustedFragrances;
  }
);

export const getProductsTextures = createSelector(
  (_state, { answers }) => answers,
  answers =>
    answers && {
      cleanser: answers.pref_texture_cleanser,
      moisturizer: answers.pref_texture_moisturizer,
    }
);

export const getSupplementsMaintainProgressList = createSelector(
  (_state, { answers } = {}) => answers,
  answers => {
    return (
      answers &&
      Object.entries(answers)
        .map(([key, value]) => {
          if (key === supplementsHairGrowth && supplementsHasSeenProgressAnswers.includes(value)) {
            return 'Hair growth';
          }
          if (key === supplementsThickness && supplementsHasSeenProgressAnswers.includes(value)) {
            return 'Hair thickness';
          }
          if (
            key === supplementsScalpComfort &&
            supplementsHasSeenProgressAnswers.includes(value)
          ) {
            return 'Scalp comfort';
          }
          if (
            key === supplementsLessShedding &&
            supplementsHasSeenProgressAnswers.includes(value)
          ) {
            return 'Less shedding';
          }
          if (key === supplementsBabyHairs && supplementsHasSeenProgressAnswers.includes(value)) {
            return 'New baby hairs';
          }
          if (key === supplementsScalpFlakes && supplementsHasSeenProgressAnswers.includes(value)) {
            return 'Scalp flakiness';
          }
          if (key === supplementsShine && supplementsHasSeenProgressAnswers.includes(value)) {
            return 'Hair shine';
          }
          if (key === supplementsScalpOil && supplementsHasSeenProgressAnswers.includes(value)) {
            return 'Scalp oiliness';
          }
          if (key === supplementsSoftness && supplementsHasSeenProgressAnswers.includes(value)) {
            return 'Hair softness';
          }
          return null;
        })
        .filter(el => el !== null)
    );
  }
);

export const getSupplementsFocusImprovingList = createSelector(
  (_state, { answers } = {}) => answers,
  answers => {
    return (
      answers &&
      Object.entries(answers)
        .map(([key, value]) => {
          if (
            key === supplementsHairGrowth &&
            supplementsHasSeenNoProgressAnswers.includes(value)
          ) {
            return 'Hair growth';
          }
          if (key === supplementsThickness && supplementsHasSeenNoProgressAnswers.includes(value)) {
            return 'Hair thickness';
          }
          if (
            key === supplementsScalpComfort &&
            supplementsHasSeenNoProgressAnswers.includes(value)
          ) {
            return 'Scalp comfort';
          }
          if (
            key === supplementsLessShedding &&
            supplementsHasSeenNoProgressAnswers.includes(value)
          ) {
            return 'Less shedding';
          }
          if (key === supplementsBabyHairs && supplementsHasSeenNoProgressAnswers.includes(value)) {
            return 'New baby hairs';
          }
          if (
            key === supplementsScalpFlakes &&
            supplementsHasSeenNoProgressAnswers.includes(value)
          ) {
            return 'Scalp flakiness';
          }
          if (key === supplementsShine && supplementsHasSeenNoProgressAnswers.includes(value)) {
            return 'Hair shine';
          }
          if (key === supplementsScalpOil && supplementsHasSeenNoProgressAnswers.includes(value)) {
            return 'Scalp oiliness';
          }
          if (key === supplementsSoftness && supplementsHasSeenNoProgressAnswers.includes(value)) {
            return 'Hair softness';
          }
          return null;
        })
        .filter(el => el !== null)
    );
  }
);

export const getSupplementsProgressPhases = createSelector(
  (_state, { answers } = {}) => answers,
  answers => {
    if (!answers) return null;

    return {
      phase1: supplementsLessThanTwoMonths.includes(answers.product_supplements_usage_duration)
        ? 0
        : answers.product_supplements_usage_duration === supplementsTwoMonths
        ? 50
        : 100,
      phase2: supplementsLessThanThreeMonths.includes(answers.product_supplements_usage_duration)
        ? 0
        : answers.product_supplements_usage_duration === supplementsThreeMonths
        ? 25
        : answers.product_supplements_usage_duration === supplementsFourMonths
        ? 50
        : answers.product_supplements_usage_duration === supplementsFiveMonths
        ? 75
        : 100,
      phase3: supplementsLessThanSixMonths.includes(answers.product_supplements_usage_duration)
        ? 0
        : 50,
    };
  }
);

/**
 * Feedback end-of-the-road message
 */

export const getFeedbackEndOfRoadMessage = createSelector(getFeedback, get('endOfTheRoadMessage'));

/**
 * Consultation survey
 */

export const getConsultationSurveyDetails = createSelector(getFeedback, get('consultationSurvey'));

export const getConsultationSurveyTextures = createSelector(
  getConsultationSurveyDetails,
  answers => ({
    cleanser: answers.pref_texture_cleanser,
    moisturizer: answers.pref_texture_moisturizer,
  })
);

export const getConsultationSurveyPubkeyFromOrder = createSelector(
  getHaircareOrderDetails,
  getSkincareOrderDetails,
  (haircare, skincare) => ({
    [feedbackCategories.HAIRCARE]: get('formulaset.survey')(haircare),
    [feedbackCategories.SKINCARE]: get('formulaset.survey')(skincare),
  })
);

export const getSkincareConsultationSurveyPubkeyFromOrder = createSelector(
  getSkincareOrderDetails,
  (haircare, skincare) => ({
    [feedbackCategories.HAIRCARE]: get('formulaset.survey')(haircare),
    [feedbackCategories.SKINCARE]: get('formulaset.survey')(skincare),
  })
);

export const getConsultationSurveyFragrance = createSelector(
  getConsultationSurveyDetails,
  answers => (answers?.pref_fragrance_free ? FRAGRANCE_FREE.name : answers?.pref_fragrance)
);

export const getSkincareConsultationSurveyFragrance = createSelector(
  getConsultationSurveyDetails,
  answers =>
    answers?.pref_fragrance_free_skin ? FRAGRANCE_FREE.name : answers?.pref_fragrance_skin
);

export const getConsultationSurveyIsSoldoutFragrance = createSelector(
  getConsultationSurveyDetails,
  getOrNil(false, 'is_soldout_fragrance')
);

export const isDataLoadingForFeedbackResult = createSelector(
  getSkincareFocusImprovingList,
  getSkincareMaintainProgressList,
  getSkincareAdjustFragranceIntensityList,
  getConsultationSurveyTextures,
  getSkincareConsultationSurveyFragrance,
  getProductsTextures,
  getSkincareFragrance,
  (
    improvingData,
    progressingData,
    adjustingData,
    previousTexturesData,
    previousFragranceData,
    newTexturesData,
    newFragranceData
  ) =>
    // If one of the set of data is null, we return true to display the loader
    isNull(improvingData) ||
    isNull(progressingData) ||
    isNull(adjustingData) ||
    isNull(previousTexturesData) ||
    isNull(previousFragranceData) ||
    isNull(newTexturesData) ||
    isNull(newFragranceData)
);
