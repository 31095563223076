import * as feedbackComponents from 'Apps/Feedback/constants/feedbackComponents';
import { PRODUCTS } from 'Apps/Feedback/constants/feedbackSections';
import { skincareCleanserTexture } from 'Apps/Feedback/constants/feedbackSlugs';

import { feedbackCategories } from 'constants/feedback';
import { productsSlugs } from 'constants/products';

const cleanserTexture = {
  name: skincareCleanserTexture,
  route: `/feedback/${feedbackCategories.SKINCARE}/cleanser-texture`,
  category: PRODUCTS,
  productSlug: productsSlugs.CLEANSER, // this assumes that the textures for the Minis are the same as the Full-size
  feedbackCategory: feedbackCategories.SKINCARE,
  shouldBeIncluded: ({ formulas, feedbackAnswers }) =>
    formulas?.some(f => f.variant.product.type === productsSlugs.CLEANSER_MINI) &&
    feedbackAnswers?.cleanser_satisfaction,
  title: 'Which cleanser texture do you want for your next order?',
  getInitialValue: answers => ({
    pref_texture_cleanser: answers?.pref_texture_cleanser,
  }),
  getAnswers: ({ selected }) => ({
    pref_texture_cleanser: selected,
  }),
  Component: feedbackComponents.TextureContainer,
};

export default cleanserTexture;
