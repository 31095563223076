import { appApi } from 'dux/app/api';

import type { PrescriptionRaw } from './types';

const prescriptionApiSlice = appApi.injectEndpoints({
  endpoints: (build) => ({
    getPrescription: build.query<PrescriptionRaw, void>({
      query: () => ({
        url: `v1/account/hair_profile/prescription/`,
        endpointName: 'prescriptionQuery',
      }),
      providesTags: ['prescription'],
    }),
  }),
});

export const {
  useGetPrescriptionQuery,
  endpoints: { getPrescription: getPrescriptionQuery },
} = prescriptionApiSlice;
