import { PRODUCTS } from 'Apps/Feedback/constants/feedbackSections';
import { transitionProducts } from 'Apps/Feedback/constants/feedbackSlugs';

const productsTransitionScreen = {
  name: transitionProducts,
  route: `/feedback/haircare/${transitionProducts}`,
  category: PRODUCTS,
  shouldBeIncluded: () => true,
  Component: 'HaircareTransitionScreen',
  skipSave: true,
  title: "Now we'll ask you about your experience with your individual products.",
  orderInfosBlock: true,
  style: {
    mode: 'light',
  },
};

export default productsTransitionScreen;
