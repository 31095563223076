import { Link } from 'react-router';

import { FIRST_SKINCARE_QUESTION_ROUTE } from 'Apps/Consultation/constants';

import { SKINCARE_CART_URL } from 'constants/cart';

import bakuchiol from 'assets/images/ingredients/bakuchiol.png';
import ceramides from 'assets/images/ingredients/ceramides.png';
import fermentedRiceWater from 'assets/images/ingredients/fermented-rice-water.png';
import hyaluronicAcid from 'assets/images/ingredients/hyaluronic-acid.png';
import mangoLeaf from 'assets/images/ingredients/mango-leaf.png';
import niacinamide from 'assets/images/ingredients/niacinamide.png';
import squalane from 'assets/images/ingredients/squalane.png';
import blemishesAfter from 'assets/images/landingPages/skincare/ba-blemishes-after.jpg';
import blemishesBefore from 'assets/images/landingPages/skincare/ba-blemishes-before.jpg';
import fineLinesAfter from 'assets/images/landingPages/skincare/ba-fine-lines-after.jpg';
import fineLinesBefore from 'assets/images/landingPages/skincare/ba-fine-lines-before.jpg';
import hydrationAfter from 'assets/images/landingPages/skincare/ba-hydration-after.jpg';
import hydrationBefore from 'assets/images/landingPages/skincare/ba-hydration-before.jpg';
import deriskingCarbonNeutral from 'assets/images/landingPages/skincare/derisking-carbon-neutral.svg';
import deriskingFreeShipping from 'assets/images/landingPages/skincare/derisking-free-shipping.svg';
import deriskingReviewRefine from 'assets/images/landingPages/skincare/derisking-review-refine.svg';
import introductionSectionFallbackDesktop from 'assets/images/landingPages/skincare/fallback-introduction-section-desktop.jpg';
import introductionSectionFallbackMobile from 'assets/images/landingPages/skincare/fallback-introduction-section-mobile.jpg';
import peptidesImg from 'assets/images/landingPages/skincare/peptides.png';
import expertDrLJMaxfield from 'assets/images/landingPages/skincare/skincare-lp-experts-dr-maxfield.jpg';
import expertDrLJMaxfieldThumbnail from 'assets/images/landingPages/skincare/skincare-lp-experts-dr-maxfield-thumb.png';
import expertDrToralVaidya from 'assets/images/landingPages/skincare/skincare-lp-experts-dr-vaidya.jpg';
import expertDrToralVaidyaThumbnail from 'assets/images/landingPages/skincare/skincare-lp-experts-dr-vaidya-thumb.png';
import expertGinaMari from 'assets/images/landingPages/skincare/skincare-lp-experts-gina-mari.jpg';
import expertGinaMariThumbnail from 'assets/images/landingPages/skincare/skincare-lp-experts-gina-mari-thumb.png';
import vitaminCImg from 'assets/images/landingPages/skincare/vitamin_c.png';
import andyImg from 'assets/images/productPages/andy-sample-formula-card.jpg';
import kassieImg from 'assets/images/productPages/kassie-sample-formula-card.jpg';
import leslieImg from 'assets/images/productPages/leslie-sample-formula-card.jpg';
import BcorpLogo from 'assets/images/skincare/b-corp-logo.png';
import ClimateLogo from 'assets/images/skincare/climate-logo.png';
import SustainabilityImgDesktop from 'assets/images/skincare/sustainability-d.jpg';
import SustainabilityImgMobile from 'assets/images/skincare/sustainability-m.jpg';

export const introductionContent = {
  headline: 'Custom skincare for your healthiest skin ever',
  video: {
    mobile: 'skincare-lp-hero-mobile',
    desktop: 'skincare-lp-hero-desktop',
  },
  fallbackImage: {
    src: {
      mobile: introductionSectionFallbackMobile,
      desktop: introductionSectionFallbackDesktop,
    },
    alt: 'Eye dropper used on Prose skincare product',
  },
};

export const factorsContent = {
  catchline: 'Made to order for always-improving results',
  guarantee: "Love your first order (or it's on us)",
};

export const scienceVideo = {
  video: {
    videoDesktop: 'pdp-skincare-hero-desktop',
    videoMobile: 'pdp-skincare-hero-desktop',
  },
  values: [
    {
      title: (
        <span>
          15m formula
          <br />
          combinations
        </span>
      ),
      precision: 'and only one that’s uniquely yours',
      id: 1,
    },
    {
      title: (
        <span>
          80+
          <br />
          unique factors
        </span>
      ),
      precision: 'used to create your one-of-a-kind products',
      id: 2,
    },
    {
      title: (
        <span>
          2000+ people
          <br />
          tested
        </span>
      ),
      precision: 'with diverse skin types and tones',
      id: 3,
    },
  ],
};

export const beforeAndAfter = {
  title: 'REAL PEOPLE, REAL RESULTS',
  subtitle: 'Visible improvement of all skin concerns, including:',
  results: [
    {
      title: 'fine lines',
      beforeImage: fineLinesBefore,
      beforeImageAlt:
        'A close up on a man’s crows feet wrinkles before using Prose skin care lines',
      afterImage: fineLinesAfter,
      afterImageAlt: 'A close up on a man’s crows feet wrinkles after using Prose skin care lines',
    },
    {
      title: 'blemishes',
      beforeImage: blemishesBefore,
      beforeImageAlt: 'A close up on a woman’s pimples before using skin care products by Prose',
      afterImage: blemishesAfter,
      afterImageAlt: 'A close up on a woman’s pimples after using skin care products by Prose',
    },
    {
      title: 'hydration',
      beforeImage: hydrationBefore,
      beforeImageAlt: 'A close up on a woman’s dry skin before using Prose skin care routine',
      afterImage: hydrationAfter,
      afterImageAlt:
        'A close up on a woman’s dry skin immediately after using Prose skin care routine',
    },
  ],
  disclaimer:
    'Individual results after 4 weeks of using Prose Cleanser, Serum, and Moisturizer twice a day - or 1 application of Prose Moisturizer. Results may vary.',
};

export const expertsModule = {
  headlineDesktop: 'Developed by experts, loved by dermatologists',
  headlineMobile: `Developed by experts,
  loved by dermatologists`,
  subHeadline:
    'Made by our in-house cosmetic chemists and tested by renowned dermatologists — for scientifically backed formulas that address every thing affecting your skin.',
  experts: [
    {
      poster: expertDrLJMaxfield,
      videoSrc: 'skincare-lp-experts-dr-maxfield',
      alt: 'Close-up of Dr. Maxfield, Dermatologist',
      name: 'Dr. LJ Maxfield',
      description: 'DO, FAAD Board-Certified Dermatologist',
      instagramHandle: '@dr.ljmaxfield',
      instagramThumbnail: expertDrLJMaxfieldThumbnail,
      instagramThumbnailAlt: 'Dr. LJ Maxfield (@dr.ljmaxfield) instagram thumbnail',
    },
    {
      poster: expertGinaMari,
      videoSrc: 'skincare-lp-experts-gina-mari',
      alt: 'Video of Gina Mari',
      name: 'Gina MarÍ',
      description: 'Esthetician and Facialist',
      instagramHandle: '@ginamariskincare',
      instagramThumbnail: expertGinaMariThumbnail,
      instagramThumbnailAlt: 'Gina Marí (@ginamariskincare) instagram thumbnail',
    },
    {
      poster: expertDrToralVaidya,
      videoSrc: 'skincare-lp-experts-dr-vaidya',
      alt: 'Video of Dr Vaidy',
      name: 'Dr. Toral vaidya',
      description: 'MD, MPH and Dermatology Resident Physician',
      instagramHandle: '@toralmd',
      instagramThumbnail: expertDrToralVaidyaThumbnail,
      instagramThumbnailAlt: 'Dr. Toral Vaidya(@toralmd) instagram thumbnail',
    },
  ],
};

export const deriskingModuleContent = [
  {
    image: deriskingFreeShipping,
    imageAlt: '15% off + free shipping',
    description: (
      <>
        15% off + free shipping for
        <br />
        subscription orders
      </>
    ),
  },
  {
    image: deriskingReviewRefine,
    imageAlt: 'Evolving formulas',
    description: (
      <>
        Evolving formulas via
        <br />
        Review & Refine<sup>®</sup>
      </>
    ),
  },
  {
    image: deriskingCarbonNeutral,
    imageAlt: 'Carbon-neutral',
    description: (
      <>
        Carbon-neutral +<br /> B-Corp certified
      </>
    ),
  },
];

export const sustainability = {
  title: 'Putting sustainability first',
  description:
    'As a certified B-corporation, we’re big on transparency. From carbon-neutral production to sustainable sourcing, we do anything (and everything) to leave people and the planet better than we found them.',
  imageDesktop: SustainabilityImgDesktop,
  imageMobile: SustainabilityImgMobile,
  imageAlt:
    'Woman with curly hair and natural ingredients symbolizing beauty and sustainable skincare',
  logos: {
    bCorp: BcorpLogo,
    climate: ClimateLogo,
  },
  cta: {
    label: 'learn more',
    path: '/sustainability-and-social-impact',
    dataClick: 'sustainability',
    dataFrom: 'sustainability',
  },
};

export const sampleFormulationModule = {
  subTitle: 'EVERY FORMULA IS ONE OF A KIND',
  title: 'Customized for every combination of skin concerns',
  cards: [
    {
      title: 'Andy',
      description: (
        <>
          30 years old, <br />
          New York, 10013
        </>
      ),
      image: {
        src: andyImg,
        alt: 'Andy’s formula, showcasing made-to-order custom skincare personalized for his unique skin concerns and environmental factors',
      },
      concerns: 'Dry skin, hyperpigmentation, fine lines and wrinkles',
      environment: 'Pollution, strong UV rays, low humidity',
      ingredients: [
        {
          label: 'Bakuchiol',
          description: 'Helps decrease the appearance of hyperpigmentation',
          image: bakuchiol,
        },
        {
          label: 'Squalane',
          description: 'Helps minimize the appearance of wrinkles',
          image: squalane,
        },
        {
          label: 'Hyaluronic acid',
          description: 'Hydrates and helps skin look lifted',
          image: hyaluronicAcid,
        },
      ],
    },
    {
      title: 'Kassie',
      description: (
        <>
          32 years old, <br />
          Washington, 20001
        </>
      ),
      image: {
        src: kassieImg,
        alt: 'Kassie’s formula, showcasing made-to-order custom skincare personalized for her unique skin concerns and environmental factors',
      },
      concerns: 'Oily skin, hyperpigmentation, fine lines and wrinkles',
      environment: 'Low UV rays, hard water, seasonal humidity',
      ingredients: [
        {
          label: 'Niacinamide',
          description: 'Helps improve elasticity and reduce the look of fine lines',
          image: niacinamide,
        },
        {
          label: 'Peptides',
          description: 'Supports a brighter, more even skin tone',
          image: peptidesImg,
        },
        {
          label: 'Ceramides',
          description: 'Hydrates and reinforces skin barrier',
          image: ceramides,
        },
      ],
    },
    {
      title: 'Leslie',
      description: (
        <>
          29 years old, <br />
          Los Angeles, 91011
        </>
      ),
      image: {
        src: leslieImg,
        alt: 'Leslie’s formula, showcasing made-to-order custom skincare personalized for her unique skin concerns and environmental factors',
      },
      concerns: 'Oily skin, breakouts, sensitivity, redness',
      environment: 'Pollution, seasonal humidity, wind',
      ingredients: [
        {
          label: 'Mango Leaf Extract',
          description: 'Helps minimize blemishes and controls shine',
          image: mangoLeaf,
        },
        {
          label: 'Fermented Rice',
          description: 'Helps soothe and minimize the look of redness',
          image: fermentedRiceWater,
        },
        {
          label: 'Vitamin C',
          description: 'Luminates skin and reduces dark spots',
          image: vitaminCImg,
        },
      ],
    },
  ],
};

export const faq = {
  title: 'Frequently asked questions',
  questions: [
    {
      question: 'What makes Prose skincare different from other brands?',
      answer: `Prose skincare is customized to meet your skin’s unique needs, and formulated to address all combinations of skin concerns. We take into account your skin goals, lifestyle, environment, and ingredient preferences to create custom formulas that bring out your healthiest skin. All Prose skincare products are co-developed with a Skincare Advisory board made up of dermatologists and other skin experts, from cosmetic chemists to endocrinologists.`,
    },
    {
      question: 'Are the ingredients safe to use?',
      answer: (
        <>
          We believe in using the cleanest and highest-quality ingredients possible. Every
          ingredient in our skincare is non-comedogenic, and meticulously sourced and researched to
          ensure safety, quality, and efficacy. While we pull from a robust portfolio of ingredients
          to address a diverse range of needs, your custom formulas will only contain the
          ingredients that your skin needs.
          <br />
          <br />
          All Prose skincare products are cruelty-free, vegan, and free of BHT, sulfates, drying
          alcohols, silicones, and colorants.
        </>
      ),
    },
    {
      question: 'When can I expect to start seeing results with my products?',
      answer: `While some individuals may see immediate results, our chemists recommend consistent use of Prose skincare products for four weeks. This allows your skin adequate time to adjust to your custom formulas.`,
    },
    {
      question: 'Do I have to purchase the entire Prose skincare line?',
      answer: `Prose skincare products are designed to work together for the best results. We’ll recommend a complete skincare routine based on your unique consultation, but you can also opt for individual products. If you use any prescribed skincare topicals, we recommend consulting your doctor before using them with Prose skincare.`,
    },
    {
      question: `What if I have an allergy?`,
      answer: `Ensuring the safety and efficacy of your formulas is our top priority. Before placing an order, contact us here and let us know if you have any allergies or ingredient sensitivities.`,
    },
    {
      question: 'Can I try a sample of my Prose skincare?',
      answer: (
        <>
          Because all of our formulas are freshly bottled and made to order, we’re unable to offer
          samples. Not ready to take the leap? Reach out to us{' '}
          <Link to="/faq/contact-us">here</Link> with any questions or concerns — or read more about
          The Prose Promise™ <Link to="/faq/5f3da05bceb96c001a595c02">here</Link>.
        </>
      ),
    },
    {
      question: 'What if my product looks different from what’s on the website?',
      answer:
        'Because Prose products are truly custom, each formula is unique. This means that color and scent can vary slightly from formula to formula, even if you choose fragrance-free. For example, your moisturizer might appear white or slightly beige, depending on the active ingredients — but no matter the color, your products are always made to order according to our stringent safety and quality standards.',
    },
  ],
};

export const cta = {
  hasCompletedSkincareConsultation: {
    label: 'Order your prose',
    url: SKINCARE_CART_URL,
  },
  hasNotAnsweredSkincareQuestionsAndHasHaircareSubscription: {
    label: 'Get your formula',
    url: FIRST_SKINCARE_QUESTION_ROUTE,
  },
  hasNotAnsweredSkincareQuestions: {
    label: 'Get your formula',
    url: '/consultation/skincare',
  },
};

export const ticker = [
  'paraben free',
  'sulfate free',
  'phthalate free',
  'free of mineral oils',
  'free of GMOs',
  'cruelty-free',
  'carbon neutral',
];
