import { type ChangeEvent, Fragment, useEffect, useState } from 'react';

import { Link } from 'react-router';

import { useAppDispatch, useAppSelector } from 'dux/app/hooks';

import { theme } from '@prose-ui';
import { legacyTheme, styled } from '@prose-ui/legacy';

import type { StaticImageData } from 'Components/Image';
import Spacer from 'Components/Spacer';
import { TextField } from 'Components/TextField';
import { Typography } from 'Components/Typography';

import { formatPriceWithCurrency } from 'utils/currencies';
import { parseLetters } from 'utils/textUtils';

import { getIsAuthenticated } from 'dux/auth/selectors';
import {
  getCartV2Currency,
  getCartV2UpdatingStatus,
  getComputedCartV2Items,
  getItemCustomizationError,
  getItemQuantityInCartV2,
} from 'dux/cartV2/selectors';
import { useTrackCartV2OnRemoveFromCart } from 'dux/cartV2/hooks';
import {
  getCartRecommendationV2,
  updateCartCustomizationV2,
  updateCartV2ItemsQuantity,
} from 'dux/cartV2/thunks';

const Container = styled.div``;

const Title = styled(Typography)`
  font-size: 36px;
  margin-bottom: 16px;
  ${legacyTheme.breakpoints.up('md')} {
    margin-bottom: 0px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 16px;
  ${legacyTheme.breakpoints.up('md')} {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }
`;

const ProductCardContainer = styled.div`
  display: flex;
  background-color: white;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 24px;
`;

const Image = styled.img`
  width: 100%;
`;

const ImageContainer = styled.div`
  width: 68px;
`;

const Label = styled(Typography)`
  font-size: 20px;
`;

const Description = styled(Typography)`
  font-size: 12px;
`;

const RemoveButton = styled.button`
  :disabled {
    opacity: 0.5;
  }

  color: ${theme.colors.primary[400]};
  background: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
`;

const Content = styled.div`
  width: 100%;
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${theme.colors.primary[400]};
  margin: 16px 0px;
`;

const MonogramSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const MonogramDescription = styled(Typography)`
  color: ${theme.colors.primary[400]};
`;

const StyledTextField = styled(TextField)`
  width: 80px;
  padding-bottom: 0px;
`;

const StyledSelect = styled.select`
  width: 59px;
  height: 40px;
  border-color: ${theme.colors.neutral[700]};
  padding-left: 18px;
  appearance: none;
`;

const SelectContainer = styled.div`
  position: relative;
`;

const ChevronDown = styled.div`
  position: absolute;
  width: 6px;
  height: 6px;
  border-left: 1px solid;
  border-bottom: 1px solid black;
  transform: rotate(-45deg);
  top: 14px;
  right: 14px;
`;

type ProductCardProps = {
  label: string;
  /* NOTE: Typing this as any since we only check if the error is falsy in this component.
  Once the cartV2 dux is migrated, we should use the type defined in its slice or thunk. */
  monogramError?: any;
  monogram?: string;
  onSaveMonogram: (monogram: string) => void;
  disabled?: boolean;
  hasMonogram?: boolean;
  description: string;
  price: string;
  quantity: number;
  slug: string;
  onClickRemove: () => void;
  onChangeQuantity: (quantity: number) => void;
  picture: StaticImageData;
};

const ProductCard = ({
  slug,
  disabled = false,
  label,
  picture,
  description,
  quantity,
  onClickRemove,
  onChangeQuantity,
  price,
  hasMonogram = false,
  onSaveMonogram,
  monogram = '',
  monogramError = false,
}: ProductCardProps) => {
  const [monogramLocal, setMonogramLocal] = useState(monogram);

  useEffect(() => {
    setMonogramLocal(monogram);
  }, [monogram]);

  return (
    <ProductCardContainer data-testid={`${slug}-card`}>
      <ImageContainer>
        <Image alt={`${label} item`} src={picture?.src} width="100%" />
      </ImageContainer>
      <Spacer size={16} />
      <Content>
        <Label variant="p1">{label}</Label>
        <Spacer size={16} />

        <Description variant="mono1">{description}</Description>
        <Spacer size={16} />
        <FlexRow>
          <FlexRow>
            <SelectContainer>
              <StyledSelect
                aria-label="Please select a quantity"
                data-click="quantity"
                data-from={`${slug}-card`}
                data-testid="quantity-selector"
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  onChangeQuantity(parseInt(e.target.value, 10))
                }
                value={quantity?.toString()}
              >
                <option disabled value="" />
                {[...Array(4)]
                  .map((_e, i) => (i + 1).toString())
                  .map((listItem) => (
                    <option key={listItem} value={listItem}>
                      {listItem}
                    </option>
                  ))}
              </StyledSelect>
              <ChevronDown />
            </SelectContainer>
            <Spacer axis="horizontal" size={16} />
            <RemoveButton
              data-testid={`${slug}-remove-button`}
              disabled={disabled}
              onClick={onClickRemove}
            >
              <Typography variant="p3">Remove</Typography>
            </RemoveButton>
          </FlexRow>
          <div>
            <Typography variant="p1">{price}</Typography>
          </div>
        </FlexRow>
        {hasMonogram && (
          <>
            <Separator />
            <MonogramSection>
              <MonogramDescription variant="p1">
                Monogram your brush - 1-3 uppercase characters, optional.
              </MonogramDescription>
              <StyledTextField
                data-testid="monogram-editor"
                id="monogram"
                onBlur={() => {
                  if (monogram !== monogramLocal) {
                    onSaveMonogram(monogramLocal);
                  }
                }}
                onChange={async (event: ChangeEvent<HTMLInputElement>) => {
                  const monogramValue = parseLetters(event.target.value)
                    .substring(0, 3)
                    .toUpperCase();
                  setMonogramLocal(monogramValue);
                }}
                placeholder="ABC"
                style={{ color: theme.colors.neutral[900] }}
                value={monogramLocal}
              />
            </MonogramSection>
            {monogramError && (
              <Typography color="red" variant="p4">
                Error updating your monogram
              </Typography>
            )}
          </>
        )}
      </Content>
    </ProductCardContainer>
  );
};

const CardsContainer = styled.div`
  width: 100%;
`;

const CardContainer = styled.div`
  width: 100%;
  ${legacyTheme.breakpoints.up('md')} {
    width: 80%;
  }
  ${legacyTheme.breakpoints.up('lg')} {
    width: 60%;
  }
`;

type Item = {
  variant_slug: string;
  product_type: string;
  description: string;
  hasMonogram: boolean;
  label: string;
  customization?: {
    data?: {
      monograms: string;
    };
  };
  picture: StaticImageData;
  quantity: number;
  unit_price: number;
};

export const CartAccessories = () => {
  const dispatch = useAppDispatch();

  const items = useAppSelector(getComputedCartV2Items) as Item[];
  const isUpdating = useAppSelector(getCartV2UpdatingStatus);
  const currency = useAppSelector(getCartV2Currency);
  const itemCustomizationError = useAppSelector(getItemCustomizationError);
  const isAuthenticated = useAppSelector(getIsAuthenticated);
  const itemQuantityInCartV2 = useAppSelector(getItemQuantityInCartV2);

  const title = `Your cart${
    itemQuantityInCartV2 ? `(${itemQuantityInCartV2})` : ` is empty (${itemQuantityInCartV2})`
  }`;

  const updateQuantity = (quantity: number, item: Item) =>
    dispatch(
      updateCartV2ItemsQuantity({
        quantity,
        variant_slug: item.variant_slug || item.product_type,
      }),
    );

  const { onRemove: onRemoveTracking } = useTrackCartV2OnRemoveFromCart();

  const onRemove = async (item: Item) => {
    if (!isUpdating) {
      onRemoveTracking(item.variant_slug || item.product_type);
      await updateQuantity(0, item);
      dispatch(getCartRecommendationV2());
    }
  };

  return (
    <Container>
      <TitleContainer>
        <Title data-testid="cart-title-quantity" variant="h1">
          {title}
        </Title>
        {!isAuthenticated && (
          <Typography variant="p1">
            Already have an account? <Link to="/signin?next=/checkout/accessories">Sign in</Link>
          </Typography>
        )}
      </TitleContainer>
      <CardsContainer>
        {items.map((item) => (
          <Fragment key={item.variant_slug || item.product_type}>
            <CardContainer>
              <ProductCard
                description={item.description}
                disabled={isUpdating}
                hasMonogram={item.hasMonogram}
                label={item.label}
                monogram={item.customization?.data?.monograms}
                monogramError={itemCustomizationError}
                onChangeQuantity={(newQuantity) => !isUpdating && updateQuantity(newQuantity, item)}
                onClickRemove={() => onRemove(item)}
                onSaveMonogram={(monograms) => {
                  if (monograms !== item.customization?.data?.monograms) {
                    dispatch(
                      /* @ts-expect-error - cartV2 has no TS types for now */
                      updateCartCustomizationV2({
                        item,
                        data: { monograms },
                      }),
                    );
                  }
                }}
                picture={item.picture}
                price={formatPriceWithCurrency({
                  price: item.unit_price.toFixed(2),
                  currency,
                })}
                quantity={item.quantity}
                slug={item.variant_slug || item.product_type}
              />
            </CardContainer>
            <Spacer size={16} />
          </Fragment>
        ))}
      </CardsContainer>
    </Container>
  );
};
