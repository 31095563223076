import * as feedbackComponents from 'Apps/Feedback/constants/feedbackComponents';
import { OVERALL } from 'Apps/Feedback/constants/feedbackSections';

import { feedbackCategories } from 'constants/feedback';

import allCategoriesTransitionDesktop from 'assets/images/reviewAndRefine/all-categories-transition-intro-desktop-2x.jpg';
import allCategoriesTransitionMobile from 'assets/images/reviewAndRefine/all-categories-transition-intro-mobile-3x.jpg';

const skincareTransitionScreen = {
  name: '',
  route: '/feedback/skincare',
  shouldBeIncluded: () => true,
  category: OVERALL,
  Component: feedbackComponents.SkincareTransitionScreen,
  skipSave: true,
  title: 'Give us feedback on your skincare routine',
  subtitle:
    'Review your recent products and let us know how you’re progressing with your skin goals. We’ll tailor your formulas to your current needs.',
  paragraphs: [],
  legalNotice: 'Your review may be made public to share your experience with our community. ',
  ctaNextText: "Let's get started",
  feedbackCategory: feedbackCategories.SKINCARE,
  style: {
    mode: 'dark',
    backgroundImages: {
      desktop: allCategoriesTransitionDesktop,
      mobile: allCategoriesTransitionMobile,
      tablet: allCategoriesTransitionMobile,
    },
  },
};

export default skincareTransitionScreen;
