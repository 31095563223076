import * as feedbackComponents from 'Apps/Feedback/constants/feedbackComponents';
import { OVERALL } from 'Apps/Feedback/constants/feedbackSections';

import { feedbackCategories } from 'constants/feedback';

import allCategoriesTransitionDesktop from 'assets/images/reviewAndRefine/all-categories-transition-intro-desktop-2x.jpg';
import allCategoriesTransitionMobile from 'assets/images/reviewAndRefine/all-categories-transition-intro-mobile-3x.jpg';

const skincareTransitionScreen = {
  name: '',
  route: `/feedback/${feedbackCategories.SKINCARE}`,
  shouldBeIncluded: () => true,
  category: OVERALL,
  Component: feedbackComponents.SkincareTransitionScreen,
  skipSave: true,
  title: 'Give us feedback on your Skincare Starter Set',
  subtitle:
    'Answer a few questions about your product texture + fragrance so we can refine your full-size routine. Keep up consistent use and expect visible results after week 4. Your Review & Refine process will be more in-depth when you review your full-size products.',
  paragraphs: [],
  legalNotice: 'Your review may be made public to share your experience with our community.',
  ctaNextText: "Let's get started",
  feedbackCategory: feedbackCategories.SKINCARE,
  style: {
    mode: 'dark',
    backgroundImages: {
      desktop: allCategoriesTransitionDesktop,
      mobile: allCategoriesTransitionMobile,
      tablet: allCategoriesTransitionMobile,
    },
  },
};

export default skincareTransitionScreen;
