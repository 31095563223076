import { useState } from 'react';

import { useAppSelector } from 'dux/app/hooks';

import { theme } from '@prose-ui';
import { useTrialOfferPromotionModalContent } from 'hooks/useRedeemCouponForTrialOffer';

import { FIRST_CONSULTATION_ROUTE } from 'Apps/Consultation/constants';

import { BottomModal } from 'Components/BottomModal';

import { HAIRCARE_CART_URL, SKINCARE_CART_URL } from 'constants/cart';
import { type Currency } from 'constants/currencies';

import { BaseBanner, LongLinkCta, StyledLinkedCTA } from 'Blocks/Banner/BannerComponents';

import {
  skincareMinisDisplayLogic,
  trialOfferDisplayLogic,
  trialOfferMixedWithSkincareMinisDisplayLogic,
} from 'layouts/offerDisplayLogic';

import * as bannerContent from 'assets/content/banner';
import trialOfferClawClipperGWPMixedWithSkincareMinisModalContent from 'assets/content/promotions/trialOfferClawClipperGWPMixedWithSkincareMinisModal';
import trialOfferGWPMixedWithSkincareMinisModalContent from 'assets/content/promotions/trialOfferGWPMixedWithSkincareMinisModal';
import trialOfferHeatlessCurlerGWPMixedWithSkincareMinisModalContent from 'assets/content/promotions/trialOfferHeatlessCurlerGWPMixedWithSkincareMinisModal';
import trialOfferMixedWithSkincareMinisModalContent from 'assets/content/promotions/trialOfferMixedWithSkincareMinisModal';
import trialOfferPetitToteGWPMixedWithSkincareMinisModalContent from 'assets/content/promotions/trialOfferPetitToteGWPMixedWithSkincareMinisModal';
import trialOfferToiletryBagGWPMixedWithSkincareMinisModalContent from 'assets/content/promotions/trialOfferToiletryBagGWPMixedWithSkincareMinisModal';
import trialOfferWideToothCombGWPMixedWithSkincareMinisModalContent from 'assets/content/promotions/trialOfferWideToothCombGWPMixedWithSkincareMinisModal';
import { skincareMinis } from 'assets/content/skincareMinisModal';

import {
  trialOfferClawClipperGwp,
  trialOfferEvergreen,
  trialOfferHeatlessCurlSetGwp,
  trialOfferPetitToteGwp,
  trialOfferToiletryBagGwp,
  trialOfferWideToothCombGwp,
} from 'dux/featureFlags/selectors';
import { getUserCurrency } from 'dux/user/selectors';

type ShouldRenderOptions = {
  showTrialOffer?: boolean;
  pathname?: string;
  showSkincareStarterSetPromo?: boolean;
  hasHaircareSubscriptionInAnyState?: boolean;
  hasSkincareSubscriptionInAnyState?: boolean;
  locationPathname?: string;
  locationSearchParams?: URLSearchParams;
  hasHairAndSkinSubscriptions?: boolean;
  hasCompletedHaircareConsultation?: boolean;
  hasCompletedSkincareConsultation?: boolean;
  hasStartedHaircareConsultation?: boolean;
  hasStartedSkincareConsultation?: boolean;
  showPromotionAccessories2023?: boolean;
  isFlagsDoneFetching?: string;
  postPurchaseCrossSellVariantName?: string;
};

type RenderOptions = {
  couponsStatuses: {
    coupons: [
      {
        code: string;
        is_attached_to_customer: boolean;
        status: 'created' | 'redeem';
      },
    ];
  };
  hasHaircareSubscription: boolean;
  hasSkincareSubscription: boolean;
  hasCompletedHaircareConsultation: boolean;
  hasCompletedSkincareConsultation: boolean;
};

export const defaultBanner = {
  shouldRender: () => true, // This should always return true
  render: () => (
    <BaseBanner backgroundColor={theme.colors.primary[400]} event="Default Banner" hasLink>
      <LongLinkCta data-click="refer" data-from="banner" data-testid="default-banner" to="/reviews">
        {bannerContent.defaultContent}
      </LongLinkCta>
    </BaseBanner>
  ),
};

const RenderTrialOfferBanner = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { content, variant, category } = useTrialOfferPromotionModalContent();

  return (
    <>
      <BottomModal
        content={content?.bottomModal}
        dataFrom="membership-modal"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        testPrefix={`bottom-modal-trial-offer-${variant}`}
      />
      <BaseBanner
        backgroundColor={theme.colors.primary[400]}
        buttonProps={{
          ariaLabel: 'Open trial offer modal',
          onClick: () => setIsOpen(true),
          dataClick: 'offer-popup',
          dataFrom: 'banner',
        }}
        dataTestId={`banner-trial-offer-${variant}`}
        event={`Trial Offer Banner - ${variant} - ${category}`}
      >
        {content?.banner}
      </BaseBanner>
    </>
  );
};

const trialOfferVariantContent = {
  [trialOfferToiletryBagGwp]: trialOfferToiletryBagGWPMixedWithSkincareMinisModalContent,
  [trialOfferWideToothCombGwp]: trialOfferWideToothCombGWPMixedWithSkincareMinisModalContent,
  [trialOfferPetitToteGwp]: trialOfferPetitToteGWPMixedWithSkincareMinisModalContent,
  [trialOfferClawClipperGwp]: trialOfferClawClipperGWPMixedWithSkincareMinisModalContent,
  [trialOfferHeatlessCurlSetGwp]: trialOfferHeatlessCurlerGWPMixedWithSkincareMinisModalContent,
};

const RenderTrialOfferMixedSkincareMinisBanner = () => {
  const [isOpen, setIsOpen] = useState(false);
  const currency: Currency = useAppSelector(getUserCurrency);
  const { variant } = useTrialOfferPromotionModalContent();

  const content =
    variant !== trialOfferEvergreen
      ? // @ts-expect-error useTrialOfferPromotionModalContent is not typed
        trialOfferVariantContent[variant] || trialOfferGWPMixedWithSkincareMinisModalContent
      : trialOfferMixedWithSkincareMinisModalContent;

  return (
    <>
      <BottomModal
        backgroundColor={theme.colors.tertiary[200]}
        content={content.bottomModal[currency]}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
      />
      <BaseBanner
        backgroundColor={theme.colors.primary[400]}
        buttonProps={{
          ariaLabel: 'Open skincare minis + starter set offer modal',
          onClick: () => setIsOpen(true),
          dataClick: 'offer-popup',
          dataFrom: 'banner',
        }}
        event={`Trial Offer Mixed With Skincare Minis Banner - ${variant}`}
      >
        {content.banner}
      </BaseBanner>
    </>
  );
};

const RenderSkincareMinisBanner = () => {
  const [isOpen, setIsOpen] = useState(false);
  const userCurrency: Currency = useAppSelector(getUserCurrency);

  return (
    <>
      <BottomModal
        backgroundColor={theme.colors.tertiary[200]}
        content={skincareMinis.bottomModal[userCurrency]}
        dataFrom="membership-modal"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        testPrefix="bottom-modal-skincare-minis"
      />
      <BaseBanner
        backgroundColor={theme.colors.primary[400]}
        buttonProps={{
          ariaLabel: 'Open skincare minis offer modal',
          onClick: () => setIsOpen(true),
          dataClick: 'offer-popup',
          dataFrom: 'banner',
        }}
        dataTestId="banner-skincare-minis"
        event="Skincare Minis Banner"
      >
        {skincareMinis.banner}
      </BaseBanner>
    </>
  );
};

export const trialOfferBanner = {
  shouldRender: ({ showTrialOffer }: ShouldRenderOptions) =>
    trialOfferDisplayLogic({
      showTrialOffer,
    }),
  render: () => <RenderTrialOfferBanner />,
};

export const skincareMinisBanner = {
  shouldRender: ({
    showSkincareStarterSetPromo,
    locationPathname,
    hasHaircareSubscriptionInAnyState,
    hasSkincareSubscriptionInAnyState,
  }: ShouldRenderOptions) =>
    skincareMinisDisplayLogic({
      showSkincareStarterSetPromo,
      hasHaircareSubscriptionInAnyState,
      pathname: locationPathname,
      hasSkincareSubscriptionInAnyState,
    }),
  render: () => <RenderSkincareMinisBanner />,
};

export const trialOfferMixedSkincareMinisBanner = {
  shouldRender: ({
    showTrialOffer,
    showSkincareStarterSetPromo,
    hasHaircareSubscriptionInAnyState,
    hasSkincareSubscriptionInAnyState,
    locationPathname,
  }: ShouldRenderOptions) =>
    trialOfferMixedWithSkincareMinisDisplayLogic({
      pathname: locationPathname,
      showTrialOffer,
      showSkincareStarterSetPromo,
      hasHaircareSubscriptionInAnyState,
      hasSkincareSubscriptionInAnyState,
    }),

  render: () => <RenderTrialOfferMixedSkincareMinisBanner />,
};

export const checkoutMessage = {
  shouldRender: ({ locationPathname }: ShouldRenderOptions) =>
    locationPathname
      ? /\/checkout\/(haircare|skincare)\/(account-details|shipping-address|payment)/.test(
          locationPathname,
        )
      : false,
  render: () => (
    <BaseBanner
      backgroundColor={theme.colors.primary[400]}
      color={theme.colors.neutral[100]}
      dataTestId="satisfaction-guarantee-banner"
      event="Checkout message banner"
    >
      Love your first order (or it&apos;s on us)
    </BaseBanner>
  ),
};

export const noFreeShippingContent = {
  shouldRender: ({ locationSearchParams }: ShouldRenderOptions) => {
    return locationSearchParams?.has('origin') && locationSearchParams?.get('origin') === 'yjfwm';
  },
  render: () => (
    <BaseBanner
      backgroundColor={theme.colors.primary[400]}
      dataTestId="no-free-shipping-content-banner"
      event="No Free Shipping Content Banner"
      hasLink
    >
      {bannerContent.noFreeShippingContent}
    </BaseBanner>
  ),
};

export const referralCta = {
  shouldRender: ({ hasHairAndSkinSubscriptions }: ShouldRenderOptions) =>
    hasHairAndSkinSubscriptions,
  render: () => (
    <BaseBanner backgroundColor={theme.colors.primary[400]} event="Referral CTA Banner">
      <StyledLinkedCTA
        data-click="refer"
        data-from="banner"
        data-testid="referral-cta-banner"
        to="/account/refer-a-friend"
      >
        {bannerContent.referralCta}
      </StyledLinkedCTA>
    </BaseBanner>
  ),
};

export const membershipCta = {
  shouldRender: ({
    hasCompletedHaircareConsultation,
    hasCompletedSkincareConsultation,
    hasStartedHaircareConsultation,
    hasStartedSkincareConsultation,
  }: ShouldRenderOptions) =>
    hasCompletedHaircareConsultation ||
    hasCompletedSkincareConsultation ||
    (hasStartedHaircareConsultation && hasStartedSkincareConsultation),
  render: ({
    hasCompletedHaircareConsultation,
    hasCompletedSkincareConsultation,
  }: RenderOptions) => (
    <BaseBanner
      backgroundColor={theme.colors.tertiary[200]}
      color={theme.colors.neutral[900]}
      event={`Membership CTA Banner - ${
        hasCompletedHaircareConsultation
          ? 'Haircare'
          : hasCompletedSkincareConsultation
          ? 'Skincare'
          : 'Both'
      }`}
      hasLink
    >
      <StyledLinkedCTA
        color={theme.colors.primary[400]}
        data-click="membership-lp-prospects"
        data-from="banner"
        data-testid="membership-cta"
        to={
          hasCompletedHaircareConsultation
            ? HAIRCARE_CART_URL
            : hasCompletedSkincareConsultation
            ? SKINCARE_CART_URL
            : FIRST_CONSULTATION_ROUTE
        }
      >
        {bannerContent.membershipCta}
      </StyledLinkedCTA>
    </BaseBanner>
  ),
};

export const loading = {
  shouldRender: ({ isFlagsDoneFetching }: ShouldRenderOptions) => !isFlagsDoneFetching,
  render: () => (
    <BaseBanner
      backgroundColor={theme.colors.neutral[100]}
      dataTestId="loading-banner"
      event="Loading Banner"
    />
  ),
};

export const membershipMultiCategoryWithoutSubscription = {
  shouldRender: ({
    hasCompletedHaircareConsultation,
    hasCompletedSkincareConsultation,
    hasStartedHaircareConsultation,
    hasStartedSkincareConsultation,
  }: ShouldRenderOptions) =>
    (hasCompletedHaircareConsultation && hasCompletedSkincareConsultation) ||
    (hasCompletedHaircareConsultation && hasStartedSkincareConsultation) ||
    (hasCompletedSkincareConsultation && hasStartedHaircareConsultation),
  render: ({
    hasCompletedHaircareConsultation,
    hasCompletedSkincareConsultation,
  }: RenderOptions) => (
    <BaseBanner
      backgroundColor={theme.colors.tertiary[200]}
      color={theme.colors.neutral[900]}
      dataTestId="membership-multi-category-banner-without-subscription"
      event={`Membership Multi Category Without Subscription CTA Banner - ${
        hasCompletedHaircareConsultation && hasCompletedSkincareConsultation
          ? 'Account reorder'
          : hasCompletedHaircareConsultation
          ? 'Skincare consultation'
          : 'Haircare consultation'
      }`}
    >
      <StyledLinkedCTA
        color={theme.colors.primary[400]}
        data-testid="membership-cta"
        to={
          hasCompletedHaircareConsultation && hasCompletedSkincareConsultation
            ? '/account/reorder'
            : hasCompletedHaircareConsultation
            ? '/consultation/skincare'
            : '/consultation/haircare'
        }
      >
        {bannerContent.membershipMultiCategoryCta}
      </StyledLinkedCTA>
    </BaseBanner>
  ),
};

export const membershipMultiCategoryWithOneSubscription = {
  shouldRender: ({
    hasEitherHairOrSkinSubscription,
  }: {
    hasEitherHairOrSkinSubscription: boolean;
  }) => hasEitherHairOrSkinSubscription,
  render: ({
    hasHaircareSubscription,
    hasSkincareSubscription,
    hasCompletedHaircareConsultation,
    hasCompletedSkincareConsultation,
  }: RenderOptions) => (
    <BaseBanner
      backgroundColor={theme.colors.tertiary[200]}
      color={theme.colors.neutral[900]}
      dataTestId="membership-multi-category-banner-with-subscription"
      event={`Membership Multi Category CTA Banner - ${
        hasHaircareSubscription &&
        hasCompletedHaircareConsultation &&
        hasCompletedSkincareConsultation
          ? 'Skincare cart'
          : hasSkincareSubscription &&
            hasCompletedHaircareConsultation &&
            hasCompletedSkincareConsultation
          ? 'Haircare cart'
          : hasHaircareSubscription
          ? 'Skincare consultation'
          : 'Haircare consultation'
      }`}
    >
      <StyledLinkedCTA
        color={theme.colors.primary[400]}
        data-testid="membership-cta"
        to={
          hasHaircareSubscription &&
          hasCompletedHaircareConsultation &&
          hasCompletedSkincareConsultation
            ? SKINCARE_CART_URL
            : hasSkincareSubscription &&
              hasCompletedHaircareConsultation &&
              hasCompletedSkincareConsultation
            ? HAIRCARE_CART_URL
            : hasHaircareSubscription
            ? '/consultation/skincare'
            : '/consultation/haircare'
        }
      >
        {hasHaircareSubscription
          ? bannerContent.membershipMultiCategoryWithHaircareSubscriptionCta
          : bannerContent.membershipMultiCategoryWithSkincareSubscriptionCta}
      </StyledLinkedCTA>
    </BaseBanner>
  ),
};
