import isBoolean from 'lodash/fp/isBoolean';
import isString from 'lodash/fp/isString';

import { stringToBoolOrValue } from 'utils/envParsing';
import getEnv from 'utils/getEnv';

import * as flagFetchStatus from './constants/flagFetchStatus';

// Basically we want to support variant default, to do so we check if this is a boolean or string in env variable
export const activeOrVariantStructureFromEnv = envVariable => {
  const value = stringToBoolOrValue(envVariable);
  if (isString(value)) {
    return { active: true, variant: value };
  }
  if (isBoolean(value)) {
    return { active: value };
  }
  return {};
};

export default () => ({
  flagFetchStatus: flagFetchStatus.STATIC,
  // Flags as we receive them from the back-end.
  flags: {
    'kustomer-livechat': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_KUSTOMER_LIVECHAT')
    ),
    'frequency-update-clarification-ab-test': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_FREQUENCY_UPDATE_CLARIFICATION_AB_TEST')
    ),
    'homepage-email-capture-pop-up-ab-test': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_HOMEPAGE_EMAIL_CAPTURE_POP_UP_AB_TEST')
    ),
    'skincare-minis-first-charge-ab-test': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_SKINCARE_MINIS_FIRST_CHARGE_AB_TEST')
    ),
    'data-driven-promotions': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_DATA_DRIVEN_PROMOTIONS')
    ),
    'scalp-serum-content': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_SCALP_SERUM_CONTENT')
    ),
    'subscription-item-removal-ab-test': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_SUBSCRIPTION_ITEM_REMOVAL_AB_TEST')
    ),
    'post-purchase-popup-ab-test': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_POST_PURCHASE_POPUP_AB_TEST')
    ),
    'post-purchase-promo-design-phase2-ab-test': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_POST_PURCHASE_PROMO_DESIGN_PHASE2_AB_TEST')
    ),
    'key-action-card-ab-test': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_KEY_ACTION_CARD_AB_TEST')
    ),
    'skincare-concerns-order-ab-test': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_SKINCARE_CONCERNS_ORDER_AB_TEST')
    ),
    'nps-modal': activeOrVariantStructureFromEnv(getEnv('REACT_APP_FLAG_NPS_MODAL')),
    'skincare-minis': activeOrVariantStructureFromEnv(getEnv('REACT_APP_FLAG_SKINCARE_MINIS')),
    'post-purchase-promo-layout-ab-test': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_POST_PURCHASE_PROMO_LAYOUT_AB_TEST')
    ),
    'feedback-awareness-cta-ab-test': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_FEEDBACK_AWARENESS_CTA_AB_TEST')
    ),
    'skincare-minis-phase-2': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_SKINCARE_MINIS_PHASE_2')
    ),
    'payment-intent': activeOrVariantStructureFromEnv(getEnv('REACT_APP_FLAG_PAYMENT_INTENT')),
    'address-validation': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_ADDRESS_VALIDATION')
    ),
    paypal: activeOrVariantStructureFromEnv(getEnv('REACT_APP_FLAG_PAYPAL')),
    'data-driven-consultation': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_DATA_DRIVEN_CONSULTATION')
    ),
    'digital-leaflet': activeOrVariantStructureFromEnv(getEnv('REACT_APP_FLAG_DIGITAL_LEAFLET')),
    'trial-offer': activeOrVariantStructureFromEnv(getEnv('REACT_APP_FLAG_TRIAL_OFFER')),
    'post-purchase-cross-sell-offer': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_POST_PURCHASE_CROSS_SELL_OFFER')
    ),
    'growthbook-fake-experiment-ab-test': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_GROWTHBOOK_FAKE_EXPERIMENT_AB_TEST')
    ),
    'prose-uk': activeOrVariantStructureFromEnv(getEnv('REACT_APP_FLAG_PROSE_UK')),
    'subscription-snooze-button': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_SUBSCRIPTION_SNOOZE_BUTTON')
    ),
    'account-cross-sell-banner-cta-destination-ab-test': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_ACCOUNT_CROSS_SELL_BANNER_CTA_DESTINATION_AB_TEST')
    ),
    brightback: activeOrVariantStructureFromEnv(getEnv('REACT_APP_FLAG_BRIGHTBACK')),
    'kustomer-livechat-at-cart': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_KUSTOMER_LIVECHAT_AT_CART')
    ),
    'kustomer-livechat-at-subscription': activeOrVariantStructureFromEnv(
      getEnv('REACT_APP_FLAG_KUSTOMER_LIVECHAT_AT_SUBSCRIPTION')
    ),
    'express-checkout': activeOrVariantStructureFromEnv(getEnv('REACT_APP_FLAG_EXPRESS_CHECKOUT')),
    brushes: activeOrVariantStructureFromEnv(getEnv('REACT_APP_SHOW_BRUSHES')),
  },
});
