import { Elements as ElementsOG } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { getEnvOrThrow } from 'utils/getEnv';

const stripeKey = getEnvOrThrow('REACT_APP_STRIPE_KEY');

const stripePromise = loadStripe(stripeKey);

export const Elements = (props: Omit<React.ComponentProps<typeof ElementsOG>, 'stripe'>) => {
  return <ElementsOG stripe={stripePromise} {...props} />;
};
