import { useEffect, useState } from 'react';

// Keep track of the URLs already loaded
let cachedScripts: string[] = [];

/**
 * domPosition param is either "head" or "body"
 * Head means the tag will be placed in the <head> tag of the page
 * Body means the tag will be placed in the <body> tag of the page
 */

type UseScriptOptions = {
  src: string;
  attribute?: { name: string; value: string };
  domPosition?: 'head' | 'body';
};

export const useScript = (
  { src, attribute, domPosition }: UseScriptOptions = {
    src: '',
    domPosition: 'body',
  },
  // Useful to delay script injection for asynchronous reasons
  injectScript = true,
) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!src) {
      return () => {};
    }
    if (cachedScripts.includes(src)) {
      setLoaded(true);
      setError(false);
    } else if (injectScript) {
      cachedScripts.push(src);

      const element = document.createElement('script');
      element.async = true;
      element.src = src;

      if (attribute) {
        element.setAttribute(attribute.name, attribute.value);
      }

      const onLoad = () => {
        setError(false);
        setLoaded(true);
      };

      const onError = () => {
        cachedScripts = cachedScripts.filter((u) => u !== src);
        element.remove();

        setError(true);
        setLoaded(true);
      };

      element.addEventListener('load', onLoad);
      element.addEventListener('error', onError);

      const allScriptTags = document.getElementsByTagName('script');

      // Capturing last element which is in the body
      let loc: HTMLScriptElement | null = null;
      if (domPosition === 'head') {
        // Capturing first element which is in the head
        loc = allScriptTags.item(0);
      } else {
        loc = allScriptTags.item(allScriptTags.length - 1);
      }

      // Add element to the DOM
      loc?.parentNode?.insertBefore(element, loc);

      // Remove event listeners on cleanup
      return () => {
        element.removeEventListener('load', onLoad);
        element.removeEventListener('error', onError);
      };
    }

    return () => {};
  }, [src, injectScript]);

  return [loaded, error];
};
