import { useNavigate } from 'react-router';

import { Text, theme } from '@prose-ui';
import { legacyTheme, styled } from '@prose-ui/legacy';

import { Image } from 'Components/Image';

import { navbarContent } from 'assets/content/navbar';

const Container = styled.div`
  overflow-y: hidden;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  width: 100%;
  height: ${theme.env.navbarHeight};
  padding: 0 ${theme.spacing['8x']};

  background: ${theme.colors.neutral[100]};
  box-shadow: 0 2px 3px 0 color(rgb 180 180 180 / 20%);

  ${legacyTheme.breakpoints.match('md')} {
    padding: 0 20px;
  }
`;

const StyledButton = styled.button`
  ${legacyTheme.utils.resetButton}

  &:hover {
    color: ${theme.colors.primary[400]};
    text-decoration: underline;
  }

  > img {
    display: block;
  }
`;

const BackButton = styled(StyledButton)`
  justify-self: start;
`;

const ExitButton = styled(StyledButton)`
  justify-self: end;
`;

export const FeedbackNavBar = () => {
  const navigate = useNavigate();
  const exitFeedback = () => navigate('/account/feedback');
  return (
    <Container data-testid="consultation-header-nav">
      <BackButton
        aria-label="Navigate to previous screen"
        onClick={() => navigate(-1)}
        type="button"
      >
        <Text variant="bodyMd">← Back</Text>
      </BackButton>

      <StyledButton aria-label="Return Home" onClick={() => exitFeedback()} type="button">
        <Image alt={navbarContent.logo.alt} height={22} src={navbarContent.logo.image} width={62} />
      </StyledButton>

      <ExitButton aria-label="Exit Consultation" onClick={() => exitFeedback()} type="button">
        <Text variant="bodyMd">Exit</Text>
      </ExitButton>
    </Container>
  );
};
