import { countriesCode } from 'constants/countries';
import * as paymentMethodsStatuses from 'constants/paymentMethodsStatuses';
import * as statuses from 'constants/statuses';

import * as recommendationsStatus from './constants/recommendationsStatus';

const initialState = () => ({
  addresses: undefined,
  addressesError: null,
  addressesStatus: statuses.IDLE,
  error: null,
  geolocationGuessedCountry: countriesCode.US,
  geolocationGuessedRegion: null,
  isLoading: false,
  paymentMethods: null,
  paymentMethodsStatus: paymentMethodsStatuses.IDLE,
  paymentMethodsError: null,
  paypalPaymentMethods: null,
  paypalPaymentMethodsStatus: paymentMethodsStatuses.IDLE,
  paypalPaymentMethodsError: null,
  recommendationsError: null,
  recommendationsStatus: recommendationsStatus.IDLE,
  recommendations: null,
  referral: null,
  rootToast: null,
  supplementsVariant: null,
  user: null,
});

export default initialState;
