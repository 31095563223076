import { SCALP } from 'Apps/Feedback/constants/feedbackSections';
import { haircareTransitionScalp } from 'Apps/Feedback/constants/feedbackSlugs';

import { productsSlugs } from 'constants/products';

import scalpTransitionImgDesktop from 'assets/images/reviewAndRefine/scalp-transition_desktop.jpg';
import scalpTransitionImgMobile from 'assets/images/reviewAndRefine/scalp-transition_mobile.jpg';

import { commasAndListToString } from 'utils/textUtils';

const scalpTransitionScreen = {
  name: haircareTransitionScalp,
  route: `/feedback/haircare/${haircareTransitionScalp}`,
  category: SCALP,
  shouldBeIncluded: ({ formulas }) =>
    formulas?.some(f =>
      [productsSlugs.SCALP_MASK, productsSlugs.SHAMPOO].includes(f.variant.product.type)
    ),
  Component: 'HaircareTransitionScreen',
  skipSave: true,
  title: "Next, we'll ask about your scalp health.",
  subtitle: (date, formulas) =>
    `Think about how your recent ${commasAndListToString(
      formulas
        ?.filter(f =>
          [productsSlugs.SCALP_MASK, productsSlugs.SHAMPOO].includes(f.variant.product.type)
        )
        .map(f => f.variant.label)
    )} ordered on ${date} affected your scalp.`,
  style: {
    mode: 'dark',
    backgroundImages: {
      desktop: scalpTransitionImgDesktop,
      mobile: scalpTransitionImgMobile,
    },
  },
};

export default scalpTransitionScreen;
