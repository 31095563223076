const pushDataToFriendbuyStore = (data, isFriendbuyV2 = false) => {
  if (isFriendbuyV2) {
    if (!window.friendbuyAPI) return;
    window.friendbuyAPI.push(data);
  } else {
    if (!window.friendbuy) return;
    window.friendbuy.push(data);
  }
};

export const setFriendbuyUser = user => {
  pushDataToFriendbuyStore(
    [
      'track',
      'customer',
      {
        id: user.pubkey,
        email: user.email,
        firstName: user.first_name,
        lastName: user.last_name,
      },
    ],
    true
  );
};

// Called only in Friendbuy v2
// Documentation: https://developers.friendbuy.com/index.html#visitor-status
export const getVisitorStatus = callback => {
  pushDataToFriendbuyStore(['getVisitorStatus', callback], true);
};

// Called only in Friendbuy v2
export const isReferralCodeValid = friendbuyPayload => {
  return (
    friendbuyPayload.status === 'success' &&
    friendbuyPayload.payload?.referralCodeBlocked === false &&
    friendbuyPayload.payload?.isSelfReferral === false
  );
};

// Called only in Friendbuy v2
export const sendFriendbuySignupEvent = user => {
  pushDataToFriendbuyStore(
    [
      'track',
      'sign_up',
      {
        email: user.email,
        id: user.username,
        name: `${user.first_name} ${user.last_name}`,
      },
    ],
    true
  );
};

// Called only in Friendbuy v2
export const sendFriendbuyPageEvent = pageName => {
  pushDataToFriendbuyStore(['track', 'page', { name: pageName }], true);
};
