import PropTypes from 'prop-types';

import { useLocation } from 'react-router';

import { connect } from 'react-redux';

import { theme } from '@prose-ui';

import {
  getIsToastPermanent,
  getRootToastContent,
  getRootToastStyle,
  getShouldDisplayToast,
} from 'dux/user/selectors';

import BasicToast from './BasicToast';

const RootToast = ({ shouldDisplayToast, content, style, isPermanent }) => {
  const { pathname } = useLocation();

  const invalidUrl = pathname.includes('/redeem/invalid-code');

  return (
    !invalidUrl && (
      <BasicToast
        bgcolor={style.backgroundColor}
        dataTestId="root-toast-banner"
        isFullWidth
        isPermanent={isPermanent}
        shouldDisplayToast={shouldDisplayToast}
        textColor={style.color}
      >
        {content}{' '}
      </BasicToast>
    )
  );
};

RootToast.defaultProps = {
  style: { color: 'noir', backgroundColor: theme.colors.tertiary[200] },
  shouldDisplayToast: false,
  content: 'Good news! Your promo was applied',
  isPermanent: false,
};

RootToast.propTypes = {
  content: PropTypes.string,
  style: PropTypes.shape({ color: PropTypes.string, backgroundColor: PropTypes.string }),
  shouldDisplayToast: PropTypes.bool,
  isPermanent: PropTypes.bool,
};

export default connect(state => ({
  shouldDisplayToast: getShouldDisplayToast(state),
  content: getRootToastContent(state),
  style: getRootToastStyle(state),
  isPermanent: getIsToastPermanent(state),
}))(RootToast);
