import { styled } from '@prose-ui/legacy';

type InlinedListProps = {
  listItems: Array<string | [string, { dataTestId: string }]>;
}; /* text styling is handled by a parent for now */

const StyledContainer = styled.span`
  --separator-margin-x: 2ch;

  display: flex;
  flex-wrap: wrap;
  overflow: hidden;

  & > span {
    width: max-content; /* current implementation may be problematic if a single item does not fit on one line */
  }

  & > span:not(:last-of-type) {
    margin-right: calc(2 * var(--separator-margin-x));
  }

  & > span:not(:first-of-type) {
    position: relative;

    ::before /* separator */ {
      --separator-height: 0.75lh;

      content: '';

      position: absolute;
      top: calc((1lh - var(--separator-height)) / 2);
      left: calc(-1 * var(--separator-margin-x));
      transform: translateX(-50%);

      width: 0.17ch;
      height: var(--separator-height);

      background: currentcolor;
    }
  }
`;

/**
 * This component can be used to display a list of _short_ texts, with the behaviour:
 * (regardless of viewport size/breakpoints)
 * - When there is enough horizontal space, the list should be displayed horizontally with separators
 * - When there is not enough horizontal space, the list items should wrap and the associated separator should not display
 */
export const InlineableWithSeparatorsList = ({ listItems }: InlinedListProps) => {
  return (
    <StyledContainer>
      {listItems.map((item) =>
        typeof item === 'string' ? (
          <span key={item}>{item}</span>
        ) : (
          <span key={item[0]} data-testid={item[1].dataTestId}>
            {item[0]}
          </span>
        ),
      )}
    </StyledContainer>
  );
};
