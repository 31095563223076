import { PRODUCTS } from 'Apps/Feedback/constants/feedbackSections';
import { haircareHairOilSatisfaction } from 'Apps/Feedback/constants/feedbackSlugs';

import { productsSlugs } from 'constants/products';

const hairOilSatisfaction = {
  name: haircareHairOilSatisfaction,
  route: `/feedback/haircare/${haircareHairOilSatisfaction}`,
  category: PRODUCTS,
  shouldBeIncluded: ({ formulas }) =>
    formulas?.find(f => f.variant.product.type === productsSlugs.OIL),
  Component: 'Rating',
  title: 'How satisfied are you with your hair oil?',
  options: [
    { value: 1, label: 'Very Unsatisfied' },
    { value: 2, label: 'Unsatisfied' },
    { value: 3, label: 'Neutral' },
    { value: 4, label: 'Satisfied' },
    { value: 5, label: 'Very Satisfied' },
  ],
  skippable: "I haven't used it enough",
  skippableValue: 0,
  hasOpenText: true,
  getInitialValue: answers => answers?.product_hair_oil_satisfaction,
  getInitialProductFeedback: productsAnswers => {
    return productsAnswers.find(answer => answer.product === productsSlugs.OIL)?.open_text_feedback;
  },
  getAnswers: ({ selected }) => {
    if (selected === 0) {
      return {
        product_hair_oil_satisfaction: selected,
        product_hair_oil_nourishment: null,
        product_hair_oil_frizz: null,
      };
    }
    return { product_hair_oil_satisfaction: selected };
  },
  getFeedbackAnswer: ({ openText }) => ({
    product: productsSlugs.OIL,
    open_text_feedback: openText?.feedback_text,
  }),
  multi: true,
};

export default hairOilSatisfaction;
