import { forwardRef } from 'react';

import { BaseField, type BaseFieldProps } from './BaseField';

type TextFieldOwnProps = {
  autoCorrect?: boolean;
};
type TextFieldProps = TextFieldOwnProps &
  Omit<
    React.ComponentPropsWithoutRef<'input'>,
    keyof BaseFieldProps<'input'> | keyof TextFieldOwnProps
  > &
  Omit<BaseFieldProps<'input'>, 'children' | keyof TextFieldOwnProps>;

/**
 * TextField is basically a BaseField which renders a text input with disabled autocomplete.
 */
export const TextField = forwardRef<HTMLDivElement, TextFieldProps>(
  ({ autoCorrect = true, ...props }, ref) => (
    <BaseField ref={ref} {...props} markupName="input">
      {(inputProps) => (
        <input {...inputProps} autoComplete="chrome-off" autoCorrect={autoCorrect ? 'on' : 'off'} />
      )}
    </BaseField>
  ),
);

TextField.displayName = 'TextField';
