import { Link } from 'react-router';

import { theme } from '@prose-ui';
import { styled } from '@prose-ui/legacy';
import dayjs from 'dayjs';

import type { CheckoutProductCategories } from 'Apps/Checkout/hooks/useCheckoutCategory';

import { subscriptionCategories, type SubscriptionUXCategory } from 'constants/subscriptions';

import { capitalizeFirstLetter } from 'utils/textUtils';

import { Typography } from './Typography';

const StyledLink = styled(Link)`
  color: ${theme.colors.neutral[800]};
  font-weight: 600;
  text-decoration: underline;
`;

type SubscriptionTicketLegalWordingProps = {
  ctaWording: string;
  paragraph?: boolean;
  bold?: boolean;
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  category: CheckoutProductCategories | SubscriptionUXCategory | null;
  chargeDate?: string;
};

export const SubscriptionTicketLegalWording = ({
  ctaWording,
  paragraph = false,
  bold = true,
  align = 'center',
  category = subscriptionCategories.HAIRCARE,
  chargeDate = undefined,
}: SubscriptionTicketLegalWordingProps) => {
  const productsCategory =
    category === subscriptionCategories.SKINCARE
      ? subscriptionCategories.SKINCARE
      : subscriptionCategories.HAIRCARE;

  return (
    <Typography align={align} bold={bold} color="grey" paragraph={paragraph} variant="p3">
      I authorize Prose to charge my payment account{' '}
      {chargeDate ? `on ${dayjs(chargeDate).format('MM/DD/YY')}` : 'now'} and on a recurring basis
      at the selected frequency until I cancel at the then-current rate for the subscribed products
      in the order summary. I may cancel my subscription without incurring further charges at any
      time before 11:59 pm PT prior to the charge date by visiting my account, selecting Your
      Subscription, selecting Edit, and selecting Cancel My{' '}
      {capitalizeFirstLetter(productsCategory)}
      Subscription, or by emailing hello@prose.com. By clicking “{ctaWording}” I acknowledge I have
      read and agree to the above terms, and the Prose{' '}
      <StyledLink target="_blank" to="/terms/subscription">
        Subscription Terms of Service
      </StyledLink>{' '}
      and{' '}
      <StyledLink target="_blank" to="/terms">
        End User License Agreement
      </StyledLink>
      .
    </Typography>
  );
};
