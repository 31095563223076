import { sumBy } from 'remeda';

import { styled } from '@prose-ui/legacy';

import TicketLineV2 from 'Components/TicketLineV2';

import currencies from 'constants/currencies';

import { formatPriceWithCurrency } from 'utils/currencies';

const DiscountsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

type TicketGroupedLinesProps = {
  currency: keyof typeof currencies;
  items: Array<{ label: string; value: number }>;
  groupName: string;
};

export const TicketGroupedLines = ({ currency, items, groupName }: TicketGroupedLinesProps) => {
  const totalGroupValue = sumBy(items, (item) => item.value);

  if (!items.length) {
    return null;
  }

  return (
    <>
      {totalGroupValue && (
        <TicketLineV2
          currency={currency}
          dataTestId={`ticket-total-${groupName}-line`}
          label={items.length === 1 ? items[0]!.label : groupName}
          price={`-${formatPriceWithCurrency({
            price: Math.abs(items.length === 1 ? items[0]!.value : totalGroupValue).toFixed(2),
            currency,
          })}`}
        />
      )}
      {items.length > 1 && (
        <DiscountsWrapper
          aria-labelledby={`${groupName}-title`}
          data-testid={`ticket-${groupName}-content`}
          id={`ticket-${groupName}-content`}
        >
          {items.map(({ label, value }) => (
            <TicketLineV2
              key={label}
              currency={currency}
              isDiscountLine
              label={label}
              price={`-${formatPriceWithCurrency({ price: Math.abs(value), currency })}`}
            />
          ))}
        </DiscountsWrapper>
      )}
    </>
  );
};
