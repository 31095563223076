import type { StaticImageData } from 'Components/Image';

import { productsLabels, productsSlugs } from 'constants/products';

import productThumbnailBottomAlignedConditioner from 'assets/images/products/product-thumbnail-bottom-aligned-conditioner.png';
import productThumbnailBottomAlignedCurlCream from 'assets/images/products/product-thumbnail-bottom-aligned-curl-cream.png';
import productThumbnailBottomAlignedDryShampoo from 'assets/images/products/product-thumbnail-bottom-aligned-dry-shampoo.png';
import productThumbnailBottomAlignedHairMask from 'assets/images/products/product-thumbnail-bottom-aligned-hair-mask.png';
import productThumbnailBottomAlignedHairOil from 'assets/images/products/product-thumbnail-bottom-aligned-hair-oil.png';
import productThumbnailBottomAlignedSupplementsCore from 'assets/images/products/product-thumbnail-bottom-aligned-hair-supplements-jars.png';
import productThumbnailBottomAlignedLeaveinConditioner from 'assets/images/products/product-thumbnail-bottom-aligned-leave-in-conditioner.png';
import placeholderProductImage from 'assets/images/products/product-thumbnail-bottom-aligned-placeholder.png';
import productThumbnailBottomAlignedScalpMask from 'assets/images/products/product-thumbnail-bottom-aligned-scalp-mask.png';
import productThumbnailBottomAlignedScalpSerum from 'assets/images/products/product-thumbnail-bottom-aligned-scalp-serum.png';
import productThumbnailBottomAlignedShampoo from 'assets/images/products/product-thumbnail-bottom-aligned-shampoo.png';
import productThumbnailBottomAlignedCleanser from 'assets/images/products/product-thumbnail-bottom-aligned-skin-cleanser.png';
import productThumbnailBottomAlignedCleanserMini from 'assets/images/products/product-thumbnail-bottom-aligned-skin-cleanser-mini.png';
import productThumbnailBottomAlignedMoisturizer from 'assets/images/products/product-thumbnail-bottom-aligned-skin-moisturizer.png';
import productThumbnailBottomAlignedMoisturizerMini from 'assets/images/products/product-thumbnail-bottom-aligned-skin-moisturizer-mini.png';
import productThumbnailBottomAlignedSerum from 'assets/images/products/product-thumbnail-bottom-aligned-skin-serum.png';
import productThumbnailBottomAlignedSerumMini from 'assets/images/products/product-thumbnail-bottom-aligned-skin-serum-mini.png';
import productThumbnailBottomAlignedStylingGel from 'assets/images/products/product-thumbnail-bottom-aligned-styling-gel.png';
import productThumbnailBottomAlignedCandleArcadia from 'assets/images/products/prose-arcadia-candle.png';
import productThumbnailBottomAlignedBrushBoar from 'assets/images/products/prose-boar-brush.png';
import productThumbnailBottomAlignedBrushMixed from 'assets/images/products/prose-boar-nylon-brush.png';
import productThumbnailBottomAlignedCandleCorsica from 'assets/images/products/prose-corsica-candle.png';
import productThumbnailBottomAlignedHairTowel from 'assets/images/products/prose-hair-towel.png';
import productThumbnailBottomAlignedBrushDetangling from 'assets/images/products/prose-metal-tipped-brush.png';
import productThumbnailBottomAlignedCandlePrelude from 'assets/images/products/prose-prelude-candle.png';

type ProductSlug = Exclude<
  (typeof productsSlugs)[keyof typeof productsSlugs],
  | 'complete_set'
  | 'conditioner_coarse'
  | 'essentials_set'
  | 'gift'
  | 'gwp'
  | 'mask'
  | 'supplement_booster'
  | 'supplements_pouch'
  | 'trial_conditioner'
  | 'trial_shampoo'
  | 'digital_gift'
>;

export type ProductsContentWithDefault<T> = Record<ProductSlug | 'default', T>;

export const productLabel = productsLabels satisfies ProductsContentWithDefault<string>;

export const productThumbnailBottomAligned: ProductsContentWithDefault<StaticImageData> = {
  [productsSlugs.HAIR_MASK]: productThumbnailBottomAlignedHairMask,
  [productsSlugs.SHAMPOO]: productThumbnailBottomAlignedShampoo,
  [productsSlugs.CONDITIONER]: productThumbnailBottomAlignedConditioner,
  [productsSlugs.OIL]: productThumbnailBottomAlignedHairOil,
  [productsSlugs.HAIR_OIL]: productThumbnailBottomAlignedHairOil,
  [productsSlugs.DRY_SHAMPOO]: productThumbnailBottomAlignedDryShampoo,
  [productsSlugs.CURL_CREAM]: productThumbnailBottomAlignedCurlCream,
  [productsSlugs.LEAVEIN_CONDITIONER]: productThumbnailBottomAlignedLeaveinConditioner,
  [productsSlugs.SCALP_MASK]: productThumbnailBottomAlignedScalpMask,
  [productsSlugs.STYLING_GEL]: productThumbnailBottomAlignedStylingGel,
  [productsSlugs.SUPPLEMENT_CORE]: productThumbnailBottomAlignedSupplementsCore,
  [productsSlugs.CLEANSER]: productThumbnailBottomAlignedCleanser,
  [productsSlugs.CLEANSER_MINI]: productThumbnailBottomAlignedCleanserMini,
  [productsSlugs.MOISTURIZER]: productThumbnailBottomAlignedMoisturizer,
  [productsSlugs.MOISTURIZER_MINI]: productThumbnailBottomAlignedMoisturizerMini,
  [productsSlugs.SERUM]: productThumbnailBottomAlignedSerum,
  [productsSlugs.SERUM_MINI]: productThumbnailBottomAlignedSerumMini,
  [productsSlugs.SCALP_SERUM]: productThumbnailBottomAlignedScalpSerum,
  [productsSlugs.HAIR_TOWEL_WRAP]: productThumbnailBottomAlignedHairTowel,
  [productsSlugs.CANDLE]: productThumbnailBottomAlignedCandlePrelude,
  [productsSlugs.CANDLE_ARCADIA]: productThumbnailBottomAlignedCandleArcadia,
  [productsSlugs.CANDLE_CORSICA]: productThumbnailBottomAlignedCandleCorsica,
  [productsSlugs.CANDLE_PRELUDE]: productThumbnailBottomAlignedCandlePrelude,
  [productsSlugs.BRUSH]: productThumbnailBottomAlignedBrushBoar,
  [productsSlugs.BRUSH_BOAR]: productThumbnailBottomAlignedBrushBoar,
  [productsSlugs.BRUSH_DETANGLING]: productThumbnailBottomAlignedBrushDetangling,
  [productsSlugs.BRUSH_MIXED]: productThumbnailBottomAlignedBrushMixed,
  default: placeholderProductImage,
};
