import currencies from 'constants/currencies';

import trialOfferGWPMixedWithSkincareMinisModalContent from 'assets/content/promotions/trialOfferGWPMixedWithSkincareMinisModal';
import imageGift from 'assets/images/promotions/trial-offer-wide-tooth-comb-gwp-promotion-hc-sc-sss-modal.jpg';

const popUpConditions = (
  <>
    *Get [1] free Signature Wide-Tooth Comb and a total of 50% off your first haircare subscription
    order when you subscribe to 3+ products. Get your Skincare Starter Set ($65 value) when you
    subscribe to Cleanser, Serum, and Moisturizer. Subsequent subscription orders will receive free
    shipping and 15% off. Offers subject to expire and valid only while supplies last. Cannot be
    applied to previous orders or non-subscribed items. Not valid for accessories or digital gift
    cards. Exceptions may apply. For full details on our return policy, click{' '}
    <a
      data-click="return-policy"
      data-from="trial-offer-v2-hc-sc-pop-up"
      href="https://prose.com/faq/5f3da05bceb96c001a595c02"
      target="_blank"
    >
      here
    </a>
    .
  </>
);

const bottomModalConditions = (
  <>
    *Get [1] free Signature Wide-Tooth Comb and a total of 50% off your first haircare subscription
    order when you subscribe to 3+ products. Get your Skincare Starter Set ($65 value) when you
    subscribe to Cleanser, Serum, and Moisturizer. Subsequent subscription orders will receive free
    shipping and 15% off. Offers subject to expire and valid only while supplies last. Cannot be
    applied to previous orders or non-subscribed items. Not valid for accessories or digital gift
    cards. Exceptions may apply. For full details on our return policy, click{' '}
    <a href="https://prose.com/faq/5f3da05bceb96c001a595c02" target="_blank">
      here
    </a>
    .
  </>
);

const content = {
  banner: trialOfferGWPMixedWithSkincareMinisModalContent.banner,
  popUpModal: {
    [currencies.USD]: {
      ...trialOfferGWPMixedWithSkincareMinisModalContent.popUpModal[currencies.USD],
      image: imageGift,
      conditions: popUpConditions,
    },
    [currencies.CAD]: {
      ...trialOfferGWPMixedWithSkincareMinisModalContent.popUpModal[currencies.CAD],
      image: imageGift,
      conditions: popUpConditions,
    },
  },
  bottomModal: {
    [currencies.USD]: {
      ...trialOfferGWPMixedWithSkincareMinisModalContent.bottomModal[currencies.USD],
      conditions: bottomModalConditions,
    },
    [currencies.CAD]: {
      ...trialOfferGWPMixedWithSkincareMinisModalContent.bottomModal[currencies.CAD],
      conditions: bottomModalConditions,
    },
  },
} as const;

export default content;
