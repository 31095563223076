import * as Sentry from '@sentry/nextjs';
import { HTTPError as KyError } from 'ky';

import logSentryError from 'utils/logSentry';

import * as Auth from './Auth';
import { httpClient } from './HTTPClient';
import HTTPError, { isUnknownUser, type ServerErrorResponse } from './HTTPError';

type SignupParams = { first_name: string; last_name: string; email: string };

/**
 * refresh the access token, if needed
 */
export const refreshToken = async (token: { accessToken: string; refreshToken: string }) => {
  if (token == null) {
    return null;
  }

  if (token?.refreshToken && Auth.isTokenExpired(token.refreshToken)) {
    return null;
  }

  try {
    const response = await httpClient.post('v1/auth/token/refresh/', {
      json: {
        refresh: token.refreshToken,
      },
    });

    const result = await response.json<Auth.JWTResponse>();

    const newToken = { ...token, accessToken: result.access };

    return newToken;
  } catch (err) {
    if (err instanceof Error) {
      logSentryError('[AuthClient] refresh', err);
    }
    throw new Auth.AuthRefreshError('Cannot refresh token, must signin', { cause: err });
  }
};

/** try refreshing the access token, if needed */
export const tryRefreshTokens = async () => {
  const tokens = Auth.getTokens();
  const guestTokens = Auth.getGuestTokens();

  try {
    if (tokens?.refreshToken && Auth.isTokenExpired(tokens.accessToken)) {
      const newUserTokens = await refreshToken(tokens);
      if (newUserTokens) {
        Auth.setTokens(newUserTokens);
        Auth.clearGuestTokens();
        return newUserTokens;
      }
      Auth.clearTokens();
      return null;
    }

    if (
      !tokens?.refreshToken &&
      guestTokens?.refreshToken &&
      Auth.isTokenExpired(guestTokens.accessToken)
    ) {
      const newGuestTokens = await refreshToken(guestTokens);
      if (newGuestTokens) {
        Auth.setGuestTokens(newGuestTokens);
        return newGuestTokens;
      }
      Auth.clearTokens();
      return null;
    }
  } catch (error) {
    if (error instanceof Error) {
      logSentryError('[AuthService] tryRefresh', error);
    }
  }

  return null;
};

export const signup = async (params: SignupParams) => {
  const guestToken = Auth.getGuestTokens()?.accessToken;
  try {
    const response = await httpClient.post('v1/auth/signup/', {
      headers: { Authorization: guestToken ? `Bearer ${guestToken}` : undefined },
      json: params,
    });
    const result = await response.json<Auth.JWTResponse>();
    const auth: Auth.JWT = {
      accessToken: result.access,
      refreshToken: result.refresh,
    };
    Auth.setTokens(auth);
    Auth.clearGuestTokens();
    return auth;
  } catch (error) {
    if (error instanceof Error) {
      logSentryError('[AuthService] signup', error);
    }
    if (error instanceof KyError) {
      const body = (await error.response.json()) as ServerErrorResponse;

      throw new HTTPError(body);
    }
    throw error;
  }
};

export const verifySigninToken = async (token: string) => {
  try {
    const response = await httpClient.post('v1/auth/signin/verify/', { json: { token } });
    const payload = await response.json<Auth.JWTResponse>();
    const auth = {
      accessToken: payload.access,
      refreshToken: payload.refresh,
    };

    Auth.setTokens(auth);
    Auth.clearGuestTokens();
  } catch (error) {
    if (error instanceof Error) {
      logSentryError('[AuthService] verifySigninToken', error);
    }
  }
};

export const isAuthenticated = async () => {
  const auth = Auth.getTokens();
  if (auth === null) {
    return false;
  }
  if (!Auth.isTokenExpired(auth.accessToken)) {
    // Access token present and valid
    return true;
  }

  // refresh will throw if not authenticated
  const tokens = await tryRefreshTokens();
  return Boolean(tokens);
};

/*
 * Request magic link to be sent by email
 *
 * params = {
 *   username: the user email (mandatory)
 *   next: the url to redirect after authentication
 *   coupon: user coupon to recover
 * }
 *
 * returns String
 *  "success" on success
 *  "unknownUser" for inexistent emails
 *  "error" on other errors
 */
type MagicLinkRequestParams = {
  username?: string;
  next?: string;
  coupon?: string;
};
export const requestMagicLink = async (params: MagicLinkRequestParams = {}) => {
  // TODO: make username required once all calling sites have benn migrated to TypeScript
  if (!params.username) {
    Sentry.captureMessage('Called requestMagicLink without username param');
    return 'error';
  }
  const guestToken = Auth.getGuestTokens()?.accessToken;

  try {
    await httpClient.post('v1/auth/signin/', {
      headers: { Authorization: guestToken ? `Bearer ${guestToken}` : undefined },
      json: params,
    });
    return 'success';
  } catch (error: unknown) {
    if (error instanceof KyError) {
      const errorResponseJson = (await error.response.json()) as ServerErrorResponse;

      if (isUnknownUser(errorResponseJson)) {
        return 'unknownUser';
      }

      logSentryError('[Auth Service] requestMagicLink', errorResponseJson);
      return 'error';
    }

    logSentryError('[Auth Service] requestMagicLink', error);
    return 'error';
  }
};

export const postGuestUserSignup = async () => {
  const response = await httpClient.post('v1/auth/signup/guest/');
  const result = await response.json<Auth.JWTResponse>();
  const auth = {
    accessToken: result.access,
    refreshToken: result.refresh,
  };
  Auth.setGuestTokens(auth);
  return auth;
};
