import { theme } from '@prose-ui';
import { styled } from '@prose-ui/legacy';
// From: https://www.joshwcomeau.com/react/modern-spacer-gif/#the-code

type SpacingToken = keyof typeof theme.spacing;
type SizeProps = {
  axis?: 'horizontal' | 'vertical';
  size: number | SpacingToken;
};

const getHeight = ({ axis, size }: SizeProps) =>
  axis === 'horizontal' ? 1 : typeof size !== 'number' ? theme.spacing[size] : `${size}px`;
const getWidth = ({ axis, size }: SizeProps) =>
  axis === 'vertical' ? 1 : typeof size !== 'number' ? theme.spacing[size] : `${size}px`;

const Spacer = styled.span`
  display: block;
  width: ${getWidth};
  min-width: ${getWidth};
  height: ${getHeight};
  min-height: ${getHeight};

  background-color: var(--color-background, transparent);
`;

export default Spacer;
