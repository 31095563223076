import { useEffect, useState } from 'react';

import { Link, useNavigate } from 'react-router';

import { useAppDispatch as useDispatch, useAppSelector } from 'dux/app/hooks';

import { theme } from '@prose-ui';
import { legacyTheme, styled } from '@prose-ui/legacy';
import { Formik, type FormikHelpers } from 'formik';

import {
  formatSignupUserFromFormikToApi,
  setSignupFormikFieldError,
} from 'Apps/Consultation/utils';

import { BasicModal } from 'Components/BasicModal';
import { Button } from 'Components/LegacyButton';
import { TextField } from 'Components/TextField';
import { Typography } from 'Components/Typography';

import { type ConsultationCategoryProp } from 'PropTypes/consultationProps';

import * as captureEmailExitModalContent from 'assets/content/consultation/captureEmailExitModal';

import { makeValidations } from 'utils/validators';

import { trackIterableEvent } from 'dux/tracking/actions';
import * as authSelectors from 'dux/auth/selectors';
import * as signupSelectors from 'dux/signup/selectors';
import {
  apiSignupOrigin,
  heapSignupOrigin,
  heapSignupSubOrigin,
  signupUser,
} from 'dux/signup/thunks';

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${legacyTheme.spacing.s36};
`;

const ExitButton = styled(Link)`
  margin-top: 24px;

  color: ${theme.colors.neutral[800]};
  border-bottom: 1px solid ${theme.colors.neutral[800]};
`;

const StyledTextField = styled(TextField)`
  width: 300px;
`;

const Terms = styled(Typography)`
  color: ${theme.colors.neutral[800]};
  margin-top: ${legacyTheme.spacing.s24};
`;

const StyledBasicModal = styled(BasicModal)`
  max-height: 738px;
`;

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
};

type EmailCaptureExitModalProps = {
  consultationCategory: ConsultationCategoryProp;
  isOpen: boolean;
  onClose: () => void;
};

export const EmailCaptureExitModal = ({
  consultationCategory,
  isOpen,
  onClose,
}: EmailCaptureExitModalProps) => {
  const isAuthenticated = useAppSelector(authSelectors.getIsAuthenticated);
  const isMagicLinkSent = useAppSelector(signupSelectors.getIsMagicLinkSent);
  const signupError = useAppSelector(signupSelectors.getHasError);
  const signupIsLoading = useAppSelector(signupSelectors.getIsLoading);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // It can be one of request | success | magicLinkSent
  const [status, setStatus] = useState('request');

  useEffect(() => {
    if (isMagicLinkSent && !signupIsLoading) {
      setStatus('magicLinkSent');
    }
  }, [isMagicLinkSent, signupIsLoading]);

  useEffect(() => {
    if (isAuthenticated) {
      setStatus('success');
    } else {
      setStatus('request');
    }
  }, [isAuthenticated]);

  const handleSubmit = (values: FormValues, actions: FormikHelpers<FormValues>) =>
    dispatch(
      signupUser({
        // fist param: user object with the backend shape
        user: formatSignupUserFromFormikToApi(values),
        // second param: callbacks to react and formik
        actions: {
          ...actions,
          setFieldError: setSignupFormikFieldError(actions),
          onSuccess: () =>
            dispatch(
              trackIterableEvent('Started Consultation Exit', { category: consultationCategory }),
            ),
        },
        consultationCategory,
        origin: apiSignupOrigin.CONSULTATION_EXIT_MODAL[consultationCategory],
        heapOrigin: heapSignupOrigin.CONSULTATION_EXIT_MODAL,
        heapSubOrigin: heapSignupSubOrigin[consultationCategory],
      }),
    );

  const onClickFirstCta = () => {
    if (status !== 'request') {
      onClose();
      navigate(`/products/${consultationCategory}`);
    }
  };

  return (
    <StyledBasicModal
      dataClick="exit"
      dataFrom={
        status === 'request' || status === 'success'
          ? captureEmailExitModalContent.modal.dataFrom[status]
          : undefined
      }
      dataTestId="email-capture-exit-modal"
      isOpen={isOpen}
      onClose={onClose}
    >
      {signupError || (status !== 'request' && status !== 'success') ? (
        <>
          <Typography align="center" id={isOpen ? 'modal-title' : ''} paragraph variant="h2">
            Oops, something went wrong...
          </Typography>
          <ButtonsContainer>
            <Button onClick={() => window.location.reload()} variant="underlined">
              Please reload
            </Button>
          </ButtonsContainer>
        </>
      ) : (
        <>
          <Typography align="center" id={isOpen ? 'modal-title' : ''} paragraph variant="h2">
            {captureEmailExitModalContent.title[status]}
          </Typography>
          <Typography align="center" id={isOpen ? 'modal-description' : ''} variant="p2">
            {captureEmailExitModalContent.body[status]}
          </Typography>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
            }}
            onSubmit={handleSubmit}
            validate={(values) =>
              makeValidations(values, [
                ['firstName', 'required'],
                ['lastName', 'required'],
                ['email', 'required'],
                ['email', 'email'],
              ])
            }
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit: handleSubmitFormik,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmitFormik}>
                <ButtonsContainer>
                  {status === 'request' && (
                    <>
                      <StyledTextField
                        autoCorrect={false}
                        disabled={isSubmitting}
                        error={touched.firstName ? errors.firstName : undefined}
                        id="firstName"
                        label="First name*"
                        margin
                        name="firstName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.firstName}
                      />
                      <StyledTextField
                        autoCorrect={false}
                        disabled={isSubmitting}
                        error={touched.lastName ? errors.lastName : undefined}
                        id="lastName"
                        label="Last name*"
                        margin
                        name="lastName"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="text"
                        value={values.lastName}
                      />
                      <StyledTextField
                        autoCorrect={false}
                        disabled={isSubmitting}
                        error={touched.email ? errors.email : undefined}
                        id="email"
                        label="Email address*"
                        margin
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="email"
                        value={values.email}
                      />
                    </>
                  )}
                  <Button
                    data-click={captureEmailExitModalContent.firstCTA.dataClick[status]}
                    data-from={captureEmailExitModalContent.firstCTA.dataFrom[status]}
                    data-testid="email-capture-first-cta-modal"
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                    noMargin
                    onClick={onClickFirstCta}
                    type={status === 'request' ? 'submit' : undefined}
                    variant="vert"
                  >
                    {captureEmailExitModalContent.firstCTA.label[status]}
                  </Button>
                  <ExitButton
                    data-click={captureEmailExitModalContent.secondCTA.dataClick[status]}
                    data-from={captureEmailExitModalContent.secondCTA.dataFrom[status]}
                    onClick={onClose}
                    to={captureEmailExitModalContent.secondCTA.link[status]}
                  >
                    <Typography variant="p2">
                      {captureEmailExitModalContent.secondCTA.label[status]}
                    </Typography>
                  </ExitButton>
                  {status === 'request' && (
                    <Terms align="center" id={isOpen ? 'modal-conditions' : ''} variant="p2">
                      By clicking ‘Save my progress’ I acknowledge I have read and agree to the
                      Prose{' '}
                      <Link target="_blank" to="/terms">
                        End User License and Terms of Service
                      </Link>{' '}
                      and{' '}
                      <Link target="_blank" to="/privacy">
                        Privacy Policy
                      </Link>
                      .
                    </Terms>
                  )}
                </ButtonsContainer>
              </form>
            )}
          </Formik>
        </>
      )}
    </StyledBasicModal>
  );
};
