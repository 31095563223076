import { SCALP } from 'Apps/Feedback/constants/feedbackSections';
import { haircareScalpSatisfaction } from 'Apps/Feedback/constants/feedbackSlugs';

import { productsSlugs } from 'constants/products';

const scalpSatisfaction = {
  name: haircareScalpSatisfaction,
  route: `/feedback/haircare/${haircareScalpSatisfaction}`,
  category: SCALP,
  shouldBeIncluded: ({ formulas }) =>
    formulas?.some(f =>
      [productsSlugs.SCALP_MASK, productsSlugs.SHAMPOO].includes(f.variant.product.type)
    ),
  Component: 'Rating',
  title: 'Overall, how satisfied are you with the condition of your scalp?',
  subtitle: 'Consider how it looks, feels, and behaves on a daily basis',
  options: [
    { value: 1, label: 'Very Unsatisfied' },
    { value: 2, label: 'Unsatisfied' },
    { value: 3, label: 'Neutral' },
    { value: 4, label: 'Satisfied' },
    { value: 5, label: 'Very Satisfied' },
  ],
  getInitialValue: answers => answers?.scalp_satisfaction,
  getAnswers: ({ selected }) => ({ scalp_satisfaction: selected }),
};

export default scalpSatisfaction;
