import { appApi } from 'dux/app/api';

import type { PromotionsRaw } from './types';

const promotionsApiSlice = appApi.injectEndpoints({
  endpoints: (build) => ({
    getPromotions: build.query<PromotionsRaw, void>({
      query: () => ({
        url: `v1/promotions/`,
        endpointName: 'promotionsQuery',
      }),
      providesTags: ['promotions'],

      // keepUnusedDataFor: 0, // no caching for now
    }),
  }),
});

export const { useGetPromotionsQuery } = promotionsApiSlice;
