import { theme } from '@prose-ui';
import { styled } from '@prose-ui/legacy';
import { type UseComboboxPropGetters } from 'downshift';

const ListItem = styled.li<{ isHighlighted: boolean; isSelectedItem: boolean }>`
  text-align: left;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  padding: ${theme.spacing['3x']} ${theme.spacing['4x']};

  font-family: ${theme.typography.fontFamily.body};
  font-size: ${theme.typography.fontSize.sm};
  font-weight: ${theme.typography.fontWeight.normal};
  color: ${theme.colors.neutral[800]};

  border-bottom: 0.5px solid ${theme.colors.neutral[600]};

  background: ${({ isSelectedItem, isHighlighted }) =>
    (isSelectedItem || isHighlighted) && theme.colors.neutral[600]};

  cursor: pointer;
`;

const BoldText = styled.span`
  color: ${theme.colors.primary[400]};
`;

type Item = {
  label: string;
  matchedSubstrings: Array<{ offset: number; length: number }>;
};

type AutoCompleteItemProps = {
  dataTestId: string;
  item: Item;
  isHighlighted: boolean;
  isSelectedItem: boolean;
  getItemProps: UseComboboxPropGetters<Item>['getItemProps'];
};

export const AutoCompleteItem = ({
  getItemProps,
  item,
  dataTestId,
  isHighlighted,
  isSelectedItem,
}: AutoCompleteItemProps) => {
  /* This logic extracts bolded substrings from  item.label based on item.matchedSubstrings offsets and lengths, 
   then constructs an array of substrings with the non-bold and bold substrings interleaved.
   ex: 
   label: '1 Ab Avenue, Dunlap, KS, USA'
   matchedStrings: [{length: 4, offset: 0}, {length: 3, offset: 25}]
   The code below will turn this into:
   ['', '1 Ab', ' Avenue, Dunlap, KS, ', 'USA', ''], 
   where pair indexes are non-bolded strings, and pair indexes are bolded strings.
  */
  const boldRanges = item.matchedSubstrings.map(({ length, offset }) => ({
    start: offset,
    end: offset + length,
  }));
  const substrings: string[] = [];
  let lastEnd = 0;
  boldRanges.forEach((boldRange) => {
    substrings.push(item.label.slice(lastEnd, boldRange.start));
    substrings.push(item.label.slice(boldRange.start, boldRange.end));
    lastEnd = boldRange.end;
  });
  substrings.push(item.label.slice(lastEnd));

  return (
    <ListItem
      {...getItemProps({ item })}
      data-testid={dataTestId}
      isHighlighted={isHighlighted}
      isSelectedItem={isSelectedItem}
    >
      {substrings.map((string, index) =>
        index % 2 === 1 ? <BoldText key={string}>{string}</BoldText> : string,
      )}
    </ListItem>
  );
};
