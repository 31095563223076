import { createAsyncThunk } from '@reduxjs/toolkit';

import * as Sentry from '@sentry/nextjs';

import {
  RETURNING_HAIRCARE_CONSULTATION_ROUTE,
  returningConsultationRouteByCategory,
} from 'Apps/Consultation/constants';

import * as AuthService from 'Services/AuthService';
import * as CouponService from 'Services/CouponService';
import {
  isExistingUser,
  isFetchError,
  isInvalidCoupon,
  isValidationError,
} from 'Services/HTTPError';

import { productsCategories } from 'constants/products';

import { getVisitorStatus, isReferralCodeValid, sendFriendbuySignupEvent } from 'utils/friendBuy';

import * as authActions from 'dux/auth/actions';
import * as trackingActions from 'dux/tracking/actions';
import * as userActions from 'dux/user/actions';
import { getUserPubkey, getUserReferral } from 'dux/user/selectors';
import { fetchFeatureFlags } from 'dux/featureFlags/thunks';

const signupFields = ['email', 'username', 'first_name', 'last_name'];

// Those origins constants will be removed after the end of homepage-email-capture-pop-up-ab-test
export const apiSignupOrigin = {
  CONSULTATION: {
    [productsCategories.HAIRCARE]: 'consultation_sign_up_haircare',
    [productsCategories.SKINCARE]: 'consultation_sign_up_skincare',
  },
  CONSULTATION_EXIT_MODAL: {
    [productsCategories.HAIRCARE]: 'consultation_exit_modal_haircare',
    [productsCategories.SKINCARE]: 'consultation_exit_modal_skincare',
    [productsCategories.AGNOSTIC]: 'consultation_exit_modal_agnostic',
  },
  HOMEPAGE_POP_UP: 'pop_up_sign_up_v1_test',
  GIFT_CARD_GIFTER: 'gift_card_gifter',
  CHECKOUT_CART_V2: 'checkout_cartv2',
  NO_SIGN_IN_LINK_RECEIVED: {
    [productsCategories.HAIRCARE]: 'no_sign_in_link_received_haircare',
    [productsCategories.SKINCARE]: 'no_sign_in_link_received_skincare',
  },
};

export const heapSignupOrigin = {
  CONSULTATION: 'consultation_sign_up',
  CONSULTATION_EXIT_MODAL: 'consultation_exit_modal',
  HOMEPAGE_POP_UP: 'pop_up_sign_up',
  GIFT_CARD: 'gift_card',
  CHECKOUT: 'checkout',
  NO_SIGN_IN_LINK_RECEIVED: 'no_sign_in_link_received',
};

export const heapSignupSubOrigin = {
  [productsCategories.HAIRCARE]: 'hair',
  [productsCategories.SKINCARE]: 'skin',
  [productsCategories.AGNOSTIC]: 'agnostic',
  V1_TEST: 'v1_test',
  GIFTER: 'gifter',
  CART_V2: 'cart_v2',
};

const getMagicLinkParams = ({ user, referralCode, couponSource, category }) => {
  const params = {
    next: returningConsultationRouteByCategory[category] || RETURNING_HAIRCARE_CONSULTATION_ROUTE,
    username: user.username,
  };

  if (referralCode) {
    params.coupon = referralCode;
  }
  if (couponSource) {
    params.couponSource = couponSource;
  }

  return params;
};

export const signInMagicLink = createAsyncThunk(
  'signInMagicLink',
  async ({ params, firstName }) => {
    await AuthService.requestMagicLink(params);
    return firstName;
  }
);

/**
 * @typedef signupUserPayload
 * @type {object}
 * @property {any} user
 * @property {any} [actions]
 * @property {any} [extraMagicLinkParams]
 * @property {any} [consultationCategory]
 * @property {any} [origin]
 * @property {any} [subOrigin]
 * @property {any} [heapOrigin]
 * @property {any} [heapSubOrigin]
 */
export const signupUser = createAsyncThunk(
  'signup',
  async (
    /** @type {signupUserPayload} */
    {
      user,
      actions,
      extraMagicLinkParams = {},
      consultationCategory = null,
      origin,
      heapOrigin,
      heapSubOrigin,
    },
    { dispatch, getState, rejectWithValue }
  ) => {
    const state = getState();
    const referral = getUserReferral(state);
    try {
      Sentry.addBreadcrumb({
        message: `Signup ${user.first_name} ${user.last_name} ${user.email}`,
        category: 'view',
      });

      await AuthService.signup({ ...user, account_creation_origin: origin });

      await dispatch(userActions.fetchUser());

      // Fetch ff again because of user tag assigning specific variant on some ab tests after account is created
      await dispatch(fetchFeatureFlags());

      if (referral?.code) {
        if (referral.source === 'friendbuy') {
          sendFriendbuySignupEvent(user);
          getVisitorStatus(async status => {
            if (isReferralCodeValid(status)) {
              await CouponService.create({
                code: referral.code,
                source: referral.source,
              });
            }
          });
        } else {
          await CouponService.create({
            code: referral.code,
          });
        }
      }

      const updatedState = getState();
      const userPubkey = getUserPubkey(updatedState);

      dispatch(authActions.signupSuccess());
      dispatch(
        trackingActions.trackSignup({
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          user_id: userPubkey,
          account_creation_origin: heapOrigin,
          account_creation_sub_origin: heapSubOrigin,
        })
      );

      actions?.onSuccess?.();
      // hack: returns success to parent
      return true;
    } catch (error) {
      if (isExistingUser(error)) {
        const params = {
          ...getMagicLinkParams({
            user,
            ...(referral?.code ? { referralCode: referral.code } : {}),
            ...(referral?.source ? { couponSource: referral.source } : {}),
            consultationCategory,
          }),
          ...extraMagicLinkParams,
        };

        dispatch(signInMagicLink({ params, firstName: user.first_name }));
        actions?.setSubmitting(false);
      } else if (
        actions && // hack: check if actions are available because gifting does not support this
        isValidationError(error) &&
        signupFields.some(field => error.extra[field])
      ) {
        signupFields.forEach(
          field => error.extra[field] && actions.setFieldError(field, error.extra[field].join(' '))
        );
        actions?.setSubmitting(false);
      } else if (isInvalidCoupon(error)) {
        return rejectWithValue({ invalidCoupon: true });
      } else {
        if (!isFetchError(error)) {
          Sentry.captureMessage('API Service (AuthService.signup)');
        }
        actions?.setSubmitting(false);
      }

      // hack: returns failure to parent
      return false;
    }
  }
);
