import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Navigate, useLocation } from 'react-router';

import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'dux/app/hooks';

import { AUTH_KEY } from 'Services/Auth';

import { fetchAuthIfNeeded, userSignout } from 'dux/auth/actions';
import { trackUserSignOut } from 'dux/tracking/actions';
import { getIsAuthenticated, getIsAuthenticating } from 'dux/auth/selectors';

/*
  Waits for user to be authenticated
  By default redirects to the signin page if not authenticated
*/

const RequireAuth = ({ children, shouldRedirect }) => {
  const { pathname, search } = useLocation();
  const dispatch = useDispatch();

  const isAuthenticated = useSelector(getIsAuthenticated);
  const isAuthenticating = useSelector(getIsAuthenticating);
  // We have to encode the next field in case it contains some search params itself
  const signInPath = `/signin?next=${encodeURIComponent(`${pathname}${search}`)}`;

  useEffect(() => {
    dispatch(fetchAuthIfNeeded());
    // Setup jwt / state sync logic
    const handleJwtRemoval = ({ key, oldValue, newValue }) => {
      // if auth token removed and user is still in signed-in state
      if (key === AUTH_KEY && oldValue && !newValue && isAuthenticated) {
        dispatch(userSignout()); // This action's reducer resets the state
        dispatch(trackUserSignOut());
      }
    };
    // Add listener
    window.addEventListener('storage', handleJwtRemoval);
    // Clean-up listener function to be called when unmounted
    return () => window.removeEventListener('storage', handleJwtRemoval);
  }, [dispatch, isAuthenticated]);

  if (isAuthenticating) {
    return null;
  }

  if (!isAuthenticated) {
    return shouldRedirect ? <Navigate replace to={signInPath} /> : null;
  }

  return children;
};

RequireAuth.defaultProps = {
  shouldRedirect: true,
};

RequireAuth.propTypes = {
  children: PropTypes.node.isRequired,
  shouldRedirect: PropTypes.bool,
};

export default RequireAuth;
