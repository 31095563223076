import { FRAGRANCE } from 'Apps/Feedback/constants/feedbackSections';
import { haircareFragranceSatisfactionIntensity } from 'Apps/Feedback/constants/feedbackSlugs';

import { productsWithFragrance } from 'constants/products';

import { commasAndListToString } from 'utils/textUtils';

const fragranceIntensitySatisfaction = {
  name: haircareFragranceSatisfactionIntensity,
  route: `/feedback/haircare/${haircareFragranceSatisfactionIntensity}`,
  category: FRAGRANCE,
  shouldBeIncluded: ({ formulas, consultationAnswers }) =>
    !consultationAnswers?.pref_fragrance_free &&
    formulas?.some(f => productsWithFragrance.includes(f.variant.product.type)),
  Component: 'FragranceSatisfactionIntensity',
  surtitle: {
    showFormulas: 'withSurveyFragrance',
    showFragrance: true,
  },
  title: ({ formulas }) =>
    `How satisfied are you with the scent of your ${commasAndListToString(
      formulas
        .filter(f => productsWithFragrance.includes(f.variant.product.type))
        .map(f => f.variant.label)
    )}?`,
  getInitialValue: answers => ({
    fragrance_satisfaction: answers?.fragrance_satisfaction,
    fragrance_intensity: answers?.fragrance_intensity,
  }),
  getAnswers: ({ selected }) => ({
    fragrance_satisfaction: selected?.fragrance_satisfaction,
    fragrance_intensity: selected?.fragrance_intensity,
  }),
  multi: true,
};

export default fragranceIntensitySatisfaction;
