import { theme } from '@prose-ui';
import { ClassNames, css, legacyTheme } from '@prose-ui/legacy';

import { BaseField, type BaseFieldProps } from './BaseField';

const rootStyles = css`
  &::after {
    pointer-events: none;
    content: '';

    position: absolute;
    top: 24px;
    right: 20px;

    width: 0;
    height: 0;
    padding: 0;

    border-top: 6px solid rgb(0 0 0 / 12%);
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
  }
`;

const selectStyles = css`
  appearance: none;
  /* Make sure the text won't finish within the arrow */
  padding-right: ${legacyTheme.spacing.s32};
`;

const inputDisabledStyles = css`
  background-color: ${theme.colors.tertiary['100']};

  &::before {
    border: none;
  }
`;

type ListItem =
  | { label: string; value: number; dataFrom?: string; dataClick?: string }
  | { label: string; value: string; dataFrom?: string; dataClick?: string };
type ListUnion<L extends ListItem[]> = Array<string> | Array<number> | L | Readonly<L>;

type SelectFieldOwnProps<L extends ListItem[]> = {
  /**
   * The list of optons to be rendered.
   * Can be a list of values or objects like { label, value }
   */
  list: ListUnion<L>;
  value?: string | number;
  className?: string;
  classes?: {
    root?: string;
  };
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
};

type SelectFieldProps<L extends ListItem[]> = SelectFieldOwnProps<L> &
  Omit<BaseFieldProps<'select'>, 'children' | keyof SelectFieldOwnProps<L>>;

function isListItem(listItem: string | number | unknown): listItem is ListItem {
  return (
    typeof listItem !== 'string' &&
    typeof listItem !== 'number' &&
    (listItem as ListItem).label !== undefined &&
    (listItem as ListItem).value !== undefined
  );
}

/**
 * SelectField is also based on BaseField. Here it renders a select input.
 */
export const SelectField = <L extends ListItem[] = ListItem[]>({
  list,
  value,
  classes,
  ...props
}: SelectFieldProps<L>) => {
  return (
    <ClassNames>
      {({ cx, css }) => (
        <BaseField
          {...props}
          classes={{
            root: cx(css(rootStyles), classes?.root),
            component: css(selectStyles),
            inputRoot: cx({
              [css(inputDisabledStyles)]: list?.length === 1,
            }),
          }}
          markupName="select"
          value={value ? `${value}` : ''}
        >
          {(props) => (
            <select {...props} disabled={Boolean(list?.length === 1)}>
              <option disabled value="" />
              {list &&
                list.map((listItem, i) =>
                  isListItem(listItem) ? (
                    <option
                      key={listItem.label}
                      data-click={listItem.dataClick ?? null}
                      data-from={listItem.dataFrom ?? null}
                      data-testid="product-frequency-selected"
                      label={listItem.label}
                      value={listItem.value}
                    >
                      {listItem.label}
                    </option>
                  ) : (
                    <option key={i} value={listItem}>
                      {listItem}
                    </option>
                  ),
                )}
            </select>
          )}
        </BaseField>
      )}
    </ClassNames>
  );
};
