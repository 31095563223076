import {
  FIRST_HAIRCARE_QUESTION_ROUTE,
  FIRST_SKINCARE_QUESTION_ROUTE,
} from 'Apps/Consultation/constants';

import { HAIRCARE_CART_URL, SKINCARE_CART_URL } from 'constants/cart';

import image from 'assets/images/promotions/trial-offer-petit-tote-gwp-promotion-modal.jpg';

const content = {
  contentDefault: {
    banner: 'The Self-Care Sale: Get 50% off + a free gift',
    popUpModal: {
      image,
      headerTitle: 'THE SELF-CARE SALE',
      title: 'Get 50% off + a free Petit Tote ($30 value!)',
      description:
        'Subscribe to any 3+ hair or skin products to claim your free gift. Limited time only!',
      cta: {
        consultationCompleted: {
          linkTo: () => HAIRCARE_CART_URL,
          dataClick: 'order-your-prose',
          dataFrom: 'hc-sc-pop-up',
          ctaLabel: 'unlock offer',
        },
        consultationNotCompleted: {
          linkTo: (route: string) => route || FIRST_HAIRCARE_QUESTION_ROUTE,
          dataClick: 'get-your-formula',
          dataFrom: 'hc-sc-pop-up',
          ctaLabel: 'unlock offer',
        },
      },
      ctaExit: {
        dataClick: 'exit',
        dataFrom: 'hc-sc-pop-up',
      },
      conditions: (
        <>
          *Get [1] free Petit Tote when you subscribe to 3+ products in the same category (Haircare
          or Skincare). Enjoy a total of 50% off your first subscription order when you subscribe to
          haircare or skincare. Subsequent subscription orders will receive free shipping and 15%
          off. Offer is valid when your subscription order is placed by 02/09/2025 at 11:59 PM EST
          or while supplies last. Cannot be applied to previous orders or non-subscribed items. Not
          valid for accessories or digital gift cards. For full details on our return policy, click{' '}
          <a
            data-click="return-policy"
            data-from="trial-offer-trial-popup"
            href="https://prose.com/faq/5f3da05bceb96c001a595c02"
            target="_blank"
          >
            here
          </a>
          .
        </>
      ),
      tracking: {
        modalClosing: 'Trial Offer v2 HC + SC Pop-up - Closed',
        modalOpening: 'Trial Offer v2 HC + SC Pop-up - Viewed',
        modalOpening2sec: 'Trial Offer v2 HC + SC Pop-up - Viewed (2 seconds)',
      },
    },
    bottomModal: {
      title: 'NEW YEAR EXCLUSIVE',
      description: (
        <>
          <b>Get 50% off + a free Petit Tote ($30 value!)</b> when you subscribe to any 3+ hair or
          skin products.
        </>
      ),
      conditions: (
        <>
          *Get [1] free Petit Tote when you subscribe to 3+ products in the same category (Haircare
          or Skincare). Enjoy a total of 50% off your first subscription order when you subscribe to
          haircare or skincare. Subsequent subscription orders will receive free shipping and 15%
          off. Offer is valid when your subscription order is placed by 02/09/2025 at 11:59 PM EST
          or while supplies last. Cannot be applied to previous orders or non-subscribed items. Not
          valid for accessories or digital gift cards. For full details on our return policy, click{' '}
          <a
            data-click="return-policy"
            data-from="trial-offer-trial-popup"
            href="https://prose.com/faq/5f3da05bceb96c001a595c02"
            target="_blank"
          >
            here
          </a>
          .
        </>
      ),
    },
    checkoutCommunication: {
      title: 'THE SELF-CARE SALE',
      description: (
        <>
          <b>Get 50% off + a free Petit Tote ($30 value!)</b> when you subscribe to any 3+ hair or
          skin products.
        </>
      ),
      terms: 'See terms & conditions',
    },
  },
  contentHaircare: {
    banner: 'The Self-Care Sale: Get 50% off + a free gift',
    popUpModal: {
      image,
      headerTitle: 'THE SELF-CARE SALE',
      title: 'Get 50% off + a free Petit Tote ($30 value!)',
      description:
        'Subscribe to any 3+ haircare products to claim your free gift. Limited time only!',
      cta: {
        consultationCompleted: {
          linkTo: () => HAIRCARE_CART_URL,
          dataClick: 'order-your-prose',
          dataFrom: 'hc-pop-up',
          ctaLabel: 'unlock offer',
        },
        consultationNotCompleted: {
          linkTo: (route: string) => route || FIRST_HAIRCARE_QUESTION_ROUTE,
          dataClick: 'get-your-formula',
          dataFrom: 'hc-pop-up',
          ctaLabel: 'unlock offer',
        },
      },
      ctaExit: {
        dataClick: 'exit',
        dataFrom: 'hc-pop-up',
      },
      conditions: (
        <>
          *Get [1] free Petit Tote when you subscribe to 3+ products in the same category (Haircare
          or Skincare). Enjoy a total of 50% off your first subscription order when you subscribe to
          haircare or skincare. Subsequent subscription orders will receive free shipping and 15%
          off. Offer is valid when your subscription order is placed by 02/09/2025 at 11:59 PM EST
          or while supplies last. Cannot be applied to previous orders or non-subscribed items. Not
          valid for accessories or digital gift cards. For full details on our return policy, click{' '}
          <a
            data-click="return-policy"
            data-from="trial-offer-trial-popup"
            href="https://prose.com/faq/5f3da05bceb96c001a595c02"
            target="_blank"
          >
            here
          </a>
          .
        </>
      ),
      tracking: {
        modalClosing: 'Trial Offer v2 HC Pop-up - Closed',
        modalOpening: 'Trial Offer v2 HC Pop-up - Viewed',
        modalOpening2sec: 'Trial Offer v2 HC Pop-up - Viewed (2 seconds)',
      },
    },
    bottomModal: {
      title: 'THE SELF-CARE SALE',
      description: (
        <>
          <b>Get 50% off + a free Petit Tote ($30 value!)</b> when you subscribe to any 3+ haircare
          products.
        </>
      ),
      conditions: (
        <>
          *Get [1] free Petit Tote when you subscribe to 3+ products in the same category (Haircare
          or Skincare). Enjoy a total of 50% off your first subscription order when you subscribe to
          haircare or skincare. Subsequent subscription orders will receive free shipping and 15%
          off. Offer is valid when your subscription order is placed by 02/09/2025 at 11:59 PM EST
          or while supplies last. Cannot be applied to previous orders or non-subscribed items. Not
          valid for accessories or digital gift cards. For full details on our return policy, click{' '}
          <a
            data-click="return-policy"
            data-from="trial-offer-trial-popup"
            href="https://prose.com/faq/5f3da05bceb96c001a595c02"
            target="_blank"
          >
            here
          </a>
          .
        </>
      ),
    },
    checkoutCommunication: {
      title: 'THE SELF-CARE SALE',
      description: (
        <>
          <b>Get 50% off + a free Petit Tote ($30 value!)</b> when you subscribe to any 3+ haircare
          products.
        </>
      ),
      terms: 'See terms & conditions',
    },
  },
  contentSkincare: {
    banner: 'The Self-Care Sale: Get 50% off + a free gift',
    popUpModal: {
      image,
      headerTitle: 'THE SELF-CARE SALE',
      title: 'Get 50% off + a free Petit Tote ($30 value!)',
      description:
        'Subscribe to any 3+ skincare products to claim your free gift. Limited time only!',
      cta: {
        consultationCompleted: {
          linkTo: () => SKINCARE_CART_URL,
          dataClick: 'order-your-prose',
          dataFrom: 'sc-pop-up',
          ctaLabel: 'unlock offer',
        },
        consultationNotCompleted: {
          linkTo: () => FIRST_SKINCARE_QUESTION_ROUTE,
          dataClick: 'get-your-formula',
          dataFrom: 'sc-pop-up',
          ctaLabel: 'unlock offer',
        },
      },
      ctaExit: {
        dataClick: 'exit',
        dataFrom: 'sc-pop-up',
      },
      conditions: (
        <>
          *Get [1] free Petit Tote when you subscribe to 3+ products in the same category (Haircare
          or Skincare). Enjoy a total of 50% off your first subscription order when you subscribe to
          haircare or skincare. Subsequent subscription orders will receive free shipping and 15%
          off. Offer is valid when your subscription order is placed by 02/09/2025 at 11:59 PM EST
          or while supplies last. Cannot be applied to previous orders or non-subscribed items. Not
          valid for accessories or digital gift cards. For full details on our return policy, click{' '}
          <a
            data-click="return-policy"
            data-from="trial-offer-trial-popup"
            href="https://prose.com/faq/5f3da05bceb96c001a595c02"
            target="_blank"
          >
            here
          </a>
          .
        </>
      ),
      tracking: {
        modalClosing: 'Trial Offer v2 SC Pop-up - Closed',
        modalOpening: 'Trial Offer v2 SC Pop-up - Viewed',
        modalOpening2sec: 'Trial Offer v2 SC Pop-up - Viewed (2 seconds)',
      },
    },
    bottomModal: {
      title: 'THE SELF-CARE SALE',
      description: (
        <>
          <b>Get 50% off + a free Petit Tote ($30 value!)</b> when you subscribe to any 3+ skincare
          products.
        </>
      ),
      conditions: (
        <>
          *Get [1] free Petit Tote when you subscribe to 3+ products in the same category (Haircare
          or Skincare). Enjoy a total of 50% off your first subscription order when you subscribe to
          haircare or skincare. Subsequent subscription orders will receive free shipping and 15%
          off. Offer is valid when your subscription order is placed by 02/09/2025 at 11:59 PM EST
          or while supplies last. Cannot be applied to previous orders or non-subscribed items. Not
          valid for accessories or digital gift cards. For full details on our return policy, click{' '}
          <a
            data-click="return-policy"
            data-from="trial-offer-trial-popup"
            href="https://prose.com/faq/5f3da05bceb96c001a595c02"
            target="_blank"
          >
            here
          </a>
          .
        </>
      ),
    },
    checkoutCommunication: {
      title: 'THE SELF-CARE SALE',
      description: (
        <>
          <b>Get 50% off + a free Petit Tote ($30 value!)</b> when you subscribe to any 3+ skincare
          products.
        </>
      ),
      terms: 'See terms & conditions',
    },
  },
};

export default content;
