import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Navigate, useLocation } from 'react-router';

import { connect } from 'react-redux';

import UserFraud from 'Apps/Main/Scenes/UserFraud';

import Delay from 'Components/Delay';

import * as CouponService from '../Services/CouponService';
import { isFetchError, isInvalidCoupon } from '../Services/HTTPError';

import ErrorScene from 'Scenes/ErrorScene';
import LoadingScene from 'Scenes/LoadingScene';

import { getVisitorStatus, isReferralCodeValid } from 'utils/friendBuy';
import { forwardUTMIfNeeded, toRelativeURL } from 'utils/utm';

import * as trackingActions from 'dux/tracking/actions';
import {
  openRootToast as openRootToastAction,
  setReferral as setReferralAction,
} from 'dux/user/actions';
import { getIsAuthenticated, getIsAuthenticating } from 'dux/auth/selectors';

/**
 * Sets a referral code in the store if user is likelly allowed to use that code
 * In case user is logged, attach coupon to the user right away
 */

const RedeemLanding = ({
  isAuthenticating,
  isAuthenticated,
  setReferral,
  trackBreadcrumb,
  openRootToast,
}) => {
  const [isLoading, setIsLoading] = useState(isAuthenticating || isAuthenticated);
  const [referralError, setReferralError] = useState(null);
  const { pathname, search } = useLocation();
  const params = new URLSearchParams(search);
  const referral = {
    // fbuy_ref_code is legacy but still used by email campaigns, DO NOT remove it upon friendbuy v2 migration
    code: params.get('referralCode') || params.get('fbuy_ref_code'),
    source: params.get('utm_source'),
  };
  const fraud = params.get('fbuy_fraud');
  const targetURL = forwardUTMIfNeeded(document.location, params.get('next') ?? '/');

  useEffect(() => {
    trackBreadcrumb(`${pathname}${search}`);
  }, [pathname, search]);

  useEffect(() => {
    let didCleanup = false;

    // Only called in friendbuy v2
    async function createCoupon() {
      if (referral.code) {
        if (referral.source === 'friendbuy') {
          getVisitorStatus(async status => {
            if (isReferralCodeValid(status)) {
              try {
                await CouponService.create({
                  code: referral.code,
                  source: referral.source,
                });
                setReferral({ code: referral.code, source: referral.source });
                openRootToast();
                if (!didCleanup) {
                  setIsLoading(false);
                }
              } catch (err) {
                setReferral(null);
                if (!didCleanup) {
                  setReferralError(err);
                }
              }
            } else {
              setReferral(null);
              if (!didCleanup) {
                setReferralError({ code: 'coupon_invalid' }); // FIXME: do not force api error like this
              }
            }
          });
        } else {
          try {
            await CouponService.create({
              code: referral.code,
            });
            setReferral({ code: referral.code });
            openRootToast();
            if (!didCleanup) {
              setIsLoading(false);
            }
          } catch (err) {
            setReferral(null);
            if (!didCleanup) {
              setReferralError(err);
            }
          }
        }
      }
    }

    // call createCoupon only if the user is authenticated
    if (!isAuthenticating && isAuthenticated) {
      createCoupon();
    }

    // whether or not the loading should be shown
    if (!isAuthenticating && !isAuthenticated) {
      // put the coupon into memory as the customer isn't authenticated
      setReferral({ code: referral.code, source: referral.source });
      setIsLoading(false);
    }

    return () => {
      didCleanup = true;
    };
  }, [
    isAuthenticating,
    isAuthenticated,
    referral.code,
    referral.source,
    openRootToast,
    setReferral,
  ]);

  if (referralError && isInvalidCoupon(referralError)) {
    return <Navigate replace to="/redeem/invalid-code" />;
  }

  if (referralError) {
    return <ErrorScene isOffline={isFetchError(referralError)} />;
  }

  if (fraud) {
    return <UserFraud />;
  }

  if (isLoading) {
    return (
      <Delay>
        {({ pastDelay }) => {
          return <LoadingScene pastDelay={pastDelay} />;
        }}
      </Delay>
    );
  }

  return <Navigate replace to={referral.code ? toRelativeURL(targetURL) : 'invalid-code'} />;
};

RedeemLanding.propTypes = {
  isAuthenticating: PropTypes.bool.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  setReferral: PropTypes.func.isRequired,
  openRootToast: PropTypes.func.isRequired,
  trackBreadcrumb: PropTypes.func,
};

RedeemLanding.defaultProps = {
  trackBreadcrumb: () => {},
};

const mapStateToProps = state => ({
  isAuthenticating: getIsAuthenticating(state),
  isAuthenticated: getIsAuthenticated(state),
});

const mapDispatchToProps = {
  setReferral: setReferralAction,
  openRootToast: openRootToastAction,
  trackBreadcrumb: trackingActions.trackBreadcrumb,
};

export default connect(mapStateToProps, mapDispatchToProps)(RedeemLanding);
