import { Link } from 'react-router';

import arganOilImg from 'assets/images/productPages/ingredients/argan_oil.png';
import castorOilImg from 'assets/images/productPages/ingredients/castor_oil.png';
import honeyImg from 'assets/images/productPages/ingredients/honey.png';
import hyaluronicAcidImg from 'assets/images/productPages/ingredients/hyaluronic_acid.png';
import jojobaOilImg from 'assets/images/productPages/ingredients/jojoba_oil.png';
import lilacExtractImg from 'assets/images/productPages/ingredients/lilac_extract.png';
import mangoButterImg from 'assets/images/productPages/ingredients/mango_butter.png';
import oatOilImg from 'assets/images/productPages/ingredients/oat_oil.png';
import sachaInchiOilImg from 'assets/images/productPages/ingredients/sacha_inchi_oil.png';
import silkProteinsImg from 'assets/images/productPages/ingredients/silk_proteins.png';
import sunflowerOilImg from 'assets/images/productPages/ingredients/sunflower_oil.png';
import vitaminEImg from 'assets/images/productPages/ingredients/vitamin_e.png';
import carouselImg1 from 'assets/images/productPages/prose-custom-pre-shampoo-hair-mask.jpg';
import carouselImg2 from 'assets/images/productPages/prose-custom-pre-shampoo-hair-mask-close-up.jpg';
import carouselImg4 from 'assets/images/productPages/prose-custom-pre-shampoo-hair-mask-in-bathroom.jpg';
import carouselImg3 from 'assets/images/productPages/prose-model-using-pre-shampoo-hair-mask.jpg';
import formulaModuleImgDesktop from 'assets/images/productPages/prose-model-using-pre-shampoo-hair-mask-close-up-desktop.jpg';
import formulaModuleImgMobile from 'assets/images/productPages/prose-model-using-pre-shampoo-hair-mask-close-up-mobile.jpg';
import carouselImg5 from 'assets/images/productPages/prose-ugc-hairmask.jpg';

export const hairMaskPageContent = {
  sampleFormulasSlider: [
    {
      title: "REMY'S NOURISHING + PROTECTIVE HAIR MASK",
      ingredients: [
        {
          image: jojobaOilImg,
          name: 'Jojoba Oil',
          benefit: 'Nourishing',
        },
        {
          image: hyaluronicAcidImg,
          name: 'Hyaluronic Acid',
          benefit: 'Fiber Repair',
        },
        {
          image: oatOilImg,
          name: 'Oat Oil',
          benefit: 'Color Protection',
        },
        {
          image: silkProteinsImg,
          name: 'Silk Proteins',
          benefit: 'Cuticle Nutrition',
        },
        {
          image: mangoButterImg,
          name: 'Mango Butter',
          benefit: 'Deep Nutrition',
        },
        {
          image: castorOilImg,
          name: 'Castor Oil',
          benefit: 'Anti-Breakage',
        },
      ],
    },
    {
      title: "FIONA'S HYDRATING + RESTORING HAIR MASK",
      ingredients: [
        {
          image: sachaInchiOilImg,
          name: 'Sacha Inchi Oil',
          benefit: 'Fiber Repairing',
        },
        {
          image: hyaluronicAcidImg,
          name: 'Hyarulonic Acid',
          benefit: 'Hair Health',
        },
        {
          image: arganOilImg,
          name: 'Argan Oil',
          benefit: 'Nourishment',
        },
        {
          image: honeyImg,
          name: 'Honey',
          benefit: 'Hydratation',
        },
        {
          image: oatOilImg,
          name: 'Oat Oil',
          benefit: 'Color Protection',
        },
        {
          image: vitaminEImg,
          name: 'Vitamin E',
          benefit: 'Antioxidant',
        },
      ],
    },
    {
      title: "MARIN'S SMOOTHING + STRENGTHENING HAIR MASK",
      ingredients: [
        {
          image: mangoButterImg,
          name: 'Mango Butter',
          benefit: 'Deep Nutrition',
        },
        {
          image: lilacExtractImg,
          name: 'Lilac Extract',
          benefit: 'Hydration',
        },
        {
          image: sunflowerOilImg,
          name: 'Sunflower Oil',
          benefit: 'Antioxydant',
        },
        {
          image: castorOilImg,
          name: 'Castor Oil',
          benefit: 'Anti-Breakage',
        },
        {
          image: silkProteinsImg,
          name: 'Silk Proteins',
          benefit: 'Cuticle Nutrition',
        },
        {
          image: jojobaOilImg,
          name: 'Jojoba Oil',
          benefit: 'Nourishing',
        },
      ],
    },
  ],
  proTips: [
    {
      background: 'vert',
      question: 'What does a hair mask do?',
      description:
        'Your hair’s cuticle is more receptive to our soothing mask before you wash. Using your Custom Hair Mask first allows the unique blend of nourishing ingredients to work their way inside each strand and better condition and repair your hair. It also helps your shampoo give a gentler clean with zero irritation.',
    },
  ],
  faq: [
    {
      question: 'How does my custom Hair Mask work?',
      answer:
        'Our Hair Mask is customized to your hair needs and uniquely formulated with the ideal level of moisturizing ingredients to hydrate and repair the lengths and ends of your hair.',
    },
    {
      question: 'How do I use my Prose Hair Mask?',

      answer:
        'The Hair Mask should be applied to damp hair before you shampoo, and left on for up to 30 minutes (if you can spare the time). Usage and frequency will vary according to your hair needs, so check the notes that come with your order--they’ll give you some personalized pointers.',
    },
    {
      question: 'What hair mask should I use?',

      answer: (
        <>
          The best hair mask you can use is one that’s suited to your hair type, porosity, density,
          and local climate — which makes a custom Hair Mask the ideal choice. Geo-aggressors like
          pollution, pollen, and UV rays can impact the kind of care your hair needs. Your diet and
          lifestyle can impact your hair too, determining what nutrients your hair needs and what
          kind of stress it’s experiencing. We make it easy by factoring it all into your Custom
          Hair Mask to deliver the results you want. Take the{' '}
          <Link to="/consultation/haircare">consultation</Link> to find out what your hair needs.
        </>
      ),
    },
    {
      question: 'How is this Hair Mask different from shampoo or conditioner?',
      answer:
        'Our Hair Mask contains the highest concentrations of your most-needed active ingredients, making it a potent pre-wash treatment to round out your hair care routine.',
    },
    {
      question: 'Can I use the Hair Mask as a leave-in conditioner?',
      answer:
        'Unlike our Leave-In Conditioner, the Hair Mask should typically be left on for 5-30 minutes, as specified in the notes you receive with your products. It should always be followed by your Prose Shampoo and thoroughly washed out for the best results.',
    },
    {
      question: 'Is the Hair Mask color-safe?',
      answer:
        'Yes — if you indicate that you have color-treated or dyed hair during the consultation, you can trust that your Hair Mask (and your whole routine) will be color-safe.',
    },
    {
      question: 'Is the Hair Mask made for all hair types?',
      answer:
        'Prose custom hair masks work on straight (type 1), wavy (type 2), curly (type 3), and coily (type 4) hair. All of your Prose formulas are tailored for your individual hair density and porosity, so your hair gets the exact care that it needs. That means that your Custom Hair Mask will deliver the results you want, whether your hair is straight (type 1), wavy (type 2), or curly (type 3), or coily (type 4).',
    },
    {
      question: 'How do I customize my Hair Mask?',
      answer: (
        <>
          Creating your own Custom Hair Mask formula is as easy as 1, 2, 3: <br />
          1. <Link to="/consultation/haircare">Take your online consultation</Link> about your hair,
          lifestyle and environment to understand what kind of care your hair needs. <br />
          2. From your answers, we’ll create a personalized hair mask based on 85+ factors and
          formulated to support your unique needs at this moment in time. <br />
          3. You can always share feedback on your hair mask formula after every order using our
          Review and Refine® survey so we can provide evolving, custom-fitting care.
        </>
      ),
    },
    {
      question: 'How often should I use my Hair Mask?',
      answer:
        'How frequently you use your Hair Mask will depend on your unique hair needs. External factors like pollution and humidity or stress levels and hormones can impact the sebum production (AKA, the natural oils your scalp produces to nourish your hair). Your unique Hair Mask formula will take all of that into account to keep your scalp happy and balanced — and your hair healthy.',
    },
    {
      question: 'Are there any ingredients I should avoid in a hair mask?',
      answer:
        'At Prose, we only use ingredients that are high quality and highly effective. That means that every ingredient in your Custom Hair Mask has been meticulously researched by our world-class R&D team and sustainably sourced from trusted partners around the globe. And because Prose formulas are always free from anything harmful, we never use ingredients like sulfates, parabens, phthalates, mineral oil, alcohol, or GMOs. ',
    },
    {
      question: 'Can Prose Custom Hair Mask treat medical conditions like hair loss or dandruff?',
      answer:
        'Prose is not intended to treat medical conditions. Our formulas are a 100% drug-free alternative to prescription products and OTC (over-the-counter) medicines. Instead, we use only the highest quality, naturally powerful and proven-effective ingredients to enhance shine, volume, hair health, and so much more.',
    },
  ],
  imageCarousel: [
    {
      image: carouselImg1,
      alt: 'Prose custom hair mask packaging',
    },
    {
      image: carouselImg2,
      alt: 'Prose custom hair mask packaging shown close up',
    },
    {
      image: carouselImg3,
      alt: 'Prose model using custom hair mask in the shower',
    },
    {
      image: carouselImg4,
      alt: 'Prose custom hair mask packaging shown in bathroom',
    },
    {
      image: carouselImg5,
      alt: 'Models posing with custom hair mask bottles',
    },
  ],
  formulaModule: {
    header: (
      <>
        A personalized hair mask,
        <br /> made to order just for you
      </>
    ),
    description:
      'Every hair mask we formulate is personalized to 80+ factors from your in-depth consultation. Analyzing everything from your hair type to your zip code, we’ll create a formula tailored to your one-of-a-kind needs. Below are just a few examples from the millions of possibilities.',
    hero: {
      imageDesktop: formulaModuleImgDesktop,
      imageMobile: formulaModuleImgMobile,
      alt: 'Prose model using custom hair mask',
    },
  },
  noListModule: {
    description:
      'Every ingredient in your Custom Hair Mask is rigorously researched, backed by science, and precisely selected to target your unique hair goals and concerns. We responsibly source only the clean ingredients you need — nothing more, nothing less.',
    title: 'A hair mask with naturally powerful + proven-effective ingredients',
  },
};
