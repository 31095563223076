import { useEffect, useMemo } from 'react';
import { forbidExtraProps } from 'airbnb-prop-types';

import { useLocation } from 'react-router';

import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'dux/app/hooks';

import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import split from 'lodash/fp/split';

import * as checkoutStatus from '../constants/checkoutStatus';

import LoadingScene from 'Scenes/LoadingScene';
import CheckoutCompletedScene from '../Scenes/CheckoutCompletedScene';
import CheckoutErrorSceneV2 from '../Scenes/CheckoutErrorSceneV2';
import CheckoutIdleSceneV2 from '../Scenes/CheckoutIdleSceneV2';

import { Elements } from 'utils/stripeUtils';

import * as trackingActions from 'dux/tracking/actions';
import { getCheckoutStatusV2 } from 'dux/cartV2/selectors';

import CheckoutFormV2 from './CheckoutFormV2';

const getCurrentStepFromPathname = flow(split('/'), get('[3]'));

// For status specific logic report to each scene
const componentForStatus = {
  [checkoutStatus.ERROR]: CheckoutErrorSceneV2,
  [checkoutStatus.FINISHED]: CheckoutCompletedScene,
  [checkoutStatus.IDLE]: CheckoutIdleSceneV2,
  [checkoutStatus.LOADING]: LoadingScene,
  [checkoutStatus.SAVING]: CheckoutFormV2,
  [checkoutStatus.SUCCESS]: CheckoutFormV2,
};

const CheckoutContainerV2 = () => {
  const { pathname, search } = useLocation();
  const dispatch = useDispatch();

  const params = useMemo(() => new URLSearchParams(search), [search]);
  const step = useMemo(() => getCurrentStepFromPathname(pathname), [pathname]);
  // Get status from state
  const status = useSelector(getCheckoutStatusV2);

  // Track current step for analytics
  useEffect(() => {
    dispatch(trackingActions.trackPaymentStep(step));
  }, [step]);

  // Select the right Component to render depending on checkout status
  const CheckoutComponent = componentForStatus[status] || CheckoutErrorSceneV2;

  // Inject URL infos as props to checkout component
  return (
    <Elements
      options={{
        fonts: [
          {
            family: 'Simplon Norm',
            src: 'url(/fonts/SimplonNorm-Regular-WebS.woff)',
          },
        ],
      }}
    >
      <CheckoutComponent params={params} step={step} />
    </Elements>
  );
};

CheckoutContainerV2.propTypes = forbidExtraProps({});

export default CheckoutContainerV2;
