import { HAIR } from 'Apps/Feedback/constants/feedbackSections';
import { haircareHairSatisfaction } from 'Apps/Feedback/constants/feedbackSlugs';

import { productsSlugs } from 'constants/products';

const hairSatisfaction = {
  name: haircareHairSatisfaction,
  route: `/feedback/haircare/${haircareHairSatisfaction}`,
  category: HAIR,
  shouldBeIncluded: ({ formulas }) =>
    formulas?.some(f =>
      [productsSlugs.HAIR_MASK, productsSlugs.SHAMPOO, productsSlugs.CONDITIONER].includes(
        f.variant.product.type
      )
    ),
  Component: 'Rating',
  title: 'Overall, how satisfied are you with the condition of your hair?',
  subtitle: 'Consider how it looks, feels, and behaves on a daily basis',
  options: [
    { value: 1, label: 'Very Unsatisfied' },
    { value: 2, label: 'Unsatisfied' },
    { value: 3, label: 'Neutral' },
    { value: 4, label: 'Satisfied' },
    { value: 5, label: 'Very Satisfied' },
  ],
  getInitialValue: answers => answers?.hair_satisfaction,
  getAnswers: ({ selected }) => ({ hair_satisfaction: selected }),
};

export default hairSatisfaction;
