export const productsSlugs = {
  BRUSH: 'brush',
  BRUSH_BOAR: 'brush_boar',
  BRUSH_DETANGLING: 'brush_detangling',
  BRUSH_MIXED: 'brush_mixed',
  CANDLE: 'candle',
  CANDLE_ARCADIA: 'candle_arcadia',
  CANDLE_CORSICA: 'candle_corsica',
  CANDLE_PRELUDE: 'candle_prelude',
  CLEANSER: 'cleanser',
  COMPLETE_SET: 'complete_set',
  CONDITIONER: 'conditioner',
  CONDITIONER_COARSE: 'conditioner_coarse',
  CURL_CREAM: 'curl_cream',
  DIGITAL_GIFT: 'digital_gift',
  DRY_SHAMPOO: 'dry_shampoo',
  ESSENTIALS_SET: 'essentials_set',
  GIFT: 'gift',
  GIFT_WITH_PURCHASE: 'gwp',
  HAIR_MASK: 'hair_mask',
  HAIR_OIL:
    'hair_oil' /* currently 'leavein' is for hairoil, but this could also appear in data sometimes */,
  HAIR_TOWEL_WRAP: 'hair_towel_wrap',
  LEAVEIN_CONDITIONER: 'leavein_conditioner',
  MASK: 'mask',
  MOISTURIZER: 'moisturizer',
  OIL: 'leavein' /* currently used for hair oil */,
  SCALP_MASK: 'scalp_mask',
  SCALP_SERUM: 'scalp_serum',
  SERUM: 'serum',
  SHAMPOO: 'shampoo',
  STYLING_GEL: 'styling_gel',
  SUPPLEMENT_BOOSTER: 'supplement_booster',
  SUPPLEMENT_CORE: 'supplement_core',
  SUPPLEMENTS_POUCH: 'supplements_pouch',
  TRIAL_CONDITIONER: 'trial_conditioner',
  TRIAL_SHAMPOO: 'trial_shampoo',
  CLEANSER_MINI: 'cleanser_mini',
  MOISTURIZER_MINI: 'moisturizer_mini',
  SERUM_MINI: 'serum_mini',
} as const;

export const supplementsVariantsSlugs = {
  SUPPLEMENT_0001: 'supplement_core_0001',
  SUPPLEMENT_0002: 'supplement_core_0002',
  SUPPLEMENT_0003: 'supplement_core_0003',
  SUPPLEMENT_0004: 'supplement_core_0004',
  SUPPLEMENT_0006: 'supplement_core_0006',
  SUPPLEMENT_POUCH_0001: 'supplement_core_0001_pouch',
  SUPPLEMENT_POUCH_0002: 'supplement_core_0002_pouch',
  SUPPLEMENT_POUCH_0003: 'supplement_core_0003_pouch',
  SUPPLEMENT_POUCH_0004: 'supplement_core_0004_pouch',
  SUPPLEMENT_POUCH_0006: 'supplement_core_0006_pouch',
} as const;

export const productsVariants = {
  LEAVEIN_CONDITIONER_XTRA_V1: 'leavein_conditioner_xtra_v1',
} as const;

export const productsLabels = {
  [productsSlugs.HAIR_MASK]: 'Hair Mask',
  [productsSlugs.DIGITAL_GIFT]: 'Digital Gift Cards',
  [productsSlugs.BRUSH]: 'Brushes',
  [productsSlugs.SCALP_MASK]: 'Scalp Mask',
  [productsSlugs.SHAMPOO]: 'Shampoo',
  [productsSlugs.CONDITIONER]: 'Conditioner',
  [productsSlugs.LEAVEIN_CONDITIONER]: 'Leave-In Conditioner',
  [productsSlugs.DRY_SHAMPOO]: 'Dry Shampoo',
  [productsSlugs.OIL]: 'Hair Oil',
  [productsSlugs.HAIR_OIL]: 'Hair Oil',
  [productsSlugs.CURL_CREAM]: 'Curl Cream',
  [productsSlugs.SCALP_SERUM]: 'Scalp Serum',
  [productsSlugs.SUPPLEMENT_CORE]: 'Root Source® Hair Supplements',
  [productsSlugs.SERUM]: 'Serum',
  [productsSlugs.MOISTURIZER]: 'Moisturizer',
  [productsSlugs.CLEANSER]: 'Cleanser',
  [productsSlugs.CANDLE]: 'Candle',
  [productsSlugs.CANDLE_ARCADIA]: 'Arcadia Candle',
  [productsSlugs.CANDLE_CORSICA]: 'Corsica Candle',
  [productsSlugs.CANDLE_PRELUDE]: 'Prelude Candle',
  [productsSlugs.HAIR_TOWEL_WRAP]: 'Hair Towel Wrap',
  [productsSlugs.STYLING_GEL]: 'Styling Gel',
  [productsSlugs.BRUSH_BOAR]: 'Boar Bristle Brush',
  [productsSlugs.BRUSH_MIXED]: 'Boar + Nylon Brush',
  [productsSlugs.BRUSH_DETANGLING]: 'Tipped-Metal Brush',
  [productsSlugs.CLEANSER_MINI]: 'Cleanser',
  [productsSlugs.MOISTURIZER_MINI]: 'Moisturizer',
  [productsSlugs.SERUM_MINI]: 'Serum',
  default: '',
} as const;

export const haircareTopicalProducts = [
  productsSlugs.HAIR_MASK,
  productsSlugs.SCALP_MASK,
  productsSlugs.SHAMPOO,
  productsSlugs.CONDITIONER,
  productsSlugs.DRY_SHAMPOO,
  productsSlugs.OIL,
  productsSlugs.CURL_CREAM,
  productsSlugs.LEAVEIN_CONDITIONER,
  productsSlugs.STYLING_GEL,
  productsSlugs.SCALP_SERUM,
] as const;

export const haircareSupplementsProducts = [productsSlugs.SUPPLEMENT_CORE] as const;

export const skincareMinisSlugs = [
  productsSlugs.CLEANSER_MINI,
  productsSlugs.MOISTURIZER_MINI,
  productsSlugs.SERUM_MINI,
] as const;

export const skincareProducts = [
  productsSlugs.CLEANSER,
  productsSlugs.SERUM,
  productsSlugs.MOISTURIZER,
  ...skincareMinisSlugs,
] as const;

export const products = [
  ...haircareTopicalProducts,
  productsSlugs.SUPPLEMENT_CORE,
  ...skincareProducts,
] as const;

export type ProductSlug = (typeof products)[number];

export const commonProductsRoutePath = '/products/';

export const haircareProductsRoute = {
  [productsSlugs.HAIR_MASK]: `${commonProductsRoutePath}custom-hair-mask`,
  [productsSlugs.SCALP_MASK]: `${commonProductsRoutePath}custom-scalp-mask`,
  [productsSlugs.SHAMPOO]: `${commonProductsRoutePath}custom-shampoo`,
  [productsSlugs.CONDITIONER]: `${commonProductsRoutePath}custom-conditioner`,
  [productsSlugs.OIL]: `${commonProductsRoutePath}custom-hair-oil`,
  [productsSlugs.DRY_SHAMPOO]: `${commonProductsRoutePath}custom-dry-shampoo`,
  [productsSlugs.CURL_CREAM]: `${commonProductsRoutePath}custom-curl-cream`,
  [productsSlugs.LEAVEIN_CONDITIONER]: `${commonProductsRoutePath}custom-leave-in-conditioner`,
  [productsSlugs.STYLING_GEL]: `${commonProductsRoutePath}custom-styling-gel`,
  [productsSlugs.SUPPLEMENT_CORE]: `${commonProductsRoutePath}custom-hair-supplements`,
  [productsSlugs.SCALP_SERUM]: `${commonProductsRoutePath}custom-scalp-serum`,
};

export const skincareProductsRoute = {
  [productsSlugs.CLEANSER]: `${commonProductsRoutePath}custom-cleanser`,
  [productsSlugs.SERUM]: `${commonProductsRoutePath}custom-serum`,
  [productsSlugs.MOISTURIZER]: `${commonProductsRoutePath}custom-moisturizer`,
};

export const accessoriesProductsRoute = {
  [productsSlugs.BRUSH]: `${commonProductsRoutePath}brushes`,
  [productsSlugs.CANDLE]: `${commonProductsRoutePath}prose-candle`,
  [productsSlugs.HAIR_TOWEL_WRAP]: `${commonProductsRoutePath}prose-hair-towel`,
};

export const productsWithFragrance = [
  productsSlugs.HAIR_MASK,
  productsSlugs.SCALP_SERUM,
  productsSlugs.SHAMPOO,
  productsSlugs.CONDITIONER,
  productsSlugs.LEAVEIN_CONDITIONER,
  productsSlugs.CURL_CREAM,
  productsSlugs.STYLING_GEL,
  productsSlugs.CLEANSER,
  productsSlugs.SERUM,
  productsSlugs.MOISTURIZER,
  productsSlugs.CLEANSER_MINI,
  productsSlugs.SERUM_MINI,
  productsSlugs.MOISTURIZER_MINI,
] as const;

/**
 * Categories
 */

export const productsCategories = {
  OTHERS: 'others',
  COUPON: 'coupon',
  FORMULA: 'formula',
  GIFT: 'gift',
  ITEM: 'item',
  AGNOSTIC: 'agnostic',
  HAIRCARE: 'haircare',
  SKINCARE: 'skincare',
  SUPPLEMENTS: 'supplements',
  ACCESSORIES: 'accessories',
} as const;

export const productsSubCategories = {
  TOPICALS: 'topicals',
  SUPPLEMENTS: 'supplements',
  LIFESTYLE: 'lifestyle',
  TOOLS: 'tools',
} as const;

/**
 * XXX: Obsolete, prices are api driven. To be removed
 */

export const productsPrices = {
  [productsSlugs.CURL_CREAM]: 26,
  [productsSlugs.CONDITIONER]: 28,
  [productsSlugs.DRY_SHAMPOO]: 26,
  [productsSlugs.HAIR_MASK]: 42,
  [productsSlugs.LEAVEIN_CONDITIONER]: 26,
  [productsSlugs.OIL]: 48,
  [productsSlugs.SCALP_MASK]: 42,
  [productsSlugs.SHAMPOO]: 28,
  [productsSlugs.SUPPLEMENT_CORE]: 52,
  [productsSlugs.STYLING_GEL]: 30,
} as const;

/* This is a workaround because API is unpredictable when receiving the Minis slugs in a POST to the `/v2/subscriptions/{subscriptionPubkey}/preview` endpoint */
export const nonSubscribableProducts = [...skincareMinisSlugs] as const;
