import * as APIClient from './APIClient';

/** @typedef {import('../dux/products/slice').Product} Product */

export const fetchProducts = async addressPubKey => {
  const response = await APIClient.get(
    `/v1/products${addressPubKey ? `?shipping_address=${addressPubKey}` : ''}`
  );
  return /** @type {Array<Product>} */ (response.json());
};

export const fetchGiftsSets = async () => {
  const response = await APIClient.get('/v1/products/digital_gift');
  return response.json();
};
