import { useEffect } from 'react';

import { useParams } from 'react-router';

import { useAppDispatch, useAppSelector } from 'dux/app/hooks';

import { sortBy } from 'remeda';

import { Box, theme } from '@prose-ui';
import { styled } from '@prose-ui/legacy';
import { mediaMinWidth } from '@prose-ui/utils/media';

import { Typography } from 'Components/Typography';

import { IDLE, SUCCESS } from 'constants/statuses';

import ErrorContainer from 'Containers/ErrorContainer';

import { LegacyBaseScene } from 'Scenes/LegacyBaseScene';
import LoadingScene from 'Scenes/LoadingScene';

import * as digitalLeafletSelectors from 'dux/digitalLeaflet/selectors';
import { getSortedProductSlugs } from 'dux/products/selectors';
import { fetchDigitalLeaflet } from 'dux/digitalLeaflet/thunks';
import { fetchProducts } from 'dux/products/thunks';

import ImageBgHero from './ImageBgHero';
import ProductsSection from './ProductsSection';

const TextBox = styled(Box)`
  --padding-x: ${theme.spacing['4x']};
  --padding-y: ${theme.spacing['4x']};

  padding-inline: var(--padding-x);
  padding-block: var(--padding-y);

  text-align: center;
  max-width: calc(272px + 2 * var(--padding-x));

  ${mediaMinWidth('sm')} {
    --padding-x: ${theme.spacing['8x']};

    max-width: calc(520px + 2 * var(--padding-x));
    text-align: left;
  }
`;

const Title = styled(Typography)`
  font-size: 28px;
  margin-bottom: ${theme.spacing['2x']};

  ${mediaMinWidth('sm')} {
    margin-bottom: ${theme.spacing['4x']};
    font-size: 48px;
  }
`;

const Paragraph = styled(Typography)`
  font-size: 14px;
  text-align: center;

  ${mediaMinWidth('sm')} {
    font-size: 16px;
    text-align: left;
  }
`;

const OrderNumberBlock = styled.div`
  display: grid;
  justify-items: center;
  text-align: center;

  color: ${theme.colors.neutral[900]};

  grid-gap: ${theme.spacing['2x']};
  margin-block: ${theme.spacing['10x']};
  ${mediaMinWidth('sm')} {
    grid-gap: ${theme.spacing['6x']};
    margin-block: ${theme.spacing['15x']};
  }
`;

const OrderNumberText = styled.p`
  margin: 0;

  font-family: ${theme.typography.fontFamily.label};
  font-size: ${theme.typography.fontSize.sm};
  font-weight: 500;
  text-transform: uppercase;
`;

const CustomProductsText = styled.p`
  margin: 0;

  font-family: ${theme.typography.fontFamily.heading};
  font-weight: 400;

  font-size: ${theme.typography.fontSize.lg};
  ${mediaMinWidth('sm')} {
    font-size: ${theme.typography.fontSize['2xl']};
  }
`;

const DigitalLeaflet = () => {
  const { orderPubKey } = useParams();

  const dispatch = useAppDispatch();

  const data = useAppSelector(digitalLeafletSelectors.getData);
  const status = useAppSelector(digitalLeafletSelectors.getStatus);
  const error = useAppSelector(digitalLeafletSelectors.getError);

  useEffect(() => {
    dispatch(fetchProducts());
  }, []);

  useEffect(() => {
    if (orderPubKey && (status === IDLE || data?.order.pubkey !== orderPubKey)) {
      dispatch(fetchDigitalLeaflet({ orderPubKey }));
    }
  }, [data, orderPubKey]);

  const sortedProductSlugs = useAppSelector((state) =>
    getSortedProductSlugs(state, { rankType: 'leaflets' }),
  );

  if (error) return <ErrorContainer error={error} />;

  /* @ts-expect-error - LoadingScene does not have types for now */
  if (!data || status !== SUCCESS) return <LoadingScene background="beige" />;

  const { customer, order, heroData, productsSectionTitle } = data;

  const sortedOrderItems = sortBy(order.displayedItems, (item) =>
    sortedProductSlugs.indexOf((item.formula.type ?? '').replace(/_mini$/, '')),
  );

  return (
    <LegacyBaseScene footer header>
      <Box borderBottom="1px" borderColor="neutral.900" width="full">
        <ImageBgHero
          contentPositions={{
            desktop: 1,
            mobile: 2,
          }}
          imageParams={{
            mobile: { src: heroData.images.mobile.imageData.src, imgObjectPosition: 'center 5%' },
            desktop: {
              src: heroData.images.desktop.imageData.src,
              imgObjectPosition: '75% center',
              alt: heroData.images.desktop.alt,
            },
          }}
          minHeight={{ desktop: '534px', mobile: '447px' }}
        >
          <TextBox marginBottom={{ mobile: '8x', desktop: 'none' }} width="full">
            <Title color="noirDark" variant="hero2">
              {heroData.headingText}, {customer.first_name}
            </Title>

            <Paragraph color="noirDark" variant="p1">
              {heroData.bodyText}
            </Paragraph>
          </TextBox>
        </ImageBgHero>
      </Box>

      <OrderNumberBlock>
        <OrderNumberText>order #{order.serial_no}</OrderNumberText>
        <CustomProductsText>{productsSectionTitle}</CustomProductsText>
      </OrderNumberBlock>

      <ProductsSection customerFirstName={customer.first_name} orderItems={sortedOrderItems} />
    </LegacyBaseScene>
  );
};

export default DigitalLeaflet;
