import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Navigate, useParams } from 'react-router';

import { connect } from 'react-redux';

import * as Sentry from '@sentry/nextjs';

import { isFetchError, isInvalidStylistSlug } from 'Services/HTTPError';

import ErrorScene from 'Scenes/ErrorScene';
import LoadingScene from 'Scenes/LoadingScene';

import { checkStylistSlug as checkStylistSlugAction } from 'dux/stylist/actions';
import { getIsAuthenticating } from 'dux/auth/selectors';
import { getStylistError, getStylistPubkey } from 'dux/stylist/selectors';

/**
 * Checks that stylist is correct and redirects to consultation
 */

const StylistLanding = ({ isAuthenticating, checkStylistSlug, error, stylistPubkey }) => {
  const { slug: stylistSlug } = useParams();

  useEffect(() => {
    // ensure we are ready and need to fetch stylist
    if (stylistSlug && !isAuthenticating && !stylistPubkey) {
      checkStylistSlug(stylistSlug);
    }
  }, [checkStylistSlug, isAuthenticating, stylistPubkey, stylistSlug]);

  if (error) {
    if (isFetchError(error)) {
      return <ErrorScene isOffline onClick={() => checkStylistSlug(stylistSlug)} />;
    }
    if (isInvalidStylistSlug(error)) {
      return (
        <ErrorScene
          hideCTA
          title="Sorry, we couldn't find that stylist! Please double check that your URL is correct."
        />
      );
    }
    Sentry.captureMessage('Invalid state on StylistLanding');
    return <ErrorScene />;
  }

  if (stylistPubkey) {
    return <Navigate replace to="/consultation" />;
  }

  return <LoadingScene />;
};

StylistLanding.propTypes = {
  isAuthenticating: PropTypes.bool.isRequired,
  checkStylistSlug: PropTypes.func.isRequired,
  error: PropTypes.object,
  stylistPubkey: PropTypes.string,
};

const mapStateToProps = state => ({
  stylistPubkey: getStylistPubkey(state),
  isAuthenticating: getIsAuthenticating(state),
  error: getStylistError(state),
});

const mapDispatchToProps = {
  checkStylistSlug: checkStylistSlugAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(StylistLanding);
