import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createFilter } from 'redux-persist-transform-filter';

import accountCards from 'dux/accountCards/reducers';
import accountOverview from 'dux/accountOverview/reducers';
import { appApi } from 'dux/app/api';
import auth from 'dux/auth/reducers';
import { reducer as cartV2 } from 'dux/cartV2/slice';
import checkout from 'dux/checkout/reducers';
import consentManagement from 'dux/consentManagement/reducers';
import consultation from 'dux/consultation/slice';
import countries from 'dux/countries/reducers';
import { reducer as digitalLeaflet } from 'dux/digitalLeaflet/slice';
import featureFlags from 'dux/featureFlags/reducers';
import { reducer as feedback } from 'dux/feedback/slice';
import gifting from 'dux/gift/reducers';
import { reducer as giftWithPurchase } from 'dux/giftWithPurchase/slice';
import { reducer as guestAuth } from 'dux/guestAuth/slice';
import membership from 'dux/membership/reducers';
import orders from 'dux/orders/reducers';
import singleOrder from 'dux/postPurchaseOrder/reducers';
import products from 'dux/products/reducers';
import { reducer as questionSets } from 'dux/questionSets/slice';
import router from 'dux/router/slice';
import selfies from 'dux/selfies/reducers';
import signup from 'dux/signup/reducers';
import stylist from 'dux/stylist/reducers';
import subscriptions from 'dux/subscriptions/reducers';
import support from 'dux/support/reducers';
import user from 'dux/user/reducers';

const appReducer = combineReducers({
  [appApi.reducerPath]: appApi.reducer,
  accountOverview,
  auth,
  cartV2,
  guestAuth,
  signup,
  checkout,
  consentManagement,
  countries,
  digitalLeaflet,
  feedback,
  gifting,
  giftWithPurchase,
  questionSets,
  orders,
  selfies,
  singleOrder,
  stylist,
  consultation,
  user,
  subscriptions,
  featureFlags,
  accountCards,
  support,
  membership,
  products,
  router,
});

const createRootReducer = () => appReducer;

export default createRootReducer;

// Persisted State Setup
const consultationSubsetFilter = createFilter('consultation', ['bufferedAnswers']);

const persistConfig = {
  key: 'consultation',
  storage,
  whitelist: ['consultation', 'signup'], // only consultation will be persisted
  transforms: [consultationSubsetFilter],
};

type NotPersistedRootState = ReturnType<typeof appReducer>;
export const persistedReducer = persistReducer<NotPersistedRootState>(persistConfig, appReducer);
