import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'dux/app/hooks';

import { doNothing } from 'remeda';

import getEnv from 'utils/getEnv';
import logSentryError from 'utils/logSentry';
import useResponsiveVariant from 'utils/useResponsiveVariant';
import { useScript } from 'utils/useScript';

import { getIsAuthenticated } from 'dux/auth/selectors';
import { getHasGeneratedLivechat } from 'dux/support/selectors';
import { getUserFirstName, getUserLastName } from 'dux/user/selectors';

import { livechatGenerated, livechatOpened } from './actions';
import { useGetKustomerLivechatTokenQuery } from './api-slice';

// kustomer api: init livechat
const initKustomerLivechat = (
  origin: string,
  { isMobile }: { isMobile: boolean } = { isMobile: false },
) => {
  return new Promise((resolve) => {
    window.Kustomer.start(
      // see https://developer.kustomer.com/chat-sdk/v2.0-Web/docs/kustomer-start#options
      {
        scheduleId: '6179669332a979509ead4cbc',
        ...(isMobile
          ? origin === 'Cart' && {
              chatIconPosition: { alignment: 'right', verticalPadding: 120, width: 16 },
            }
          : undefined),
      },
      () => {
        resolve(undefined);
      },
    );
  });
};
// kustomer api: log customer into kustomer
const logIntoKustomer = (token?: string) => {
  return new Promise((resolve, reject) => {
    if (token) {
      // see https://developer.kustomer.com/chat-sdk/v2.0-Web/docs/kustomer-login#options
      window.Kustomer.login(
        {
          jwtToken: token,
        },
        (loginCallbackResponse, err) => {
          if (loginCallbackResponse) {
            resolve(loginCallbackResponse);
          }

          if (err) {
            reject(err);
          }
        },
      );
    } else {
      resolve(undefined);
    }
  });
};
// kustomer api: send information describing the customer to the kustomer dashboard
const describeCustomer = (firstName: string, lastName: string) => {
  return new Promise((resolve, reject) => {
    // see https://developer.kustomer.com/chat-sdk/v2.0-Web/docs/kustomer-describe-customer#options
    if (firstName && lastName) {
      window.Kustomer.describeCustomer(
        {
          customAttributes: {
            firstNameStr: firstName,
            lastNameStr: lastName,
          },
        },
        (callbackResponse, error) => {
          if (callbackResponse) {
            resolve(callbackResponse);
          }

          if (error) {
            reject(error);
          }
        },
      );
    }
  });
};

export const useKustomerLivechat = ({
  origin,
  showLivechat,
}: {
  origin?: string;
  showLivechat: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { isMobile } = useResponsiveVariant();
  const hasGeneratedLivechat: boolean = useAppSelector(getHasGeneratedLivechat);
  const userFirstname: string = useAppSelector(getUserFirstName);
  const userLastname: string = useAppSelector(getUserLastName);
  const isAuthenticated = useAppSelector(getIsAuthenticated);
  const [initScript, setInitScript] = useState(false);
  const { data: { token } = {} } = useGetKustomerLivechatTokenQuery(undefined, {
    skip: !(showLivechat && isAuthenticated),
  });

  useEffect(() => {
    // (@pierre) Not accesible by Next.js server
    if (typeof window === 'undefined' || typeof document === 'undefined') return doNothing();
    // tracking requirement
    if (!origin) return doNothing();
    // (@pierre) Condition: simple feature flag or AB test
    if (!showLivechat) return doNothing();
    // wait token retrival completion when customer is logged in
    if (isAuthenticated && !token) return doNothing();
    // (@pierre) If already opened, we don't want to generate the chat anymore
    if (hasGeneratedLivechat) return doNothing();

    const controller = new AbortController();

    const livechatOpenedCallback = () => dispatch(livechatOpened());

    type GenerateLivechatOptions = {
      origin: string;
      token?: string;
      isMobile: boolean;
      firstName: string;
      lastName: string;
    };
    /**
     * Generates a livechat session
     * - init
     * - log into kustomer (if applicable)
     * - send customer informations to the kustomer dashboard
     *
     */
    const generateLivechat = async ({
      origin,
      token,
      isMobile,
      firstName,
      lastName,
    }: GenerateLivechatOptions) => {
      await initKustomerLivechat(origin, { isMobile });

      try {
        await logIntoKustomer(token);
      } catch (err) {
        console.error('Auth to kustomer failed ', err);
        logSentryError(`[${origin} - Kustomer Livechat - Login]`, err);
      }
      try {
        // see https://developer.kustomer.com/chat-sdk/v2.0-Web/docs/kustomer-describe-customer#options
        await describeCustomer(firstName, lastName);
      } catch (err) {
        console.error('Failed to describe the customer', err);
        logSentryError(`[${origin} - Kustomer Livechat - Describe customer]`, err);
      }

      /**
       * (@pierre)
       * Since the hook can be used in several places
       * We don't want to generate the livechat more than once.
       * The opening icon will always be present when triggered the first time.
       */
      dispatch(livechatGenerated());

      // (@pierre) Data tracking: we want to know when the user has clicked on the opening icon
      window.Kustomer.addListener('onOpen', livechatOpenedCallback);
    };

    /**
     * (@pierre)
     * Trick for e2e:
     * Delay the Kustomer's script/CDN injection as much as possible, so the event listener can be set before
     * If the script is injected before the listener is listening, the widget is not shown
     */
    if (origin) {
      setInitScript(true);
    }
    // (@pierre) Show the widget once Kustomer's script has loaded
    window.addEventListener(
      'kustomerLoaded',
      () =>
        generateLivechat({
          origin,
          token,
          isMobile,
          firstName: userFirstname,
          lastName: userLastname,
        }),
      {
        signal: controller.signal,
      },
    );

    return () => {
      controller.abort();
      window.Kustomer?.removeListener?.('onOpen', livechatOpenedCallback);
    };
  }, [token, showLivechat, origin, isMobile, hasGeneratedLivechat]);

  //  (@pierre) Kustomer's script/CDN
  useScript(
    {
      src: 'https://cdn.kustomerapp.com/chat-web/widget.js',
      attribute: {
        name: 'data-kustomer-api-key',
        // INFO:"it's ok here if the api key is undefined, in worse case the widget just doesn't load"
        value: getEnv('REACT_APP_KUSTOMER_LIVECHAT_API_KEY')!,
      },
    },
    initScript,
  );
};
