import { Typography } from 'Components/Typography';

import { SKINCARE_CART_URL } from 'constants/cart';
import currencies from 'constants/currencies';

import defaultImage from 'assets/images/promotions/skincare-minis-promotion-modal.jpg';

import { formatPriceWithCurrency } from 'utils/currencies';

const STARTER_SET_PRICE = 65;

const tracking = {
  modalClosing: 'Skincare Minis Offer Pop-up - Closed',
  modalOpening: 'Skincare Minis Offer Pop-up - Viewed',
  modalOpening2sec: 'Skincare Minis Offer Pop-up - Viewed (2 seconds)',
};

export const skincareMinis = {
  banner: 'Claim your free Skincare Starter Set',
  popUpModal: {
    [currencies.USD]: {
      tracking,
      badge: (
        <div>
          <Typography style={{ fontWeight: 500 }}>FREE</Typography>
          <Typography style={{ fontWeight: 300 }} variant="p3">
            {formatPriceWithCurrency({
              price: STARTER_SET_PRICE,
              currency: currencies.USD,
              minimumFractionDigits: 0,
            })}
            &nbsp;VALUE
          </Typography>
        </div>
      ),
      image: defaultImage,
      headerTitle: 'TRY CUSTOM SKINCARE FOR FREE',
      title: 'Free Skincare Starter Set',
      description: (
        <>
          Get a <b>FREE</b> trial-size routine (
          {formatPriceWithCurrency({
            price: STARTER_SET_PRICE,
            currency: currencies.USD,
            minimumFractionDigits: 0,
          })}{' '}
          value) when you subscribe to skincare.
        </>
      ),
      cta: {
        consultationCompleted: {
          linkTo: SKINCARE_CART_URL,
          ctaLabel: 'GET YOUR FREE ROUTINE',
        },
        consultationNotCompleted: {
          linkTo: '/consultation/skincare/first-question',
          ctaLabel: 'GET YOUR FREE ROUTINE',
        },
      },
      conditions: (
        <>
          *Get your Skincare Starter Set (
          {formatPriceWithCurrency({
            price: STARTER_SET_PRICE,
            currency: currencies.USD,
            minimumFractionDigits: 0,
          })}{' '}
          value) when you subscribe to Cleanser, Serum, and Moisturizer. Offer only valid while
          supplies last. Free shipping and 15% off will be applied to all following skincare
          subscription orders. Exceptions may apply. Offer valid while stocks last. For full details
          on our return policy,{' '}
          <a
            data-click="return-policy"
            data-from="sss-pop-up"
            href="https://prose.com/faq/5f3da05bceb96c001a595c02"
            target="_blank"
          >
            click here
          </a>
          .
        </>
      ),
    },
    [currencies.CAD]: {
      tracking,
      badge: <Typography style={{ fontWeight: 500 }}>FREE</Typography>,
      image: defaultImage,
      headerTitle: 'TRY CUSTOM SKINCARE FOR FREE',
      title: 'Free Skincare Starter Set',
      description: (
        <>
          Get a <b>FREE</b> trial-size routine when you subscribe to skincare.
        </>
      ),
      cta: {
        consultationCompleted: {
          linkTo: SKINCARE_CART_URL,
          ctaLabel: 'GET YOUR FREE ROUTINE',
        },
        consultationNotCompleted: {
          linkTo: '/consultation/skincare/first-question',
          ctaLabel: 'GET YOUR FREE ROUTINE',
        },
      },
      conditions: (
        <>
          *Get your Skincare Starter Set when you subscribe to Cleanser, Serum, and Moisturizer.
          Offer only valid while supplies last. Free shipping and 15% off will be applied to all
          following skincare subscription orders. Exceptions may apply. Offer valid while stocks
          last. For full details on our return policy,{' '}
          <a
            data-click="return-policy"
            data-from="sss-pop-up"
            href="https://prose.com/faq/5f3da05bceb96c001a595c02"
            target="_blank"
          >
            click here
          </a>
          .
        </>
      ),
    },
  },
  bottomModal: {
    [currencies.USD]: {
      title: 'free skincare Starter Set',
      description: (
        <>
          Subscribe to skincare and get a personalized trial-size routine (
          {formatPriceWithCurrency({
            price: STARTER_SET_PRICE,
            currency: currencies.USD,
            minimumFractionDigits: 0,
          })}
          &nbsp;value)&nbsp;<b>FREE</b>
        </>
      ),
      conditions: (
        <>
          *Get your Skincare Starter Set (
          {formatPriceWithCurrency({
            price: STARTER_SET_PRICE,
            currency: currencies.USD,
            minimumFractionDigits: 0,
          })}{' '}
          value) when you subscribe to Cleanser, Serum, and Moisturizer. Offer only valid while
          supplies last. Free shipping and 15% off will be applied to all following skincare
          subscription orders. Exceptions may apply. Offer valid while stocks last. For full details
          on our return policy,{' '}
          <a href="https://prose.com/faq/5f3da05bceb96c001a595c02" target="_blank">
            click here
          </a>
          .
        </>
      ),
    },
    [currencies.CAD]: {
      title: 'free skincare Starter Set',
      description: (
        <div>
          Subscribe to skincare and get a personalized trial-size routine&nbsp;<b>FREE</b>
        </div>
      ),
      conditions: (
        <>
          *Get your Skincare Starter Set when you subscribe to Cleanser, Serum, and Moisturizer.
          Offer only valid while supplies last. Free shipping and 15% off will be applied to all
          following skincare subscription orders. Exceptions may apply. Offer valid while stocks
          last. For full details on our return policy,{' '}
          <a href="https://prose.com/faq/5f3da05bceb96c001a595c02" target="_blank">
            click here
          </a>
          .
        </>
      ),
    },
  },
  checkoutCommunication: {
    [currencies.USD]: {
      title: 'free skincare Starter Set',
      description: (
        <>
          Subscribe to skincare and get a personalized trial-size routine (
          {formatPriceWithCurrency({
            price: STARTER_SET_PRICE,
            currency: currencies.USD,
            minimumFractionDigits: 0,
          })}
          &nbsp;value)&nbsp;<b>FREE</b>
        </>
      ),
      terms: 'See terms & conditions',
    },
    [currencies.CAD]: {
      title: 'free skincare Starter Set',
      description: (
        <>
          Subscribe to skincare and get a personalized trial-size routine <b>FREE</b>
        </>
      ),
      terms: 'See terms & conditions',
    },
  },
  checkoutCommunicationShippingFee: {
    [currencies.USD]: {
      title: 'free skincare Starter Set',
      description: (
        <>
          Subscribe to skincare and get a personalized trial-size routine (
          {formatPriceWithCurrency({
            price: STARTER_SET_PRICE,
            currency: currencies.USD,
            minimumFractionDigits: 0,
          })}
          &nbsp;value)&nbsp;<b>FREE</b>
        </>
      ),
      terms: 'See terms & conditions',
    },
    [currencies.CAD]: {
      title: 'free skincare Starter Set',
      description: (
        <>
          Subscribe to skincare and get a personalized trial-size routine <b>FREE</b>
        </>
      ),
      terms: 'See terms & conditions',
    },
  },
  subscribeAndSaveSection: {
    label: 'Skincare Starter Set',
  },
} as const;
