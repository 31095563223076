import { matchPath } from 'react-router';

import { createAsyncThunk } from '@reduxjs/toolkit';

import * as Sentry from '@sentry/nextjs';

import {
  FIRST_SKINCARE_QUESTION_ROUTE,
  HAIRCARE_FINAL_ROUTE,
  SIGNIN,
  SKINCARE_FINAL_ROUTE,
  STYLING_GEL_CONSULTATION_QUESTION_SET_KEY,
  SUPPLEMENTS_CONSULTATION_QUESTION_SET_KEY,
} from 'Apps/Consultation/constants';

import * as HairProfileService from 'Services/HairProfileService';
import { isInvalidZipcode } from 'Services/HTTPError';
import * as UserService from 'Services/UserService';

import * as fragrances from 'constants/haircareFragrances';
import { productsCategories } from 'constants/products';

import { streamlineFragranceLabel } from 'utils/fragrances';
import sleep from 'utils/sleep';

import {
  trackBufferAnswer,
  trackConsultationFinish,
  trackFragranceUpdated,
  trackIterableEvent,
  trackUserProfile,
} from 'dux/tracking/actions';
import { getIsAuthenticated } from 'dux/auth/selectors';
import { getHomepageEmailCapturePopUpAbTestFlagVariant } from 'dux/featureFlags/selectors';
import { getStylistPubkey } from 'dux/stylist/selectors';
import {
  getHasCompletedHairProfileFromApi,
  getHasSignedUpWithoutNames,
  getUserFragrance,
  getUserSkincareFragrance,
} from 'dux/user/selectors';
import { getPrescriptionQuery } from 'dux/prescription/apiSlice';
import { fetchUser } from 'dux/user/thunks';

import {
  getAggressorsError,
  getBufferedAnswersData,
  getConsultationAnswers,
  getConsultationQuestionForIndex,
  getCurrentlyLoadedQuestionSet,
  getFirstHaircareConsultationQuestionRoute,
  hasBufferedAnswers,
} from './selectors';

export const initializeConsultation = createAsyncThunk(
  'consultation/initialize',
  async (_payload, { getState }) => {
    const stylistSlug = window.localStorage?.getItem('stylist');

    const state = getState();
    const stylistPubkey = getStylistPubkey(state);
    const userHasBufferedAnswers = hasBufferedAnswers(state);

    if (stylistSlug && stylistPubkey) {
      await UserService.patch({ stylist: stylistPubkey });
      window.localStorage.removeItem('stylist');
    }

    const initialConsultation = {
      privacy_policies: true,
      terms_conditions: true,
      ...(userHasBufferedAnswers ? getBufferedAnswersData(state) : {}),
    };

    const hairProfile = await HairProfileService.patch(initialConsultation);

    return hairProfile;
  }
);

export const buffer = createAsyncThunk(
  'consultation/buffer',
  async (
    {
      selected,
      currentQuestionIndex,
      consultationCategory,
      consultationSubsetCategory,
      routeParams,
    },
    { dispatch, getState }
  ) => {
    const state = getState();
    const question = getConsultationQuestionForIndex(state, {
      consultationCategory,
      questionIndex: currentQuestionIndex,
      consultationSubsetCategory,
      routeParams,
    });
    const answers = getConsultationAnswers(state);

    const trackingPayload = {
      answer: selected,
      category: question.category,
      index: currentQuestionIndex,
      name: question.name,
    };
    dispatch(trackBufferAnswer(trackingPayload));

    return question.getAnswers({ selected, answers, question });
  }
);

export const saveAnswer = createAsyncThunk(
  'consultation/saveAnswer',
  async ({ payload, addDelayToResponse }) => {
    const updatedHairProfile = await HairProfileService.patch(payload);
    if (addDelayToResponse) {
      await sleep();
    }
    return updatedHairProfile;
  }
);

export const onNext =
  ({
    selected,
    currentQuestionIndex,
    consultationCategory,
    consultationSubsetCategory,
    routeParams,
  }) =>
  async (dispatch, getState) => {
    const state = getState();
    const isAuthenticated = getIsAuthenticated(state);
    const standalone = routeParams?.has('standalone');
    const currentAnswers = getConsultationAnswers(state);
    const hasSignedUpWithoutNames = getHasSignedUpWithoutNames(state);
    const homepageEmailCapturePopUpAbTestFlagVariant =
      getHomepageEmailCapturePopUpAbTestFlagVariant(state);

    // Store current question's answers

    const currentQuestion = getConsultationQuestionForIndex(state, {
      consultationCategory,
      questionIndex: currentQuestionIndex,
      consultationSubsetCategory,
      routeParams,
    });

    try {
      // This question needs to be saved logic
      if (!currentQuestion.skipSave) {
        // This question answers will be buffered
        if (currentQuestion.public && !isAuthenticated) {
          if (!currentQuestion.multi) {
            await sleep();
          }
          await dispatch(
            buffer({
              selected,
              currentQuestionIndex,
              consultationCategory,
              consultationSubsetCategory,
              routeParams,
            })
          );
        } else {
          const trackingPayload = {
            answer: selected,
            category: currentQuestion.category,
            index: currentQuestionIndex,
            name: currentQuestion.name,
          };
          dispatch(trackBufferAnswer(trackingPayload));
          await dispatch(
            saveAnswer({
              payload: currentQuestion.getAnswers({
                selected,
                answers: currentAnswers,
                question: currentQuestion,
              }),
              addDelayToResponse: !currentQuestion.multi,
            })
          );
          dispatch(trackIterableEvent('Updated Consultation', { category: consultationCategory }));
        }
      }

      // Route to next question

      const updatedState = getState();
      const answers = getConsultationAnswers(updatedState);
      const questions = getCurrentlyLoadedQuestionSet(updatedState, {
        consultationCategory,
        consultationSubsetCategory,
        routeParams,
      });
      const nextQuestion = questions.find(
        (question, questionIndex) =>
          questionIndex > currentQuestionIndex &&
          question.shouldBeIncluded({
            isAuthenticated,
            answers,
            hasSignedUpWithoutNames,
            homepageEmailCapturePopUpAbTestFlagVariant,
          })
      );
      // Default to FINAL_ROUTE
      let nextQuestionRoute =
        consultationCategory === productsCategories.SKINCARE
          ? SKINCARE_FINAL_ROUTE
          : HAIRCARE_FINAL_ROUTE;
      // Happy path, we have an explicit next route
      if (nextQuestion?.route) {
        nextQuestionRoute = nextQuestion.route;
      } else if (consultationCategory === productsCategories.AGNOSTIC) {
        // We do not have an explicit route and we do not know the productsCategory
        nextQuestionRoute =
          selected === productsCategories.SKINCARE
            ? FIRST_SKINCARE_QUESTION_ROUTE
            : getFirstHaircareConsultationQuestionRoute(state);
      }

      // Supplements-only consultation
      if (consultationSubsetCategory === SUPPLEMENTS_CONSULTATION_QUESTION_SET_KEY) {
        nextQuestionRoute = nextQuestionRoute?.concat('?questionSet=supplements');
      }
      // Styling-Gel-only consultation
      if (consultationSubsetCategory === STYLING_GEL_CONSULTATION_QUESTION_SET_KEY) {
        nextQuestionRoute = nextQuestionRoute?.concat('?questionSet=styling-gel');
      }

      /**
       * Signin route is overridden in questionsSets
       * Here we want to check that the we match the correct question independently from the category
       */
      const { '*': currentQuestionRoute } =
        matchPath('/consultation/:category/*', currentQuestion.route)?.params ?? {};
      const shouldReplaceRoute = currentQuestionRoute === SIGNIN;

      if (
        nextQuestionRoute === HAIRCARE_FINAL_ROUTE ||
        nextQuestionRoute === SKINCARE_FINAL_ROUTE
      ) {
        dispatch(trackConsultationFinish({ category: consultationCategory }));
        dispatch(trackIterableEvent('Completed Consultation', { category: consultationCategory }));
      }

      return shouldReplaceRoute // in case of sign-in, we want to remove sign-ing "question" from history stack
        ? {
            nextRoute: standalone ? '/consultation/haircare/results/stored' : nextQuestionRoute,
            options: { replace: true },
          }
        : {
            nextRoute: standalone ? '/consultation/haircare/results/stored' : nextQuestionRoute,
            options: {},
          };
    } catch (error) {
      Sentry.withScope(scope => {
        scope.setLevel('error');
        Sentry.captureMessage(`[Consultation Action] onNext : ${error.toString()}`);
      });
      return null;
    }
  };

export const fetchAggressors = createAsyncThunk(
  'consultation/fetchAggressors',
  async (_payload, { rejectWithValue }) => {
    try {
      const data = await HairProfileService.fetchAggressors();
      return data;
    } catch (error) {
      Sentry.withScope(scope => {
        scope.setLevel('error');
        Sentry.captureMessage(`[Consultation Action] fetchAggressors : ${JSON.stringify(error)}`);
      });
      return rejectWithValue(error);
    }
  }
);

export const setZipcode =
  ({ answers, recover }) =>
  async (dispatch, getState) => {
    await dispatch(saveAnswer({ payload: answers }));

    await dispatch(fetchAggressors());

    const aggressorsError = getAggressorsError(getState());

    if (aggressorsError && isInvalidZipcode(aggressorsError)) {
      // saves again the previous answer to erase invalid zipcode
      await dispatch(saveAnswer({ payload: recover }));
    }

    if (aggressorsError) {
      // throws to avoid skipping question
      throw aggressorsError;
    }
  };

export const fetchFragrances = createAsyncThunk('consultation/fetchFragrances', async () => {
  const data = await HairProfileService.fetchHaircareFragrances();
  return data;
});

export const fetchSkincareTextures = createAsyncThunk(
  'consultation/fetchSkincareTextures',
  async () => {
    const data = await HairProfileService.fetchSkincareTextures();
    return data;
  }
);

export const fetchSkincareFragrances = createAsyncThunk(
  'consultation/fetchSkincareFragrances',
  async () => {
    const data = await HairProfileService.fetchSkincareFragrances();
    return data;
  }
);

export const fetchScoring = createAsyncThunk('consultation/fetchScoring', async () => {
  const data = await HairProfileService.fetchScoring();
  return data;
});

export const fetchSkincareScoring = createAsyncThunk(
  'consultation/fetchSkincareScoring',
  async () => {
    const data = await HairProfileService.fetchSkincareScoring();
    return data;
  }
);

export const fetchHaircareIngredients = createAsyncThunk(
  'consultation/fetchHaircareIngredients',
  async () => {
    const data = await HairProfileService.fetchIngredients();
    return data;
  }
);

export const fetchSkincareIngredients = createAsyncThunk(
  'consultation/fetchSkincareIngredients',
  async () => {
    const data = await HairProfileService.fetchSkincareIngredients();
    return data;
  }
);

export const fetchSkincareIncis = createAsyncThunk('consultation/fetchSkincareIncis', async () => {
  const data = await HairProfileService.fetchSkincareIncis();
  return data;
});

export const fetchHaircareIncis = createAsyncThunk('consultation/fetchHaircareIncis', async () => {
  const data = await HairProfileService.fetchHaircareIncis();
  return data;
});

export const completeHairProfile = createAsyncThunk(
  'consultation/completeHairProfile',
  async () => {
    const result = await HairProfileService.patch({ status: 'completed' });
    return result;
  }
);

export const finishConsultation = consultationCategory => async (dispatch, getState) => {
  dispatch(trackUserProfile());

  try {
    const hasCompletedHairProfileFromApi = getHasCompletedHairProfileFromApi(getState());

    // toggle hair profile as completed if not yet done
    // TODO: This patch should be obsolete now, QA regression after removal
    if (!hasCompletedHairProfileFromApi) {
      await dispatch(completeHairProfile());
    }

    // To refresh the questions completion (e.g. has_answered_skincare_questions) before reaching the Cart
    dispatch(fetchUser());

    if (consultationCategory === productsCategories.HAIRCARE) {
      const prescriptionQueryPromise = dispatch(getPrescriptionQuery.initiate());
      await Promise.all([prescriptionQueryPromise, dispatch(fetchHaircareIngredients())]);
      prescriptionQueryPromise.unsubscribe();
    } else if (consultationCategory === productsCategories.SKINCARE) {
      await Promise.all([dispatch(fetchSkincareScoring()), dispatch(fetchSkincareIngredients())]);
    }
  } catch (error) {
    Sentry.withScope(scope => {
      scope.setLevel('error');
      Sentry.captureMessage(`[Consultation] finishConsultation: ${error.toString()}`);
    });
  }
};

export const fetchHairProfile = createAsyncThunk('consultation/fetchHairProfile', async () => {
  const hairProfile = await HairProfileService.get();
  return hairProfile;
});

/**
 * Update fragrance (Patch)
 * This method can be used one-shot
 * meaning outside the consultation and the R&R feedback
 */

export const updateFragrance =
  ({ category, fragrance, origin }) =>
  async (dispatch, getState) => {
    const state = getState();

    try {
      const newFragrance = ![fragrances.NONE, fragrances.FRAGRANCE_FREE].includes(fragrance.name)
        ? fragrance.name
        : null;
      const isFragranceFree = [fragrances.NONE, fragrances.FRAGRANCE_FREE].includes(fragrance.name);
      const payload = {
        ...(category === productsCategories.SKINCARE
          ? { pref_fragrance_skin: newFragrance, pref_fragrance_free_skin: isFragranceFree }
          : fragrance?.fragrance_group === 'hairoil'
          ? { pref_hair_oil_fragrance: fragrance.name }
          : { pref_fragrance: newFragrance, pref_fragrance_free: isFragranceFree }),
      };
      const currentFragance =
        category === productsCategories.SKINCARE
          ? getUserSkincareFragrance(state)
          : getUserFragrance(state);
      const previousFragrance = streamlineFragranceLabel(currentFragance);
      await dispatch(saveAnswer({ payload }));

      const trackingPayload = {
        fragrance_updated_page_origin: origin,
        from_fragrance_selection: previousFragrance,
        new_fragrance_selection:
          fragrance.name !== fragrances.NONE ? fragrance.name : fragrance.label,
      };

      dispatch(await trackFragranceUpdated(trackingPayload));
    } catch (error) {
      Sentry.captureMessage(`API action (Subscriptions.updateFragrance) - ${error?.toString()}`);
    }
  };

export const fetchHairProfileProductVariantBySlug = createAsyncThunk(
  'consultation/fetchHairProfileProductVariantBySlug',
  async payload => {
    const hairProfileProductVariant = await HairProfileService.getHairProfileProductVariantBySlug({
      product: payload,
    });
    return hairProfileProductVariant;
  }
);

export const fetchRecommendationBySlug = createAsyncThunk(
  'consultation/fetchRecommendationBySlug',
  async payload => {
    const hairProfileProductVariant = await HairProfileService.getRecommendationBySlug({
      product: payload,
    });
    return hairProfileProductVariant;
  }
);
