import { createSelector } from '@reduxjs/toolkit';

import find from 'lodash/fp/find';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import map from 'lodash/fp/map';

import * as supportStatuses from './constants/supportStatuses';

const getState = state => state.support;
const getFetchedCategories = createSelector(getState, get('fetchedCategories'));
const getFetchedArticles = createSelector(getState, get('fetchedArticles'));
const getFetchedTags = createSelector(getState, get('fetchedTags'));

export const getNormalizedCategories = createSelector(getFetchedCategories, fetchedCategories => {
  const getUsefulData = (acc, { id, attributes }) => {
    if (attributes?.deleted === true) {
      return acc;
    }

    const { title, slug, description } = get('langs.en_us')(attributes);

    return { ...acc, [id]: { id, title, description, slug } };
  };
  const byId = fetchedCategories.data.reduce(getUsefulData, {});

  return { byId, allIds: Object.keys(byId) };
});

export const getNormalizedArticles = createSelector(getFetchedArticles, fetchedArticles => {
  if (!fetchedArticles) {
    return null;
  }

  const getUsefulData = (
    acc,
    { id, attributes: { title, tags, slug, htmlBody, deleted = false, status }, relationships }
  ) => {
    if (deleted || status !== 'published') {
      return acc;
    }
    /* get article id. `id` is actually the article revision id */
    const artId = get('article.data.id')(relationships);
    /* categories within articles are the one to check. The ones associated with
    a revision are assigned at the revision creation and never updated. */
    const categories = flow(
      get('data'),
      find({ id: artId }),
      get('attributes.categories'),
      map(get('id'))
    )(fetchedArticles);

    return { ...acc, [id]: { id, title, tags, categories, slug, htmlBody } };
  };

  const byId = fetchedArticles.included.reduce(getUsefulData, {});

  return { byId, allIds: Object.keys(byId) };
});

export const getNormalizedTags = createSelector(getFetchedTags, fetchedTags => {
  const getUsefulData = (acc, { id, attributes: { name } }) => {
    return { ...acc, [id]: { id, name } };
  };

  const byId = fetchedTags.data.reduce(getUsefulData, {});

  return { byId, allIds: Object.keys(byId) };
});

export const getFaqQuestions = createSelector(
  getNormalizedArticles,
  getNormalizedTags,
  (_state, { tagName }) => tagName,
  (normalizedArticles, normalizedTags, tagName) => {
    if (!normalizedArticles) {
      return null;
    }
    const isPopular = article => {
      return article.tags.some(tagId => {
        const { name } = normalizedTags.byId[tagId];
        return tagName === name;
      });
    };

    return normalizedArticles.allIds.reduce((acc, articleId) => {
      const article = normalizedArticles.byId[articleId];
      return isPopular(article) ? [...acc, article] : acc;
    }, []);
  }
);

export const getPopularFaqQuestions = state => getFaqQuestions(state, { tagName: 'popular' });
export const getTop3FaqQuestions = state => getFaqQuestions(state, { tagName: 'contact-us-top-3' });

const getCategoriesStatus = createSelector(getState, get('categoriesStatus'));
const getArticlesStatus = createSelector(getState, get('articlesStatus'));
const getTagsStatus = createSelector(getState, get('fetchedTagsStatus'));

export const getError = createSelector(
  getCategoriesStatus,
  getArticlesStatus,
  getTagsStatus,
  (categoriesStatus, articlesStatus, tagsStatus) =>
    categoriesStatus === supportStatuses.ERROR ||
    articlesStatus === supportStatuses.ERROR ||
    tagsStatus === supportStatuses.ERROR
);

export const getIsLoading = createSelector(
  getCategoriesStatus,
  getArticlesStatus,
  getTagsStatus,
  (categoriesStatus, articlesStatus, tagsStatus) =>
    [supportStatuses.IDLE, supportStatuses.LOADING].includes(categoriesStatus) ||
    [supportStatuses.IDLE, supportStatuses.LOADING].includes(articlesStatus) ||
    [supportStatuses.IDLE, supportStatuses.LOADING].includes(tagsStatus)
);

export const getFaqCategories = createSelector(getNormalizedCategories, normalizedCategories => {
  const topics = normalizedCategories.allIds.map(categoryId => {
    const category = normalizedCategories.byId[categoryId];
    return category;
  });
  topics.push({
    id: 'contact-us',
    slug: 'contact-us',
    title: 'Contact Us',
    description: 'Reach out to get in touch with our team of Pros',
  });

  return topics;
});

const getFetchedSearch = createSelector(getState, get('fetchedSearch'));

const getNormalizedSearch = createSelector(getFetchedSearch, fetchedSearch => {
  if (!fetchedSearch || fetchedSearch.length === 0) {
    return null;
  }
  const getUsefulData = (acc, { id, attributes: { categories, title, tags, slug, htmlBody } }) => {
    const cat = categories.map(c => c.id);

    return { ...acc, [id]: { id, title, tags, categories: cat, slug, htmlBody } };
  };

  const byId = fetchedSearch.data.reduce(getUsefulData, {});

  return { byId, allIds: Object.keys(byId) };
});

const getPropsTopicId = (_, props) => props.topicId;

export const getTopicTitle = createSelector(
  getNormalizedCategories,
  getPropsTopicId,
  (normalizedCategory, topicId) => {
    return normalizedCategory.byId[topicId]?.title ?? `Search results for "${topicId}"`;
  }
);

const getIsASearchTopic = createSelector(
  getNormalizedCategories,
  getPropsTopicId,
  (normalizedCategory, topicId) => normalizedCategory.byId[topicId] === undefined
);

export const getSearchStatus = createSelector(getState, get('searchStatus'));

export const getQuestionsForTopic = createSelector(
  getPropsTopicId,
  getIsASearchTopic,
  getNormalizedArticles,
  getNormalizedSearch,
  getSearchStatus,
  (topicId, isASearchTopic, normalizedArticles, normalizedSearch, searchStatus) => {
    if (!topicId) {
      return null;
    }

    if (isASearchTopic && searchStatus === supportStatuses.SUCCESS) {
      return normalizedSearch.allIds.map(articleId => {
        const article = normalizedSearch.byId[articleId];
        return article;
      });
    }

    const isInCategory = article => {
      return article.categories.some(catId => {
        return catId === topicId;
      });
    };

    return normalizedArticles.allIds.reduce((acc, articleId) => {
      const article = normalizedArticles.byId[articleId];
      return isInCategory(article) ? [...acc, article] : acc;
    }, []);
  }
);

// livechat state
export const getHasGeneratedLivechat = createSelector(getState, get('hasGeneratedLivechat'));
export const getHasOpenedLivechat = createSelector(getState, get('hasOpenedLivechat'));
