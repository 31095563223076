import { useState } from 'react';

import { Link } from 'react-router';

import { useAppDispatch, useAppSelector } from 'dux/app/hooks';

import { Modal, theme } from '@prose-ui';
import { legacyTheme, styled } from '@prose-ui/legacy';

import { Image } from 'Components/Image';

import { type Currency } from 'constants/currencies';

import { skincareMinis } from 'assets/content/skincareMinisModal';
import { ReactComponent as Cross } from 'assets/images/cross_icon.svg';

import useResponsiveVariant from 'utils/useResponsiveVariant';

import { trackHeapEvent } from 'dux/tracking/actions';
import { getHasCompletedSkincareConsultation, getUserCurrency } from 'dux/user/selectors';

import { Button } from './LegacyButton';
import Spacer from './Spacer';
import { Typography } from './Typography';

const Header = styled.div`
  height: 35px;
  padding: 10px 10px 5px 17px;
  background-color: ${theme.colors.tertiary[200]};
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
`;

const CloseModalButton = styled.button`
  cursor: pointer;
  appearance: none;
  background: none;
  border: none;
  padding: 0;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -4px;
`;

const StyledModal = styled(Modal)`
  padding: 0;
  min-width: 330px;
`;

const Content = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
`;

const BadgeLabel = styled(Typography)`
  font-size: 18px;
  text-align: center;
  padding-top: 5px;
`;

const Badge = styled.div`
  position: absolute;
  top: 14px;
  right: 14px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 76px;
  height: 76px;
  background-color: ${theme.colors.tertiary[200]};
  border-radius: 76px;

  ${legacyTheme.breakpoints.up('md')} {
    top: 40px;
    right: 27px;
  }
`;

const TextContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 28px;
  padding: 16px;
`;

const HeaderLabel = styled(Typography)`
  font-size: 14px;
  font-weight: 600;
`;

const Title = styled(Typography)`
  max-width: 300px;
  font-size: 24px;

  ${legacyTheme.breakpoints.up('md')} {
    max-width: 380px;
  }
`;

const Description = styled(Typography)`
  font-size: 14px;

  ${legacyTheme.breakpoints.up('sm')} {
    max-width: 430px;
  }
`;

const Conditions = styled(Typography)`
  font-size: 10px;
  padding: 0 8px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  max-width: 280px;
`;

type SkincareModalModalProps = {
  onClose: () => void;
  isOpen: boolean;
};

const SkincareMinisModal = ({ isOpen = false, onClose }: SkincareModalModalProps) => {
  const dispatch = useAppDispatch();
  const { isTablet } = useResponsiveVariant();
  const hasCompletedSkincareConsultation = useAppSelector(getHasCompletedSkincareConsultation);
  const currency = useAppSelector(getUserCurrency) as Currency;

  return (
    <StyledModal
      events={{
        onModalClose: () =>
          dispatch(trackHeapEvent(skincareMinis.popUpModal[currency].tracking.modalClosing)),
        onModalOpen: () =>
          dispatch(trackHeapEvent(skincareMinis.popUpModal[currency].tracking.modalOpening)),
        timer: {
          onModalOpenAfterTime: () =>
            dispatch(trackHeapEvent(skincareMinis.popUpModal[currency].tracking.modalOpening2sec)),
        },
      }}
      hideClose
      isOpen={isOpen}
      onClose={onClose}
      zIndexValue={10000}
    >
      <Header data-testid="skincare-minis-popup-modal">
        <HeaderLabel medium variant="mono1">
          {skincareMinis.popUpModal[currency].headerTitle}
        </HeaderLabel>
        <CloseModalButton
          data-click="exit"
          data-from="sss-pop-up"
          data-testid="skincare-minis-close-modal"
          id="modal-close-button"
          onClick={onClose}
        >
          <Cross height="13" width="13" />
        </CloseModalButton>
      </Header>
      <Content>
        <Badge>
          <BadgeLabel>{skincareMinis.popUpModal[currency].badge}</BadgeLabel>
        </Badge>
        <StyledImage
          alt="list of skincare minis products"
          src={skincareMinis.popUpModal[currency].image}
        />
        <TextContent>
          <Title align="center" id={isOpen ? 'modal-title' : ''} variant="h2">
            {skincareMinis.popUpModal[currency].title}
          </Title>
          {isTablet ? <Spacer size={20} /> : <Spacer size={30} />}

          <Description align="center" id={isOpen ? 'modal-description' : ''} variant="p1">
            {skincareMinis.popUpModal[currency].description}
          </Description>
          <Spacer size={20} />
          <StyledButton
            Component={Link}
            data-click="offer-cta"
            data-from="sss-pop-up"
            data-testid="skincare-minis-popup-cta"
            onClick={onClose}
            to={
              hasCompletedSkincareConsultation
                ? skincareMinis.popUpModal[currency].cta.consultationCompleted.linkTo
                : skincareMinis.popUpModal[currency].cta.consultationNotCompleted.linkTo
            }
            variant="vert"
          >
            {hasCompletedSkincareConsultation
              ? skincareMinis.popUpModal[currency].cta.consultationCompleted.ctaLabel
              : skincareMinis.popUpModal[currency].cta.consultationNotCompleted.ctaLabel}
          </StyledButton>
          <Spacer size={20} />

          <Conditions
            align="center"
            color="grey"
            id={isOpen ? 'modal-conditions' : ''}
            italic
            variant="p3"
          >
            {skincareMinis.popUpModal[currency].conditions}
          </Conditions>
        </TextContent>
      </Content>
    </StyledModal>
  );
};

type SkincareMinisModalWithStateLogicProps = {
  isOpen: boolean;
};

export const SkincareModalModalWithStateLogic = ({
  isOpen,
}: SkincareMinisModalWithStateLogicProps) => {
  const [open, setOpen] = useState(isOpen);
  const onClose = () => setOpen(false);

  return <SkincareMinisModal isOpen={open} onClose={onClose} />;
};
