import { createSlice } from '@reduxjs/toolkit';

import type { Status } from 'constants/statuses';
import * as statuses from 'constants/statuses';

import { userSignout } from 'dux/auth/actions';

import { fetchGiftsSets, fetchProducts } from './thunks';

type Ranks = {
  pdp: number | null; // for selling purposes
  leaflets: number | null; // for usage purposes
};

type Product = {
  category: string;
  type: string;
  sub_category: string;
  ranks: Ranks;
};

type ProductsState = {
  fetchedProducts: Array<Product>;
  giftsSets: unknown; // TODO
  productsStatus: Status;
  giftsSetsStatus: Status;
};

const initialState: ProductsState = {
  fetchedProducts: [],
  giftsSets: null,
  productsStatus: statuses.IDLE,
  giftsSetsStatus: statuses.IDLE,
};

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // reinitialize state upon signout
      .addCase(userSignout, () => initialState)

      .addCase(fetchProducts.pending, (draft) => {
        draft.productsStatus = statuses.LOADING;
      })
      .addCase(fetchProducts.fulfilled, (draft, { payload }) => {
        draft.productsStatus = statuses.SUCCESS;
        draft.fetchedProducts = payload;
      })
      .addCase(fetchProducts.rejected, (draft) => {
        draft.productsStatus = statuses.ERROR;
      })
      .addCase(fetchGiftsSets.pending, (draft) => {
        draft.giftsSetsStatus = statuses.LOADING;
      })
      .addCase(fetchGiftsSets.fulfilled, (draft, { payload }) => {
        draft.giftsSetsStatus = statuses.SUCCESS;
        draft.giftsSets = payload;
      })
      .addCase(fetchGiftsSets.rejected, (draft) => {
        draft.giftsSetsStatus = statuses.ERROR;
      });
  },
});

export const { reducer } = productsSlice;
