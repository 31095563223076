import { SCALP } from 'Apps/Feedback/constants/feedbackSections';
import {
  haircareImprovements,
  haircareNoConcernInImprovements,
  haircareNoImprovements,
  haircareScalpOiliness,
  haircareSomeImprovements,
} from 'Apps/Feedback/constants/feedbackSlugs';

import { productsSlugs } from 'constants/products';

const scalpOiliness = {
  name: haircareScalpOiliness,
  route: `/feedback/haircare/${haircareScalpOiliness}`,
  category: SCALP,
  shouldBeIncluded: ({ formulas }) =>
    formulas?.some(f =>
      [productsSlugs.SCALP_MASK, productsSlugs.SHAMPOO].includes(f.variant.product.type)
    ),
  title: "Did you see improvements in your scalp's oiliness?",
  subtitle: 'Consider whether you get greasy too quickly, or wash more than you’d like',
  options: [
    { value: haircareImprovements, label: "Yes, it's balanced now" },
    { value: haircareSomeImprovements, label: 'Some, but could be better' },
    { value: haircareNoImprovements, label: "No, it's just as oily" },
    { value: haircareNoConcernInImprovements, label: "It wasn't a concern" },
  ],
  getInitialValue: answers => answers?.scalp_oiliness,
  getAnswers: ({ selected }) => ({ scalp_oiliness: selected }),
};

export default scalpOiliness;
