import { getRootToastContent, getRootToastStyle, getShouldDisplayToast } from './selectors';
import { actions } from './slice';

export const {
  setReferral,
  clearReferral,
  setCustomerOrigin,
  openRootToast,
  closeRootToast,
  setUserCountry,
  setUserRegion,
  seenPromotionalModal,
} = actions;

export const setRootToast =
  ({ content, style }) =>
  async (dispatch, getState) => {
    const state = getState();
    const isAlreadyOpen = getShouldDisplayToast(state);
    const previousContent = getRootToastContent(state);
    const previousStyle = getRootToastStyle(state);

    const sameAsPreviousToast =
      content === previousContent &&
      style?.color === previousStyle?.color &&
      style?.backgroundColor === previousStyle?.backgroundColor;

    if (!isAlreadyOpen || (isAlreadyOpen && sameAsPreviousToast)) {
      dispatch(openRootToast({ content, style }));
    }

    if (isAlreadyOpen && !sameAsPreviousToast) {
      dispatch(closeRootToast());
      setTimeout(() => {
        dispatch(openRootToast({ content, style }));
      }, 250); // ANIMATE_OUT_DURATION in BasicToast component
    }
  };

export * from './thunks';
