import currencies from 'constants/currencies';

import trialOfferGWPMixedWithSkincareMinisModalContent from 'assets/content/promotions/trialOfferGWPMixedWithSkincareMinisModal';
import image60Percent from 'assets/images/promotions/trial-offer-claw-clipper-gwp-promotion-hc-sc-sss-modal-60.jpg';

const popUpConditions = (
  <>
    *Get [1] free Midi Claw Clip and a total of 60% off your first haircare subscription order when
    you subscribe to 3+ products. Get your Skincare Starter Set ($65 value) when you subscribe to
    Cleanser, Serum, and Moisturizer. Subsequent subscription orders will receive free shipping and
    15% off. Offers subject to expire and valid only while supplies last. Cannot be applied to
    previous orders or non-subscribed items. Not valid for accessories or digital gift cards.
    Exceptions may apply. For full details on our return policy, click{' '}
    <a
      data-click="return-policy"
      data-from="trial-offer-v2-hc-sc-pop-up"
      href="https://prose.com/faq/5f3da05bceb96c001a595c02"
      target="_blank"
    >
      here
    </a>
    .
  </>
);

const bottomModalDescription = (
  <>
    Try Prose with <b>60% off + a FREE gift</b>, or a <b>complimentary Skincare Starter Set</b> when
    you subscribe to 3+ products.
  </>
);

const bottomModalConditions = (
  <>
    *Get [1] free Midi Claw Clip and a total of 60% off your first haircare subscription order when
    you subscribe to 3+ products. Get your Skincare Starter Set ($65 value) when you subscribe to
    Cleanser, Serum, and Moisturizer. Subsequent subscription orders will receive free shipping and
    15% off. Offers subject to expire and valid only while supplies last. Cannot be applied to
    previous orders or non-subscribed items. Not valid for accessories or digital gift cards.
    Exceptions may apply. For full details on our return policy, click{' '}
    <a href="https://prose.com/faq/5f3da05bceb96c001a595c02" target="_blank">
      here
    </a>
    .
  </>
);

const content = {
  banner: 'Get 60% off haircare + free gift, or try skincare on Prose',
  popUpModal: {
    [currencies.USD]: {
      ...trialOfferGWPMixedWithSkincareMinisModalContent.popUpModal[currencies.USD],
      image: image60Percent,
      headerTitle: 'SPRING WELCOME OFFER',
      conditions: popUpConditions,
    },
    [currencies.CAD]: {
      ...trialOfferGWPMixedWithSkincareMinisModalContent.popUpModal[currencies.CAD],
      image: image60Percent,
      headerTitle: 'SPRING WELCOME OFFER',
      conditions: popUpConditions,
    },
  },
  bottomModal: {
    [currencies.USD]: {
      ...trialOfferGWPMixedWithSkincareMinisModalContent.bottomModal[currencies.USD],
      title: 'SPRING WELCOME OFFER',
      description: bottomModalDescription,
      conditions: bottomModalConditions,
    },
    [currencies.CAD]: {
      ...trialOfferGWPMixedWithSkincareMinisModalContent.bottomModal[currencies.CAD],
      title: 'SPRING WELCOME OFFER',
      description: bottomModalDescription,
      conditions: bottomModalConditions,
    },
  },
};

export default content;
