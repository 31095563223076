import { forwardRef } from 'react';

import { theme } from '@prose-ui';
import { ClassNames, css, legacyTheme } from '@prose-ui/legacy';

// inspired by the implementation of Material UI Typography component

const rootStyles = css`
  display: block;
  margin: 0;

  & a {
    color: inherit;
    text-decoration: underline;
  }
`;

const inlineStyles = css`
  display: inline;
`;

const hero1 = css`
  ${legacyTheme.typography.hero1}
`;
const hero2 = css`
  ${legacyTheme.typography.hero2}
`;
const heroMono = css`
  ${legacyTheme.typography['hero-mono']}
`;
const h1rMono = css`
  ${legacyTheme.typography['h1r-mono']}
`;
const h2rMono = css`
  ${legacyTheme.typography['h2r-mono']}
`;
const h1r = css`
  ${legacyTheme.typography.h1r}
`;
const h2r = css`
  ${legacyTheme.typography.h2r}
`;
const h1 = css`
  ${legacyTheme.typography.h1}
`;
const h2 = css`
  ${legacyTheme.typography.h2}
`;
const h3 = css`
  ${legacyTheme.typography.h3}
`;
const h4 = css`
  ${legacyTheme.typography.h4}
`;
const mono1 = css`
  ${legacyTheme.typography.mono1}
`;
const mono2 = css`
  ${legacyTheme.typography.mono2}
`;
const mono3 = css`
  ${legacyTheme.typography.mono3}
`;
const mono4 = css`
  ${legacyTheme.typography.mono4}
`;
const p1 = css`
  ${legacyTheme.typography.p1}
`;
const p2 = css`
  ${legacyTheme.typography.p2}
`;
const p3 = css`
  ${legacyTheme.typography.p3}
`;
const p4 = css`
  ${legacyTheme.typography.p4}
`;

const variantMapping = {
  hero1,
  hero2,
  'hero-mono': heroMono,
  'h1r-mono': h1rMono,
  'h2r-mono': h2rMono,
  h1r,
  h2r,
  h1,
  h2,
  h3,
  h4,
  mono1,
  mono2,
  mono3,
  mono4,
  p1,
  p2,
  p3,
  p4,
} as const;

const left = css`
  text-align: left;
`;
const center = css`
  text-align: center;
`;
const right = css`
  text-align: right;
`;
const justify = css`
  text-align: justify;
`;

const alignMapping = {
  left,
  center,
  right,
  justify,
};

const pre = css`
  white-space: pre;
`;
const nowrap = css`
  white-space: nowrap;
`;
const preWrap = css`
  white-space: pre-wrap;
`;
const preLine = css`
  white-space: pre-line;
`;
const breakSpaces = css`
  white-space: break-spaces;
`;

const whitespaceMapping = {
  pre,
  nowrap,
  'pre-wrap': preWrap,
  'pre-line': preLine,
  'break-spaces': breakSpaces,
};

const upperCaseStyles = css`
  text-transform: uppercase;
`;
const italicStyles = css`
  font-style: italic;
`;
const underlineStyles = css`
  text-decoration: underline;
`;

const lilasDark2 = css`
  color: ${theme.colors.tertiary[300]};
`;
const noir = css`
  color: ${theme.colors.primary[400]};
`;
const noirDark = css`
  color: ${theme.colors.neutral[900]};
`;
const grey = css`
  color: ${theme.colors.neutral[800]};
`;
const red = css`
  color: red;
`;
const rouge = css`
  color: ${theme.colors.error[200]};
`;
const sorbet = css`
  color: ${theme.colors.accent[200]};
`;
const gold = css`
  color: ${theme.colors.secondary[300]};
`;
const vert = css`
  color: ${theme.colors.primary[300]};
`;
const white = css`
  color: ${theme.colors.neutral[100]};
`;
const lime = css`
  color: ${theme.colors.highlight[200]};
`;
const lavender = css`
  color: ${theme.colors.tertiary[200]};
`;

const colorMapping = {
  noir,
  noirDark,
  grey,
  red,
  rouge,
  sorbet,
  gold,
  vert,
  white,
  lilasDark2,
  lime,
  lavender,
};

const boldStyles = css`
  font-weight: 600;
`;
const normalStyles = css`
  font-weight: 400;
`;
const mediumStyles = css`
  font-weight: 500;
`;

const paragraphStyles = css`
  margin-bottom: 1em;

  &.hero1 {
    margin-bottom: 0.5em;
  }

  &.hero2 {
    margin-bottom: 0.5em;
  }

  &.h1r {
    margin-bottom: 0.5em;
  }

  &.h2r {
    margin-bottom: 0.5em;
  }

  &.h1 {
    margin-bottom: 0.5em;
  }

  &.h2 {
    margin-bottom: 0.5em;
  }

  &.h3 {
    margin-bottom: 0.5em;
  }

  &.h4 {
    margin-bottom: 0.5em;
  }

  &.mono1 {
    margin-bottom: 0.6em;
  }

  &.mono2 {
    margin-bottom: 0.6em;
  }

  &.mono3 {
    margin-bottom: 0.6em;
  }

  &.mono4 {
    margin-bottom: 0.6em;
  }

  &.p1 {
    margin-bottom: 0.6em;
  }

  &.p2 {
    margin-bottom: 0.6em;
  }

  &.p3 {
    margin-bottom: 0.6em;
  }

  &.p4 {
    margin-bottom: 0.6em;
  }
`;

const headlineMapping = {
  hero1: 'h1',
  hero2: 'h1',
  'hero-mono': 'h1',
  'h1r-mono': 'h1',
  'h2r-mono': 'h2',
  h1r: 'h1',
  h2r: 'h2',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  mono1: 'p',
  mono2: 'p',
  mono3: 'p',
  mono4: 'p',
  p1: 'p',
  p2: 'p',
  p3: 'p',
  p4: 'p',
  inherit: 'span',
} as const;

/** WARN: Polymorphic component shenaningan !
 * If eager to learn, have a look at
 * [How to Build Strongly Typed Polymorphic Components](https://www.freecodecamp.org/news/build-strongly-typed-polymorphic-components-with-react-and-typescript)
 */

// INFO: The Polymorphic prop, isolated
type AsProp<C extends React.ElementType> = {
  markupName?: C;
};

// INFO: List props to Omit from the Polymorphic component's own props
type PropsToOmit<C extends React.ElementType, P> = keyof (AsProp<C> & P);

/* INFO: Polymorphic prop joined with the Component own props + the children prop.
 * Those props are omitted of the Polymophic component's props to avoid unintentional leak
 */
type PolymorphicComponentProp<C extends React.ElementType, Props = {}> = React.PropsWithChildren<
  Props & AsProp<C>
> &
  Omit<React.ComponentPropsWithoutRef<C>, PropsToOmit<C, Props>>;

// INFO: Type of the Ref prop
type PolymorphicRef<C extends React.ElementType> = React.ComponentPropsWithRef<C>['ref'];

// INFO: Joint of the Polymophic prop + Component's own props + ref prop
type PolymorphicComponentPropWithRef<
  C extends React.ElementType,
  Props = {},
> = PolymorphicComponentProp<C, Props> & { ref?: PolymorphicRef<C> };

export type TypographyProps<C extends React.ElementType> = PolymorphicComponentPropWithRef<
  C,
  {
    align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
    whiteSpace?: 'normal' | 'pre' | 'nowrap' | 'pre-wrap' | 'pre-line' | 'break-spaces';
    className?: string;
    bold?: boolean;
    medium?: boolean;
    classes?: { [key: string]: string };
    style?: React.CSSProperties;
    color?:
      | 'inherit'
      | 'noir'
      | 'grey'
      | 'gold'
      | 'red'
      | 'rouge'
      | 'sorbet'
      | 'vert'
      | 'white'
      | 'lilasDark2'
      | 'noirDark'
      | 'lime'
      | 'lavender';
    inline?: boolean;
    paragraph?: boolean;
    upperCase?: boolean;
    italic?: boolean;
    underline?: boolean;
    variant?:
      | 'inherit'
      | 'hero1'
      | 'hero2'
      | 'hero-mono'
      | 'h1r-mono'
      | 'h2r-mono'
      | 'h1r'
      | 'h2r'
      | 'h1'
      | 'h2'
      | 'h3'
      | 'h4'
      | 'mono1'
      | 'mono2'
      | 'mono3'
      | 'mono4'
      | 'p1'
      | 'p2'
      | 'p3'
      | 'p4';
  }
>;

/* INFO: Polymorphic setup + forwardRef fn is pretty heavy. Without
 * this type annoted to the component variable, props checking
 * wouldn't kick in when using the component.
 */
type TypographyComponent = (<C extends React.ElementType = 'span'>(
  props: TypographyProps<C>,
) => React.ReactNode | null) & { displayName?: string | undefined };

/**
 * @deprecated Please use Text from @prose-ui instead.
 * variant mappping:
 * hero1 -> heading1
 * hero2 -> heading3
 * h1 -> heading4
 * h2 -> heading5
 * h3 -> heading6
 * h4 -> No corespondances
 * p1 -> bodyLg
 * p2 -> bodyMd
 * p3 -> bodySm
 * p4 -> No corespondances
 * mono1 -> labelLg
 * mono2 -> labelMd
 * mono3 -> labelSm
 * mono4 -> labelXs
 */
export const Typography: TypographyComponent = forwardRef(
  <C extends React.ElementType = 'span'>(
    {
      align = 'inherit',
      bold = false,
      medium = false,
      children,
      className: classNameProp,
      color = 'inherit',
      inline = false,
      markupName,
      paragraph = false,
      variant = 'inherit',
      upperCase = false,
      italic = false,
      whiteSpace = 'normal',
      underline = false,
      ...props
    }: TypographyProps<C>,
    ref?: PolymorphicRef<C>,
  ) => {
    const Component = markupName ?? headlineMapping[variant] ?? 'span';

    return (
      <ClassNames>
        {({ cx, css }) => (
          <Component
            ref={ref}
            className={cx(
              css(rootStyles),
              { [css(inlineStyles)]: inline },
              variant !== 'inherit' && css(variantMapping[variant]),
              align !== 'inherit' && css(alignMapping[align]),
              whiteSpace !== 'normal' && css(whitespaceMapping[whiteSpace]),
              { [css(upperCaseStyles)]: upperCase },
              { [css(italicStyles)]: italic },
              { [css(underlineStyles)]: underline },
              color !== 'inherit' && css(colorMapping[color]),
              { [css(boldStyles)]: bold },
              { [css(normalStyles)]: !bold },
              { [css(mediumStyles)]: medium },
              { [css(paragraphStyles)]: paragraph },
              variant !== 'inherit' && variant,
              classNameProp,
            )}
            {...props}
          >
            {children}
          </Component>
        )}
      </ClassNames>
    );
  },
);
Typography.displayName = 'Typography';
