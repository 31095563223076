import { Link as ReactLink } from 'react-router';

import NextLink from 'next/link';

type HybridLinkProps = {
  children?: string | React.ReactNode;
  nextMode?: boolean;
  to: string;
  onClick?: () => void;
};

export const HybridLink = ({ children, nextMode = false, to, ...props }: HybridLinkProps) =>
  nextMode ? (
    <NextLink href={to} {...props}>
      {children}
    </NextLink>
  ) : (
    <ReactLink to={to} {...props}>
      {children}
    </ReactLink>
  );
