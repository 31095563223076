import { createSelector } from '@reduxjs/toolkit';

import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import includes from 'lodash/fp/includes';
import isNil from 'lodash/fp/isNil';

import {
  PostPurchasePromoDesignPhase2AbTestVariant3StepTemplate,
  PostPurchasePromoDesignPhase2AbTestVariantBasicTemplate,
  PostPurchasePromoDesignPhase2AbTestVariantVideoTemplate,
} from 'abTesting/postPurchasePromoDesignPhase2/variants';

import * as userSelectors from 'dux/user/selectors';

import * as flagFetchStatuses from './constants/flagFetchStatus';
import * as flagVariants from './constants/flagVariants';
import initialState from './initialState';

// Get state.featureFlags
const getFeatureFlagsSubState = getOr(initialState, 'featureFlags');
// Get state.featureFlags.flags
export const getFlags = createSelector(getFeatureFlagsSubState, get('flags'));

// Get state.featureFlags.flagFetchStatus
const getFlagsFetchStatus = createSelector(getFeatureFlagsSubState, get('flagFetchStatus'));
// Get state.featureFlags.flagFetchStatus === USER_CONTEXT_SPECIFIC_FAIL || USER_CONTEXT_SPECIFIC_SUCCESS
export const isFlagsDoneFetching = createSelector(
  getFlagsFetchStatus,
  flagFetchStatus =>
    flagFetchStatus === flagFetchStatuses.USER_CONTEXT_SPECIFIC_FAIL ||
    flagFetchStatus === flagFetchStatuses.USER_CONTEXT_SPECIFIC_SUCCESS
);
// Get state.featureFlags.flagFetchStatus === USER_CONTEXT_SPECIFIC_PENDING
export const getIsFlagsLoading = createSelector(
  getFlagsFetchStatus,
  flagFetchStatus => flagFetchStatus === flagFetchStatuses.USER_CONTEXT_SPECIFIC_PENDING
);

// Get state.featureFlags.flags.express-checkout
const getExpressCheckoutFlag = createSelector(getFlags, get('express-checkout'));
// Get state.featureFlags.flags.express-checkout.active
const isExpressCheckoutFlagActive = createSelector(getExpressCheckoutFlag, get('active'));
// Get state.featureFlags.flags.express-checkout.variant
const getExpressCheckoutFlagVariant = createSelector(getExpressCheckoutFlag, get('variant'));
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForExpressCheckout = createSelector(
  isExpressCheckoutFlagActive,
  getExpressCheckoutFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowExpressCheckout = createSelector(
  getActiveOrVariantForExpressCheckout,
  activeOrVariant =>
    activeOrVariant === 'on' || activeOrVariant === 'off'
      ? activeOrVariant === 'on'
      : activeOrVariant
);

// Get state.featureFlags.flags.kustomer-livechat-at-cart
const getKustomerLivechatAtCartFlag = createSelector(getFlags, get('kustomer-livechat-at-cart'));
// Get state.featureFlags.flags.kustomer-livechat-at-cart.active
const isKustomerLivechatAtCartFlagActive = createSelector(
  getKustomerLivechatAtCartFlag,
  get('active')
);
// Get state.featureFlags.flags.kustomer-livechat-at-cart.variant
const getKustomerLivechatAtCartFlagVariant = createSelector(
  getKustomerLivechatAtCartFlag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForKustomerLivechatAtCart = createSelector(
  isKustomerLivechatAtCartFlagActive,
  getKustomerLivechatAtCartFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowKustomerLivechatAtCart = createSelector(
  getActiveOrVariantForKustomerLivechatAtCart,
  activeOrVariant =>
    activeOrVariant === 'on' || activeOrVariant === 'off'
      ? activeOrVariant === 'on'
      : activeOrVariant
);

// Get state.featureFlags.flags.brightback
const getBrightbackFlag = createSelector(getFlags, get('brightback'));
// Get state.featureFlags.flags.brightback.active
const isBrightbackFlagActive = createSelector(getBrightbackFlag, get('active'));
// Get state.featureFlags.flags.brightback.variant
const getBrightbackFlagVariant = createSelector(getBrightbackFlag, get('variant'));
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForBrightback = createSelector(
  isBrightbackFlagActive,
  getBrightbackFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowBrightback = createSelector(
  getActiveOrVariantForBrightback,
  activeOrVariant =>
    activeOrVariant === 'on' || activeOrVariant === 'off'
      ? activeOrVariant === 'on'
      : activeOrVariant
);

// Get state.featureFlags.flags.subscription-snooze-button
const getSubscriptionSnoozeButtonFlag = createSelector(getFlags, get('subscription-snooze-button'));
// Get state.featureFlags.flags.subscription-snooze-button.active
const isSubscriptionSnoozeButtonFlagActive = createSelector(
  getSubscriptionSnoozeButtonFlag,
  get('active')
);
// Get state.featureFlags.flags.subscription-snooze-button.variant
const getSubscriptionSnoozeButtonFlagVariant = createSelector(
  getSubscriptionSnoozeButtonFlag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForSubscriptionSnoozeButton = createSelector(
  isSubscriptionSnoozeButtonFlagActive,
  getSubscriptionSnoozeButtonFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowSubscriptionSnoozeButton = createSelector(
  getActiveOrVariantForSubscriptionSnoozeButton,
  activeOrVariant =>
    activeOrVariant === 'on' || activeOrVariant === 'off'
      ? activeOrVariant === 'on'
      : activeOrVariant
);

// Get state.featureFlags.flags.growthbook-fake-experiment-ab-test
const getGrowthbookFakeExperimentAbTestFlag = createSelector(
  getFlags,
  get('growthbook-fake-experiment-ab-test')
);
// Get state.featureFlags.flags.growthbook-fake-experiment-ab-test.active
const isGrowthbookFakeExperimentAbTestFlagActive = createSelector(
  getGrowthbookFakeExperimentAbTestFlag,
  get('active')
);
// Get state.featureFlags.flags.growthbook-fake-experiment-ab-test.variant
const getGrowthbookFakeExperimentAbTestFlagVariant = createSelector(
  getGrowthbookFakeExperimentAbTestFlag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active` value
export const getActiveOrVariantForGrowthbookFakeExperimentAbTest = createSelector(
  isGrowthbookFakeExperimentAbTestFlagActive,
  getGrowthbookFakeExperimentAbTestFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Make sure the variant is in the boundaries of the test
export const isGrowthbookFakeExperimentAbTestInExperiment = createSelector(
  getActiveOrVariantForGrowthbookFakeExperimentAbTest,
  activeOrVariant => includes(activeOrVariant, [flagVariants.CONTROL, flagVariants.VARIANT1])
);
// Get state.featureFlags.flags.prose-uk
const getProseUkFlag = createSelector(getFlags, get('prose-uk'));
// Get state.featureFlags.flags.prose-uk.active
const isProseUkFlagActive = createSelector(getProseUkFlag, get('active'));
// Get state.featureFlags.flags.prose-uk.variant
const getProseUkFlagVariant = createSelector(getProseUkFlag, get('variant'));
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForProseUk = createSelector(
  isProseUkFlagActive,
  getProseUkFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);

// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowProseUk = createSelector(getActiveOrVariantForProseUk, activeOrVariant =>
  activeOrVariant === 'on' || activeOrVariant === 'off' ? activeOrVariant === 'on' : activeOrVariant
);

// Get state.featureFlags.flags.digital-leaflet
const getDigitalLeafletFlag = createSelector(getFlags, get('digital-leaflet'));
// Get state.featureFlags.flags.digital-leaflet.active
const isDigitalLeafletFlagActive = createSelector(getDigitalLeafletFlag, get('active'));
// Get state.featureFlags.flags.digital-leaflet.variant
const getDigitalLeafletFlagVariant = createSelector(getDigitalLeafletFlag, get('variant'));
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForDigitalLeaflet = createSelector(
  isDigitalLeafletFlagActive,
  getDigitalLeafletFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowDigitalLeaflet = createSelector(
  getActiveOrVariantForDigitalLeaflet,
  activeOrVariant =>
    activeOrVariant === 'on' || activeOrVariant === 'off'
      ? activeOrVariant === 'on'
      : activeOrVariant
);

// Get state.featureFlags.flags.trial-offer
const getTrialOfferFlag = createSelector(getFlags, get('trial-offer'));
// Declared here to avoid dependency cycle
export const trialOfferEvergreen = 'evergreen';
export const trialOfferCombGwp = 'comb-gwp';
export const trialOfferToiletryGwp = 'toiletry-gwp';
export const trialOfferTravelPouchesGwp = 'travel-pouches-gwp';
export const trialOfferGwpSummerSale = 'summer-sale-gwp';
export const trialOfferToiletryAndCombGwp = 'toiletry-and-comb-gwp';
export const trialOfferHeatlessCurlSetBlackFridaySetGwp = 'heatless-curl-set-black-friday-set-gwp';
export const trialOfferHeatlessCurlSetGwp = 'heatless-curl-set-gwp';
export const trialOfferCollapsibleWaterBottleGwp = 'collapsible-water-bottle-gwp';
export const trialOfferPetitToteGwp = 'petit-tote-gwp';
export const trialOfferWideToothCombGwp = 'wide-tooth-comb-gwp';
export const trialOfferToiletryBagGwp = 'toiletry-bag-gwp';
export const trialOfferClawClipperGwp = 'claw-clipper-gwp';

const enabledVariants = [
  trialOfferEvergreen,
  trialOfferCombGwp,
  trialOfferToiletryGwp,
  trialOfferTravelPouchesGwp,
  trialOfferGwpSummerSale,
  trialOfferToiletryAndCombGwp,
  trialOfferHeatlessCurlSetBlackFridaySetGwp,
  trialOfferHeatlessCurlSetGwp,
  trialOfferCollapsibleWaterBottleGwp,
  trialOfferPetitToteGwp,
  trialOfferWideToothCombGwp,
  trialOfferToiletryBagGwp,
  trialOfferClawClipperGwp,
];
// Get state.featureFlags.flags.trial-offer.active
const isTrialOfferFlagActive = createSelector(getTrialOfferFlag, get('active'));
// Get state.featureFlags.flags.trial-offer.variant
export const getTrialOfferFlagVariant = createSelector(getTrialOfferFlag, get('variant'));
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForTrialOffer = createSelector(
  isTrialOfferFlagActive,
  getTrialOfferFlagVariant,
  (active, variant) => active && !isNil(variant) && enabledVariants.includes(variant) && variant
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowTrialOffer = createSelector(
  getActiveOrVariantForTrialOffer,
  userSelectors.getHasHaircareSubscriptionInAnyState,
  userSelectors.getHasSkincareSubscriptionInAnyState,
  (activeOrVariant, hasHaircareSubscription, hasSkincareSubscription) =>
    activeOrVariant === 'off' || (hasHaircareSubscription && hasSkincareSubscription)
      ? false
      : activeOrVariant
);

// Get state.featureFlags.flags.paypal
const getPaypalFlag = createSelector(getFlags, get('paypal'));
// Get state.featureFlags.flags.paypal.active
const isPaypalFlagActive = createSelector(getPaypalFlag, get('active'));
// Get state.featureFlags.flags.paypal.variant
const getPaypalFlagVariant = createSelector(getPaypalFlag, get('variant'));
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForPaypal = createSelector(
  isPaypalFlagActive,
  getPaypalFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowPaypal = createSelector(getActiveOrVariantForPaypal, activeOrVariant =>
  activeOrVariant === 'on' || activeOrVariant === 'off' ? activeOrVariant === 'on' : activeOrVariant
);
// Get state.featureFlags.flags.address-validation
const getAddressValidationFlag = createSelector(getFlags, get('address-validation'));
// Get state.featureFlags.flags.address-validation.active
const isAddressValidationFlagActive = createSelector(getAddressValidationFlag, get('active'));
// Get state.featureFlags.flags.address-validation.variant
const getAddressValidationFlagVariant = createSelector(getAddressValidationFlag, get('variant'));
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForAddressValidation = createSelector(
  isAddressValidationFlagActive,
  getAddressValidationFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowAddressValidation = createSelector(
  getActiveOrVariantForAddressValidation,
  activeOrVariant =>
    activeOrVariant === 'on' || activeOrVariant === 'off'
      ? activeOrVariant === 'on'
      : activeOrVariant
);
// Get state.featureFlags.flags.data-driven-consultation
const getDataDrivenConsultationFlag = createSelector(getFlags, get('data-driven-consultation'));
// Get state.featureFlags.flags.data-driven-consultation.active
const isDataDrivenConsultationFlagActive = createSelector(
  getDataDrivenConsultationFlag,
  get('active')
);
// Get state.featureFlags.flags.data-driven-consultation.variant
const getDataDrivenConsultationFlagVariant = createSelector(
  getDataDrivenConsultationFlag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForDataDrivenConsultation = createSelector(
  isDataDrivenConsultationFlagActive,
  getDataDrivenConsultationFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowDataDrivenConsultation = createSelector(
  getActiveOrVariantForDataDrivenConsultation,
  activeOrVariant =>
    activeOrVariant === 'on' || activeOrVariant === 'off'
      ? activeOrVariant === 'on'
      : activeOrVariant
);

// Get state.featureFlags.flags.payment-intent
const getPaymentIntentFlag = createSelector(getFlags, get('payment-intent'));
// Get state.featureFlags.flags.payment-intent.active
const isPaymentIntentFlagActive = createSelector(getPaymentIntentFlag, get('active'));
// Get state.featureFlags.flags.payment-intent.variant
const getPaymentIntentFlagVariant = createSelector(getPaymentIntentFlag, get('variant'));
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForPaymentIntent = createSelector(
  isPaymentIntentFlagActive,
  getPaymentIntentFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowPaymentIntent = createSelector(
  getActiveOrVariantForPaymentIntent,
  activeOrVariant =>
    activeOrVariant === 'on' || activeOrVariant === 'off'
      ? activeOrVariant === 'on'
      : activeOrVariant
);
// Get state.featureFlags.flags.skincare-minis-phase-2
const getSkincareMinisPhase2Flag = createSelector(getFlags, get('skincare-minis-phase-2'));
// Get state.featureFlags.flags.skincare-minis-phase-2.active
const isSkincareMinisPhase2FlagActive = createSelector(getSkincareMinisPhase2Flag, get('active'));
// Get state.featureFlags.flags.skincare-minis-phase-2.variant
const getSkincareMinisPhase2FlagVariant = createSelector(
  getSkincareMinisPhase2Flag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForSkincareMinisPhase2 = createSelector(
  isSkincareMinisPhase2FlagActive,
  getSkincareMinisPhase2FlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowSkincareMinisPhase2 = createSelector(
  getActiveOrVariantForSkincareMinisPhase2,
  activeOrVariant =>
    activeOrVariant === 'on' || activeOrVariant === 'off'
      ? activeOrVariant === 'on'
      : activeOrVariant
);

// Get state.featureFlags.flags.feedback-awareness-cta-ab-test
const getFeedbackAwarenessCtaAbTestFlag = createSelector(
  getFlags,
  get('feedback-awareness-cta-ab-test')
);
// Get state.featureFlags.flags.feedback-awareness-cta-ab-test.active
const isFeedbackAwarenessCtaAbTestFlagActive = createSelector(
  getFeedbackAwarenessCtaAbTestFlag,
  get('active')
);
// Get state.featureFlags.flags.feedback-awareness-cta-ab-test.variant
const getFeedbackAwarenessCtaAbTestFlagVariant = createSelector(
  getFeedbackAwarenessCtaAbTestFlag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active` value
export const getActiveOrVariantForFeedbackAwarenessCtaAbTest = createSelector(
  isFeedbackAwarenessCtaAbTestFlagActive,
  getFeedbackAwarenessCtaAbTestFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Make sure the variant is in the boundaries of the test
export const isFeedbackAwarenessCtaAbTestInExperiment = createSelector(
  getActiveOrVariantForFeedbackAwarenessCtaAbTest,
  activeOrVariant => includes(activeOrVariant, [flagVariants.CONTROL, flagVariants.VARIANT1])
);
// Get state.featureFlags.flags.post-purchase-promo-layout-ab-test
const getPostPurchasePromoLayoutAbTestFlag = createSelector(
  getFlags,
  get('post-purchase-promo-layout-ab-test')
);
// Get state.featureFlags.flags.post-purchase-promo-layout-ab-test.active
const isPostPurchasePromoLayoutAbTestFlagActive = createSelector(
  getPostPurchasePromoLayoutAbTestFlag,
  get('active')
);
// Get state.featureFlags.flags.post-purchase-promo-layout-ab-test.variant
const getPostPurchasePromoLayoutAbTestFlagVariant = createSelector(
  getPostPurchasePromoLayoutAbTestFlag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active` value
export const getActiveOrVariantForPostPurchasePromoLayoutAbTest = createSelector(
  isPostPurchasePromoLayoutAbTestFlagActive,
  getPostPurchasePromoLayoutAbTestFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Make sure the variant is in the boundaries of the test
export const isPostPurchasePromoLayoutAbTestInExperiment = createSelector(
  getActiveOrVariantForPostPurchasePromoLayoutAbTest,
  activeOrVariant => includes(activeOrVariant, [flagVariants.CONTROL, flagVariants.VARIANT1])
);
// Get state.featureFlags.flags.skincare-minis
const getSkincareMinisFlag = createSelector(getFlags, get('skincare-minis'));
// Get state.featureFlags.flags.skincare-minis.active
const isSkincareMinisFlagActive = createSelector(getSkincareMinisFlag, get('active'));
// Get state.featureFlags.flags.skincare-minis.variant
const getSkincareMinisFlagVariant = createSelector(getSkincareMinisFlag, get('variant'));
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForSkincareMinis = createSelector(
  isSkincareMinisFlagActive,
  getSkincareMinisFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowSkincareMinis = createSelector(
  getActiveOrVariantForSkincareMinis,
  activeOrVariant =>
    activeOrVariant === 'on' || activeOrVariant === 'off'
      ? activeOrVariant === 'on'
      : activeOrVariant
);

// Get state.featureFlags.flags.nps-modal
const getNpsModalFlag = createSelector(getFlags, get('nps-modal'));
// Get state.featureFlags.flags.nps-modal.active
const isNpsModalFlagActive = createSelector(getNpsModalFlag, get('active'));
// Get state.featureFlags.flags.nps-modal.variant
export const getNpsModalFlagVariant = createSelector(getNpsModalFlag, get('variant'));
// Compute derived state, returns `variant` if set, else returns `active` value
export const getActiveOrVariantForNpsModal = createSelector(
  isNpsModalFlagActive,
  getNpsModalFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);

// Get state.featureFlags.flags.skincare-concerns-order-ab-test
const getSkincareConcernsOrderAbTestFlag = createSelector(
  getFlags,
  get('skincare-concerns-order-ab-test')
);
// Get state.featureFlags.flags.skincare-concerns-order-ab-test.active
const isSkincareConcernsOrderAbTestFlagActive = createSelector(
  getSkincareConcernsOrderAbTestFlag,
  get('active')
);
// Get state.featureFlags.flags.skincare-concerns-order-ab-test.variant
const getSkincareConcernsOrderAbTestFlagVariant = createSelector(
  getSkincareConcernsOrderAbTestFlag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active` value
export const getActiveOrVariantForSkincareConcernsOrderAbTest = createSelector(
  isSkincareConcernsOrderAbTestFlagActive,
  getSkincareConcernsOrderAbTestFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Make sure the variant is in the boundaries of the test
export const isSkincareConcernsOrderAbTestInExperiment = createSelector(
  getActiveOrVariantForSkincareConcernsOrderAbTest,
  activeOrVariant => includes(activeOrVariant, [flagVariants.CONTROL, flagVariants.VARIANT1])
);

// Get state.featureFlags.flags.post-purchase-popup-ab-test
const getPostPurchasePopupAbTestFlag = createSelector(getFlags, get('post-purchase-popup-ab-test'));
// Get state.featureFlags.flags.post-purchase-popup-ab-test.active
const isPostPurchasePopupAbTestFlagActive = createSelector(
  getPostPurchasePopupAbTestFlag,
  get('active')
);
// Get state.featureFlags.flags.post-purchase-popup-ab-test.variant
const getPostPurchasePopupAbTestFlagVariant = createSelector(
  getPostPurchasePopupAbTestFlag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active` value
export const getActiveOrVariantForPostPurchasePopupAbTest = createSelector(
  isPostPurchasePopupAbTestFlagActive,
  getPostPurchasePopupAbTestFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Make sure the variant is in the boundaries of the test
export const isPostPurchasePopupAbTestInExperiment = createSelector(
  getActiveOrVariantForPostPurchasePopupAbTest,
  activeOrVariant => includes(activeOrVariant, [flagVariants.CONTROL, flagVariants.VARIANT1])
);

// Get state.featureFlags.flags.key-action-card-ab-test
const getKeyActionCardAbTestFlag = createSelector(getFlags, get('key-action-card-ab-test'));
// Get state.featureFlags.flags.key-action-card-ab-test.active
const isKeyActionCardAbTestFlagActive = createSelector(getKeyActionCardAbTestFlag, get('active'));
// Get state.featureFlags.flags.key-action-card-ab-test.variant
const getKeyActionCardAbTestFlagVariant = createSelector(
  getKeyActionCardAbTestFlag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active` value
export const getActiveOrVariantForKeyActionCardAbTest = createSelector(
  isKeyActionCardAbTestFlagActive,
  getKeyActionCardAbTestFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Make sure the variant is in the boundaries of the test
export const isKeyActionCardAbTestInExperiment = createSelector(
  getActiveOrVariantForKeyActionCardAbTest,
  activeOrVariant => includes(activeOrVariant, [flagVariants.CONTROL, flagVariants.VARIANT1])
);

// Get state.featureFlags.flags.subscription-item-removal-ab-test
const getSubscriptionItemRemovalAbTestFlag = createSelector(
  getFlags,
  get('subscription-item-removal-ab-test')
);
// Get state.featureFlags.flags.subscription-item-removal-ab-test.active
const isSubscriptionItemRemovalAbTestFlagActive = createSelector(
  getSubscriptionItemRemovalAbTestFlag,
  get('active')
);
// Get state.featureFlags.flags.subscription-item-removal-ab-test.variant
const getSubscriptionItemRemovalAbTestFlagVariant = createSelector(
  getSubscriptionItemRemovalAbTestFlag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active` value
export const getActiveOrVariantForSubscriptionItemRemovalAbTest = createSelector(
  isSubscriptionItemRemovalAbTestFlagActive,
  getSubscriptionItemRemovalAbTestFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Make sure the variant is in the boundaries of the test
export const isSubscriptionItemRemovalAbTestInExperiment = createSelector(
  getActiveOrVariantForSubscriptionItemRemovalAbTest,
  activeOrVariant => includes(activeOrVariant, [flagVariants.CONTROL, flagVariants.VARIANT1])
);
// Get state.featureFlags.flags.scalp-serum-content
const getScalpSerumContentFlag = createSelector(getFlags, get('scalp-serum-content'));
// Get state.featureFlags.flags.scalp-serum-content.active
const isScalpSerumContentFlagActive = createSelector(getScalpSerumContentFlag, get('active'));
// Get state.featureFlags.flags.scalp-serum-content.variant
const getScalpSerumContentFlagVariant = createSelector(getScalpSerumContentFlag, get('variant'));
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForScalpSerumContent = createSelector(
  isScalpSerumContentFlagActive,
  getScalpSerumContentFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowScalpSerumContent = createSelector(
  getActiveOrVariantForScalpSerumContent,
  activeOrVariant =>
    activeOrVariant === 'on' || activeOrVariant === 'off'
      ? activeOrVariant === 'on'
      : activeOrVariant
);
// Make sure the variant is in the boundaries of the test
// Get state.featureFlags.flags.post-purchase-promo-design-phase2-ab-test
const getPostPurchasePromoDesignPhase2AbTestFlag = createSelector(
  getFlags,
  get('post-purchase-promo-design-phase2-ab-test')
);
// Get state.featureFlags.flags.post-purchase-promo-design-phase2-ab-test.active
const isPostPurchasePromoDesignPhase2AbTestFlagActive = createSelector(
  getPostPurchasePromoDesignPhase2AbTestFlag,
  get('active')
);
// Get state.featureFlags.flags.post-purchase-promo-design-phase2-ab-test.variant
const getPostPurchasePromoDesignPhase2AbTestFlagVariant = createSelector(
  getPostPurchasePromoDesignPhase2AbTestFlag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active` value
export const getActiveOrVariantForPostPurchasePromoDesignPhase2AbTest = createSelector(
  isPostPurchasePromoDesignPhase2AbTestFlagActive,
  getPostPurchasePromoDesignPhase2AbTestFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Make sure the variant is in the boundaries of the test
export const isPostPurchasePromoDesignPhase2AbTestInExperiment = createSelector(
  getActiveOrVariantForPostPurchasePromoDesignPhase2AbTest,
  activeOrVariant => {
    return includes(activeOrVariant, [
      PostPurchasePromoDesignPhase2AbTestVariantBasicTemplate,
      PostPurchasePromoDesignPhase2AbTestVariant3StepTemplate,
      PostPurchasePromoDesignPhase2AbTestVariantVideoTemplate,
    ]);
  }
);
// Get state.featureFlags.flags.data-driven-promotions
const getDataDrivenPromotionsFlag = createSelector(getFlags, get('data-driven-promotions'));
// Get state.featureFlags.flags.data-driven-promotions.active
const isDataDrivenPromotionsFlagActive = createSelector(getDataDrivenPromotionsFlag, get('active'));
// Get state.featureFlags.flags.data-driven-promotions.variant
const getDataDrivenPromotionsFlagVariant = createSelector(
  getDataDrivenPromotionsFlag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForDataDrivenPromotions = createSelector(
  isDataDrivenPromotionsFlagActive,
  getDataDrivenPromotionsFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowDataDrivenPromotions = createSelector(
  getActiveOrVariantForDataDrivenPromotions,
  activeOrVariant =>
    activeOrVariant === 'on' || activeOrVariant === 'off'
      ? activeOrVariant === 'on'
      : activeOrVariant
);

// Get state.featureFlags.flags.skincare-minis-first-charge-ab-test
const getSkincareMinisFirstChargeAbTestFlag = createSelector(
  getFlags,
  get('skincare-minis-first-charge-ab-test')
);
// Get state.featureFlags.flags.skincare-minis-first-charge-ab-test.active
const isSkincareMinisFirstChargeAbTestFlagActive = createSelector(
  getSkincareMinisFirstChargeAbTestFlag,
  get('active')
);
// Get state.featureFlags.flags.skincare-minis-first-charge-ab-test.variant
const getSkincareMinisFirstChargeAbTestFlagVariant = createSelector(
  getSkincareMinisFirstChargeAbTestFlag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active` value
export const getActiveOrVariantForSkincareMinisFirstChargeAbTest = createSelector(
  isSkincareMinisFirstChargeAbTestFlagActive,
  getSkincareMinisFirstChargeAbTestFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Make sure the variant is in the boundaries of the test
export const isSkincareMinisFirstChargeAbTestInExperiment = createSelector(
  getActiveOrVariantForSkincareMinisFirstChargeAbTest,
  activeOrVariant => includes(activeOrVariant, [flagVariants.CONTROL, flagVariants.VARIANT1])
);

// Get state.featureFlags.flags.frequency-update-clarification-ab-test
const getFrequencyUpdateClarificationAbTestFlag = createSelector(
  getFlags,
  get('frequency-update-clarification-ab-test')
);
// Get state.featureFlags.flags.frequency-update-clarification-ab-test.active
const isFrequencyUpdateClarificationAbTestFlagActive = createSelector(
  getFrequencyUpdateClarificationAbTestFlag,
  get('active')
);
// Get state.featureFlags.flags.frequency-update-clarification-ab-test.variant
const getFrequencyUpdateClarificationAbTestFlagVariant = createSelector(
  getFrequencyUpdateClarificationAbTestFlag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active` value
export const getActiveOrVariantForFrequencyUpdateClarificationAbTest = createSelector(
  isFrequencyUpdateClarificationAbTestFlagActive,
  getFrequencyUpdateClarificationAbTestFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Make sure the variant is in the boundaries of the test
export const isFrequencyUpdateClarificationAbTestInExperiment = createSelector(
  getActiveOrVariantForFrequencyUpdateClarificationAbTest,
  activeOrVariant => includes(activeOrVariant, [flagVariants.CONTROL, flagVariants.VARIANT1])
);

// Get state.featureFlags.flags.kustomer-livechat
const getKustomerLivechatFlag = createSelector(getFlags, get('kustomer-livechat'));
// Get state.featureFlags.flags.kustomer-livechat.active
const isKustomerLivechatFlagActive = createSelector(getKustomerLivechatFlag, get('active'));
// Get state.featureFlags.flags.kustomer-livechat.variant
const getKustomerLivechatFlagVariant = createSelector(getKustomerLivechatFlag, get('variant'));
// Compute derived state, returns `variant` if set, else returns `active`
const getActiveOrVariantForKustomerLivechat = createSelector(
  isKustomerLivechatFlagActive,
  getKustomerLivechatFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Compute derived state to be used as React Props, returns `variant` if set and not `on`/`off`, else returns `active` boolean
export const shouldShowKustomerLivechat = createSelector(
  getActiveOrVariantForKustomerLivechat,
  activeOrVariant =>
    activeOrVariant === 'on' || activeOrVariant === 'off'
      ? activeOrVariant === 'on'
      : activeOrVariant
);

// Get state.featureFlags.flags.homepage-email-capture-pop-up-ab-test
const getHomepageEmailCapturePopUpAbTestFlag = createSelector(
  getFlags,
  get('homepage-email-capture-pop-up-ab-test')
);
// Get state.featureFlags.flags.homepage-email-capture-pop-up-ab-test.active
const isHomepageEmailCapturePopUpAbTestFlagActive = createSelector(
  getHomepageEmailCapturePopUpAbTestFlag,
  get('active')
);
// Get state.featureFlags.flags.homepage-email-capture-pop-up-ab-test.variant
export const getHomepageEmailCapturePopUpAbTestFlagVariant = createSelector(
  getHomepageEmailCapturePopUpAbTestFlag,
  get('variant')
);
// Compute derived state, returns `variant` if set, else returns `active` value
export const getActiveOrVariantForHomepageEmailCapturePopUpAbTest = createSelector(
  isHomepageEmailCapturePopUpAbTestFlagActive,
  getHomepageEmailCapturePopUpAbTestFlagVariant,
  (active, variant) => (active && !isNil(variant) ? variant : active)
);
// Make sure the variant is in the boundaries of the test
export const isHomepageEmailCapturePopUpAbTestInExperiment = createSelector(
  getActiveOrVariantForHomepageEmailCapturePopUpAbTest,
  activeOrVariant => includes(activeOrVariant, [flagVariants.CONTROL, flagVariants.VARIANT1])
);
