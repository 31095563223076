import { FRAGRANCE } from 'Apps/Feedback/constants/feedbackSections';
import { cleanserFragranceSatisfactionIntensity } from 'Apps/Feedback/constants/feedbackSlugs';

import { feedbackCategories } from 'constants/feedback';
import { productsSlugs } from 'constants/products';

const cleanserFragranceIntensitySatisfaction = {
  name: cleanserFragranceSatisfactionIntensity,
  route: `/feedback/skincare/fragrance-cleanser`,
  category: FRAGRANCE,
  feedbackCategory: feedbackCategories.SKINCARE,
  shouldBeIncluded: ({ formulas, skinFragrance, consultationAnswers }) =>
    !consultationAnswers?.pref_fragrance_free_skin &&
    formulas?.some(
      f => [productsSlugs.CLEANSER].includes(f.variant.product.type) && skinFragrance !== null
    ),
  Component: 'FragranceSatisfactionIntensity',
  surtitle: {
    showFormulas: 'cleanser',
    showFragrance: true,
  },
  title: 'How satisfied are you with the scent of your cleanser?',
  getInitialValue: answers => ({
    fragrance_satisfaction: answers?.fragrance_cleanser_satisfaction,
    fragrance_intensity: answers?.fragrance_cleanser_intensity,
  }),
  getAnswers: ({ selected }) => ({
    fragrance_cleanser_satisfaction: selected?.fragrance_satisfaction,
    fragrance_cleanser_intensity: selected?.fragrance_intensity,
  }),
  multi: true,
};

export default cleanserFragranceIntensitySatisfaction;
