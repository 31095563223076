import PropTypes from 'prop-types';
import { forbidExtraProps } from 'airbnb-prop-types';

import { Link, useNavigate } from 'react-router';

import { useAppSelector as useSelector } from 'dux/app/hooks';

import { legacyTheme, styled } from '@prose-ui/legacy';
import { Field, getIn } from 'formik';

import CheckoutBlock from 'Apps/Checkout/Blocks/CheckoutBlock';
import { CHECKOUT_OPTIMISATION_THEME } from 'Apps/Checkout/constants/checkoutInputThemes';
import * as checkoutSteps from 'Apps/Checkout/constants/checkoutSteps';
import { useCheckoutCategory } from 'Apps/Checkout/hooks/useCheckoutCategory';

import { Button } from 'Components/LegacyButton';
import PhoneTextField from 'Components/PhoneTextField';
import Spacer from 'Components/Spacer';
import TextOptin from 'Components/TextOptinComponents';
import { Typography } from 'Components/Typography';
import PaymentTextField from '../Components/PaymentTextField';

import { getCountryFromPhoneNumber } from 'constants/countries';

import { labelWithToolTipCheckoutOptimisation as marketingSmsLabelCheckoutOptimisation } from 'assets/content/marketingSmsOptin';
import { labelCheckoutOptimisation as transactionalSmsLabelCheckoutOptimisation } from 'assets/content/transactionalSmsOptin';

import addOriginParam from 'utils/addOriginParam';

import { getHasCompletedAccountDetailsSection } from 'dux/checkout/selectors';
import * as checkoutCartSelectors from 'dux/checkoutCart/selectors';
import { getUser, getUserPhone } from 'dux/user/selectors';

const Wrapper = styled.div`
  ${legacyTheme.breakpoints.up('sm')} {
    max-width: 300px;
  }
`;

const CheckoutAccountContact = ({
  params,
  phone,
  isSubmitting,
  textMarketingOptin,
  textOptin,
  onCancel,
  isCurrentStep,
}) => {
  const checkoutCategory = useCheckoutCategory();
  const navigate = useNavigate();

  const user = useSelector(getUser);
  const userPhone = useSelector(getUserPhone);
  const hasCompletedAccountDetailsSection = useSelector(getHasCompletedAccountDetailsSection);
  const consultationProfileCountry = useSelector(
    checkoutCartSelectors.getConsultationProfileCountryInCartSurvey
  );

  const onEdit = () =>
    navigate(
      addOriginParam(params)(`/checkout/${checkoutCategory}/${checkoutSteps.ACCOUNT_DETAILS}`)
    );

  const selectedPhoneNumberCountryFrom =
    phone?.length >= 3 ? getCountryFromPhoneNumber(phone) : consultationProfileCountry;

  const isReadyOnly = !isCurrentStep && hasCompletedAccountDetailsSection;
  const isEditing = isCurrentStep && hasCompletedAccountDetailsSection;

  return (
    <CheckoutBlock
      dataTestId="contact-summary"
      id="checkout-contact-block"
      onEdit={isReadyOnly ? onEdit : undefined}
      title="Account details"
    >
      {!isReadyOnly && (
        <Wrapper>
          <PaymentTextField
            autoCorrect={false}
            label="First name*"
            name="account.firstName"
            theme={CHECKOUT_OPTIMISATION_THEME}
          />
          <PaymentTextField
            autoCorrect={false}
            label="Last name*"
            name="account.lastName"
            theme={CHECKOUT_OPTIMISATION_THEME}
          />
          <PaymentTextField
            autoCorrect={false}
            label="Email*"
            name="account.email"
            theme={CHECKOUT_OPTIMISATION_THEME}
          />

          <Field name="account.phone">
            {({ field, form }) => {
              const isInputTouched = getIn(form.touched, 'account.phone');
              const inputError = isInputTouched ? getIn(form.errors, 'account.phone') : '';

              return (
                <PhoneTextField
                  ariaInvalid={Boolean(inputError)}
                  ariaRequired
                  autoCorrect={false}
                  error={inputError && `Phone number ${inputError.toLowerCase()}`}
                  id="phone"
                  label="Phone number*"
                  onBlur={() => form.setFieldTouched('account.phone', true)}
                  onChange={value => form.setFieldValue('account.phone', value)}
                  selectedCountry={selectedPhoneNumberCountryFrom}
                  theme={CHECKOUT_OPTIMISATION_THEME}
                  value={field.value}
                />
              );
            }}
          </Field>
        </Wrapper>
      )}

      {isReadyOnly && (
        <>
          <Typography align="left" color="noirDark" variant="p2">
            {user.first_name} {user.last_name}
          </Typography>

          <Typography align="left" color="noirDark" variant="p2">
            {user.email}
          </Typography>
          <Typography align="left" color="noirDark" variant="p2">
            {userPhone}
          </Typography>
        </>
      )}

      <Spacer size={10} />
      <Typography color="grey" variant="p3">
        By checking the box(es) below and entering your phone number above, you consent to receive
        recurring account-related text messages (such as order and shipping confirmation, or error
        alerts) and/or marketing text messages (such as ads, promotions, and special offers) from
        Prose from time to time at the number provided, including messages sent using an automatic
        telephone dialing system. You further agree to our{' '}
        <Link target="_blank" to="/terms">
          Terms of Use
        </Link>
        . Consent is not a condition of any purchase. You can opt-out at any time by replying STOP
        to any one of our messages or by logging into your Prose account and visiting your
        communication settings in your{' '}
        <Link target="_blank" to="/account/settings">
          Account Details
        </Link>
        . Message and data rates may apply. Message frequency varies. Visit our{' '}
        <Link target="_blank" to="/privacy">
          Privacy Policy
        </Link>{' '}
        for more information.
      </Typography>

      <Spacer size={10} />

      <TextOptin
        color="noirDark"
        content={transactionalSmsLabelCheckoutOptimisation}
        dataPrefix="transactional-sms"
        displayMode={isReadyOnly}
        fieldName="textOptin"
        isEditable={isCurrentStep}
        labelVariant="p2"
        value={textOptin}
      />

      <TextOptin
        color="noirDark"
        content={marketingSmsLabelCheckoutOptimisation}
        dataPrefix="marketing-sms"
        displayMode={isReadyOnly}
        fieldName="textMarketingOptin"
        isEditable={isCurrentStep}
        labelVariant="p2"
        value={textMarketingOptin}
      />

      {isEditing && (
        <Wrapper>
          <Button
            data-testid="checkout-save-contact-details-button"
            disabled={isSubmitting}
            fullWidth
            isLoading={isSubmitting}
            noMargin
            type="submit"
            variant="vert"
          >
            Save
          </Button>

          <Spacer size={10} />
          {!isSubmitting && (
            <Button fullWidth noMargin onClick={onCancel} type="button" variant="vertOutline">
              Cancel
            </Button>
          )}
        </Wrapper>
      )}
    </CheckoutBlock>
  );
};

CheckoutAccountContact.propTypes = forbidExtraProps({
  params: PropTypes.shape({}).isRequired,
  phone: PropTypes.string.isRequired,
  textMarketingOptin: PropTypes.bool,
  textOptin: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onCancel: PropTypes.func,
  isCurrentStep: PropTypes.bool.isRequired,
});

CheckoutAccountContact.defaultProps = {
  textOptin: false,
  textMarketingOptin: false,
  isSubmitting: false,
  onCancel: null,
};

export default CheckoutAccountContact;
