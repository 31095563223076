import { createApi } from '@reduxjs/toolkit/query/react';

import customBaseQuery from 'Services/customBaseQuery';

export const appApi = createApi({
  reducerPath: 'appApi',
  baseQuery: customBaseQuery,
  tagTypes: [
    'checkout',
    'feedback',
    'gift',
    'prescription',
    'subscriptions',
    'user',
    'promotions',
    'support',
  ],
  endpoints: () => ({}),
});
