import { FRAGRANCE } from 'Apps/Feedback/constants/feedbackSections';
import { haircareFragranceSatisfactionScalpSerum } from 'Apps/Feedback/constants/feedbackSlugs';

import { productsSlugs } from 'constants/products';

const scalpSerumFragranceSatisfaction = {
  name: haircareFragranceSatisfactionScalpSerum,
  route: `/feedback/haircare/${haircareFragranceSatisfactionScalpSerum}`,
  category: FRAGRANCE,
  shouldBeIncluded: ({ formulas }) =>
    formulas?.some(
      f =>
        [productsSlugs.SCALP_SERUM].includes(f.variant.product.type) &&
        f.item_object.smells_like !== null
    ),
  Component: 'Rating',
  surtitle: {
    showFormulas: 'scalp_serum',
    showFragrance: true,
  },
  title: 'How satisfied are you with the scent of your scalp serum?',
  options: [
    { value: 1, label: 'Very Unsatisfied' },
    { value: 2, label: 'Unsatisfied' },
    { value: 3, label: 'Neutral' },
    { value: 4, label: 'Satisfied' },
    { value: 5, label: 'Very Satisfied' },
  ],
  getInitialValue: answers => answers?.fragrance_satisfaction_scalp_serum,
  getAnswers: ({ selected }) => ({ fragrance_satisfaction_scalp_serum: selected }),
};

export default scalpSerumFragranceSatisfaction;
