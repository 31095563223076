import calendarPicto from 'assets/images/membership/picto-calendar.svg';
import cancelPicto from 'assets/images/membership/picto-cancel.svg';
import shippingPicto from 'assets/images/membership/picto-shipping.svg';

export const subscriptionPerksContent = {
  title: {
    haircare: {
      onlySkincareSubscription: ['Save ', 'when you subscribe to haircare'],
      onlyHaircareSubscription: ['Save ', 'when you subscribe to skincare'],
      bothSubscription: ['You’re ', 'saving ', ' as an haircare and skincare subscriber!'],
      noSubscription: ['Save ', ' when you subscribe to haircare'],
    },
    skincare: {
      onlySkincareSubscription: ['Save ', 'when you subscribe to haircare'],
      onlyHaircareSubscription: ['Save ', 'when you subscribe to skincare'],
      bothSubscription: ['You’re ', 'saving ', ' as an haircare and skincare subscriber!'],
      noSubscription: ['Save ', ' when you subscribe to skincare'],
    },
  },
  content: [
    { logo: cancelPicto, text: 'Cancel any time', alt: '', width: 14, height: 14 },
    { logo: shippingPicto, text: 'Free shipping', alt: '', width: 23, height: 12 },
    {
      logo: calendarPicto,
      text: 'Refills on your schedule',
      alt: '',
      width: 17,
      height: 19,
    },
  ],
};
