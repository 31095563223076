import { useEffect } from 'react';

import { useLocation } from 'react-router';

// scrolls to the top of the window every time the route changes

function scrollTop() {
  // hope that the component is already rendered after the animation frame responds
  window.requestAnimationFrame(() => window.scrollTo(0, 0));
}

const ScrollTopOnChange = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    scrollTop();
  }, [pathname]);

  return null;
};

ScrollTopOnChange.propTypes = {};

export default ScrollTopOnChange;
