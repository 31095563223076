import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import PropTypes from 'prop-types';

import Carousel from 'react-slick';

import { useAppSelector, useAppSelector as useSelector } from 'dux/app/hooks';

import { theme } from '@prose-ui';
import { styled } from '@prose-ui/legacy';

import { Image } from 'Components/Image';
import { PerksContentTitle } from 'Components/PerksContentTitle';
import Spacer from 'Components/Spacer';
import { Typography } from 'Components/Typography';

import { subscriptionPerksContent } from 'assets/content/membership/membershipModal';

import useResponsiveVariant from 'utils/useResponsiveVariant';

import { getMembershipPerksTitle, getUserSubscriptionDiscount } from 'dux/user/selectors';

import { useCheckoutCategory } from '../hooks/useCheckoutCategory';

const StyledTypography = styled(Typography)`
  min-height: 48.5px;
`;

const MembershipCommunicationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  width: 100%;
  max-width: 400px;
  min-width: 310px;
  margin: 0 auto;
  padding: 65px 55px;

  background-color: ${theme.colors.highlight[200]};

  border-radius: ${({ borderRadiusPosition }) =>
    borderRadiusPosition === 'top' ? ' 20px 20px 0 0' : '0 0 20px 20px'};
`;

const PerkWrapper = styled.div`
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin: 4px 0px 4px 0px;
`;

const StyledCarousel = styled(Carousel)`
  width: 100%;

  .slick-dots li {
    margin: 0px;
    height: 10px;
    width: 10px;
  }
  .slick-dots li button:before {
    height: 10px;
    width: 10px;
  }
`;

const StyledImg = styled(Image)`
  margin-top: -2px;
`;

const MembershipCommunication = ({ borderRadiusPosition, className, id }) => {
  const checkoutCategory = useCheckoutCategory();
  const { isMobile } = useResponsiveVariant();
  const membershipPerksContentTitle = useSelector(getMembershipPerksTitle);
  const subscriptionDiscount = useAppSelector(getUserSubscriptionDiscount);

  const title = (
    <>
      <StyledTypography variant="p1">
        <PerksContentTitle
          discount={subscriptionDiscount}
          title={subscriptionPerksContent.title[checkoutCategory][membershipPerksContentTitle]}
          variant={membershipPerksContentTitle}
        />
      </StyledTypography>
      <Spacer size={4} />
      <Typography variant="p1">+</Typography>
      <Spacer size={4} />
    </>
  );
  const perks = subscriptionPerksContent.content.map(({ logo, text, alt, width, height }) => (
    <PerkWrapper key={text}>
      <StyledImg alt={alt} height={height} src={logo} width={width} />
      <Spacer axis="horizontal" size={12} />
      <Typography variant="p2">{text}</Typography>
    </PerkWrapper>
  ));

  return (
    <MembershipCommunicationContainer
      borderRadiusPosition={borderRadiusPosition}
      className={className}
      data-testid="cart-promo-block-message"
      id={id}
    >
      {isMobile ? (
        <>
          {title}
          {perks}
        </>
      ) : (
        <>
          {title}
          <StyledCarousel
            arrows={false}
            autoplay
            autoplaySpeed={3000}
            dots
            focusOnSelect
            infinite
            speed={500}
          >
            {perks}
          </StyledCarousel>
        </>
      )}
    </MembershipCommunicationContainer>
  );
};

MembershipCommunication.propTypes = {
  className: PropTypes.string,
  borderRadiusPosition: PropTypes.oneOf(['top', 'bottom']),
  id: PropTypes.string,
};

MembershipCommunication.defaultProps = {
  className: '',
  borderRadiusPosition: 'top',
  id: null,
};

export default MembershipCommunication;
