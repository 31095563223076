import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useLocation } from 'react-router';

import { connect } from 'react-redux';

import * as trackingActions from 'dux/tracking/actions';

// renders a Route that matches on every url
const PageTracker = ({ trackPageVisit }) => {
  const { pathname } = useLocation();
  useEffect(() => {
    // Analytics Tracking
    trackPageVisit(pathname);
  }, [pathname]);
  return null;
};

PageTracker.propTypes = {
  trackPageVisit: PropTypes.func.isRequired,
};

export default connect(null, { trackPageVisit: trackingActions.trackPageVisit })(PageTracker);
