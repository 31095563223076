// 'alart' and 'aleid' are used by AppLovin
const otherParamsToForward = ['nbt', 'nb_placement', 'alart', 'aleid'];

export const forwardUTMIfNeeded = (currentURL: string | URL, redirectionURL = '/') => {
  const sourceURL = new URL(currentURL);
  /**
   * For security reasons, we want to ensure navigation is restricted to the Prose website, excluding any external URLs.
   * If the redirectionURL is absolute, the second argument is disregarded.
   * If the redirectionURL is relative, the second argument is concatenated with it.
   */
  const targetURL = new URL(redirectionURL, sourceURL.origin);
  const hasUTM = Array.from(targetURL.searchParams.keys()).some((key) => key.startsWith('utm_'));

  if (!hasUTM) {
    sourceURL.searchParams.forEach((value, key) => {
      if (key.startsWith('utm_') || otherParamsToForward.indexOf(key) !== -1) {
        targetURL.searchParams.append(key, value);
      }
    });
  }

  return targetURL;
};

export const toRelativeURL = (absoluteURL: URL) =>
  absoluteURL.pathname + absoluteURL.search + absoluteURL.hash;
