import { useState } from 'react';

import { Link } from 'react-router';

import { useAppDispatch, useAppSelector } from 'dux/app/hooks';

import { Box, Modal, theme } from '@prose-ui';
import { legacyTheme, styled } from '@prose-ui/legacy';
import { useTrialOfferPromotionModalContent } from 'hooks/useRedeemCouponForTrialOffer';

import { Image } from 'Components/Image';

import { type Currency } from 'constants/currencies';

import trialOfferClawClipperGWPMixedWithSkincareMinisModalContent from 'assets/content/promotions/trialOfferClawClipperGWPMixedWithSkincareMinisModal';
import trialOfferGWPMixedWithSkincareMinisModalContent from 'assets/content/promotions/trialOfferGWPMixedWithSkincareMinisModal';
import trialOfferHeatlessCurlerGWPMixedWithSkincareMinisModalContent from 'assets/content/promotions/trialOfferHeatlessCurlerGWPMixedWithSkincareMinisModal';
import trialOfferMixedWithSkincareMinisModalContent from 'assets/content/promotions/trialOfferMixedWithSkincareMinisModal';
import trialOfferPetitToteGWPMixedWithSkincareMinisModalContent from 'assets/content/promotions/trialOfferPetitToteGWPMixedWithSkincareMinisModal';
import trialOfferToiletryBagGWPMixedWithSkincareMinisModalContent from 'assets/content/promotions/trialOfferToiletryBagGWPMixedWithSkincareMinisModal';
import trialOfferWideToothCombGWPMixedWithSkincareMinisModalContent from 'assets/content/promotions/trialOfferWideToothCombGWPMixedWithSkincareMinisModal';
import { ReactComponent as Cross } from 'assets/images/cross_icon.svg';

import useResponsiveVariant from 'utils/useResponsiveVariant';

import { trackHeapEvent } from 'dux/tracking/actions';
import {
  trialOfferClawClipperGwp,
  trialOfferEvergreen,
  trialOfferHeatlessCurlSetGwp,
  trialOfferPetitToteGwp,
  trialOfferToiletryBagGwp,
  trialOfferWideToothCombGwp,
} from 'dux/featureFlags/selectors';
import {
  getHasCompletedHaircareConsultation,
  getHasCompletedSkincareConsultation,
  getUserCurrency,
} from 'dux/user/selectors';

import { Button } from './LegacyButton';
import { Typography } from './Typography';

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px 5px 17px;

  background-color: ${theme.colors.tertiary[200]};

  text-transform: uppercase;
`;

const CloseModalButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  margin-top: -4px;
  padding: 0;

  appearance: none;

  background: none;
  border: none;

  cursor: pointer;
`;

const StyledModal = styled(Modal)`
  min-width: 330px;
  padding: 0;

  ${legacyTheme.breakpoints.up('sm')} {
    max-width: 550px;
  }
`;

const Content = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  background-color: ${theme.colors.neutral[200]};
`;

const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
`;

const HeaderLabel = styled(Typography)`
  font-size: 14px;
  font-weight: 600;
`;

const Description = styled(Typography)`
  font-size: 14px;
  color: ${theme.colors.primary[400]};

  ${legacyTheme.breakpoints.up('sm')} {
    max-width: 365px;
    font-size: 16px;
  }
`;

const Conditions = styled(Typography)`
  padding: 0 8px;

  font-size: 10px;
`;

const trialOfferVariantContent = {
  [trialOfferToiletryBagGwp]: trialOfferToiletryBagGWPMixedWithSkincareMinisModalContent,
  [trialOfferPetitToteGwp]: trialOfferPetitToteGWPMixedWithSkincareMinisModalContent,
  [trialOfferWideToothCombGwp]: trialOfferWideToothCombGWPMixedWithSkincareMinisModalContent,
  [trialOfferClawClipperGwp]: trialOfferClawClipperGWPMixedWithSkincareMinisModalContent,
  [trialOfferHeatlessCurlSetGwp]: trialOfferHeatlessCurlerGWPMixedWithSkincareMinisModalContent,
};

type TrialOfferMixedWithSkincareMinisModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const TrialOfferMixedWithSkincareMinisModal = ({
  isOpen = false,
  onClose,
}: TrialOfferMixedWithSkincareMinisModalProps) => {
  const dispatch = useAppDispatch();
  const currency = useAppSelector(getUserCurrency) as Currency;
  const hasCompletedHaircareConsultation = useAppSelector(getHasCompletedHaircareConsultation);
  const hasCompletedSkincareConsultation = useAppSelector(getHasCompletedSkincareConsultation);
  const { isMobile } = useResponsiveVariant();
  const { variant } = useTrialOfferPromotionModalContent();

  const content =
    variant !== trialOfferEvergreen
      ? // @ts-expect-error useTrialOfferPromotionModalContent is not typed
        trialOfferVariantContent[variant] || trialOfferGWPMixedWithSkincareMinisModalContent
      : trialOfferMixedWithSkincareMinisModalContent;

  return (
    <StyledModal
      events={{
        onModalClose: () =>
          dispatch(trackHeapEvent(content.popUpModal[currency].tracking.modalClosing)),
        onModalOpen: () =>
          dispatch(trackHeapEvent(content.popUpModal[currency].tracking.modalOpening)),
        timer: {
          onModalOpenAfterTime: () =>
            dispatch(trackHeapEvent(content.popUpModal[currency].tracking.modalOpening2sec)),
        },
      }}
      hideClose
      isOpen={isOpen}
      onClose={onClose}
      zIndexValue={10000}
    >
      <Header>
        <HeaderLabel medium variant="mono1">
          {content.popUpModal[currency].headerTitle}
        </HeaderLabel>
        <CloseModalButton
          data-click="exit"
          data-from="skincare-minis-hc-trial-popup"
          data-testid="skincare-minis-close-modal"
          id="modal-close-button"
          onClick={onClose}
        >
          <Cross height="13" width="13" />
        </CloseModalButton>
      </Header>
      <Content>
        <StyledImage
          alt="list of skincare minis products"
          src={content.popUpModal[currency].image}
        />
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          padding={{ mobile: '4x', tablet: '8x', desktop: '8x' }}
        >
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            gap={{ mobile: '2x', tablet: '3x', desktop: '3x' }}
          >
            <Description align="center" id={isOpen ? 'modal-description' : ''} variant="p1">
              {content.popUpModal[currency].description[isMobile ? 'mobile' : 'desktop']}
            </Description>
          </Box>
          <Box paddingY={{ mobile: '5x', tablet: '6x', desktop: '6x' }}>
            <Button
              Component={Link}
              data-click="offer-cta"
              data-from="skincare-minis-hc-trial-popup"
              fullWidth
              noMargin
              onClick={onClose}
              to={
                hasCompletedHaircareConsultation && hasCompletedSkincareConsultation
                  ? '/account/reorder'
                  : '/consultation/category'
              }
              variant="vert"
            >
              {content.popUpModal[currency].cta.ctaLabel}
            </Button>
          </Box>

          <Conditions align="center" id={isOpen ? 'modal-conditions' : ''} variant="p3">
            {content.popUpModal[currency].conditions}
          </Conditions>
        </Box>
      </Content>
    </StyledModal>
  );
};

type TrialOfferMixedWithSkincareMinisModalWithStateLogicProps = {
  isOpen: boolean;
};

export const TrialOfferMixedWithSkincareMinisModalWithStateLogic = ({
  isOpen,
}: TrialOfferMixedWithSkincareMinisModalWithStateLogicProps) => {
  const [open, setOpen] = useState(isOpen);
  const onClose = () => setOpen(false);

  return <TrialOfferMixedWithSkincareMinisModal isOpen={open} onClose={onClose} />;
};
