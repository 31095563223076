import { createSlice } from '@reduxjs/toolkit';

import { userSignout } from 'dux/auth/actions';
import { CLEAR_CHECKOUT } from 'dux/checkout/actionTypes';

import { loadOrder } from './thunks';

const initialState = {
  status: 'idle',
  data: null,
  error: null,
};

const postPurchaseOrderSlice = createSlice({
  name: 'checkoutOrder',
  initialState,
  extraReducers: builder => {
    builder
      // reinitialize state upon signout
      .addCase(userSignout, () => initialState)

      .addCase(loadOrder.pending, draft => {
        draft.status = 'loading';
        draft.error = null;
      })
      .addCase(loadOrder.fulfilled, (draft, { payload }) => {
        draft.status = 'success';
        draft.data = payload.data;
      })
      .addCase(loadOrder.rejected, (draft, action) => {
        draft.status = 'error';
        draft.error = action.error;
      });

    // --- external duxes ---
    builder.addCase(CLEAR_CHECKOUT, () => initialState);
  },
});

export const { reducer } = postPurchaseOrderSlice;
