import { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';

import { useParams } from 'react-router';

import { styled } from '@prose-ui/legacy';

import { Button } from 'Components/LegacyButton';
import { Typography } from 'Components/Typography';

import * as APIClient from 'Services/APIClient';

import { LegacyBaseScene as BaseScene } from 'Scenes/LegacyBaseScene';
import LoadingScene from 'Scenes/LoadingScene';
import NotFound from 'Scenes/NotFound';

import logSentryError from 'utils/logSentry';

const FixedWidthLegacyButton = styled(Button)`
  width: 275px;
`;

const PaddedTypography = styled(Typography)`
  padding: 0px 10px;
`;

const Leaflet = ({ footer, header }) => {
  const { orderBoxPubKey } = useParams();
  const [pdf, setPdf] = useState(null);
  const [pdfError, setPdfError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getPdf = async () => {
      setIsLoading(true);
      const url = `v2/leaflets/${orderBoxPubKey}/`;
      try {
        const response = await APIClient.getFile(url);
        const pdfLink = await response.display('leaflet.pdf');
        setPdf(pdfLink);
      } catch (error) {
        setPdfError(true);
        logSentryError('[Leaflets] getPdf', error);
      }
      setIsLoading(false);
    };
    getPdf();
  }, []);

  if (isLoading) return <LoadingScene />;
  if (pdfError) return <NotFound />;

  return (
    <BaseScene background="beige" footer={footer} header={header}>
      <PaddedTypography align="center" color="noir" paragraph variant="h1">
        Your Prose ingredients and instructions
      </PaddedTypography>
      <a
        data-testid="link-view-leaflet"
        download="leaflet.pdf"
        href={pdf}
        rel="noreferrer"
        target="_blank"
      >
        <FixedWidthLegacyButton data-testid="button-view-leaflet" variant="vert">
          SEE YOUR LEAFLET
        </FixedWidthLegacyButton>
      </a>
    </BaseScene>
  );
};

Leaflet.propTypes = {
  footer: PropTypes.bool,
  header: PropTypes.bool,
};

Leaflet.defaultProps = {
  footer: true,
  header: true,
};

export default Leaflet;
