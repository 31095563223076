import { Navigate, useParams } from 'react-router';

import { isInvalidOrderFilter } from 'Services/HTTPError';

import ErrorContainer from 'Containers/ErrorContainer';

import LoadingScene from 'Scenes/LoadingScene';

import useGetApi from 'utils/useGetApi';

// Our customers can scan a QR on their product an be redirected to this component.
// The QR refers to a proditem pubkey

const ProdItemLookUp = () => {
  const { productionItemPubkey } = useParams();
  const [{ isLoading, data = [], error }, callApi] = useGetApi(
    `/v1/digital_leaflet/item/${productionItemPubkey}`
  );

  if (error) {
    if (isInvalidOrderFilter(error)) {
      return <Navigate replace to="/account/history" />;
    }

    return (
      <ErrorContainer
        error={error}
        retry={callApi}
        sentryMessage="Unhandled API error proditem lookup"
      />
    );
  }

  if (isLoading) {
    return <LoadingScene />;
  }

  const foundOrderPubkey = data?.order.pubkey;

  return (
    <Navigate
      replace
      to={foundOrderPubkey ? `/digital-leaflet/${foundOrderPubkey}` : '/account/history'}
    />
  );
};

ProdItemLookUp.propTypes = {};

export default ProdItemLookUp;
