import { combineReducers } from '@reduxjs/toolkit';

import { addresses } from 'dux/checkoutAddresses/reducers';
import { addressValidation } from 'dux/checkoutAddressesValidation/reducers';
import cart from 'dux/checkoutCart/reducers';
import { payment, paypal, stripe } from 'dux/checkoutPayment/slice';

export default combineReducers({
  addressValidation,
  addresses,
  cart,
  payment,
  paypal,
  stripe,
});
