import { appApi } from 'dux/app/api';

type KustomerToken = {
  token: string;
};

const kustomerApi = appApi.injectEndpoints({
  endpoints: (build) => ({
    getKustomerLivechatToken: build.query<KustomerToken, void>({
      query: () => ({ url: 'v1/kustomer/token/', method: 'POST' }),
      providesTags: ['support'],
    }),
  }),
});

export const { useGetKustomerLivechatTokenQuery } = kustomerApi;
