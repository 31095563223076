import { useState } from 'react';

import { Link, matchPath, useLocation } from 'react-router';

import { useAppSelector } from 'dux/app/hooks';

import { theme } from '@prose-ui';
import { legacyTheme, styled } from '@prose-ui/legacy';
import type { Entries } from 'type-fest';

import { BottomModal } from 'Components/BottomModal';

import { haircareRoutes, skincareRoutes } from 'constants/routes';

import { BaseBanner } from 'Blocks/Banner/BannerComponents';

import { handleKeyPress } from 'utils/a11y';

import { getUserProfile } from 'dux/user/selectors';
import { useGetPromotionsQuery } from 'dux/promotionsNew/apiSlice';
import type { Banners } from 'dux/promotionsNew/types';

const DataDrivenBanner = styled.div<{
  hasLink: boolean;
  backgroundColor: string;
  color: string;
  isUnderline?: boolean;
}>`
  ${legacyTheme.typography.p2};

  width: 100%;
  height: ${legacyTheme.props.bannerHeight};
  z-index: ${legacyTheme.zIndex.appBar};

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-left: inherit;

  transition: background 0.3s ease;
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};

  ${legacyTheme.breakpoints.up('md')} {
    justify-content: center;
    padding-left: unset;
  }

  ${legacyTheme.breakpoints.up('sm')} {
    justify-content: ${({ hasLink }) => (hasLink ? 'space-between' : 'center')};
    padding-left: ${({ hasLink }) => (hasLink ? '20px' : 'inherit')};
  }

  font-style: normal;
  text-decoration: ${({ isUnderline }) => (isUnderline ? 'underline' : 'none')};
  cursor: ${({ isUnderline }) => (isUnderline ? 'pointer' : 'default')};
`;

const StyledLinkedCTA = styled(Link)`
  color: ${theme.colors.neutral[100]};
  width: 100%;
`;

type Subsections =
  | 'general'
  | 'checkout-form'
  | 'cart'
  | 'checkout-success'
  | 'consultation'
  | 'supplements'
  | 'haircare'
  | 'skincare';

const supplementsProductRoute = '/products/custom-hair-supplements';
const sectionsToBanner: Record<Subsections, { type: Banners; paths: string[] }> = {
  general: {
    type: 'banner_general',
    paths: [''],
  },
  'checkout-form': {
    type: 'banner_checkout_form',
    paths: ['/checkout/:category/:step'],
  },
  cart: {
    type: 'banner_cart',
    paths: ['/checkout/:category'],
  },
  'checkout-success': {
    type: 'banner_success_page',
    paths: ['/checkout/success'],
  },
  consultation: {
    type: 'banner_consultation',
    paths: ['/checkout/:category'],
  },
  supplements: {
    type: 'banner_supplements',
    paths: [supplementsProductRoute],
  },
  haircare: {
    type: 'banner_haircare',
    paths: [...haircareRoutes.filter((route) => route !== supplementsProductRoute)],
  },
  skincare: {
    type: 'banner_skincare',
    paths: [...skincareRoutes],
  },
} as const;

// this will be reused for other promotion types (popup etc)
const getSubsection = (pathname: string): Subsections => {
  // From the subsectionToBanner object, get all the entries (key and value pairs)
  const subsectionToBannerEntries = Object.entries(sectionsToBanner) as Entries<
    typeof sectionsToBanner
  >;

  // Find the first entry where the value matches the pathname, default to 'general' if not found
  const [subsection] = subsectionToBannerEntries.find(([_, banner]) =>
    banner.paths.some((subsectionPath) => matchPath({ path: subsectionPath }, pathname)),
  ) ?? ['general', ''];

  return subsection;
};

const RenderDataDrivenBanner = () => {
  const [isBottomModalOpen, setIsBottomModalOpen] = useState(false);
  const { pathname } = useLocation();

  const subsection = getSubsection(pathname);
  const bannerType = sectionsToBanner[subsection].type;

  const userProfile = useAppSelector(getUserProfile);

  const { data: promotionData, isLoading, isSuccess, error } = useGetPromotionsQuery(userProfile); // passing a state as an argument to a query is a strategy to auto-refetch it if the state changes

  if (isLoading) {
    return (
      <BaseBanner
        backgroundColor={theme.colors.neutral[100]}
        data-testid="loading-banner"
        event="Data Driven Banner - Loading"
      />
    );
  }

  if (!isSuccess || error) return null;

  const banner = promotionData[bannerType] ?? promotionData.banner_general;

  if (!banner) {
    return null;
  }

  const configurationDisplay = banner.configuration_display;
  const link = banner.configuration_display.link;

  return (
    <>
      {banner.configuration_display['bottom-modal'] && (
        <BottomModal
          backgroundColor={theme.colors.tertiary[200]}
          dataFrom="membership-modal"
          htmlContent={banner.configuration_display['bottom-modal']}
          isOpen={isBottomModalOpen}
          onClose={() => setIsBottomModalOpen(false)}
        />
      )}
      <DataDrivenBanner
        aria-label={configurationDisplay['aria-label']}
        backgroundColor={configurationDisplay['background-color']}
        color={configurationDisplay.color}
        data-click={configurationDisplay['data-click']}
        data-from={configurationDisplay['data-from']}
        hasLink={Boolean(link?.url)}
        isUnderline={Boolean(configurationDisplay['is-underlined'])}
        onClick={() => setIsBottomModalOpen(true)}
        onKeyPress={handleKeyPress(() => setIsBottomModalOpen(true))}
        role="button"
        tabIndex={0}
      >
        {link?.url ? (
          <StyledLinkedCTA
            dangerouslySetInnerHTML={{
              __html: configurationDisplay.text,
            }}
            to={link.url}
          />
        ) : (
          <p
            dangerouslySetInnerHTML={{
              __html: configurationDisplay.text,
            }}
          />
        )}
      </DataDrivenBanner>
    </>
  );
};

// Temporary, will be removed once the dd banners are fully implemented
export const dataDrivenBanner = {
  shouldRender: ({ showDataDrivenPromotions }: { showDataDrivenPromotions: boolean }) =>
    showDataDrivenPromotions,
  render: () => <RenderDataDrivenBanner />,
};
