import { createSlice } from '@reduxjs/toolkit';

import * as paymentMethodsStatuses from 'constants/paymentMethodsStatuses';
import * as statuses from 'constants/statuses';

import { userSignout } from 'dux/auth/thunks';
import { completeHairProfile, saveAnswer } from 'dux/consultation/thunks';
import { loadOrder } from 'dux/postPurchaseOrder/thunks';
import { create as createSubscription, updateSubscription } from 'dux/subscriptions/thunks';

import * as recommendationsStatus from './constants/recommendationsStatus';
import initialState from './initialState';
import {
  createAddress,
  createPaymentMethod,
  deletePayPalPaymentMethods,
  fetchAddresses,
  fetchAllPaymentMethods,
  fetchPaymentMethods,
  fetchPayPalPaymentMethods,
  fetchRecommendations,
  fetchSupplementsVariant,
  fetchUser,
  patchUser,
  setDefaultPaymentMethod,
} from './thunks';

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setReferral: (draft, { payload }) => {
      draft.referral = payload || null;
    },
    clearReferral: draft => {
      draft.referral = null;
    },
    openRootToast: (draft, { payload = {} }) => {
      const { content, style, isPermanent } = payload;
      draft.rootToast = {
        shouldDisplayToast: true,
        content,
        style,
        isPermanent,
      };
    },
    closeRootToast: draft => {
      if (draft.rootToast) {
        draft.rootToast.shouldDisplayToast = false;
      }
    },
    setCustomerOrigin: (draft, { payload }) => {
      if (draft.user) {
        draft.user.customer_origin = payload.customer_origin;
      }
    },
    setUserCountry: (draft, { payload }) => {
      draft.geolocationGuessedCountry = payload;
    },
    setUserRegion: (draft, { payload }) => {
      draft.geolocationGuessedRegion = payload;
    },
    seenPromotionalModal: () => {
      // TODO: use redux-persist?
    },
  },
  extraReducers: builder => {
    builder
      // reset state upon user signout
      .addCase(userSignout, () => initialState())

      .addCase(fetchUser.pending, draft => {
        draft.error = null;
        draft.isLoading = true;
      })
      .addCase(fetchUser.fulfilled, (draft, { payload }) => {
        draft.error = null;
        draft.isLoading = false;
        draft.user = payload;
      })
      .addCase(fetchUser.rejected, (draft, { error }) => {
        draft.error = error;
        draft.isLoading = false;
      })

      .addCase(patchUser.pending, draft => {
        draft.error = null;
        draft.isLoading = true;
      })
      .addCase(patchUser.fulfilled, (draft, { payload }) => {
        draft.error = null;
        draft.isLoading = false;
        draft.user = payload;
      })
      .addCase(patchUser.rejected, (draft, { error }) => {
        draft.error = error;
        draft.isLoading = false;
      })

      .addCase(fetchAddresses.pending, draft => {
        draft.error = null;
        draft.isLoading = true;
        draft.addressesStatus = statuses.LOADING;
        draft.addressesError = null;
      })
      .addCase(fetchAddresses.fulfilled, (draft, { payload }) => {
        draft.error = null;
        draft.isLoading = false;
        draft.addresses = [...payload].sort((x, y) =>
          x.primary === y.primary ? 0 : x.primary ? -1 : 1
        );
        draft.addressesStatus = statuses.SUCCESS;
      })
      .addCase(fetchAddresses.rejected, (draft, { error }) => {
        draft.error = { code: 'unhandled' };
        draft.isLoading = false;
        draft.addressesStatus = statuses.ERROR;
        draft.addressesError = error;
      })

      .addCase(createAddress.fulfilled, (draft, { payload }) => {
        draft.addresses.push(payload);
      })

      .addCase(fetchPaymentMethods.pending, draft => {
        draft.paymentMethodsStatus = paymentMethodsStatuses.LOADING;
      })
      .addCase(fetchPaymentMethods.fulfilled, (draft, { payload }) => {
        draft.paymentMethodsStatus = paymentMethodsStatuses.SUCCESS;
        draft.paymentMethods = payload;
      })
      .addCase(fetchPaymentMethods.rejected, (draft, { error }) => {
        draft.paymentMethodsError = error;
        draft.paymentMethodsStatus = paymentMethodsStatuses.ERROR;
      })

      .addCase(createPaymentMethod.fulfilled, (draft, { payload }) => {
        draft.error = false;
        draft.isLoading = false;
        if (!draft.paymentMethods.find(pm => pm.id === payload.id)) {
          draft.paymentMethods.push(payload);
        }
      })

      .addCase(fetchPayPalPaymentMethods.pending, draft => {
        draft.paypalPaymentMethodsStatus = paymentMethodsStatuses.LOADING;
      })
      .addCase(fetchPayPalPaymentMethods.fulfilled, (draft, { payload }) => {
        draft.paypalPaymentMethodsStatus = paymentMethodsStatuses.SUCCESS;
        draft.paypalPaymentMethods = payload;
      })
      .addCase(fetchPayPalPaymentMethods.rejected, (draft, { error }) => {
        draft.paypalPaymentMethodsSError = error;
        draft.paypalPaymentMethodsStatus = paymentMethodsStatuses.ERROR;
      })
      .addCase(
        deletePayPalPaymentMethods.pending /* only pending handled: relies on fetchPayPalPaymentMethods being called subsequently */,
        draft => {
          draft.paypalPaymentMethodsStatus = paymentMethodsStatuses.LOADING;
        }
      )

      .addCase(fetchRecommendations.pending, draft => {
        draft.recommendationsError = null;
        draft.recommendationsStatus = recommendationsStatus.LOADING;
      })
      .addCase(fetchRecommendations.fulfilled, (draft, { payload }) => {
        draft.recommendations = payload;
        draft.recommendationsStatus = recommendationsStatus.SUCCESS;
      })
      .addCase(fetchRecommendations.rejected, (draft, { error }) => {
        draft.recommendationsError = error;
        draft.recommendationsStatus = recommendationsStatus.ERROR;
      })

      .addCase(fetchSupplementsVariant.pending, draft => {
        draft.supplementsVariant = null;
      })
      .addCase(fetchSupplementsVariant.fulfilled, (draft, { payload }) => {
        draft.supplementsVariant = payload;
      })
      .addCase(fetchSupplementsVariant.rejected, draft => {
        draft.supplementsVariant = null;
      })

      .addCase(setDefaultPaymentMethod.pending, draft => {
        draft.paymentMethodsStatus = paymentMethodsStatuses.LOADING;
      })
      .addCase(setDefaultPaymentMethod.fulfilled, draft => {
        draft.paymentMethodsStatus = paymentMethodsStatuses.SUCCESS;
      })
      .addCase(setDefaultPaymentMethod.rejected, (draft, { error }) => {
        draft.paymentMethodsError = error;
        draft.paymentMethodsStatus = paymentMethodsStatuses.ERROR;
      })

      // --- external duxes ---
      .addCase(loadOrder.fulfilled.type, (draft, action) => {
        if (action.isPayment) {
          draft.referralCode = null;
          draft.user.profile.has_order = true;
          draft.user.profile.has_order_with_formulas = true;
          draft.user.profile.order_count += 1;
        }
      })
      .addCase(createSubscription.fulfilled.type, (draft, action) => {
        draft.user.profile.has_active_subscription = action.payload.status === 'active';
      })
      .addCase(updateSubscription.fulfilled.type, (draft, action) => {
        draft.user.profile.has_active_subscription = action.payload.status === 'active';
      })
      .addCase(saveAnswer.fulfilled.type, (draft, action) => {
        if (action?.meta?.arg?.payload?.profile_zipcode) {
          draft.user.zipcode = action.meta.arg.payload.profile_zipcode;
        }
        if (
          action?.meta?.arg?.payload?.pref_fragrance ||
          action?.meta?.arg?.payload?.pref_fragrance_free
        ) {
          draft.user.profile.fragrance = action.meta.arg.payload.pref_fragrance_free
            ? 'FREE'
            : action.meta.arg.payload.pref_fragrance;
          draft.user.profile.is_fragrance_soldout = false;
        }
      })
      .addCase(completeHairProfile.fulfilled.type, draft => {
        draft.user.profile.has_answered_haircare_questions = true;
      })

      .addCase(fetchAllPaymentMethods.pending, draft => {
        draft.paymentMethodsStatus = paymentMethodsStatuses.LOADING;
      })
      .addCase(fetchAllPaymentMethods.fulfilled, (draft, { payload }) => {
        draft.paymentMethodsStatus = paymentMethodsStatuses.SUCCESS;
        draft.paymentMethods = payload;
      })
      .addCase(fetchAllPaymentMethods.rejected, (draft, { error }) => {
        draft.paymentMethodsError = error;
        draft.paymentMethodsStatus = paymentMethodsStatuses.ERROR;
      });
  },
});

export const { reducer, actions } = userSlice;
