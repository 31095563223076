import { useEffect, useRef, useState } from 'react';

import { omit } from 'remeda';

import debounce from 'lodash/debounce';

import { theme } from '@prose-ui';
import { styled } from '@prose-ui/legacy';
import { useCombobox } from 'downshift';

import { CHECKOUT_OPTIMISATION_THEME } from 'Apps/Checkout/constants/checkoutInputThemes';

import { TextField } from 'Components/TextField';

import * as AddressService from 'Services/AddressService';
import { isAbortError, isFetchError } from 'Services/HTTPError';

import logSentryError from 'utils/logSentry';

import { AutoCompleteItem } from './AutoCompleteItem';

const Wrapper = styled.div`
  position: relative;
`;

const AutoCompleteOptions = styled.ul<{ isOpen: boolean }>`
  position: absolute;
  left: 0;
  z-index: 1;
  width: 100%;
  max-height: 230;
  margin: 0;
  margin-top: -10px;
  padding: 0;

  background: ${theme.colors.neutral[100]};
  list-style: none;
  overflow-y: scroll;
  box-shadow: 0 4px 4px 0 rgb(0 0 0 / 15%);
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
`;

type PlacePrediction = {
  id: string;
  label: string;
  address1: string;
  matchedSubstrings: Array<{ offset: number; length: number }>;
};

type SelectedPlace = {
  address1: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
};

/**
 * AutoComplete used to pre-fill all address fields
 *
 * User input is the first address field.
 * If the options are not used, it still keeps user input on the field.
 *
 * The options are listed requiring a special network service (see Google cloudFunctions in prose-functions).
 * After an option is selected, we hit the network again to get the places details and be able to fill all address fields.
 *
 * AbortController is used to improve the UX, we cancel all previous requests to treat only the last request sent.
 *
 * To reduce the number of requests sent to Google Maps API:
 * - at least 3 characters containing digits AND alphas are necessary in the input
 * - a debounce of 100 ms from the last keyup is set
 */

type AddressAutoCompleteProps = {
  // Metadata for screen readers
  ariaInvalid?: boolean;
  ariaRequired?: boolean;
  // Callback when an address is selected
  onAddressAutoComplete: (selectedPlace: SelectedPlace) => void;
  // Callback to be called when the input value changes
  onChange: (value: string) => void;
  // test id for cypress
  testId?: string;
  // The props to be passed directly to the TextField component
  textFieldProps: {
    id: string;
    label: string;
    onBlur: (e: React.FocusEventHandler<HTMLInputElement>) => void;
    error?: string;
    className?: string;
    autoCorrect: boolean;
  };
  // The value of the input field
  value: string;
  selectedCountry?: string;
  theme?: typeof CHECKOUT_OPTIMISATION_THEME;
};

export const AddressAutoComplete = ({
  selectedCountry,
  onAddressAutoComplete,
  onChange,
  testId = '',
  textFieldProps,
  value,
  theme: variant,
  ariaInvalid = false,
  ariaRequired = false,
}: AddressAutoCompleteProps) => {
  const [isInDelayAfterClosing, setIsInDelayAfterClosing] = useState(false);

  /** AbortController implem taken from
   * https://medium.com/codex/resilient-fetch-requests-in-javascript-with-abortcontroller-a-guide-with-react-examples-573dba8a3758
   */
  const abortFetchPlaces = useRef<AbortController | null>(null);
  const [places, setPlaces] = useState<PlacePrediction[]>([]);

  useEffect(() => {
    // UX improvement: treat only the last request sent
    return () => {
      abortFetchPlaces.current?.abort();
    };
  }, []);

  const handleInputChange = async (val: string) => {
    try {
      // To improve the UX, we cancel all previous requests to treat only the last request sent
      abortFetchPlaces.current?.abort();

      const fetchPlacesController = new AbortController();
      abortFetchPlaces.current = fetchPlacesController;

      const placesResult = val
        ? await AddressService.fetchPlaces(val, selectedCountry, {
            signal: fetchPlacesController.signal,
          })
        : [];

      setPlaces(placesResult);
    } catch (error) {
      if (!(isFetchError(error) || isAbortError(error))) {
        // Report unpredicted errors
        logSentryError('[AddressAutoComplete] handleInputChange', error, { logLevel: 'info' });
      }
    }
  };

  const debouncedInputChangeHandler = debounce(async (args) => {
    const inputVal = args.toString();

    /**
     * To reduce the number of requests sent to Google Maps API:
     * - at least 3 characters containing digits AND alphas
     * - debounce of 100 ms from the last keyup
     */
    if (inputVal?.length > 2 && Boolean(inputVal.match(/[0-9]+.*[A-Za-z]+.*|[A-Za-z]+.[0-9].*/g))) {
      await handleInputChange(inputVal);
    } else {
      setPlaces([]);
    }
  }, 250);

  const handlePlaceClick = async (place: PlacePrediction) => {
    try {
      setIsInDelayAfterClosing(true);

      const address = (await AddressService.fetchSelectedPlace(place.id)) as SelectedPlace;

      onAddressAutoComplete(address);
      setIsInDelayAfterClosing(false);
    } catch (error) {
      if (!isFetchError(error)) {
        // Report unpredicted errors
        setIsInDelayAfterClosing(false);
        logSentryError('[AddressAutoComplete] handlePlaceClick', error, { logLevel: 'info' });
      }
    }
  };

  const {
    isOpen,
    selectedItem,
    closeMenu,
    getMenuProps,
    getInputProps,
    getItemProps,
    highlightedIndex,
  } = useCombobox({
    inputValue: value,
    async onInputValueChange({ inputValue }) {
      onChange(inputValue);
      await debouncedInputChangeHandler(inputValue);
    },
    items: places,
    itemToString(item) {
      return item ? item.address1 : '';
    },
    async onSelectedItemChange({ selectedItem: newSelectedItem }) {
      await handlePlaceClick(newSelectedItem);
    },
  });

  /* TODO: Take some time to access if it would be a good idea to set on the internal label element the result of getLabelProps() */
  const inputProps = omit(getInputProps(), ['aria-labelledby']);
  return (
    <Wrapper>
      <TextField
        {...inputProps}
        {...textFieldProps}
        aria-invalid={ariaInvalid ? 'true' : 'false'}
        aria-required={ariaRequired ? 'true' : 'false'}
        data-testid={testId}
        onBlur={closeMenu}
        theme={variant}
      />
      <AutoCompleteOptions {...getMenuProps()} isOpen={isOpen || isInDelayAfterClosing}>
        {places.map((suggestion, i) => (
          <AutoCompleteItem
            key={suggestion.id}
            dataTestId={`address-suggestion-${i}`}
            getItemProps={getItemProps}
            isHighlighted={i === highlightedIndex}
            isSelectedItem={selectedItem?.id === suggestion.id}
            item={suggestion}
          />
        ))}
      </AutoCompleteOptions>
    </Wrapper>
  );
};
