import { createABTestHook } from 'abTesting/hooks';
import { HomepageEmailCapturePopUpExperiment } from 'abTesting/testConfigs';

import {
  getActiveOrVariantForHomepageEmailCapturePopUpAbTest,
  isHomepageEmailCapturePopUpAbTestInExperiment,
} from 'dux/featureFlags/selectors';

export const useHomepageEmailCapturePopUpAbTest = createABTestHook({
  isInExperimentSelector: isHomepageEmailCapturePopUpAbTestInExperiment,
  variantNameSelector: getActiveOrVariantForHomepageEmailCapturePopUpAbTest,
  experimentConfig: HomepageEmailCapturePopUpExperiment,
});
