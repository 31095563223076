import { createSelector } from '@reduxjs/toolkit';

import filter from 'lodash/fp/filter';
import find from 'lodash/fp/find';
import flow from 'lodash/fp/flow';
import get from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import isEqual from 'lodash/fp/isEqual';
import map from 'lodash/fp/map';
import omit from 'lodash/fp/omit';
import orderBy from 'lodash/fp/orderBy';
import pick from 'lodash/fp/pick';
import pipe from 'lodash/fp/pipe';
import sortBy from 'lodash/fp/sortBy';
import without from 'lodash/fp/without';

import currencies from 'constants/currencies';
import {
  products,
  productsCategories,
  productsSlugs,
  productsSubCategories,
  skincareMinisSlugs,
  skincareProducts,
} from 'constants/products';
import * as Status from 'constants/statuses';

import { brush, candle, digital_gift, hair_towel_wrap } from 'assets/content/accessoriesProducts';
import { brush_boar, brush_detangling, brush_mixed } from 'assets/content/brushes';
import {
  conditioner,
  curl_cream,
  dry_shampoo,
  hair_density_set,
  hair_mask,
  leavein,
  leavein_conditioner,
  scalp_mask,
  scalp_serum,
  shampoo,
  styling_gel,
  supplement_core,
  supplements_pouch,
} from 'assets/content/haircareProducts';
import { brushesPageContent } from 'assets/content/productPages/brushes';
import { candlePageContent } from 'assets/content/productPages/candle';
import { cleanserContent } from 'assets/content/productPages/cleanser';
import { conditionerPageContent } from 'assets/content/productPages/conditioner';
import { curlCreamPageContent } from 'assets/content/productPages/curl_cream';
import { dryShampooPageContent } from 'assets/content/productPages/dry_shampoo';
import { hairMaskPageContent } from 'assets/content/productPages/hair_mask';
import { hairTowelPageContent } from 'assets/content/productPages/hairTowel';
import { leaveinPageContent } from 'assets/content/productPages/leavein';
import { leaveInConditionerPageContent } from 'assets/content/productPages/leavein_conditioner';
import { moisturizerPageContent } from 'assets/content/productPages/moisturizer';
import { sampleFormulationCards } from 'assets/content/productPages/sampleFormulationCard';
import { scalpMaskPageContent } from 'assets/content/productPages/scalp_mask';
import { serumPageContent } from 'assets/content/productPages/serum';
import { shampooPageContent } from 'assets/content/productPages/shampoo';
import { sharedProductPageContent } from 'assets/content/productPages/shared';
import { stylingGelPageContent } from 'assets/content/productPages/styling_gel';
import {
  cleanser,
  cleanser_mini,
  moisturizer,
  moisturizer_mini,
  serum,
  serum_mini,
} from 'assets/content/skincareProducts';
import { trial_conditioner, trial_shampoo } from 'assets/content/trialKits';
import completeSetPicture from 'assets/images/gift_complete.png';
import basicSetPicture from 'assets/images/gift_essentials.png';
import giftCardAsset from 'assets/images/gift-card.png';

import { getHairProfileProductsVariantsList } from 'dux/consultation/selectors';
import { shouldShowScalpSerumContent } from 'dux/featureFlags/selectors';

//-----------------------------------------------------------
// From the api
//-----------------------------------------------------------

/* root states */
const getState = state => state?.products;
export const getFetchedProducts = createSelector(getState, get('fetchedProducts'));
export const getGiftsSetsFromAPI = createSelector(getState, get('giftsSets'));
export const getGiftsSetsFromAPIWithOrderedPrice = createSelector(getGiftsSetsFromAPI, giftSet => ({
  ...giftSet,
  variants: flow(get('variants'), orderBy(['price'], ['asc']))(giftSet),
}));

export const getLowestGiftSetPrice = createSelector(
  getGiftsSetsFromAPI,
  flow(get('variants'), orderBy(['price'], ['asc']), getOr(null, '[0]price'))
);

export const getCategoryByProductSlug = createSelector(
  getFetchedProducts,
  (_state, { productSlug }) => productSlug,
  (fetchedProducts, productSlug) =>
    fetchedProducts?.find(({ type }) => type === productSlug)?.category
);

/* status */
export const getProductsStatus = createSelector(getState, get('productsStatus'));
const getIsIdle = createSelector(getProductsStatus, isEqual(Status.IDLE));
const getIsLoading = createSelector(getProductsStatus, isEqual(Status.LOADING));
const getIsSuccess = createSelector(getProductsStatus, isEqual(Status.SUCCESS));
const getIsError = createSelector(getProductsStatus, isEqual(Status.ERROR));
export const getProductsStatusMap = createSelector(
  getIsIdle,
  getIsLoading,
  getIsSuccess,
  getIsError,
  (isIdle, isLoading, isSuccess, isError) => ({
    isIdle,
    isLoading,
    isSuccess,
    isError,
  })
);

/* prices */
const getPrice = ({ currency, type, price, subscribed_price }) => ({
  currency,
  slug: type,
  regularPrice: price,
  priceWithSubscription: subscribed_price,
});
export const getProductsPrices = createSelector(getFetchedProducts, fetchedProducts =>
  fetchedProducts.map(product => getPrice(product))
);

export const getSortedProductSlugs = createSelector(
  getFetchedProducts,
  (_, { rankType }) => rankType,
  (fetchedProducts, rankType) => {
    return orderBy(
      [product => product.ranks[rankType] ?? -1, 'type'],
      ['desc', 'asc']
    )(fetchedProducts).map(product => product.type);
  }
);

export const getFilteredFetchedHaircareProducts = createSelector(
  getFetchedProducts,
  fetchedProducts =>
    flow(
      filter(({ category, sub_category }) => {
        return (
          category === productsCategories.HAIRCARE &&
          (sub_category === productsSubCategories.SUPPLEMENTS ||
            sub_category === productsSubCategories.TOPICALS)
        );
      }),
      orderBy([getOr(-1, ['ranks', 'pdp']), 'type'], ['desc', 'asc'])
    )(fetchedProducts)
);

export const getFilteredFetchedAccessoriesProducts = createSelector(
  getFetchedProducts,
  flow(
    filter(
      ({ category, sub_category }) =>
        category === productsCategories.OTHERS ||
        (category === productsCategories.HAIRCARE && sub_category === productsSubCategories.TOOLS)
    ),
    orderBy([getOr(-1, ['ranks', 'pdp']), 'type'], ['desc', 'asc'])
  )
);

export const getFilteredFetchedSkincareProducts = createSelector(
  getFetchedProducts,
  fetchedProducts =>
    flow(
      filter(({ category }) => category === productsCategories.SKINCARE),
      orderBy([getOr(-1, ['ranks', 'pdp']), 'type'], ['desc', 'asc'])
    )(fetchedProducts)
);

export const getProductPricesContentFromAPI = createSelector(
  getFetchedProducts,
  (_state, { productSlug }) => productSlug,
  (fetchedProducts, productSlug) => find(({ type }) => type === productSlug)(fetchedProducts)
);

export const getFilteredFetchedHaircareProductsIsLoaded = createSelector(
  getFilteredFetchedHaircareProducts,
  filteredFetchedProducts => Boolean(filteredFetchedProducts?.length)
);

export const getFilteredFetchedSkincareProductsIsLoaded = createSelector(
  getFilteredFetchedSkincareProducts,
  filteredFetchedProducts => Boolean(filteredFetchedProducts?.length)
);

export const getFetchedHaircareProductsMergedWithLocalProductsList = createSelector(
  getFilteredFetchedHaircareProducts,
  filteredFetchedProducts =>
    filteredFetchedProducts.map(product => ({
      ...find({ slug: product.type })([
        conditioner,
        curl_cream,
        dry_shampoo,
        hair_mask,
        leavein,
        leavein_conditioner,
        scalp_mask,
        scalp_serum,
        shampoo,
        supplement_core,
        supplements_pouch,
        styling_gel,
      ]),
      category: product.category,
      currency: product.currency,
      slug: product.type,
      regularPrice: product.price,
      priceWithSubscription: product.subscribed_price,
      isSubscribable: product.is_subscribable,
    }))
);

export const getFetchedSkincareProductsMergedWithLocalProductsList = createSelector(
  getFilteredFetchedSkincareProducts,
  filteredFetchedProducts =>
    filteredFetchedProducts.map(product => ({
      ...find({ slug: product.type })([cleanser, serum, moisturizer]),
      currency: product.currency,
      category: product.category,
      dataClickOnCollection: 'clicked',
      slug: product.type,
      regularPrice: product.price,
      priceWithSubscription: product.subscribed_price,
      isSubscribable: product.is_subscribable,
    }))
);

export const getFetchedAccessoriesProductsMergedWithLocalProductsList = createSelector(
  getFilteredFetchedAccessoriesProducts,
  filteredFetchedProducts =>
    filteredFetchedProducts.map(product => ({
      ...find({ slug: product.type })([candle, hair_towel_wrap, brush]),
      currency: product.currency,
      category: product.category,
      slug: product.type,
      regularPrice: product.price,
      priceWithSubscription: product.price,
      isCustom: product.is_custom,
      isSubscribable: product.is_subscribable,
    }))
);

//-----------------------------------------------------------
// Content selectors
//-----------------------------------------------------------

const getProductPageContentBySlug = () => ({
  [productsSlugs.CANDLE]: candlePageContent,
  [productsSlugs.CONDITIONER]: conditionerPageContent,
  [productsSlugs.CURL_CREAM]: curlCreamPageContent,
  [productsSlugs.DRY_SHAMPOO]: dryShampooPageContent,
  [productsSlugs.OIL]: leaveinPageContent,
  [productsSlugs.LEAVEIN_CONDITIONER]: leaveInConditionerPageContent,
  [productsSlugs.HAIR_MASK]: hairMaskPageContent,
  [productsSlugs.SHAMPOO]: shampooPageContent,
  [productsSlugs.SCALP_MASK]: scalpMaskPageContent,
  [productsSlugs.STYLING_GEL]: stylingGelPageContent,
  [productsSlugs.CLEANSER]: cleanserContent,
  [productsSlugs.MOISTURIZER]: moisturizerPageContent,
  [productsSlugs.SERUM]: serumPageContent,
  [productsSlugs.HAIR_TOWEL_WRAP]: hairTowelPageContent,
  [productsSlugs.BRUSH]: brushesPageContent,
});

/* Use within ProductCollection & ProductsGrid */
export const getHaircareProductsBlockContent = createSelector(
  getFetchedHaircareProductsMergedWithLocalProductsList,
  mergedProducts => {
    const mergedProductsCopy = [...mergedProducts];
    const scalpSerumIndex = mergedProducts.findIndex(
      ({ slug }) => slug === productsSlugs.SCALP_SERUM
    );
    // Add hair density set after scalp serum on PLP
    if (scalpSerumIndex !== -1) {
      mergedProductsCopy.splice(scalpSerumIndex + 1, 0, hair_density_set);
    }
    return { cards: mergedProductsCopy };
  }
);

/* Use within ProductCollection & ProductsGrid */
export const getSkincareProductsBlockContent = createSelector(
  getFetchedSkincareProductsMergedWithLocalProductsList,
  mergedProducts => ({ cards: mergedProducts })
);

const getBrushContentMergedWithAPI = createSelector(getFetchedProducts, fetchedProducts => {
  const brushFromApi = find({ type: productsSlugs.BRUSH })(fetchedProducts);
  return {
    ...brush,
    ...brushFromApi,
    regularPrice: brushFromApi?.price,
  };
});

const getDigitalGiftContentMergedWithAPI = createSelector(
  getGiftsSetsFromAPI,
  getLowestGiftSetPrice,
  (giftSets, regularPrice) => {
    return {
      ...giftSets,
      ...digital_gift,
      currency: giftSets?.variants?.[0]?.currency || digital_gift.currency,
      regularPrice: regularPrice || digital_gift.price,
    };
  }
);

const getVariantBrushContent = createSelector(getBrushContentMergedWithAPI, content => {
  return orderBy(
    ['order'],
    ['asc']
  )(content.variants?.map(({ type }) => ({ ...content, ...content.variantsContent[type] })) || []);
});

/* Use within ProductCollection & ProductsGrid */
export const getAccessoriesProductsBlockContent = createSelector(
  getFetchedAccessoriesProductsMergedWithLocalProductsList,
  getVariantBrushContent,
  getDigitalGiftContentMergedWithAPI,
  (accessoriesList, brushContent, digitalGiftContent) => {
    const productContent = [
      ...accessoriesList.filter(({ slug }) => slug !== productsSlugs.BRUSH),
      ...brushContent,
      digitalGiftContent,
    ];

    return {
      cards: orderBy(['displayingOrderOnPLP'], ['asc'])(productContent),
    };
  }
);

/* XXX: This is a mess, and it needs a deep refactor */
const getProductsContentWithGiftSet = createSelector(
  shouldShowScalpSerumContent,
  showScalpSerum => ({
    brush_boar,
    brush_mixed,
    brush_detangling,
    curl_cream,
    dry_shampoo,
    hair_mask,
    hair_towel_wrap,
    scalp_mask,
    ...(showScalpSerum ? { scalp_serum } : {}),
    shampoo,
    conditioner,
    trial_conditioner,
    trial_shampoo,
    leavein,
    leavein_conditioner,
    candle,
    supplement_core,
    styling_gel,
    brush,
    cleanser,
    moisturizer,
    serum,
    cleanser_mini,
    serum_mini,
    moisturizer_mini,
  })
);

/* For account/subscription */
export const getSubscriptionProductsContent = createSelector(
  state => state,
  (_state, mustHaveJars) => mustHaveJars,
  (_state, mustHaveJars) => ({
    curl_cream,
    dry_shampoo,
    hair_mask,
    scalp_mask,
    shampoo,
    conditioner,
    trial_conditioner,
    trial_shampoo,
    leavein,
    leavein_conditioner,
    styling_gel,
    scalp_serum,
    supplement_core: mustHaveJars ? supplement_core : supplements_pouch,
    cleanser,
    moisturizer,
    serum,
  })
);

const giftImages = {
  'The Essentials': basicSetPicture,
  'The Complete Set': completeSetPicture,
  'Prose Gift Card': giftCardAsset,
};

export const getProductsContentWithGiftSetMergedWithFetchedProducts = createSelector(
  getProductsContentWithGiftSet,
  getFetchedHaircareProductsMergedWithLocalProductsList,
  getFetchedSkincareProductsMergedWithLocalProductsList,
  getFetchedAccessoriesProductsMergedWithLocalProductsList,
  (
    productsContentWithGiftSet,
    fetchedHairProducts,
    fetchedSkinProducts,
    fetchedAccessoriesProducts
  ) => {
    const mergedProducts = {};
    Object.keys(productsContentWithGiftSet).forEach(slug => {
      const fetchedProduct = find({ slug })([
        ...fetchedHairProducts,
        ...fetchedSkinProducts,
        ...fetchedAccessoriesProducts,
      ]);
      mergedProducts[slug] = {
        ...productsContentWithGiftSet?.[slug],
        ...fetchedProduct,
        currency: fetchedProduct?.currency || currencies.USD,
        category: fetchedProduct?.category || productsCategories.HAIRCARE,
      };
    });
    return mergedProducts;
  }
);

/* For account / history */
export const getProductContentForProduct = createSelector(
  getProductsContentWithGiftSet,
  productsContent => {
    /* WARN: add scalp_serum to make sure only order pages are impacted by the change for now
     * otherwise pages relying on getProductsContentWithGiftSet but not yet updated to support scalp_serum
     * will break (I'm thinking about you product carousel on PDP pages)
     */
    const orderPagesProductContent = { ...productsContent, scalp_serum };
    return product => {
      if (product?.variant?.product?.type === productsSlugs.DIGITAL_GIFT) {
        return {
          picture: giftCardAsset,
          label: product.item_object?.label,
        };
      }
      if (product?.variant?.product?.type === productsSlugs.GIFT_WITH_PURCHASE) {
        return {
          picture: product.item_object?.picture_name,
          label: product.item_object?.label,
        };
      }
      return product.variant.product.type === productsSlugs.BRUSH
        ? orderPagesProductContent[product.variant.type]
        : /* checks for supplements pouches */
        product.variant.type.endsWith('_pouch')
        ? supplements_pouch
        : orderPagesProductContent[product.variant.product.type];
    };
  }
);

/* used in  checkout, and feedback */
export const getProductImageForProduct = createSelector(
  getProductsContentWithGiftSet,
  getHairProfileProductsVariantsList,
  (productsContent, hairProfileProductsVariantsList) =>
    ({ product, noPumps, mustHaveJars = true }) => {
      if (product?.category === productsCategories.GIFT) {
        return giftImages[product.label];
      }

      /* Replace image for Supplement Pouches */
      if (product?.type === productsSlugs.SUPPLEMENT_CORE && !mustHaveJars) {
        return supplements_pouch.pictureWithCap ?? supplements_pouch.picture;
      }

      const content = productsContent[product.type];
      // This logic is for Accessories variant => Candles
      const variantContent = find(productContent => productContent.variantsContent?.[product.type])(
        productsContent
      )?.variantsContent?.[product.type];

      if (!content && !variantContent) {
        return null;
      }

      if (noPumps) {
        return content?.pictureWithCap || content?.picture || variantContent?.picture;
      }
      const productVariantName =
        content && hairProfileProductsVariantsList?.[content.slug]?.variant;

      // This logic is for the Xtra base conditioner
      if (productVariantName && content?.variants) {
        return content.variants[productVariantName]?.picture || content.picture;
      }

      return content?.picture || variantContent?.picture;
    }
);

/* For product details page. todo: get rid of giftset in it */
export const getHaircareProductsPageContent = createSelector(
  getProductsContentWithGiftSetMergedWithFetchedProducts,
  getProductPageContentBySlug,
  (_state, { productSlug }) => productSlug,
  (productsContent, productPageContentBySlug, productSlug) => ({
    ...pick(['faqModule'])(sharedProductPageContent),
    ...omit('imageCarousel')(productPageContentBySlug[productSlug]),
    heroModule: {
      imageCarousel: productPageContentBySlug[productSlug].imageCarousel,
      product: productsContent[productSlug],
      ...pick(['guarantee'])(sharedProductPageContent),
      ...pick(['waitingListNotice'])(sharedProductPageContent),
    },
    noListModule: {
      ...sharedProductPageContent.noListModule,
      ...pick(['description', 'list', 'title'])(productPageContentBySlug[productSlug].noListModule),
    },
    productsModule: {
      headline: sharedProductPageContent.productsModule.headline,
      cards: Object.keys(productsContent)
        .filter(
          key => products.includes(key) && !skincareProducts.includes(key) && key !== productSlug
        )
        .map(key => productsContent[key]),
    },
    faq: productPageContentBySlug[productSlug].faq,
  })
);

export const getSocialImpactProductsCarouselContent = createSelector(
  getProductsContentWithGiftSetMergedWithFetchedProducts,
  productsContent =>
    flow(
      filter(key => products.includes(key) && !skincareProducts.includes(key)),
      map(key => productsContent[key]),
      orderBy(
        [product => ['supplement_core', 'conditioner', 'shampoo'].indexOf(product.slug)],
        ['desc']
      )
    )(Object.keys(productsContent))
);

export const getSkincareProductsPageContent = createSelector(
  getProductsContentWithGiftSetMergedWithFetchedProducts,
  getProductPageContentBySlug,
  (_state, { productSlug }) => productSlug,
  (productsContent, productPageContentBySlug, productSlug) => ({
    ...pick(['faqModule'])(sharedProductPageContent),
    ...omit('imageCarousel')(productPageContentBySlug[productSlug]),
    heroModule: {
      imageCarousel: productPageContentBySlug[productSlug].imageCarousel,
      product: productsContent[productSlug],
      ...pick(['guarantee'])(sharedProductPageContent),
      ...pick(['waitingListNotice'])(sharedProductPageContent),
    },
    sampleFormulationModule: sampleFormulationCards(productSlug),
    noListModule: {
      ...sharedProductPageContent.noListModule,
      ...pick(['description', 'list', 'title'])(productPageContentBySlug[productSlug].noListModule),
    },
    productsModule: {
      headline: sharedProductPageContent.productsModule.skincareHeadline,
      cards: Object.keys(productsContent)
        .filter(
          key => without(skincareMinisSlugs)(skincareProducts).includes(key) && key !== productSlug
        )
        .map(key => productsContent[key]),
    },
    metrics: productPageContentBySlug[productSlug].metrics,
    faq: productPageContentBySlug[productSlug].faq,
    numbersModule: productPageContentBySlug[productSlug].numbersModule,
  })
);

export const getCandleProductPageContent = createSelector(
  getProductsContentWithGiftSetMergedWithFetchedProducts,
  getProductPageContentBySlug,
  (productsContent, productPageContentBySlug) => ({
    ticker: productPageContentBySlug[productsSlugs.CANDLE].ticker,
    fragrancesHero: productPageContentBySlug[productsSlugs.CANDLE].fragrancesHero,
    heroModule: {
      ...productPageContentBySlug[productsSlugs.CANDLE].heroModule,
      product: productsContent[productsSlugs.CANDLE],
      variantsContent: flow(
        sortBy('order'),
        map(variant => ({
          ...variant,
          image: { src: variant.selectorPicture, alt: variant.selectorPictureAlt },
        }))
      )(candle.variantsContent),
    },
    sustainability: productPageContentBySlug[productsSlugs.CANDLE].sustainability,
  })
);

export const getBrushProductPageContent = createSelector(
  getProductsContentWithGiftSetMergedWithFetchedProducts,
  getProductPageContentBySlug,
  (productsContent, productPageContentBySlug) => ({
    ticker: productPageContentBySlug[productsSlugs.BRUSH].ticker,
    makeItYours: productPageContentBySlug[productsSlugs.BRUSH].makeItYours,
    faq: productPageContentBySlug[productsSlugs.BRUSH].faq,
    values: productPageContentBySlug[productsSlugs.BRUSH].values,
    variantsHero: productPageContentBySlug[productsSlugs.BRUSH].variantsHero,
    heroModule: {
      ...productPageContentBySlug[productsSlugs.BRUSH].heroModule,
      product: productsContent[productsSlugs.BRUSH],
      variantsContent: flow(
        sortBy('order'),
        map(variant => ({
          ...variant,
          image: { src: variant.selectorPicture, alt: variant.selectorPictureAlt },
        }))
      )(brush.variantsContent),
    },
    sustainability: productPageContentBySlug[productsSlugs.BRUSH].sustainability,
  })
);

export const getAccessoriesProductPageContent = createSelector(
  getProductsContentWithGiftSetMergedWithFetchedProducts,
  getProductPageContentBySlug,
  (_state, { productSlug }) => productSlug,
  (productsContent, productPageContentBySlug, productSlug) => ({
    sustainability: productPageContentBySlug[productSlug].sustainability,
    ticker: productPageContentBySlug[productSlug].ticker,
    modules: productPageContentBySlug[productSlug].modules,
    faq: productPageContentBySlug[productSlug].faq,
    heroModule: {
      ...productPageContentBySlug[productSlug].heroModule,
      product: productsContent[productSlug],
    },
    productsModule: {
      headline: sharedProductPageContent.productsModule.headline,
      cards: Object.keys(productsContent)
        .filter(
          key => products.includes(key) && !skincareProducts.includes(key) && key !== productSlug
        )
        .map(key => productsContent[key]),
    },
  })
);

/* For product details supplements page. todo: get rid of giftset in it,
and possibly merge it with getProductPageContent */
export const getSupplementsProductPageContent = createSelector(
  getProductsContentWithGiftSetMergedWithFetchedProducts,
  (_state, { productDetails }) => productDetails,
  (productsContent, productDetails) => ({
    ...omit(['heroModule', ''])(productDetails),
    heroModule: {
      imageCarousel: productDetails.heroModule.imageCarousel,
      product: productsContent.supplement_core,
      guarantee: sharedProductPageContent.guarantee,
    },
    faqModule: {
      ...omit(['questions', 'preSupplementsRefillPouchesQuestions'])(productDetails.faqModule),
      questions: productDetails.faqModule.questions,
    },
    noListModule: {
      ...sharedProductPageContent.noListModule,
      ...pick(['description', 'list', 'title'])(productDetails.noListModule),
    },
    productsModule: {
      ...productDetails.productsModule,
      headline: sharedProductPageContent.productsModule.headline,
      cards: pipe(
        pick(products),
        omit([productsSlugs.SUPPLEMENT_CORE, ...skincareMinisSlugs]),
        Object.values
      )(productsContent),
    },
    blogPosts: productDetails.blogPosts,
  })
);
