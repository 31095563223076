import { forwardRef, useState } from 'react';

import { Text, theme } from '@prose-ui';
import { ClassNames, css, cx, legacyTheme, styled } from '@prose-ui/legacy';
import type { EmptyObject } from 'type-fest';

import {
  CHECKOUT_DEFAULT_THEME,
  CHECKOUT_OPTIMISATION_THEME,
} from 'Apps/Checkout/constants/checkoutInputThemes';

import type { StaticImageData } from './Image';

const ImageContainer = styled.div`
  position: absolute;
  right: 18px;
  top: 18px;
`;

const Root = styled.div`
  position: relative;

  padding-bottom: 18px;
  min-width: 80px;

  /* stylelint-disable-next-line selector-class-pattern */
  &.checkoutTheme {
    width: 100%;
    max-width: 300px;
    margin-top: ${legacyTheme.spacing.s8};
    margin-bottom: ${legacyTheme.spacing.s8};
  }

  /* stylelint-disable-next-line selector-class-pattern */
  &.checkoutOptimizationTheme {
    width: 100%;
    margin: 0;
    padding-bottom: 10px;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  &.checkoutOptimizationThemeError {
    padding-bottom: 14px;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  &.maxWidth {
    max-width: 400px;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  &.fullWidth {
    width: 100%;
  }

  &.margin {
    margin-top: ${legacyTheme.spacing.s8};
    margin-bottom: ${legacyTheme.spacing.s8};
  }

  &.disabled {
    opacity: 0.4;
  }
`;

const InputRoot = styled.div`
  position: relative;
  background: ${theme.colors.neutral[100]};
  min-height: 55px;

  &::before {
    pointer-events: none;
    content: '';

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    border: solid 0.5px ${theme.colors.neutral[600]};
    box-shadow: 2px 2px 3px 0 ${theme.colors.neutral[600]};
    mix-blend-mode: multiply;
  }

  &.underline {
    &::after {
      pointer-events: none; /* Transparent to the hover style. */
      /* Doing the other way around crash on IE 11 "''" https://github.com/cssinjs/jss/issues/242 */
      content: '';

      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      transform: scaleX(0);

      border-bottom: 2px solid ${theme.colors.primary[400]};

      transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    }

    &.error::after {
      transform: scaleX(1); /* error is always underlined in red */
      border-bottom-color: ${theme.colors.error[200]};
    }

    &.focused::after {
      transform: scaleX(1);
    }
  }
`;

const Label = styled.label`
  color: ${theme.colors.neutral[800]};
  position: absolute;
  top: 0;
  left: ${theme.spacing['4x']};
  transform-origin: top left;
  transform: translate(0, 1.125em) scale(1);
  pointer-events: none;
  transition:
    color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

  &.shrink {
    transform: translate(0, ${theme.spacing['2x']}) scale(0.75);

    overflow: hidden;
    /* If label is > 1 line, we want to show an ellipsis */
    width: 100%;

    color: ${theme.colors.primary[400]};
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  /* stylelint-disable-next-line selector-class-pattern */
  &.labelError {
    color: ${theme.colors.error[200]};
  }

  /* stylelint-disable-next-line selector-class-pattern */
  &.hiddenLabel {
    display: none;
  }
`;

const CharCount = styled.div`
  position: absolute;
  bottom: 0;
  right: 10px;
  font-size: ${legacyTheme.spacing.s12};
  color: ${theme.colors.neutral[800]};

  &.alert {
    color: ${theme.colors.error[200]};
  }
`;

const InfoMessage = styled.p`
  color: ${theme.colors.primary[400]};
  position: absolute;
  margin: 0;
  left: calc(2 * ${theme.spacing['2x']});
  bottom: -4px;
`;

const ErrorMessage = styled(InfoMessage)`
  color: ${theme.colors.error[200]};
`;

const component = css`
  ${legacyTheme.typography.p1};
  color: ${theme.colors.neutral[800]};
  background: transparent;
  margin-top: 20px;
  width: 100%;
  padding: 4px 16px;
  border: none;
  overflow: hidden;
  white-space: var(--white-space, nowrap);
  text-overflow: ellipsis;
`;

const componentNoLabel = css`
  margin-top: ${legacyTheme.spacing.s16};
`;

type CheckoutOptimisationTheme = typeof CHECKOUT_DEFAULT_THEME | typeof CHECKOUT_OPTIMISATION_THEME;
type ControlsElements = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

type BaseFieldChildrenRenderProps = {
  id: string;
  value?: string;
  name: string;
  className: string;
  onFocus: (event: React.FocusEvent<ControlsElements>) => void;
  onBlur: (event: React.FocusEvent<ControlsElements>) => void;
  onChange?: (event: React.ChangeEvent<any>) => void;
  disabled: boolean;
};

// type BaseFieldChildrenProps<C extends React.ElementType> = BaseFieldChildrenRenderProps &
// Omit<React.ComponentPropsWithoutRef<C>, keyof BaseFieldChildrenRenderProps>;
type BaseFieldChildrenProps<C extends React.ElementType> = PolymorphicComponentProp<
  C,
  BaseFieldChildrenRenderProps
>;

/** WARN: Polymorphic component shenaningan !
 * If eager to learn, have a look at
 * [How to Build Strongly Typed Polymorphic Components](https://www.freecodecamp.org/news/build-strongly-typed-polymorphic-components-with-react-and-typescript)
 */

// INFO: The Polymorphic prop, isolated
type AsProp<C extends React.ElementType> = {
  markupName?: C;
};

// INFO: List props to Omit from the Polymorphic component's own props
type PropsToOmit<C extends React.ElementType, P> = keyof (AsProp<C> & P);

/* INFO: Polymorphic prop joined with the Component own props + the children prop.
 * Those props are omitted of the Polymophic component's props to avoid unintentional leak
 */
type PolymorphicComponentProp<C extends React.ElementType, Props = EmptyObject> = Props &
  AsProp<C> &
  Omit<React.ComponentPropsWithoutRef<C>, PropsToOmit<C, Props>>;

// INFO: Joint of the Polymophic prop + Component's own props + ref prop
type PolymorphicComponentPropWithRef<
  C extends React.ElementType,
  Props = EmptyObject,
> = PolymorphicComponentProp<C, Props> & { ref?: React.ForwardedRef<HTMLDivElement> };

export type BaseFieldProps<C extends React.ElementType> = PolymorphicComponentPropWithRef<
  C,
  {
    /**
     * Enables to overrides the element inner styles.
     * Use with caution.
     */
    classes?: {
      root?: string;
      inputRoot?: string;
      component?: string;
    };
    className?: string;

    /**
     * Function to render the element, will be called with the component props
     */
    children: (props: BaseFieldChildrenProps<C>) => React.ReactNode;
    // children: (props: BaseFieldChildrenRenderProps) => React.ReactNode;

    /**
     * Special disabled state UI
     */
    disabled?: boolean;

    /**
     * Label will be hidden, this is an a11y feature
     */
    hiddenLabel?: boolean;

    /**
     * The id of the children component.
     * Use this property to make `label` and `helperText` accessible for screen readers.
     */
    id: string;

    /**
     * An image on the right of the input.
     */
    image?: {
      src: string | StaticImageData;
      alt: string;
    };

    /**
     * The label content.
     */
    label?: React.ReactNode;

    /**
     * The value of the `Input` element, required for a controlled component.
     */
    value?: string;

    /**
     * The error  message to display under the component.
     * Error message have priority over info.
     */
    error?: string;

    /**
     * The info message to display under the component.
     * Info message are overriden by error message.
     */
    info?: string;

    /**
     * The element will have width 100%.
     * Limited by the maxWidth prop.
     */
    fullWidth?: boolean;

    /**
     * The element will have a max width of 400px.
     * True by default for backward comp purposes.
     * To be deprecated because too much contextual.
     */
    maxWidth?: boolean;

    /**
     * The element will receive a default margin
     * (top and bottom).
     */
    margin?: boolean;

    /**
     * add an UI for characters remaining
     */
    maxCount?: number;
    maxCountAlert?: number;

    onFocus?: BaseFieldChildrenProps<C>['onFocus'];
    onBlur?: BaseFieldChildrenProps<C>['onBlur'];
    onChange?: BaseFieldChildrenProps<C>['onChange'];

    /**
     * Changes the element appearance
     */
    theme?: CheckoutOptimisationTheme;

    /**
     * Controls text wrapping. Non exhaustive list to be updated as needed
     */
    whiteSpace?: 'nowrap' | 'normal';
  }
>;

/* INFO: Polymorphic setup + forwardRef fn is pretty heavy. Without
 * this type annoted to the component variable, props checking
 * wouldn't kick in when using the component.
 */
type BaseFieldComponent = (<C extends React.ElementType = 'input'>(
  props: BaseFieldProps<C>,
) => React.ReactNode) & { displayName?: string | undefined };

/**
 * Currently used a base for all *Field components.
 * Cannot be used alone, have to be extended to be usefull.
 * default max width: **400px**.
 */
export const BaseField: BaseFieldComponent = forwardRef(
  <C extends React.ElementType = 'input'>(
    {
      className,
      classes,
      children,
      disabled = false,
      id,
      image,
      label,
      value,
      error,
      fullWidth = false,
      hiddenLabel = false,
      info,
      margin = false,
      maxCount,
      maxCountAlert = 9,
      maxWidth = true,
      onFocus,
      onBlur,
      theme: variant,
      whiteSpace = 'nowrap',
      markupName: _markupName,
      ...props
    }: BaseFieldProps<C>,
    ref?: React.ForwardedRef<HTMLDivElement>,
  ) => {
    const [focused, setFocused] = useState(false);

    const handleFocus = (event: React.FocusEvent<ControlsElements>) => {
      if (!focused) {
        setFocused(true);
      }
      if (onFocus) {
        onFocus(event);
      }
    };

    const handleBlur = (event: React.FocusEvent<ControlsElements>) => {
      if (focused) {
        setFocused(false);
      }
      if (onBlur) {
        onBlur(event);
      }
    };

    const childrenProps = {
      id,
      value,
      name: id,
      onFocus: handleFocus,
      onBlur: handleBlur,
      disabled,
      ...props,
    } as BaseFieldChildrenProps<C>;

    return (
      <Root
        ref={ref}
        className={cx(
          classes?.root,
          {
            checkoutTheme: variant === CHECKOUT_DEFAULT_THEME,
            maxWidth,
            fullWidth,
            margin,
            disabled,
            checkoutOptimisationTheme: variant === CHECKOUT_OPTIMISATION_THEME,
            checkoutOptimisationThemeError: variant === CHECKOUT_OPTIMISATION_THEME && error,
          },
          className,
        )}
        style={{ '--white-space': whiteSpace }}
      >
        <InputRoot
          className={cx(
            classes?.inputRoot,
            /* INFO: both classes are required for visual styling */
            {
              focused,
              error: Boolean(error),
            },
            'underline',
          )}
        >
          {label && (
            <Label
              className={cx({
                shrink: value || focused,
                labelError: Boolean(error),
                hiddenLabel,
              })}
              htmlFor={id}
            >
              <Text __color="inherit" as="span" variant="body3regular">
                {label}
              </Text>
            </Label>
          )}
          <ClassNames>
            {({ cx, css }) =>
              children({
                ...childrenProps,
                className: cx(
                  css(component),
                  classes?.component,
                  (!label || hiddenLabel) && css(componentNoLabel),
                ),
              })
            }
          </ClassNames>
          {image && (
            <ImageContainer>
              <img
                alt={image.alt}
                src={typeof image.src === 'string' ? image.src : image.src.src}
              />
            </ImageContainer>
          )}
          {maxCount && (
            <CharCount
              className={cx({
                alert: value && maxCount - value.length <= maxCountAlert,
              })}
            >
              {value && maxCount - value.length}
            </CharCount>
          )}
        </InputRoot>
        {error && (
          <ErrorMessage
            aria-live="assertive"
            className={cx({
              errorMessageCheckoutOptimisation: variant === CHECKOUT_OPTIMISATION_THEME,
            })}
            data-testid="field-error"
            role="alert"
          >
            <Text __color="inherit" as="span" variant="body5regular">
              {error}
            </Text>
          </ErrorMessage>
        )}
        {info && !error && (
          <InfoMessage>
            <Text __color="inherit" as="span" variant="body5regular">
              {info}
            </Text>
          </InfoMessage>
        )}
      </Root>
    );
  },
);

BaseField.displayName = 'BaseField';
