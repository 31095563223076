import { createSlice } from '@reduxjs/toolkit';

import flow from 'lodash/fp/flow';
import set from 'lodash/fp/set';

import { userSignout } from 'dux/auth/actions';

import * as supportStatuses from './constants/supportStatuses';
import initialState from './initialState';
import { fetchArticles, fetchCategories, fetchSearch, fetchTags } from './thunks';

const supportSlice = createSlice({
  name: 'support',
  initialState,
  reducers: {
    clearSearch: flow(set('searchStatus', supportStatuses.IDLE), set('fetchedSearch', [])),
    // livechat state
    livechatGenerated: draft => {
      draft.hasGeneratedLivechat = true;
    },
    livechatOpened: draft => {
      draft.hasOpenedLivechat = true;
    },
  },
  extraReducers: builder => {
    builder
      // reinitialize state upon signout
      .addCase(userSignout, () => initialState)

      // fetchCategories
      .addCase(fetchCategories.pending, draft => {
        draft.categoriesStatus = supportStatuses.LOADING;
      })
      .addCase(fetchCategories.fulfilled, (draft, action) => {
        draft.categoriesStatus = supportStatuses.SUCCESS;
        draft.fetchedCategories = action.payload.categories;
      })
      .addCase(fetchCategories.rejected, draft => {
        draft.categoriesStatus = supportStatuses.ERROR;
      })

      // fetchArticles
      .addCase(fetchArticles.pending, draft => {
        draft.articlesStatus = supportStatuses.LOADING;
      })
      .addCase(fetchArticles.fulfilled, (draft, action) => {
        draft.articlesStatus = supportStatuses.SUCCESS;
        draft.fetchedArticles = action.payload.articles;
      })
      .addCase(fetchArticles.rejected, draft => {
        draft.articlesStatus = supportStatuses.ERROR;
      })

      // fetchSearch
      .addCase(fetchSearch.pending, draft => {
        draft.searchStatus = supportStatuses.LOADING;
      })
      .addCase(fetchSearch.fulfilled, (draft, action) => {
        draft.searchStatus = supportStatuses.SUCCESS;
        draft.fetchedSearch = action.payload.search;
      })
      .addCase(fetchSearch.rejected, draft => {
        draft.searchStatus = supportStatuses.ERROR;
      })

      // fetchTags
      .addCase(fetchTags.pending, draft => {
        draft.fetchedTagsStatus = supportStatuses.LOADING;
      })
      .addCase(fetchTags.fulfilled, (draft, action) => {
        draft.fetchedTagsStatus = supportStatuses.SUCCESS;
        draft.fetchedTags = action.payload.fetchedTags;
      })
      .addCase(fetchTags.rejected, draft => {
        draft.fetchedTagsStatus = supportStatuses.ERROR;
      });
  },
});

export const { reducer } = supportSlice;

export const { actions } = supportSlice;
