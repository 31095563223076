import { type ReactNode, useEffect, useRef, useState } from 'react';

import isString from 'lodash/fp/isString';

import { styled } from '@prose-ui/legacy';

import { Image, type StaticImageData } from 'Components/Image';

import { handleKeyPress } from 'utils/a11y';
import getEnv from 'utils/getEnv';
import usePrefersReducedMotion from 'utils/usePrefersReducedMotion';

const videoRoot = getEnv('REACT_APP_MEDIA_ROOT_URL');

import { useAppDispatch } from 'dux/app/hooks';

import { trackHeapEvent } from 'dux/tracking/actions';

const getVideoUrl = (videoName: string) =>
  isString(videoRoot) && Boolean(videoRoot) ? videoRoot + videoName : undefined;

const VideoWrapper = styled.div`
  position: relative;
  height: 100%;
  cursor: pointer;
`;

const StyledVideo = styled.video<{
  fullWidth: boolean;
  fullHeight: boolean;
  fitCover: boolean;
  isPositionAbsolute: boolean;
}>`
  width: ${({ fullWidth }) => fullWidth && '100%'};
  height: ${({ fullHeight }) => fullHeight && '100%'};
  display: block;
  object-fit: ${({ fitCover }) => fitCover && 'cover'};
  position: ${({ isPositionAbsolute }) => isPositionAbsolute && 'absolute'};
`;

const OverlayWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
`;

type VideoProps = {
  autoplay?: boolean;
  autoplayDelay?: number;
  defaultControls?: boolean;
  fitCover?: boolean;
  forcePause?: boolean;
  fullHeight?: boolean;
  fullWidth?: boolean;
  isPositionAbsolute?: boolean;
  loop?: boolean;
  muted?: boolean;
  onPause?: () => void;
  onPlay?: () => void;
  overlay?: {
    playing?: ReactNode;
    paused?: ReactNode;
  };
  posterImage: StaticImageData;
  posterImageAlt: string;
  preload?: 'metadata' | 'none' | 'auto';
  trackingText?: string;
  videoSource: string;
};

export const Video = ({
  autoplay = false,
  autoplayDelay = 0,
  defaultControls = false,
  fitCover = false,
  forcePause = false,
  fullHeight = false,
  fullWidth = false,
  isPositionAbsolute = false,
  loop = false,
  muted = false,
  onPause,
  onPlay,
  overlay,
  posterImage,
  posterImageAlt,
  preload = 'metadata',
  trackingText = '',
  videoSource: videoSrc,
}: VideoProps) => {
  const isReducedMotion = usePrefersReducedMotion();
  const dispatch = useAppDispatch();

  const videoUrl = getVideoUrl(videoSrc);

  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleOnPlay = () => {
    setIsPlaying(true);
    if (trackingText) {
      dispatch(trackHeapEvent(`${trackingText} played`));
    }
    if (onPlay) {
      onPlay();
    }
  };

  const handleOnPause = () => {
    setIsPlaying(false);
    if (trackingText) {
      dispatch(trackHeapEvent(`${trackingText} paused`));
    }
    if (onPause) {
      onPause();
    }
  };

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | null = null;

    if (autoplay) {
      timeoutId = setTimeout(() => videoRef.current?.play(), autoplayDelay);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [autoplay, autoplayDelay]);

  useEffect(() => {
    videoRef?.current?.load();
  }, [videoSrc]);

  useEffect(() => {
    if (forcePause && !(videoRef.current?.paused || videoRef.current?.ended)) {
      videoRef?.current?.pause();
    }
  }, [forcePause]);

  return (
    <VideoWrapper>
      <StyledVideo
        ref={videoRef}
        autoPlay={!isReducedMotion && autoplay && autoplayDelay <= 0}
        controls={defaultControls ? true : undefined}
        disablePictureInPicture
        fitCover={fitCover}
        fullHeight={fullHeight}
        fullWidth={fullWidth}
        isPositionAbsolute={isPositionAbsolute}
        loop={loop}
        muted={muted}
        onKeyPress={handleKeyPress(handleOnPlay)}
        onPause={handleOnPause}
        onPlay={handleOnPlay}
        playsInline
        poster={posterImage.src}
        preload={preload}
        tabIndex={isPlaying ? 0 : -1}
      >
        {!isReducedMotion && (
          <>
            <source src={videoUrl && `${videoUrl}.mp4`} type="video/mp4" />
            <source src={videoUrl && `${videoUrl}.webm`} type="video/webm" />
          </>
        )}

        <Image alt={posterImageAlt} src={posterImage} />
        {posterImageAlt}
      </StyledVideo>

      {overlay?.playing && isPlaying && (
        <OverlayWrapper
          onClick={() => videoRef?.current?.pause()}
          onKeyPress={handleKeyPress(() => videoRef.current?.pause())}
        >
          {overlay.playing}
        </OverlayWrapper>
      )}
      {overlay?.paused && !isPlaying && !videoRef.current?.seeking && (
        <OverlayWrapper
          onClick={() => videoRef?.current?.play()}
          onKeyPress={handleKeyPress(() => videoRef.current?.play())}
        >
          {overlay.paused}
        </OverlayWrapper>
      )}
    </VideoWrapper>
  );
};
